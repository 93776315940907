import { removeLoader, showAlert, showLoader } from 'deskera-ui-library';
import { isEmpty, uniqBy } from 'lodash';
import {
  BOOKS_DATE_FORMAT,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  JWO_BILLED_STATUS,
  JWO_DISPATCH_STATUS,
  JWO_RECEIPT_STATUS,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  RECORD_SAVED_EVENT_DOC_TYPE,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { COLUMN_CODE } from '../../../Constants/TableConstants';
import { Invoice } from '../../../Models/Invoice';
import { Store } from '../../../Redux/Store';
import DateFormatService from '../../../Services/DateFormat';
import MRPProductsService from '../../../Services/MRP/MRPProducts';
import OperationsService from '../../../Services/MRP/Operations';
import WorkOrderService, {
  IWorkOrder,
  IWorkOrderItems,
  getSOByProductCode
} from '../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../Services/NumberFormat';
import ProductService from '../../../Services/Product';
import {
  ISalesOrder,
  SalesOrderItemsEntity
} from '../../../Services/SalesOrder';
import {
  COMMON_EVENTS,
  commonCustomEvent
} from '../../../Services/event/commonEvents';
import WarehouseManagementHelper from '../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import Utility, {
  deepClone,
  getCapitalized,
  getRandomNumber,
  reduceArrayByKey
} from '../../../Utility/Utility';
import { WORK_ORDER_PR_PO } from '../../Settings/AdvancedSettings/AdvancedSettings';
import {
  calculateReservedQuantitiesDataForSubstitutes,
  filterLinkedLineItems
} from '../BomExplosion/BOMExplosionHelper';
import {
  BOM_EXPLOSION_COLUMN_KEYS,
  JOB_CARD_STATUS,
  WORK_ORDER_COLS,
  WORK_ORDER_STATUS
} from '../Constants/MRPColumnConfigs';
import { PROCESS_QC_STATUS } from '../Constants/MRPEnums';
import {
  REQUIRED_ITEM_TABLE,
  WORK_ORDER_OPERATION_TABLE,
  WO_MASTER_GRID_KEYS
} from '../Constants/TableConstant';
import { getAttachmentIdListFromAttachmentResponse } from './InnerComponents/WOAttachments/WOAttachmentHelper';
import RawMaterialHelper from './InnerComponents/BomMaterialComponent/RawMaterialHelper';

export class WorkOrderHelper {
  static parseFlatArrayOfWarehouseInventoryData(
    rowData: any,
    advancedTrackingType: ADVANCE_TRACKING
  ) {
    // warehouseInventoryFlatArray with substitute
    let warehouseInventoryFlatArray: any =
      rowData?.warehouseInventoryData?.map((wiDItem: any) => {
        return {
          ...wiDItem,
          productName: rowData?.itemName?.name ?? ''
        };
      }) ?? [];
    rowData?.bomProductSubstitutesDetails?.forEach((element: any) => {
      if (!Utility.isEmpty(element?.warehouseInventoryData)) {
        warehouseInventoryFlatArray = [
          ...warehouseInventoryFlatArray,
          ...(element?.warehouseInventoryData?.map((wiDItem: any) => {
            return {
              ...wiDItem,
              productName: rowData?.itemName?.name ?? ''
            };
          }) ?? [])
        ];
      }
    });
    const codesSet: any = new Set(
      warehouseInventoryFlatArray.map((item: any) => item.warehouseCode)
    );
    const uniquesWarehouseCodes = [...codesSet];
    const warehouseInventoryData = uniquesWarehouseCodes.map(
      (warehouseCode: any) => {
        let advancedTrackingData: any[] = [];
        let totalQuantity: number = 0;
        let productToQuantity: any = {};

        const existingWareHouseTrackingData =
          warehouseInventoryFlatArray?.filter(
            (item: any) => item.warehouseCode === warehouseCode
          );

        if (!Utility.isEmpty(existingWareHouseTrackingData)) {
          advancedTrackingData = existingWareHouseTrackingData?.reduce(
            (previous: any[], current: any) => [
              ...previous,
              ...(current?.advancedTrackingData ?? [])
            ],
            []
          );
        }
        if (advancedTrackingType === ADVANCE_TRACKING.NONE) {
          existingWareHouseTrackingData?.forEach((internal: any) => {
            totalQuantity += internal?.quantity;
            productToQuantity[internal.productName] = internal?.quantity;
          });
        } else {
          advancedTrackingData.forEach((item: any) => {
            if (item?.qtyToFulfil) {
              totalQuantity += Number(item?.qtyToFulfil);
            } else {
              totalQuantity = 0;
            }
          });
        }

        return {
          advancedTrackingData: advancedTrackingData,
          quantity: totalQuantity,
          productToQuantity: productToQuantity,
          warehouseCode: warehouseCode,
          warehouseName: existingWareHouseTrackingData?.[0].warehouseName ?? ''
        };
      }
    );
    return warehouseInventoryData;
  }

  static parseWarehouseInventoryDataInNewFormat(
    warehouseInventoryData: any,
    trackingType: ADVANCE_TRACKING
  ) {
    if (trackingType === ADVANCE_TRACKING.NONE) {
      const newWarehouseInventoryData = warehouseInventoryData.map(
        (warehouseItem: any) => {
          let { warehouse, row, rack, bin, ...newWarehouseItem } =
            warehouseItem;

          newWarehouseItem = {
            ...newWarehouseItem,
            uomQuantity: newWarehouseItem?.quantity,
            qtyToFulfilUom: newWarehouseItem?.qtyToFulfil
          };

          return newWarehouseItem;
        }
      );
      return newWarehouseInventoryData;
    }

    let result: any = [];
    warehouseInventoryData?.forEach((warehouseInventoryObject: any) => {
      warehouseInventoryObject?.advancedTrackingData?.forEach(
        (advanceTrackingObj: any) => {
          let copyAdv = {
            ...advanceTrackingObj,
            qtyToFulfilUom: advanceTrackingObj?.qtyToFulfil
          };
          let object = {
            advancedTrackingData: [copyAdv],
            id: null,
            quantity:
              trackingType === ADVANCE_TRACKING.BATCH
                ? advanceTrackingObj?.qtyToFulfil ?? 0
                : warehouseInventoryObject?.quantity ?? 0,
            uomQuantity:
              trackingType === ADVANCE_TRACKING.BATCH
                ? advanceTrackingObj?.qtyToFulfil ?? 0
                : warehouseInventoryObject?.quantity ?? 0,
            serialBatchNumber: advanceTrackingObj?.serialBatchNumber,
            qtyToFulfil:
              trackingType === ADVANCE_TRACKING.BATCH
                ? advanceTrackingObj?.qtyToFulfil ?? 0
                : warehouseInventoryObject?.quantity,
            qtyToFulfilUom:
              trackingType === ADVANCE_TRACKING.BATCH
                ? advanceTrackingObj?.qtyToFulfil ?? 0
                : warehouseInventoryObject?.quantity,
            manufacturingDate: advanceTrackingObj?.manufacturingDate,
            expiryDate: advanceTrackingObj?.expiryDate,
            costPerUnit: advanceTrackingObj?.costPerUnit,
            rackCode: advanceTrackingObj?.rackCode ?? null,
            rowCode: advanceTrackingObj?.rowCode ?? null,
            binCode: advanceTrackingObj?.binCode ?? null,
            warehouseName: advanceTrackingObj?.warehouseName,
            warehouseCode: advanceTrackingObj?.warehouseCode,
            advancedTrackingType: trackingType
          };
          result.push(object);
        }
      );
    });
    return result;
  }

  static isRowVisible = (data: any) => {
    return (
      data?.produceProductType !== PRODUCE_PRODUCT_TYPE.SCRAP &&
      data?.produceProductType !== PRODUCE_PRODUCT_TYPE.CO_PRODUCT
    );
  };

  static getCurrentWOWithoutScrapItems = (activeBOMProduct: any) => {
    return {
      ...activeBOMProduct,
      workOrderItems:
        activeBOMProduct?.workOrderItems?.filter((woItem: any) => {
          return (
            woItem.produceProductType !== PRODUCE_PRODUCT_TYPE.SCRAP &&
            woItem.produceProductType !== PRODUCE_PRODUCT_TYPE.CO_PRODUCT
          );
        }) ?? []
    };
  };

  static isPlannedStartDateReadOnly = (woDetails: any) => {
    let readOnly = false;

    if (
      woDetails?.totalInProgressJobCards > 0 ||
      woDetails?.totalCompletedJobCards > 0
    ) {
      readOnly = true;
    }

    return readOnly;
  };

  static getHighestPlannedEndDate = (data: any) => {
    let highestPlannedEndDate = null;

    for (let i = 0; i < data.length; i++) {
      const plannedEndDate = new Date(data[i].plannedEndDate);

      if (!highestPlannedEndDate || plannedEndDate > highestPlannedEndDate) {
        highestPlannedEndDate = plannedEndDate;
      }
    }

    return highestPlannedEndDate;
  };

  /**
   * @deprecated depricated for refactoring
   */
  static WO_CARD_NAME = {
    MATERIAL_COST: 'Material Cost',
    OPERATION_COST: 'Operation Cost',
    OPERATOR_COST: 'Operator Cost',
    WORKSTATION_COST: 'Workstation Cost',
    JWO_COST: 'JWO Cost',
    TOTAL_COST: 'Total Cost',
    QUANTITY: 'Quantity',
    YIELD: 'Yield'
  };

  static woCount: number = 1;
  static getDummyDataForLO = () => {
    let woData = [];
    for (let i = 0; i < 2; i++) {
      woData.push({
        productCode: `P-000000${WorkOrderHelper.woCount}`,
        workOrderCode: `000000${WorkOrderHelper.woCount}`,
        workOrderSeqCode: `WO-000000${WorkOrderHelper.woCount}`
      });
      WorkOrderHelper.woCount++;
    }

    let linkedDocuments = [
      {
        documentType: 'PURCHASE_ORDER',
        documentCode: '0000125',
        documentSequenceCode: `O-000000${WorkOrderHelper.woCount}`,
        documentCreatedDate: null,
        documentReceiveByDate: '24-08-2023',
        productCodes: ['P-0000502', 'P-0000466']
      }
    ];

    let jwo = [
      {
        id: 5251,
        status: 'COMPLETED',
        documentSequenceCode: 'JC-0000475',
        jobCardCode: 'JC-0000470',
        billedStatus: 'PENDING',
        dispatchStatus: 'PENDING_DISPATCH',
        receiptStatus: 'UNRECEIVED'
      },
      {
        id: 5252,
        documentSequenceCode: 'JC-0000476',
        sequenceFormat: null,
        jobCardCode: 'JC-0000471',
        status: 'COMPLETED',
        billedStatus: 'PENDING',
        dispatchStatus: 'PENDING_DISPATCH',
        receiptStatus: 'UNRECEIVED'
      }
    ];
    return { workOrderChildDetails: woData, linkedDocuments, jwo };
  };

  static WORK_ORDER_BTN_STATUS: any = {
    COMPLETE_ORDER: 'Complete Order',
    START_ORDER: 'Start Order',
    STOP_ORDER: 'Stop Order',
    RESUME_ORDER: 'Resume Order'
  };

  static WO_Linked_Documents = {};

  static getWOLinkedDocuments = () => {
    return WorkOrderHelper.WO_Linked_Documents;
  };

  static setWOLinkedDocuments = (data: any) => {
    WorkOrderHelper.WO_Linked_Documents = data;
  };

  static WO_Linked_Documents_Grid_Data = {};

  static getWOLinkedDocumentsGridData = () => {
    return WorkOrderHelper.WO_Linked_Documents_Grid_Data;
  };

  static setWOLinkedDocumentsGridData = (data: any) => {
    WorkOrderHelper.WO_Linked_Documents_Grid_Data = data;
  };

  static getOperationRow(
    workOrderData: any,
    workOrderOperation: any,
    operationDetailsObject: any,
    woOperationIndex: number,
    isEditMode: boolean,
    refreshCost = false,
    operatorsListResponse: any[] = []
  ) {
    let operatorsForWoOperation: any[] = [];
    let allOperatorsForWorkOrder: any[] = [];
    let totalOperationCost = getOperationCost(
      operationDetailsObject,
      workOrderData.manufactureQuantity
    );
    const operatorListResponse =
      Store.getState().mrpOperators.data?.operators?.content || [];

    if (!Utility.isEmpty(operationDetailsObject?.operators)) {
      operationDetailsObject.operators.forEach((operator: any) => {
        let selectedOperatorData = operator
          ? operatorListResponse.find(
              (operatorResponse: any) =>
                operatorResponse.id === operator.operatorId
            )
          : null;

        if (Utility.isEmpty(selectedOperatorData)) {
          selectedOperatorData = operator
            ? operatorsListResponse.find(
                (operatorResponse: any) =>
                  operatorResponse.id === operator.operatorId
              )
            : null;
        }

        if (!Utility.isEmpty(selectedOperatorData)) {
          allOperatorsForWorkOrder.push({
            ...selectedOperatorData,
            operationTime: operationDetailsObject?.operationTime || 0,
            operationId: operationDetailsObject?.id
          });
          operatorsForWoOperation.push({
            ...selectedOperatorData,
            operationTime: operationDetailsObject?.operationTime || 0,
            operationId: operationDetailsObject?.id
          });
        }
      });
    }
    let operationRow = {
      [WORK_ORDER_OPERATION_TABLE.OPERATION_NAME]: {
        ...operationDetailsObject,
        operators: operatorsForWoOperation,
        workstationDetails: operationDetailsObject?.workstationDetails
      },
      [WORK_ORDER_OPERATION_TABLE.OPERATION_TIME]:
        (operationDetailsObject?.operationTime ?? 0) *
        workOrderData.manufactureQuantity,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_STATUS]: isEditMode
        ? workOrderOperation.status
          ? [workOrderOperation.status]
          : ['PENDING']
        : ['PENDING'],
      [WORK_ORDER_OPERATION_TABLE.OPERATION_COST]: isEditMode
        ? refreshCost
          ? totalOperationCost
          : workOrderOperation?.operationCost
        : totalOperationCost,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_OPERATORS_COST]: isEditMode
        ? refreshCost
          ? getOperatorCostForOperation(
              operationDetailsObject?.operationTime,
              operatorsForWoOperation,
              workOrderData.manufactureQuantity
            )
          : workOrderOperation?.operatorCost
        : getOperatorCostForOperation(
            operationDetailsObject?.operationTime,
            operatorsForWoOperation,
            workOrderData.manufactureQuantity
          ),
      [WORK_ORDER_OPERATION_TABLE.OPERATION_COMPLETED_QTY]: isEditMode
        ? workOrderOperation.completedQuantity
        : 0,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_DESCRIPTION]: isEditMode
        ? workOrderOperation.description
        : operationDetailsObject?.description,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_INDEX]: woOperationIndex + 1,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_WO_CODE]:
        workOrderOperation.workOrderOperationCode,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_DEPENDENCY]:
        workOrderOperation.workOrderOperationCode
          ? undefined
          : workOrderOperation.operationDependency,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_PROCESS_TYPE]:
        workOrderOperation.processType || null,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM]:
        workOrderOperation.productCode || workOrderOperation.product || null,
      [WORK_ORDER_OPERATION_TABLE.OPERATION_QC_NEEDED]:
        workOrderOperation.qcNeeded || false,
      [WORK_ORDER_OPERATION_TABLE.LINKEDIN_MACHINES]:
        operationDetailsObject.linkedInMachines || []
    };
    operationRow.workstationCost = isEditMode
      ? refreshCost
        ? getWorkstationCost(operationRow, workOrderData.manufactureQuantity)
        : workOrderOperation?.workstationCost
      : getWorkstationCost(operationRow, workOrderData.manufactureQuantity);
    operationRow.callingOperationDetails = false;
    return operationRow;
  }

  static getRawMaterialRow(
    workOrderData: any,
    workOrderItem: any,
    selectedComponentProductShortInfo: any,
    componentProductShortInfoList: any,
    woItemIndex: number,
    isEditMode: boolean
  ) {
    const warehouseData = Store.getState().warehouse.data?.content || [];
    let selectedWareHouse = {};
    if (isEditMode && workOrderItem?.sourceWarehouseCode) {
      selectedWareHouse = warehouseData?.find(
        (warehouse: any) =>
          warehouse.code === workOrderItem?.sourceWarehouseCode
      );
    } else {
      selectedWareHouse = warehouseData?.find(
        (warehouse: any) =>
          warehouse.code === selectedComponentProductShortInfo?.warehouseCode
      );
    }
    let invalidFields = [];
    if (selectedComponentProductShortInfo) {
      let unitRequiredQty =
        workOrderItem?.uomQuantity ||
        workOrderItem.componentProductUnitQty ||
        workOrderItem.quantity ||
        0;
      let requiredQuantity =
        unitRequiredQty * (workOrderData.manufactureQuantity || 0);

      const availableQuantityWithUOM =
        selectedComponentProductShortInfo.availableUomQuantity ??
        selectedComponentProductShortInfo.availableQuantity;
      const reservedQuantityWithUOM =
        selectedComponentProductShortInfo.reservedUomQuantity ??
        selectedComponentProductShortInfo.reservedQuantity ??
        0;
      let availableQuantity =
        availableQuantityWithUOM - reservedQuantityWithUOM;
      if (isEditMode) {
        requiredQuantity =
          workOrderItem?.plannedUomQuantity ||
          workOrderItem.plannedQuantity ||
          0;
      }
      if (requiredQuantity > availableQuantity) {
        invalidFields.push(REQUIRED_ITEM_TABLE.AVAILABLE_QTY);
      }
      if (!selectedComponentProductShortInfo?.productId) {
        selectedComponentProductShortInfo.productId =
          selectedComponentProductShortInfo.pid;
      }
      let selectedProductSubstitutes = getSubstitutesArray(
        workOrderData.product,
        workOrderItem,
        componentProductShortInfoList,
        isEditMode
      );
      let warehouseInventoryData = deepClone(
        workOrderItem?.warehouseInventoryData || []
      );
      warehouseInventoryData = warehouseInventoryData?.map(
        (inventoryItem: any) => {
          if (inventoryItem?.advancedTrackingData) {
            const advancedTrackingData =
              inventoryItem.advancedTrackingData || [];
            inventoryItem.advancedTrackingData = advancedTrackingData.map(
              (item: any) => ({
                ...item,
                qtyToFulfil:
                  selectedComponentProductShortInfo?.advancedTracking ===
                  ADVANCE_TRACKING.SERIAL
                    ? item.qtyToFulfil
                    : item.qtyToFulfilUom ?? item.qtyToFulfil,
                existingReserveQty: item['qtyToFulfil'] || 0
              })
            );
          }
          inventoryItem = {
            ...inventoryItem,
            row: {
              rowCode: inventoryItem?.rowCode,
              rowName: inventoryItem?.rowName
            },
            rack: {
              rackCode: inventoryItem?.rackCode,
              rackName: inventoryItem?.rackName
            },
            bin: {
              binCode: inventoryItem?.binCode,
              binName: inventoryItem?.binName
            },
            quantity:
              selectedComponentProductShortInfo?.advancedTracking ===
              ADVANCE_TRACKING.SERIAL
                ? inventoryItem.quantity
                : inventoryItem.uomQuantity ?? inventoryItem.quantity
          };
          return inventoryItem;
        }
      );
      return {
        invalidFields,
        productCode:
          selectedComponentProductShortInfo?.pid ??
          workOrderItem.productDetails?.productId,
        [REQUIRED_ITEM_TABLE.ITEM_NAME]: selectedComponentProductShortInfo,
        [REQUIRED_ITEM_TABLE.SOURCE_WAREHOUSE]: selectedWareHouse || {},
        [REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS]:
          selectedProductSubstitutes,
        [REQUIRED_ITEM_TABLE.WAREHOUSE_INVENTORY_DATA]: warehouseInventoryData,
        [REQUIRED_ITEM_TABLE.AVAILABLE_QTY]: availableQuantity,
        [REQUIRED_ITEM_TABLE.ACTUAL_REQUIRED_QTY]:
          workOrderData?.status === WORK_ORDER_STATUS.COMPLETED
            ? Utility.roundOff(
                workOrderItem.producedQuantity,
                QTY_ROUNDOFF_PRECISION
              )
            : Utility.roundOff(requiredQuantity, QTY_ROUNDOFF_PRECISION),
        [REQUIRED_ITEM_TABLE.PRODUCED_QUANTITY]:
          workOrderItem.producedQuantity ?? 0,
        [REQUIRED_ITEM_TABLE.REQUIRED_QTY]: Utility.roundOff(
          requiredQuantity,
          QTY_ROUNDOFF_PRECISION
        ),
        componentProductUnitQty: unitRequiredQty,
        [REQUIRED_ITEM_TABLE.STOCK_UOM]: workOrderItem.stockUom,
        documentUOMSchemaDefinition: workOrderItem?.documentUOMSchemaDefinition,
        [REQUIRED_ITEM_TABLE.PRODUCE_PRODUCT_TYPE]:
          workOrderItem?.produceProductType,
        [REQUIRED_ITEM_TABLE.INNER_BOM_PRODUCT_DETAILS]:
          workOrderItem?.innerBomProductDetails,
        reservedQuantitiesData: workOrderItem?.reservedQuantitiesData,
        costPerUnit:
          (workOrderData.status === WORK_ORDER_STATUS.COMPLETED ||
          selectedComponentProductShortInfo?.type === PRODUCT_TYPE.NON_TRACKED
            ? workOrderItem.costPerUnit
            : selectedComponentProductShortInfo?.inventoryPrice) ?? 0,
        workOrderItemCode: workOrderItem.workOrderItemCode,
        addToRequisition: workOrderItem.addToRequisition ?? false,
        sequenceNumber: workOrderItem.sequenceNumber ?? woItemIndex,
        productDetails: workOrderItem.productDetails,
        bomMetaCode: workOrderItem.bomMetaCode,
        isNewRow: workOrderItem.isNewRow,
        isAdHocItem: workOrderItem.isAdHocItem
      };
    }
    return {};
  }

  static async getProductShortInfoFor(shortInfoRequest: any) {
    try {
      let result = await MRPProductsService.getProductShortInfo(
        shortInfoRequest
      );
      return result;
    } catch (error: any) {
      return {};
    }
  }

  static async getOperationDetailsById(idsArray: any) {
    try {
      let result = await OperationsService.getOperationsById(idsArray);
      return result;
    } catch (error: any) {
      return {};
    }
  }

  static deductConvertedQuantityForSOtoWOFlow = (salesOrder: any) => {
    let newSalesOrder = { ...salesOrder };
    newSalesOrder.salesOrderItems = newSalesOrder?.salesOrderItems?.map(
      (soItem: any) => {
        return {
          ...soItem,
          productQuantity: soItem?.productQuantity - soItem?.qtyConvertedToWo
        };
      }
    );
    newSalesOrder.salesOrderItems = newSalesOrder?.salesOrderItems?.filter(
      (item: any) => item.productQuantity
    );
    return newSalesOrder;
  };

  static soHasRemainingQtyToBuild = (salesOrder: any) => {
    let hasItemToBuild = salesOrder?.salesOrderItems?.some(
      (soItem: any) => soItem.productQuantity - soItem.qtyConvertedToWo
    );
    return hasItemToBuild;
  };

  static isSOConvertedFromWorkOrder = (salesOrder: any) => {
    let hasItemToBuild = salesOrder?.linkedWorkOrderDocuments?.some(
      (soItem: any) => soItem.isConvertedFromWorkOrder
    );
    return hasItemToBuild;
  };
}

export enum FOCUS_FIELD_KEY {
  ACTUAL_OPERATING_COST = 'actual_operating_cost'
}

export const amountFormatter = (amount: number, currency: any) =>
  `${Utility.getCurrencySymbolFromCode(currency)} ${
    amount < 0 ? '(' : ''
  }${NumberFormatService.getNumber(Math.abs(amount))}${amount < 0 ? ')' : ''}`;

export const getPercentageColor = (val: number) => {
  switch (val) {
    case 0:
      return 'bg-red';

    case 100:
      return 'bg-green';

    default:
      return 'bg-orange';
  }
};

export const isProductAlreadyExisting = (workOrderItems: any, obj: any) => {
  const found = workOrderItems?.find((item: any) => {
    return item.productId === obj.productId;
  });

  return !Utility.isEmpty(found);
};

/**
 *
 * @param items @deprecated remove after refactoring
 * @returns
 */
export const getMaterialEstimatedCost = (items: any) => {
  let sumFromSalesPrice = 0;
  sumFromSalesPrice = items?.workOrderItems?.reduce(
    (accumulator: any, object: any) => {
      if (object?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE) {
        let itemCost = 0;
        if (items.status === WORK_ORDER_STATUS.COMPLETED) {
          itemCost = Number(object.costPerUnit);
        } else {
          itemCost = Number(object?.itemName?.inventoryPrice);
        }
        if (object?.itemName?.advancedTracking === TRACKING_TYPE.NONE) {
          return (
            Number(accumulator) + itemCost * parseFloat(object?.requiredQty)
          );
        } else {
          return (
            Number(accumulator) + itemCost * parseFloat(object?.requiredQty)
          );
        }
      } else {
        return accumulator;
      }
    },
    0
  );
  return sumFromSalesPrice;
};

export const getMaterialActualCost = (workOrderData: any) => {
  let sumFromSalesPrice = 0;
  sumFromSalesPrice = (workOrderData?.workOrderItems || []).reduce(
    (accumulator: number, workOrderItem: any) =>
      accumulator +
      getTotalCostOfLineItem(
        workOrderItem,
        workOrderData?.status,
        workOrderData?.manufactureQuantity
      ),
    0
  );
  return sumFromSalesPrice;
};

//operation cost

//Expecting single operation object and manufacturing quantity
export const getOperationCost = (
  operation: any,
  manufactureQuantity: number
) => {
  let timeInHour =
    Utility.calculateMinutesInHours(operation?.operationTime ?? 0) *
    manufactureQuantity;
  let quantity = manufactureQuantity || 1;
  let totalOperationCost = (operation?.costPerHour ?? 0) * timeInHour;
  totalOperationCost = (operation?.fixedRate ?? 0) + totalOperationCost;
  return totalOperationCost;
};

/**
 * @deprecated remove after refactoring
 */
export const getEstimatedOperationCost = (items: any) => {
  let sumFromOperationCost = items?.workOrderOperations?.reduce(
    (accumulator: any, object: any) => {
      return (
        (isNaN(accumulator) ? 0 : accumulator) +
        Number(
          Math.abs(
            getOperationCost(object?.operationName, items.manufactureQuantity)
          )
        )
      );
    },
    0
  );
  return sumFromOperationCost;
};

/**
 * @deprecated remove after refactoring renamed to getTotalAdditionalCharges in WOConstingHelper.tsx
 */
export const getEstimatedAdditionalCharges = (wo: any) => {
  const totalAdditionalCost =
    wo?.additionalCharges?.additionalChargeAmount || 0;
  return totalAdditionalCost;
};

export const getActualOperationCost = (items: any, jobCardsData: any) => {
  let sumFromOperationCost = items?.workOrderOperations?.reduce(
    (accumulator: any, object: any) => {
      let actualOperationTimeFromJC: number =
        jobCardsData?.[object?.operationName?.id]?.actualTime ||
        object?.operationName?.operationTime;
      let operationObject = {
        ...object?.operationName,
        operationTime: actualOperationTimeFromJC
      };
      let operationCost = getOperationCost(
        {
          ...operationObject,
          operationTime: actualOperationTimeFromJC
        },
        items.manufactureQuantity
      );
      return accumulator + Number(operationCost);
    },
    0
  );

  return sumFromOperationCost * Number(items?.actualQuantity ?? 1);
};

export const getActualOperationCostFromJC = (items: any, jobCardsData: any) => {
  let sumFromOperationCost = jobCardsData?.reduce(
    (accumulator: any, object: any) => {
      return (
        accumulator + Number(object?.operationCostDetails?.actualCost || 0)
      );
    },
    0
  );
  return sumFromOperationCost;
};

// Operator Estimated Cost

export const getOperatorCostForOperation = (
  operationTime: number,
  operators: any[],
  manufacturingQuantity: number
) => {
  let operationTimeInHour = Utility.calculateMinutesInHours(operationTime);
  let quantity = Utility.roundOffToTenantDecimalScale(manufacturingQuantity);
  let sumFromOperatorCost: number = 0;

  operators?.forEach((operator: any) => {
    sumFromOperatorCost =
      sumFromOperatorCost +
      Number(operator?.costPerHour) * operationTimeInHour * quantity +
      Number(operator?.fixedRate);
  });

  return Number(sumFromOperatorCost);
};

/**
 * @deprecated remove after refactoring
 */
export const getEstimatedOperatorCost = (
  items: any,
  manufactureQuantity = 1
) => {
  let sumFromOperatorCost: number = 0;

  items?.forEach((operator: any) => {
    sumFromOperatorCost =
      sumFromOperatorCost +
      Number(operator?.costPerHour) *
        Utility.calculateMinutesInHours(operator?.operationTime) +
      Number(operator?.fixedRate);
  });

  return sumFromOperatorCost * Number(manufactureQuantity);
};

// Operator Actual Cost
export const getActualOperatorCost = (items: any, manufactureQuantity = 1) => {
  let sumFromOperatorCost: number = 0;

  items?.forEach((operator: any) => {
    sumFromOperatorCost =
      sumFromOperatorCost +
      Number(operator?.costPerHour) *
        Utility.calculateMinutesInHours(operator?.operationTime) +
      Number(operator?.fixedRate);
  });

  return sumFromOperatorCost * Number(manufactureQuantity);
};

export const getActualOperatorCostFromJC = (items: any, jobCardsData: any) => {
  let sumFromOperatorCost = jobCardsData?.reduce(
    (accumulator: any, object: any) => {
      return accumulator + Number(object?.operatorCostDetails?.actualCost || 0);
    },
    0
  );
  return sumFromOperatorCost;
};
// Workstation Cost
export const getWorkstationCost = (operation: any, manufactureQuantity = 1) => {
  let operationTime: number = Number(operation?.operationTime) / 60;
  let workstationObject = operation?.operationName?.workstationDetails;
  let workstationCost: number =
    Number(workstationObject?.electricityCost) +
    Number(workstationObject?.consumableCost) +
    Number(workstationObject?.rentCost);
  workstationCost = workstationCost * operationTime;
  return workstationCost || 0;
};

// ZEN-14133: need to take updated workstation from JC instead of operation that has old workstation
export const getTotalWorkstationCost = (
  workOrderItem: any,
  jobCards: any[]
) => {
  let cost = 0;
  jobCards?.forEach((element: any) => {
    let workstationObject = element?.workstationDetails;
    cost =
      cost +
      (Number(workstationObject?.electricityCost) +
        Number(workstationObject?.consumableCost) +
        Number(workstationObject?.rentCost)) *
        (Number(element?.plannedTime || 0) / 60);
  });
  return cost || 0;
};

export const getFilteredSO = (data: any[]) => {
  const filteredData = data?.filter((item: any) => {
    let soConvertedFromdWO = WorkOrderHelper.isSOConvertedFromWorkOrder(item);
    let hasItemToBuild = item?.salesOrderItems?.some((soItem: any) => {
      return soItem.productQuantity - soItem.qtyConvertedToWo;
    });
    return (
      (item.fulfillmentStatus !== FULFILLMENT_STATUS.FULLY_FULFILLED ||
        item.status !== DOCUMENT_STATUS.PROCESSED) &&
      hasItemToBuild &&
      !soConvertedFromdWO
    );
  });
  return filteredData;
};

export const getFilteredInvoices = (data: any[]) => {
  const filteredData = data?.filter((item: any) => {
    const atLeastOneLinkedDocIsWO = item?.linkedDocuments?.some(
      (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
    );
    return (
      item.fulfillmentStatus !== FULFILLMENT_STATUS.FULLY_FULFILLED &&
      (Utility.isEmpty(item?.linkedDocuments) || !atLeastOneLinkedDocIsWO)
    );
  });
  return filteredData;
};

export const isTrackingInfoAvailable = (woDetails: any) => {
  let sum = 0;
  let isRRBInfoEmpty = false;
  if (woDetails?.product?.advancedTracking === ADVANCE_TRACKING.NONE) {
    sum = woDetails?.warehouseInventoryData?.reduce(
      (accumulator: any, object: any) => {
        return accumulator + Number(object.quantity);
      },
      0
    );
  } else {
    sum = woDetails?.advancedTrackingData?.reduce(
      (accumulator: any, object: any) => {
        return accumulator + Number(object.qtyToFulfil);
      },
      0
    );
  }
  if (woDetails?.product?.advancedTracking === ADVANCE_TRACKING.NONE) {
    if (
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        woDetails?.targetWarehouse
      )
    ) {
      if (Utility.isNotEmpty(woDetails?.targetWarehouse?.warehouseRowInfos)) {
        isRRBInfoEmpty = Utility.isEmpty(
          woDetails.warehouseInventoryData?.[0]?.rowCode
        )
          ? true
          : false;
      }
    }
    if (
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        woDetails?.targetWarehouse
      )
    ) {
      if (Utility.isNotEmpty(woDetails?.targetWarehouse?.warehouseRackInfos)) {
        isRRBInfoEmpty = Utility.isEmpty(
          woDetails.warehouseInventoryData?.[0]?.rackCode
        )
          ? true
          : false;
      }
    }
    if (
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        woDetails?.targetWarehouse
      )
    ) {
      if (Utility.isNotEmpty(woDetails?.targetWarehouse?.warehouseBinInfos)) {
        isRRBInfoEmpty = Utility.isEmpty(
          woDetails.warehouseInventoryData?.[0]?.binCode
        )
          ? true
          : false;
      }
    }
  } else {
    if (
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        woDetails?.targetWarehouse
      )
    ) {
      if (Utility.isNotEmpty(woDetails?.targetWarehouse?.warehouseRowInfos)) {
        isRRBInfoEmpty = woDetails.advancedTrackingData?.some((ele: any) =>
          Utility.isEmpty(ele?.rowCode)
        );
      }
    }
    if (
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        woDetails?.targetWarehouse
      )
    ) {
      if (Utility.isNotEmpty(woDetails?.targetWarehouse?.warehouseRackInfos)) {
        isRRBInfoEmpty = woDetails.advancedTrackingData?.some((ele: any) =>
          Utility.isEmpty(ele?.rackCode)
        );
      }
    }
    if (
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        woDetails?.targetWarehouse
      )
    ) {
      if (Utility.isNotEmpty(woDetails?.targetWarehouse?.warehouseBinInfos)) {
        isRRBInfoEmpty = woDetails.advancedTrackingData?.some((ele: any) =>
          Utility.isEmpty(ele?.binCode)
        );
      }
    }
  }
  if (
    Utility.isRRBTaggingEnabled() ||
    WarehouseManagementHelper.isRRBEnabledForWarehouse(
      woDetails?.targetWarehouse
    )
  ) {
    return (
      Utility.roundingOff(sum, QTY_ROUNDOFF_PRECISION) ===
        Utility.roundingOff(
          Number(woDetails?.actualQuantity),
          QTY_ROUNDOFF_PRECISION
        ) && !isRRBInfoEmpty
    );
  } else {
    return (
      Utility.roundingOff(sum, QTY_ROUNDOFF_PRECISION) ===
      Utility.roundingOff(
        Number(woDetails?.actualQuantity),
        QTY_ROUNDOFF_PRECISION
      )
    );
  }
};

export const isTrackingInfoAvailableForCompleteWO = (workOrder: any) => {
  let totalQuantity = 0;
  if (workOrder?.product?.advancedTracking === ADVANCE_TRACKING.NONE) {
    if (
      (workOrder?.targetWarehouse?.warehouseRowInfos?.length > 0 &&
        Utility.isEmpty(workOrder?.warehouseInventoryData?.[0]?.rowCode)) ||
      (workOrder?.targetWarehouse?.warehouseRackInfos?.length > 0 &&
        Utility.isEmpty(workOrder?.warehouseInventoryData?.[0]?.rackCode)) ||
      (workOrder?.targetWarehouse?.warehouseBinInfos?.length > 0 &&
        Utility.isEmpty(workOrder?.warehouseInventoryData?.[0]?.binCode))
    ) {
      totalQuantity = 0;
    } else {
      totalQuantity = workOrder?.warehouseInventoryData?.reduce(
        (prev: number, current: any) => prev + Number(current?.quantity ?? 0),
        0
      );
    }
  } else {
    if (workOrder?.advancedTrackingData?.length > 0) {
      if (
        (workOrder?.targetWarehouse?.warehouseRowInfos?.length > 0 &&
          workOrder.advancedTrackingData?.some((ele: any) =>
            Utility.isEmpty(ele?.rowCode)
          )) ||
        (workOrder?.targetWarehouse?.warehouseRackInfos?.length > 0 &&
          workOrder.advancedTrackingData?.some((ele: any) =>
            Utility.isEmpty(ele?.rackCode)
          )) ||
        (workOrder?.targetWarehouse?.warehouseBinInfos?.length > 0 &&
          workOrder.advancedTrackingData?.some((ele: any) =>
            Utility.isEmpty(ele?.binCode)
          ))
      ) {
        totalQuantity = 0;
      } else {
        totalQuantity = workOrder?.advancedTrackingData?.reduce(
          (prev: number, current: any) => prev + Number(current?.qtyToFulfil),
          0
        );
      }
    } else {
      totalQuantity = 0;
    }
  }

  if (
    Utility.roundingOff(totalQuantity, QTY_ROUNDOFF_PRECISION) !==
    Utility.roundingOff(
      Number(workOrder?.actualQuantity),
      QTY_ROUNDOFF_PRECISION
    )
  ) {
    return false;
  }
  return true;
};

export const getQuanityDetailText = (tracking: any) => {
  if (tracking === TRACKING_TYPE.SERIAL) {
    return 'serial';
  } else if (tracking === TRACKING_TYPE.BATCH) {
    return 'batch';
  } else {
    return '';
  }
};

export const getNonRejectedWarehouses = (warehouseData: any) => {
  return warehouseData?.content?.filter((item: any) => {
    return item.warehouseType !== 'REJECTED';
  });
};

export const getTargetWarehouses = (warehouseData: any, type: any = '') => {
  if (type === 'OUT') {
    return warehouseData?.content?.filter((item: any) => {
      return item.warehouseType === 'QA';
    });
  } else {
    return warehouseData?.content?.filter((item: any) => {
      return item.warehouseType !== 'REJECTED' && item.warehouseType !== 'QA';
    });
  }
};

export const getQAWarehouses = (warehouseData: any) => {
  const found = warehouseData?.content?.find((item: any) => {
    return item.warehouseType === 'QA';
  });

  if (Utility.isEmptyObject(found)) {
    const qaWarehouse = Store.getState()?.warehouse?.qaWarehouse;
    let warehouse: any = {};
    if (qaWarehouse?.content?.length > 0) {
      warehouse = qaWarehouse?.content?.[0];
    }
    return warehouse;
  } else {
    return found;
  }
};

export const getTargetWarehouse = (warehouseData: any) => {
  const found = warehouseData?.content?.find((item: any) => {
    return item.warehouseType === 'REJECTED';
  });

  if (Utility.isEmptyObject(found)) {
    return Store.getState()?.warehouse?.rejectedWarehouse;
  } else {
    return found;
  }
};

export const isWasteManagementGridVisible = (rows: any) => {
  let isAdhocBomEnabled =
    Store.getState().authInfo.currentTenantInfo?.data?.additionalSettings
      ?.ADHOC_BOM?.enable ?? false;
  if (isAdhocBomEnabled) {
    return true;
  }
  let filteredRows = rows?.filter((row: any) => {
    return (
      row.produceProductType === PRODUCE_PRODUCT_TYPE.SCRAP ||
      row.produceProductType === PRODUCE_PRODUCT_TYPE.CO_PRODUCT
    );
  });

  return filteredRows?.length > 0;
};

export const validateScrapByProductDetails = (workOrderItems: any) => {
  let scrapByProducts = workOrderItems?.filter((woItem: any) => {
    return (
      woItem.produceProductType === PRODUCE_PRODUCT_TYPE.SCRAP ||
      woItem.produceProductType === PRODUCE_PRODUCT_TYPE.CO_PRODUCT
    );
  });

  const found = scrapByProducts?.find((item: any) => {
    const totalQuantity =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;
    return Number(item.actualRequiredQty) !== Number(totalQuantity);
  });

  return !Utility.isEmpty(found);
};

export const hasWorkOrderItemsChanged = (
  workOrderItems: any,
  workOrderItemsClonedCopy: any
) => {
  let sum1 = 0;
  [...(workOrderItemsClonedCopy || [])]?.forEach((item1: any) => {
    sum1 += item1?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
      return Number(acc) + Number(obj?.quantity ?? 0);
    }, 0);
  });

  let sum2 = 0;
  [...(workOrderItems || [])]?.forEach((item1: any) => {
    sum2 += item1?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
      return Number(acc) + Number(obj?.quantity ?? 0);
    }, 0);
  });

  return sum1 !== sum2;
};

export const getTotalCostOfLineItem = (
  lineItemData: any,
  workOrderStatus: WORK_ORDER_STATUS,
  manufactureQuantity?: number
) => {
  if (
    lineItemData?.produceProductType === PRODUCE_PRODUCT_TYPE.SCRAP ||
    lineItemData?.produceProductType === PRODUCE_PRODUCT_TYPE.CO_PRODUCT ||
    Utility.isEmpty(lineItemData)
  ) {
    return 0;
  }
  let lineItems = lineItemData ? [{ ...lineItemData }] : [];
  let lineItemListWithSubstitutes = lineItems;
  if (lineItemData?.bomProductSubstitutesDetails) {
    lineItemListWithSubstitutes = [
      ...lineItems,
      ...lineItemData.bomProductSubstitutesDetails
    ];
  }
  let totalCost = 0;
  lineItemListWithSubstitutes.forEach((element: any) => {
    const isServiceProduct =
      element?.itemName?.type === PRODUCT_TYPE.NON_TRACKED;

    if (!Utility.isEmpty(element?.warehouseInventoryData)) {
      let productTrackingType = Utility.isEmpty(element?.itemName)
        ? element?.advancedTracking
        : element?.itemName?.advancedTracking;

      let unitCost =
        workOrderStatus === WORK_ORDER_STATUS.COMPLETED
          ? element.costPerUnit
          : element.inventoryPrice ?? element.itemName?.inventoryPrice;
      unitCost = Number(unitCost) || 0;
      let currentMaterialTotalCost = 0;
      if (
        productTrackingType === ADVANCE_TRACKING.SERIAL ||
        productTrackingType === ADVANCE_TRACKING.BATCH
      ) {
        let advanceTrackingData =
          element?.warehouseInventoryData?.reduce(
            (prev: any[], warehouseInventoryDataObj: any) => [
              ...prev,
              ...warehouseInventoryDataObj?.advancedTrackingData
            ],
            []
          ) ?? [];

        currentMaterialTotalCost =
          advanceTrackingData?.reduce((prev: number, advObj: any) => {
            let quantityValue =
              element?.documentUOMSchemaDefinition &&
              element?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL
                ? Utility.getUomQuantityWithoutRoundOff(
                    Number(advObj?.qtyToFulfil),
                    element?.documentUOMSchemaDefinition
                  )
                : Number(advObj?.qtyToFulfil);
            let cost = quantityValue * unitCost;
            return prev + Number(cost);
          }, 0) ?? 0;
      } else if (isServiceProduct) {
        const itemCost = Number(element.costPerUnit) || 0;
        const plannedQuantity = Number(manufactureQuantity) || 0;
        currentMaterialTotalCost = itemCost * plannedQuantity;
      } else {
        let totalQuantity: number =
          element?.warehouseInventoryData?.reduce(
            (prev: number, current: any) => {
              return Number(prev) + Number(current.quantity ?? 0);
            },
            0
          ) ?? 0;
        currentMaterialTotalCost = Number(totalQuantity * unitCost) || 0;
      }

      totalCost += currentMaterialTotalCost;
    } else if (
      workOrderStatus === WORK_ORDER_STATUS.COMPLETED &&
      isServiceProduct
    ) {
      const itemCost = Number(element.costPerUnit) || 0;
      const plannedQuantity = Number(manufactureQuantity) || 0;
      totalCost += itemCost * plannedQuantity;
    }
  });
  return totalCost;
};

export const calculateCostPerUnit = (row: any) => {
  let priceValue = row?.itemName?.inventoryPrice;
  if (row?.itemName?.advancedTracking === ADVANCE_TRACKING.NONE) {
    priceValue = row?.itemName?.inventoryPrice;
  } else {
    if (!Utility.isEmpty(row?.warehouseInventoryData)) {
      let advanceTrackingData = row?.warehouseInventoryData?.reduce(
        (prev: any[], warehouseInventoryDataObj: any) => [
          ...prev,
          ...warehouseInventoryDataObj?.advancedTrackingData
        ],
        []
      );

      let totalQuantityAssigned = advanceTrackingData?.reduce(
        (prev: number, advObj: any) => prev + advObj?.qtyToFulfil,
        0
      );
      let totalCostPerUnit = advanceTrackingData?.reduce(
        (prev: number, advObj: any) => prev + advObj?.costPerUnit,
        0
      );
      priceValue = Utility.roundOffToTenantDecimalScale(
        totalCostPerUnit / totalQuantityAssigned
      );
    }
  }
  return priceValue;
};

export const calculateTotalNonSubstitutesAlloted = (row: any) => {
  let totalAlloted = 0;
  if (row?.itemName?.advancedTracking === ADVANCE_TRACKING.NONE) {
    totalAlloted =
      row?.warehouseInventoryData?.reduce((prev: number, current: any) => {
        return prev + Number(current.quantity);
      }, 0) ?? 0;
  } else {
    let advancedTrackingDataList = row?.warehouseInventoryData?.reduce(
      (prev: any[], current: any) => {
        return [...prev, ...current?.advancedTrackingData];
      },
      []
    );
    totalAlloted =
      advancedTrackingDataList?.reduce((prev: number, current: any) => {
        let qty = current.qtyToFulfil;
        if (
          row?.documentUOMSchemaDefinition &&
          row?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL
        ) {
          qty = Utility.getUomQuantityWithoutRoundOff(
            current.qtyToFulfilUom ?? current.qtyToFulfil,
            row.documentUOMSchemaDefinition
          );
        }
        // const qty = Utility.isNotEmpty(row?.documentUOMSchemaDefinition)
        //   ? current.qtyToFulfilUom ?? current.qtyToFulfil
        //   : current.qtyToFulfil;
        return prev + Number(qty);
      }, 0) ?? 0;
  }

  return totalAlloted;
};

export const calculateTotalSubstituteAlloted = (item: any) => {
  let totalAlloted =
    item?.bomProductSubstitutesDetails?.reduce((prev: number, current: any) => {
      let totalAllotedCurrent =
        current?.warehouseInventoryData?.reduce(
          (prevValue: number, currentObj: any) => {
            return Number(prevValue) + Number(currentObj.quantity);
          },
          0
        ) || 0;
      return Number(prev) + Number(totalAllotedCurrent);
    }, 0) || 0;
  return totalAlloted;
};

export const isQuantityAllocated = (workOrder: any, isEditMode: boolean) => {
  const atleatQuantityAllocated = workOrder?.workOrderItemsClonedCopy?.find(
    (bomItem: any) => {
      return (
        bomItem?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE &&
        !Utility.isEmpty(bomItem?.warehouseInventoryData) &&
        isEditMode
      );
    }
  );

  return !Utility.isEmpty(atleatQuantityAllocated);
};

export const getStatusName = (status: any, type: any) => {
  if (type === COLUMN_CODE.JOB_WORK_OUT.DispatchStatus) {
    if (status === 'PENDING_DISPATCH_APPROVAL') {
      return 'Pending Dispatch Approval';
    } else if (status === 'IN_PROGRESS') {
      return 'In Progress';
    } else {
      let newStatus = status.split('_');
      newStatus = Utility.convertInTitleCase(newStatus[0]);
      return newStatus;
    }
  }

  if (type === COLUMN_CODE.JOB_WORK_OUT.ReceiptStatus) {
    switch (status) {
      case JWO_RECEIPT_STATUS.UNRECEIVED:
        return 'Pending';
      case JWO_RECEIPT_STATUS.PARTIAL_RECEIVED:
        return 'Partially Received';
      case JWO_RECEIPT_STATUS.FULLY_RECEIVED:
        return 'Fully Received';
      default:
        return 'Not Received';
    }
  }

  if (type === COLUMN_CODE.JOB_WORK_OUT.BilledStatus) {
    let newStatus = Utility.convertInTitleCase(status);
    return newStatus;
  }

  if (type === WO_MASTER_GRID_KEYS.WO_QC_STATUS) {
    let newStatus = Utility.convertInTitleCase(status);
    return newStatus;
  }

  return '-';
};

export const getStatusTitle = (title: string) => {
  switch (title) {
    case 'UNRECEIVED':
      return 'Not Received';
    case 'PARTIAL_RECEIVED':
      return 'Partial';
    case 'FULLY_RECEIVED':
      return 'Received';
    default:
      return getCapitalized(title?.toLowerCase());
  }
};

export const getStatusChipColor = (key: any) => {
  const commonCSS = `fw-m text-align-center text-wrap-none p-h-s text-white parent-width border-radius-s p-v-xs leading-4`;
  switch (key) {
    case 'OPEN':
    case PROCESS_QC_STATUS.OPEN:
      return `mrp_bg_blue ${commonCSS}`;
    case JWO_DISPATCH_STATUS.PENDING_DISPATCH:
    case JWO_RECEIPT_STATUS.UNRECEIVED:
    case JWO_RECEIPT_STATUS.NOT_APPLICABLE:
    case JWO_BILLED_STATUS.PENDING:
    case 'UNRECEIVED':
      return `mrp_bg_red ${commonCSS}`;
    case JWO_DISPATCH_STATUS.PARTIALLY_DISPATCHED:
    case JWO_RECEIPT_STATUS.PARTIAL_RECEIVED:
    case JWO_BILLED_STATUS.PARTIAL:
    case WORK_ORDER_STATUS.IN_PROGRESS:
    case 'PARTIAL_RECEIVED':
    case PROCESS_QC_STATUS.QUANTITY_ASSIGNED:
    case JWO_DISPATCH_STATUS.PENDING_DISPATCH_APPROVAL:
      return `mrp_bg_orange ${commonCSS}`;
    case JWO_DISPATCH_STATUS.DISPATCHED:
    case JWO_RECEIPT_STATUS.FULLY_RECEIVED:
    case JWO_BILLED_STATUS.BILLED:
    case WORK_ORDER_STATUS.COMPLETED:
    case 'FULLY_RECEIVED':
    case 'completed':
    case PROCESS_QC_STATUS.QA_DONE:
    case PROCESS_QC_STATUS.QC_DONE_UI_ONLY:
    case PROCESS_QC_STATUS.COMPLETE:
      return `mrp_bg_green ${commonCSS}`;

    default:
      return `mrp_bg_red ${commonCSS}`;
  }
};

export const calculateNoneTrackedReservedQuantities = (
  workOrderItem: any,
  originalWorkOrderItem: any
) => {
  const newInventoryData: any[] = deepClone(
    workOrderItem?.warehouseInventoryData ?? []
  );
  const updateInventoryData: any = [];
  newInventoryData.forEach((inventoryItem: any) => {
    const existingWHInventoryData = (
      originalWorkOrderItem?.warehouseInventoryData || []
    )?.find(
      (data: any) =>
        data.warehouseCode === inventoryItem.warehouseCode &&
        // eslint-disable-next-line eqeqeq
        data.binCode == inventoryItem.binCode &&
        // eslint-disable-next-line eqeqeq
        data.rackCode == inventoryItem.rackCode &&
        // eslint-disable-next-line eqeqeq
        data.rowCode == inventoryItem.rowCode
    );
    updateInventoryData.push({
      ...inventoryItem,
      reservedQuantity: Utility.roundingOff(
        inventoryItem.quantity - (existingWHInventoryData?.quantity || 0),
        QTY_ROUNDOFF_PRECISION
      )
    });
  });
  return updateInventoryData?.filter((iData: any) => {
    return iData.reservedQuantity !== 0;
  });
};
export const calculateAdvancedTrackedReservedQuantities = (
  workOrderItem: any,
  originalWorkOrderItem: any
) => {
  let newInventoryData: any = deepClone(
    workOrderItem?.warehouseInventoryData ?? []
  );
  newInventoryData.forEach((inventoryItem: any) => {
    const warehouseInventoryArray =
      originalWorkOrderItem?.warehouseInventoryData?.filter(
        (data: any) =>
          data.warehouseCode === inventoryItem.warehouseCode &&
          // eslint-disable-next-line eqeqeq
          data.binCode == inventoryItem.binCode &&
          // eslint-disable-next-line eqeqeq
          data.rackCode == inventoryItem.rackCode &&
          // eslint-disable-next-line eqeqeq
          data.rowCode == inventoryItem.rowCode
      );

    let advancedTrackingData = deepClone(inventoryItem.advancedTrackingData);
    if (warehouseInventoryArray?.length) {
      const exitingAdvancedTrackingData = warehouseInventoryArray?.flatMap(
        (item: any) => item.advancedTrackingData
      );
      advancedTrackingData = advancedTrackingData?.filter(
        (trackingData: any) => {
          const advanceTrackObj = exitingAdvancedTrackingData.find(
            (item: any) =>
              item.serialBatchNumber === trackingData.serialBatchNumber
          );
          const qtyKeyToConsider = workOrderItem?.documentUOMSchemaDefinition
            ? 'qtyToFulfilUom'
            : 'qtyToFulfil';
          const qtyChanged =
            (advanceTrackObj?.qtyToFulfil || 0) -
              (trackingData?.qtyToFulfil || 0) !==
            0;
          if (advanceTrackObj?.qtyToFulfil !== trackingData?.qtyToFulfil) {
            let newQuantity =
              (trackingData?.qtyToFulfil || 0) -
              (advanceTrackObj?.qtyToFulfil || 0);
            newQuantity = Utility.roundingOff(
              newQuantity,
              QTY_ROUNDOFF_PRECISION
            );
            trackingData.qtyToFulfil = newQuantity;
            trackingData.reservedQuantity = newQuantity;
          }
          return isEmpty(advanceTrackObj) || qtyChanged;
        }
      );
    } else {
      advancedTrackingData = advancedTrackingData.map((item: any) => ({
        ...item,
        reservedQuantity: Utility.roundingOff(
          item.qtyToFulfil,
          QTY_ROUNDOFF_PRECISION
        )
      }));
    }
    inventoryItem.advancedTrackingData = advancedTrackingData;
    const inventoryItemReservedQuantity = advancedTrackingData?.reduce(
      (acc: number, current: any) => acc + current?.qtyToFulfil,
      0
    );
    inventoryItem.reservedQuantity = Utility.roundingOff(
      inventoryItemReservedQuantity,
      QTY_ROUNDOFF_PRECISION
    );
  });

  if (newInventoryData) {
    newInventoryData = newInventoryData?.filter((iData: any) => {
      return iData.reservedQuantity !== 0;
    });
  }

  return newInventoryData;
};

export const mapInventoryToReservedQtyData = (
  inventoryItem: any,
  workOrderItem: any
) => ({
  productCode:
    workOrderItem?.itemName?.pid ?? workOrderItem?.itemName?.productId,
  productName: workOrderItem?.itemName?.name,
  warehouseCode: inventoryItem?.warehouseCode,
  warehouseName: inventoryItem?.warehouseName,
  availableQuantity: Utility.roundingOff(
    workOrderItem?.itemName?.inventory?.availableQuantity || 0,
    QTY_ROUNDOFF_PRECISION
  ),
  reservedQuantity: Utility.roundingOff(
    inventoryItem?.reservedQuantity,
    QTY_ROUNDOFF_PRECISION
  ),
  rowCode: inventoryItem?.rowCode,
  rowName: inventoryItem?.rowName,
  rackCode: inventoryItem?.rackCode,
  rackName: inventoryItem?.rackName,
  binCode: inventoryItem?.binCode,
  binName: inventoryItem?.binName,
  advancedTrackingType: workOrderItem?.itemName?.advancedTracking,
  advancedTrackingMetaDtos: inventoryItem.advancedTrackingData,
  isAdHocItem: workOrderItem.isAdHocItem
});
export const mapDataFromNoneTrackPopup = (obj: any) => ({
  ...obj,
  advancedTrackingData: [],
  quantity: obj.quantity,
  rowCode: obj.rowCode ?? null,
  rowName: obj.row ? obj?.row?.rowName : null,
  rackCode: obj.rackCode ?? null,
  rackName: obj.rack ? obj?.rack?.rackName : null,
  binCode: obj.binCode ?? null,
  binName: obj.bin ? obj?.bin?.binName : null,
  warehouseCode: obj.warehouseCode,
  warehouseName: obj.warehouseName
});

export const getTaggedWHQtyMessage = (taggedWH: string) =>
  `All quantities are not available in the tagged warehouse${
    taggedWH ? '<span class="fw-m"><i>(' + taggedWH + ')</i></span>' : ''
  }, but they may be available in other warehouse(s).<br><br>Please transfer them to the tagged warehouse or create PO.`;

export const evaluateAllInventoryInTaggedWarehouse = (
  taggedWH: any,
  rowData: any,
  allProductWarehouseStocks: any[],
  isSubstitute = false
) => {
  if (Utility.isEmpty(allProductWarehouseStocks)) {
    return {};
  }
  const taggedWHCode = taggedWH?.code;
  const requiredQty =
    rowData?.requiredQty ||
    rowData?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY];
  let allItemsAvailableInTaggedWH = true;
  const taggedWHStockInfo = allProductWarehouseStocks.find(
    (wh: any) => wh.code === taggedWHCode
  );

  const nonTaggedWHStockInfo = allProductWarehouseStocks.filter(
    (wh: any) => wh.code !== taggedWHCode
  );
  const prodCode = isSubstitute
    ? rowData?.pid || rowData?.productId
    : rowData?.itemName?.pid || rowData?.pid;
  const nonTaggedWHStockSummary: any[] = [];
  nonTaggedWHStockInfo.forEach((whInfo: any) => {
    if (whInfo['productAvailableQuantity'].hasOwnProperty(prodCode)) {
      const availableQuantity =
        whInfo['productAvailableQuantity'][prodCode] ?? 0;
      const reservedQuantity = whInfo['productReservedQuantity'][prodCode] ?? 0;
      const actualAvailableQty = availableQuantity - reservedQuantity;
      if (actualAvailableQty > 0) {
        nonTaggedWHStockSummary.push({
          wh: whInfo.name,
          availableQuantity: actualAvailableQty
        });
      }
    }
  });

  const availableQuantityInAllWH = allProductWarehouseStocks.reduce(
    (prev: number, current: any) => {
      return (
        prev + Number(current?.['productAvailableQuantity']?.[prodCode] || 0)
      );
    },
    0
  );

  const reservedQuantityInAllWH = allProductWarehouseStocks.reduce(
    (prev: number, current: any) => {
      return (
        prev + Number(current?.['productReservedQuantity']?.[prodCode] || 0)
      );
    },
    0
  );

  const totalAvailableStockInAllWH =
    availableQuantityInAllWH - reservedQuantityInAllWH;

  let availableQuantityInTaggedWH = taggedWHStockInfo
    ? Utility.isNotEmpty(rowData?.documentUOMSchemaDefinition)
      ? Utility.getUomQuantity(
          taggedWHStockInfo['productAvailableQuantity'][prodCode] || 0,
          rowData?.documentUOMSchemaDefinition
        )
      : taggedWHStockInfo['productAvailableQuantity'][prodCode] || 0
    : 0;

  let reservedQuantityInTaggedWH = taggedWHStockInfo
    ? Utility.isNotEmpty(rowData?.documentUOMSchemaDefinition)
      ? Utility.getUomQuantity(
          taggedWHStockInfo['productReservedQuantity'][prodCode] || 0,
          rowData?.documentUOMSchemaDefinition
        )
      : taggedWHStockInfo['productReservedQuantity'][prodCode] || 0
    : 0;
  if (
    Utility.isNotEmpty(taggedWHStockInfo) &&
    Utility.isRRBTaggingEnabled() &&
    Utility.isBinAllocationMandatory()
  ) {
    const { rowRackBinProductAvailableQuantityDtos } = taggedWHStockInfo;
    if (Utility.isNotEmpty(rowRackBinProductAvailableQuantityDtos)) {
      const productRRBDetails = rowRackBinProductAvailableQuantityDtos?.find(
        (RRBItem: any) =>
          rowData.productCode === RRBItem.productCode &&
          RRBItem.rowCode === taggedWH?.rowCode &&
          RRBItem.rackCode === taggedWH?.rackCode &&
          RRBItem.binCode === taggedWH?.binCode
      );

      availableQuantityInTaggedWH = Utility.isNotEmpty(
        rowData?.documentUOMSchemaDefinition
      )
        ? Utility.getUomQuantity(
            Utility.roundingOff(
              productRRBDetails?.availableQuantity || 0,
              QTY_ROUNDOFF_PRECISION
            ),

            rowData?.documentUOMSchemaDefinition
          )
        : Utility.roundingOff(
            productRRBDetails?.availableQuantity || 0,
            QTY_ROUNDOFF_PRECISION
          );

      reservedQuantityInTaggedWH = Utility.isNotEmpty(
        rowData?.documentUOMSchemaDefinition
      )
        ? Utility.getUomQuantity(
            Utility.roundingOff(
              productRRBDetails?.reservedQuantity || 0,
              QTY_ROUNDOFF_PRECISION
            ),
            rowData?.documentUOMSchemaDefinition
          )
        : Utility.roundingOff(
            productRRBDetails?.reservedQuantity || 0,
            QTY_ROUNDOFF_PRECISION
          );
      // }
    }
  }

  const totalAvailableStockInTaggedWH: any =
    availableQuantityInTaggedWH - reservedQuantityInTaggedWH;

  let totalAlloted = 0;
  const advancedTrackingUpdated =
    rowData?.itemName?.advancedTracking ?? rowData?.advancedTracking;
  if (advancedTrackingUpdated === ADVANCE_TRACKING.NONE) {
    totalAlloted =
      rowData?.warehouseInventoryData?.reduce((prev: number, current: any) => {
        return prev + Number(current.quantity);
      }, 0) ?? 0;
  } else {
    let advacedList = rowData?.warehouseInventoryData?.reduce(
      (prev: any[], current: any) => {
        return [...prev, ...(current?.advancedTrackingData || [])];
      },
      []
    );
    totalAlloted =
      advacedList?.reduce((prev: number, current: any) => {
        return prev + Number(current?.qtyToFulfil ?? 0);
      }, 0) ?? 0;
  }

  totalAlloted = totalAlloted + calculateTotalSubstituteAlloted(rowData);

  let qytToBeRequested = 0;
  let shortFallQty = 0;
  if (requiredQty === totalAlloted) {
    allItemsAvailableInTaggedWH = true;
  } else {
    const requiredQtyAfterAllotment = requiredQty - totalAlloted;
    if (requiredQtyAfterAllotment <= totalAvailableStockInTaggedWH) {
      allItemsAvailableInTaggedWH = true;
      qytToBeRequested = Math.abs(
        requiredQtyAfterAllotment - totalAvailableStockInTaggedWH
      );
    } else {
      allItemsAvailableInTaggedWH = false;
      if (requiredQtyAfterAllotment > totalAvailableStockInAllWH) {
        qytToBeRequested =
          totalAvailableStockInAllWH -
          totalAvailableStockInTaggedWH -
          totalAlloted;
      } else if (requiredQtyAfterAllotment <= totalAvailableStockInAllWH) {
        qytToBeRequested = Math.abs(
          requiredQtyAfterAllotment - totalAvailableStockInTaggedWH
        );
      }
    }
    shortFallQty = Math.abs(
      requiredQtyAfterAllotment - totalAvailableStockInTaggedWH
    );
  }

  return {
    allItemsAvailableInTaggedWH,
    totalAvailableStockInTaggedWH: isNaN(totalAvailableStockInTaggedWH)
      ? 0
      : totalAvailableStockInTaggedWH ?? 0,
    totalAvailableStockInAllWH: isNaN(totalAvailableStockInAllWH)
      ? 0
      : totalAvailableStockInAllWH ?? 0,
    nonTaggedWHStockSummary,
    totalAlloted,
    qytToBeRequested,
    shortFallQty
  };
};

export const broadcastOnWorkOrderSaved = (
  woCode: string,
  isEditMode: boolean,
  parentWoCode?: string
) => {
  commonCustomEvent.dispatch(COMMON_EVENTS.RECORD_SAVED, {
    id: woCode,
    type: RECORD_SAVED_EVENT_DOC_TYPE.WORK_ORDER,
    linkedDocId: parentWoCode,
    linkedDocType: DOC_TYPE.WORK_ORDER,
    isEdit: isEditMode
  });
};

export const getStatusColumnConfig = (workOrderObject: any) => {
  let statusColumnConfig = WORK_ORDER_COLS.find(
    (col: any) => col.columnCode === 'status'
  );
  var statusColumnConfigArray: any = [];
  if (statusColumnConfig) {
    statusColumnConfigArray = statusColumnConfig.options;
    let currentStatus = statusColumnConfigArray.find(
      (item: any) => item.id === workOrderObject?.status
    );
    if (!Utility.isEmpty(currentStatus)) {
      if (
        Utility.isNotEmpty(workOrderObject?.workOrderOperations) &&
        workOrderObject?.allJobCardsCompleted &&
        workOrderObject?.status !== WORK_ORDER_STATUS.COMPLETED
      ) {
        return {
          id: 'ALL JOB CARDS DONE',
          color: 'bg-chip-green text-green border-green',
          name: 'All job cards completed'
        };
      }
      return currentStatus;
    }
  }
  return {
    id: 'OPEN',
    color: 'data-grid-badge-color-1',
    name: 'Open'
  };
};

export const isPlannedQtyReadOnly = (
  workOrder: IWorkOrder,
  salesOrder: ISalesOrder | undefined | null,
  salesInvoice: Invoice | undefined | null,
  isEditMode: boolean
) => {
  let readOnlyQty = false;
  if (workOrder && isEditMode) {
    if (workOrder.totalJobCards === 0) {
      readOnlyQty =
        isQuantityAllocated(workOrder, isEditMode) ||
        !Utility.isEmpty(salesOrder?.salesOrderCode) ||
        !Utility.isEmpty(salesInvoice?.salesInvoiceCode);
    } else {
      readOnlyQty =
        workOrder?.totalCompletedJobCards > 0 ||
        isQuantityAllocated(workOrder, isEditMode) ||
        !Utility.isEmpty(salesOrder?.salesOrderCode) ||
        !Utility.isEmpty(salesInvoice?.salesInvoiceCode);
    }
  } else if (workOrder && !isEditMode) {
    readOnlyQty =
      !Utility.isEmpty(salesOrder?.salesOrderCode) ||
      !Utility.isEmpty(salesInvoice?.salesInvoiceCode);
  }
  return readOnlyQty;
};

export const checkTargetWarehouseReadStatus = (
  workOrder: IWorkOrder,
  isEditMode: boolean,
  isReadOnlyMode: boolean
) => {
  if (!Utility.isWarehouseTaggingEnabled()) {
    return isReadOnlyMode;
  } else {
    return isReadOnlyMode || isQuantityAllocated(workOrder, isEditMode);
  }
};

function getUpdatedRequiredQuantityForWorkOrderItem(
  item: any,
  manufactureQuantity: number
) {
  const qty = item[REQUIRED_ITEM_TABLE.COMPONENT_UNIT_QTY] ?? 0;

  let requiredQtyForComponent: any = 0;
  // if (item.itemName?.type === PRODUCT_TYPE.NON_TRACKED) {
  //   requiredQtyForComponent = 1;
  // }
  if (item.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
    requiredQtyForComponent = Math.round(manufactureQuantity * qty);
  } else {
    requiredQtyForComponent = Utility.roundOff(
      manufactureQuantity * qty,
      QTY_ROUNDOFF_PRECISION
    );
  }

  return requiredQtyForComponent;
}

/**
 * @param workOrder Updates manufactureQuantity, actualQuantity,
 * @param usePrevPlannedQty
 * @param isEditMode
 * @returns Object containing updated WO
 */
export const fetchProductDetailsAndUpdateWOItemsAndOperations = async (
  workOrder: IWorkOrder,
  usePrevPlannedQty: number,
  isEditMode: boolean
) => {
  let copyOfWorkOrder = { ...workOrder };
  let updatedPlannedQuantity = copyOfWorkOrder.manufactureQuantity || 1;
  if (isNaN(updatedPlannedQuantity)) {
    updatedPlannedQuantity = 1;
  } else if (Number(updatedPlannedQuantity) <= 0) {
    updatedPlannedQuantity = 1;
  }
  updatedPlannedQuantity = Utility.roundOff(
    updatedPlannedQuantity,
    QTY_ROUNDOFF_PRECISION
  );
  copyOfWorkOrder.manufactureQuantity = updatedPlannedQuantity;
  copyOfWorkOrder.actualQuantity = updatedPlannedQuantity;

  let copyWOItems = copyOfWorkOrder.workOrderItems
    ? [...copyOfWorkOrder.workOrderItems]
    : [];
  let reqForProductDetails = copyWOItems?.map((item: any) => {
    const quantity = getUpdatedRequiredQuantityForWorkOrderItem(
      item,
      updatedPlannedQuantity
    );
    const uomQuantity = Utility.getUomWarehouseQuantity(
      quantity,
      item?.documentUOMSchemaDefinition
    );
    return {
      productCode: item?.itemName?.pid,
      quantity: getUpdatedRequiredQuantityForWorkOrderItem(
        item,
        updatedPlannedQuantity
      ),
      uomQuantity: uomQuantity,
      documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition
    };
  });

  let productDetails: any[] = [];
  try {
    productDetails = await MRPProductsService.getProductShortInfo(
      reqForProductDetails
    );
  } catch (err: any) {
    console.error('Error fetching product short info: ', err);
  }

  if (!isNaN(updatedPlannedQuantity)) {
    const woItems = copyWOItems?.map((item) => {
      item = { ...item };
      let requiredQtyForComponent: any =
        getUpdatedRequiredQuantityForWorkOrderItem(
          item,
          updatedPlannedQuantity
        );

      item[REQUIRED_ITEM_TABLE.REQUIRED_QTY] = requiredQtyForComponent ?? 0;
      item[REQUIRED_ITEM_TABLE.ACTUAL_REQUIRED_QTY] =
        requiredQtyForComponent ?? 0;
      item[REQUIRED_ITEM_TABLE.PLANNED_QTY] = requiredQtyForComponent ?? 0;
      item[REQUIRED_ITEM_TABLE.PRODUCED_QUANTITY] =
        requiredQtyForComponent ?? 0;

      if (updatedPlannedQuantity < usePrevPlannedQty) {
        item.warehouseInventoryData = [];
      }

      if (
        typeof item.requiredQty !== 'undefined' &&
        typeof item.availableQty !== 'undefined' &&
        item.requiredQty > item.availableQty
      ) {
        item[REQUIRED_ITEM_TABLE.REQ_ITEM_INVALID_FIELDS] = [
          REQUIRED_ITEM_TABLE.AVAILABLE_QTY
        ];
      } else {
        item[REQUIRED_ITEM_TABLE.REQ_ITEM_INVALID_FIELDS] = [];
      }
      let updatedProduct = productDetails.find(
        (newProduct: any) => newProduct.pid === item?.itemName?.pid
      );
      item.itemName = {
        ...item.itemName,
        inventoryPrice: updatedProduct?.inventoryPrice
      };
      return item;
    });

    let copyWOOperations = copyOfWorkOrder.workOrderOperations
      ? [...copyOfWorkOrder.workOrderOperations]
      : [];
    const woOperations = copyWOOperations?.map((woOperation) => {
      woOperation = { ...woOperation };
      woOperation.operationTime = Utility.roundOffToTenantDecimalScale(
        woOperation?.operationName?.operationTime *
          Number(updatedPlannedQuantity)
      );

      let totalOperationCost = getOperationCost(
        woOperation.operationName,
        copyOfWorkOrder?.manufactureQuantity ?? 1
      );
      woOperation.operationCost = totalOperationCost;

      woOperation.operatorCost = getOperatorCostForOperation(
        woOperation?.operationName?.operationTime,
        woOperation?.operationName?.operators,
        copyOfWorkOrder?.manufactureQuantity ?? 1
      );

      woOperation.workstationCost = getWorkstationCost(
        woOperation,
        copyOfWorkOrder?.manufactureQuantity ?? 1
      );
      return woOperation;
    });

    return {
      ...copyOfWorkOrder,
      workOrderItems: woItems,
      workOrderOperations: woOperations
    };
  }
  return copyOfWorkOrder;
};

export const hasReservedQuantityChanged = (
  workOrders: IWorkOrder[],
  pristineWorkOrderData: IWorkOrder[],
  activeTabIndex: number
): boolean => {
  let reservedQuantityChanged = false;
  const workOrdersWithChangeStock =
    workOrders?.filter((workOrder: any) => {
      let updatedObj = workOrder?.workOrderItems?.filter((item: any) => {
        const hasSubstitutesUpdated =
          item?.bomProductSubstitutesDetails?.filter(
            (substitute: any) =>
              !Utility.isEmptyObject(substitute?.reservedQuantitiesData)
          );
        let obj =
          calculateReservedQuantitiesData(
            item,
            pristineWorkOrderData,
            activeTabIndex
          )?.length > 0 ||
          (!Utility.isEmpty(hasSubstitutesUpdated) &&
            hasSubstitutesUpdated?.length > 0);
        return obj;
      });
      return !Utility.isEmpty(updatedObj);
    }) ?? [];
  reservedQuantityChanged = !Utility.isEmptyObject(workOrdersWithChangeStock);
  return reservedQuantityChanged;
};

export const calculateReservedQuantitiesData = (
  workOrderItem: any,
  pristineWorkOrderData: IWorkOrder[],
  activeTabIndex: number
): any[] => {
  const originalWorkOrderItem = pristineWorkOrderData[
    activeTabIndex
  ]?.workOrderItems?.find(
    (wo: any) =>
      wo?.itemName?.productId === workOrderItem?.itemName?.productId ||
      wo?.itemName?.pid === workOrderItem?.itemName?.pid
  );
  let reservedQuantities: any[] = [];
  const inventoryData =
    workOrderItem?.itemName?.advancedTracking === ADVANCE_TRACKING.NONE
      ? calculateNoneTrackedReservedQuantities(
          workOrderItem,
          originalWorkOrderItem
        )
      : calculateAdvancedTrackedReservedQuantities(
          workOrderItem,
          originalWorkOrderItem
        );
  reservedQuantities = inventoryData.map((inventoryItem: any) =>
    mapInventoryToReservedQtyData(inventoryItem, workOrderItem)
  );
  return reservedQuantities;
};

export const getActionButtonTitle = (workOrderObject: any) => {
  if (workOrderObject?.status === WORK_ORDER_STATUS.OPEN) {
    if (workOrderObject?.allJobCardsCompleted) {
      return WorkOrderHelper.WORK_ORDER_BTN_STATUS.COMPLETE_ORDER;
    } else {
      return WorkOrderHelper.WORK_ORDER_BTN_STATUS.START_ORDER;
    }
  }
  if (workOrderObject?.status === WORK_ORDER_STATUS.IN_PROGRESS) {
    if (workOrderObject?.allJobCardsCompleted) {
      return WorkOrderHelper.WORK_ORDER_BTN_STATUS.COMPLETE_ORDER;
    } else {
      return WorkOrderHelper.WORK_ORDER_BTN_STATUS.STOP_ORDER;
    }
  }
  if (workOrderObject?.status === WORK_ORDER_STATUS.ON_HOLD) {
    return WorkOrderHelper.WORK_ORDER_BTN_STATUS.RESUME_ORDER;
  }
  if (workOrderObject?.allJobCardsCompleted) {
    return WorkOrderHelper.WORK_ORDER_BTN_STATUS.COMPLETE_ORDER;
  }
};
export const populateProductDetailWithSelectedOrDefaultBom = (
  bomProductDetail: any,
  selectedBom?: any | null,
  selectedBomMetaCode?: string
) => {
  let defaultBom =
    bomProductDetail.bomMetaDetailsList?.find((item: any) =>
      Utility.isNullish(selectedBomMetaCode)
        ? item.isDefault
        : selectedBomMetaCode === item.code
    ) ?? bomProductDetail.bomMetaDetailsList?.[0];
  let bomObject = selectedBom ?? defaultBom;

  return {
    ...bomProductDetail,
    selectedBom: bomObject,
    bomProductsConfiguration: bomObject?.bomProductsConfiguration,
    bomOperationsConfiguration: bomObject?.bomOperationsConfiguration,
    bomAddCostConfiguration: bomObject?.bomAddCostConfiguration
  };
};

/**
 * @description map assigned Substitutes for work order and available substitutes of a product. It will return both in array
 * @param workOrderData
 */
export const getSubstitutesArray = (
  selectedProductForWorkOrder: any,
  workOrderItem: any,
  productShortInfoList: any[],
  isEditMode: boolean
) => {
  let selectedProductSubstitutes =
    selectedProductForWorkOrder?.bomProductsConfiguration
      ?.find(
        (componentProduct: any) =>
          componentProduct.productCode === workOrderItem.productCode
      )
      ?.bomProductSubstitutesDetails?.map((substituteDetails: any) => {
        return {
          ...substituteDetails,
          productCode: substituteDetails.productId
        };
      }) || [];
  if (
    !Utility.isEmpty(workOrderItem?.productSubstitutesDetails) ||
    !Utility.isEmpty(workOrderItem?.bomProductSubstitutesDetails)
  ) {
    (
      workOrderItem.productSubstitutesDetails ??
      workOrderItem.bomProductSubstitutesDetails
    ).forEach((element: any) => {
      const code = element?.productCode ?? element?.productId;
      let subIndex = selectedProductSubstitutes?.findIndex(
        (mainSub: any) => mainSub?.productCode === code
      );
      const substituteShortInfoDetail = productShortInfoList?.find(
        (bomProductAndSubs: any) => {
          return bomProductAndSubs.pid === code;
        }
      );
      const isNonTracked = element.type === PRODUCT_TYPE.NON_TRACKED;
      const costPerUnit = isNonTracked
        ? element.costPerUnit ?? substituteShortInfoDetail?.purchasePrice
        : substituteShortInfoDetail?.inventoryPrice ?? element.costPerUnit ?? 0;
      const updatedSubstituteData = {
        ...element,
        itemName: substituteShortInfoDetail,
        documentSequenceCode:
          element.productDocumentSeqCode ??
          element.documentSequenceCode ??
          substituteShortInfoDetail?.documentSequenceCode,
        isProductPicked:
          element.isProductPicked ??
          (isEditMode ? !Utility.isNullish(element) : false),
        reservedQuantitiesData: [],
        availableQuantity: substituteShortInfoDetail
          ? substituteShortInfoDetail.availableQuantity -
            substituteShortInfoDetail.reservedQuantity
          : element?.availableQuantity ?? 0,
        reservedQuantity: substituteShortInfoDetail?.reservedQuantity ?? 0,
        costPerUnit: costPerUnit,
        inventoryPrice: costPerUnit
      };

      if (subIndex >= 0) {
        selectedProductSubstitutes[subIndex] = {
          ...selectedProductSubstitutes[subIndex],
          ...updatedSubstituteData
        };
      } else {
        selectedProductSubstitutes.push(updatedSubstituteData);
      }
    });
  }
  return selectedProductSubstitutes;
};

export const getProductShortInfoRequestDataFromWorkOrders = (
  workOrderList?: any[]
) => {
  let allWorkOrderItems: any[] = [];
  workOrderList?.forEach((workOrder) => {
    allWorkOrderItems.push(...(workOrder?.workOrderItems || []));
  });

  const requestPayloadForProductDetails: {
    productCode: string;
    quantity: number;
    uomQuantity?: number;
    documentUOMSchemaDefinition?: any;
  }[] = [];
  const uniqueItems = uniqBy(allWorkOrderItems, 'itemId');
  uniqueItems?.forEach((bomProduct: any) => {
    if (!bomProduct) return;

    const updatedUomQuantity = bomProduct?.uomQuantity
      ? bomProduct?.uomQuantity
      : Utility.getUomQuantity(
          bomProduct.quantity,
          bomProduct?.documentUOMSchemaDefinition
        );

    requestPayloadForProductDetails.push({
      productCode: bomProduct.productCode ?? bomProduct?.productId,
      quantity: bomProduct.quantity || 0,
      uomQuantity: updatedUomQuantity || 0,
      documentUOMSchemaDefinition: bomProduct?.documentUOMSchemaDefinition
    });

    (
      bomProduct.productSubstitutesDetails ||
      bomProduct.bomProductSubstitutesDetails
    )?.forEach((subsProduct: any) => {
      if (!subsProduct) return;

      const requiredSubstitutes = bomProduct.plannedQuantity || 0;

      requestPayloadForProductDetails.push({
        productCode: subsProduct.productCode ?? subsProduct.productId,
        quantity: requiredSubstitutes
      });
    });
  });

  return requestPayloadForProductDetails;
};

export const flattenOperationIdsFromWorkOrders = (workOrderList?: any[]) => {
  return (workOrderList || []).reduce((acc: number[], workOrder: any) => {
    if (!workOrder?.workOrderOperations) return acc;

    return [
      ...acc,
      ...workOrder.workOrderOperations.map(
        (operation: any) => operation?.operationId
      )
    ];
  }, []);
};

export const createLinkedWorkOrderDataForNewWorkOrder = (
  newWorkOrderList: IWorkOrder[],
  substituteObj: any
) => {
  if (!newWorkOrderList) {
    newWorkOrderList = [];
  } else if (
    typeof newWorkOrderList === 'object' &&
    !Array.isArray(newWorkOrderList)
  ) {
    newWorkOrderList = [newWorkOrderList];
  }

  return newWorkOrderList.map((newWorkOrder) => ({
    productCode: newWorkOrder?.productCode,
    workOrderCode: newWorkOrder?.workOrderCode,
    workOrderSeqCode: newWorkOrder?.documentSequenceCode || '',
    isSubstitute: substituteObj?.isSubstituteProduct ?? false,
    workOrderItemProductCode: substituteObj?.productCodeForSubstituteLink ?? ''
  }));
};

export const createLinkedWorkOrderDataForNewPoPr = (
  associatedPOPR: any,
  currentShortFallSetting: any
) => {
  currentShortFallSetting =
    currentShortFallSetting ?? WORK_ORDER_PR_PO.PURCHASE_ORDER;
  const isPO =
    currentShortFallSetting === WORK_ORDER_PR_PO.PURCHASE_ORDER ||
    associatedPOPR?.isNonShortFallSettingFlow;

  const isJWODoc = Utility.isNotEmpty(associatedPOPR?.jobWorkOutOrderItems);

  let productCodesArr = [];
  if (isPO) {
    productCodesArr = associatedPOPR?.purchaseOrderItems?.map(
      (orderItem: any) => orderItem?.productCode
    );
  } else {
    productCodesArr = associatedPOPR?.purchaseRequestItems?.map(
      (orderItem: any) => orderItem?.productCode
    );
  }
  if (isJWODoc) {
    productCodesArr = associatedPOPR?.jobWorkOutOrderItems?.map(
      (orderItem: any) => orderItem?.productCode
    );
  }
  if (isJWODoc) {
    return {
      documentType: DOC_TYPE.JOB_WORK_OUT_ORDER,
      documentCode: associatedPOPR?.jwoCode,
      documentSequenceCode: associatedPOPR?.documentSequenceCode,
      productCodes: productCodesArr
    };
  } else {
    const existingLinkedDocDetails = associatedPOPR?.linkedDocuments?.find(
      (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
    );
    let existingLineItemDetails: any;
    if (!Utility.isEmpty(existingLinkedDocDetails)) {
      existingLineItemDetails = existingLinkedDocDetails?.lineItemDetails;
    }
    let linkObj = {
      documentType: isPO
        ? WORK_ORDER_PR_PO.PURCHASE_ORDER
        : WORK_ORDER_PR_PO.PURCHASE_REQUEST,
      documentCode: isPO
        ? associatedPOPR?.poCode
        : associatedPOPR?.purchaseRequestCode,
      documentSequenceCode: associatedPOPR?.documentSequenceCode,
      documentReceiveByDate: associatedPOPR?.receiveByDate,
      productCodes: productCodesArr,
      lineItemDetails: filterLinkedLineItems(
        isPO
          ? associatedPOPR?.purchaseOrderItems
          : associatedPOPR?.purchaseRequestItems,
        existingLineItemDetails
      )
    };
    if (
      Store.getState().authInfo.currentTenantInfo?.data?.additionalSettings
        ?.RESERVE_WO_LINKED_PO_QUANTITY
    ) {
      linkObj['lineItemDetails'] = filterLinkedLineItems(
        isPO
          ? associatedPOPR?.purchaseOrderItems
          : associatedPOPR?.purchaseRequestItems,
        existingLineItemDetails
      );
      return linkObj;
    } else {
      return linkObj;
    }
  }
};

const parseWorkOrderItemsForRequestPayload = (
  workOrder: IWorkOrder,
  pristineWorkOrderData: IWorkOrder
) => {
  const calculateReservedQuantitiesData = (workOrderItem: any): any[] => {
    const originalWorkOrderItem = pristineWorkOrderData?.workOrderItems?.find(
      (wo: any) =>
        wo?.itemName?.productId === workOrderItem?.itemName?.productId ||
        wo?.itemName?.pid === workOrderItem?.itemName?.pid
    );
    let reservedQuantities: any[] = [];
    const inventoryData =
      workOrderItem?.itemName?.advancedTracking === ADVANCE_TRACKING.NONE
        ? calculateNoneTrackedReservedQuantities(
            workOrderItem,
            originalWorkOrderItem
          )
        : calculateAdvancedTrackedReservedQuantities(
            workOrderItem,
            originalWorkOrderItem
          );
    reservedQuantities = inventoryData.map((inventoryItem: any) =>
      mapInventoryToReservedQtyData(inventoryItem, workOrderItem)
    );

    reservedQuantities = reservedQuantities?.map((reservedItem: any) => {
      return {
        ...reservedItem,
        reservedUomQuantity:
          workOrderItem?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL
            ? Utility.getUomQuantityWithoutRoundOff(
                reservedItem.reservedQuantity,
                workOrderItem.documentUOMSchemaDefinition
              )
            : reservedItem.reservedQuantity,
        availableUomQuantity: reservedItem?.availableQuantity //check on BE from this key
      };
    });

    return reservedQuantities;
  };

  const calculateReservedQuantitiesDataForSubstitute = (
    workOrderItem: any,
    substituteItem: any
  ) => {
    let reservedQuantities: any[] = [];

    const originalWorkOrderItem = pristineWorkOrderData?.workOrderItems?.find(
      (wo: any) =>
        wo?.itemName?.productId === workOrderItem?.itemName?.productId ||
        wo?.itemName?.pid === workOrderItem?.itemName?.pid
    );

    const originalSubstituteItem =
      originalWorkOrderItem?.bomProductSubstitutesDetails?.find(
        (subs: any) => substituteItem?.itemName?.pid === subs?.itemName?.pid
      );

    const adTracking =
      substituteItem?.itemName?.advancedTracking ??
      substituteItem?.advancedTracking;
    const inventoryData =
      adTracking === ADVANCE_TRACKING.NONE
        ? calculateNoneTrackedReservedQuantities(
            substituteItem,
            originalSubstituteItem
          )
        : calculateAdvancedTrackedReservedQuantities(
            substituteItem,
            originalSubstituteItem
          );

    reservedQuantities = inventoryData.map((inventoryItem: any) => {
      return {
        ...inventoryItem,
        reservedQuantity: Utility.roundingOff(
          inventoryItem?.reservedQuantity,
          QTY_ROUNDOFF_PRECISION
        ),
        productName:
          substituteItem.productName ?? substituteItem?.itemName?.name,
        productDocSeqCode:
          substituteItem.documentSequenceCode ??
          substituteItem?.itemName?.documentSequenceCode,
        reservedUomQuantity: inventoryItem?.reservedQuantity,
        advancedTrackingMetaDtos: inventoryItem.advancedTrackingData,
        advancedTrackingType: substituteItem?.itemName?.advancedTracking ?? null
      };
    });

    return reservedQuantities;
  };

  return workOrder?.workOrderItems?.map((item: any) => {
    const componentProductId =
      item.itemName?.pid ?? item.productDetails?.productId ?? item.productCode;
    let itemCopy: any = {
      itemId: item.itemName?.id,
      plannedQuantity: item.requiredQty || 0,
      plannedUomQuantity: item.requiredQty || 0,
      producedQuantity: item.actualRequiredQty || 0,
      producedUomQuantity: item.actualRequiredQty || 0,
      productCode: componentProductId,
      sourceWarehouseCode: item?.sourceWarehouse?.code,
      warehouseInventoryData: item?.warehouseInventoryData
        ? parseWarehouseInventoryData(
            item?.warehouseInventoryData,
            item?.itemName?.advancedTracking,
            item?.documentUOMSchemaDefinition
          )
        : [],
      productSubstitutesDetails:
        item?.bomProductSubstitutesDetails?.map((substituteItem: any) => {
          return {
            advancedTracking: substituteItem.advancedTracking ?? 'NONE',
            stockUom: substituteItem.stockUom,
            type: substituteItem.type,
            documentSequenceCode:
              substituteItem.documentSequenceCode ??
              substituteItem.productDocumentSeqCode,
            productDocSeqCode:
              substituteItem.documentSequenceCode ??
              substituteItem.productDocumentSeqCode,
            productCode: substituteItem.productCode || substituteItem.productId,
            productName:
              substituteItem.productName ?? substituteItem?.itemName?.name,
            productId: substituteItem.productId,
            pid: substituteItem.pid,
            productQuantity:
              reduceArrayByKey(
                substituteItem?.warehouseInventoryData,
                'quantity'
              ) ?? 0,
            sourceWarehouseCode: substituteItem.sourceWarehouseCode,
            warehouseInventoryData: substituteItem.warehouseInventoryData,
            reservedQuantitiesData:
              calculateReservedQuantitiesDataForSubstitute(
                item,
                substituteItem
              ),
            costPerUnit: substituteItem.costPerUnit,
            isProductPicked: substituteItem?.isProductPicked ?? false
          };
        }) || [],
      reservedQuantitiesData:
        item?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
          ? calculateReservedQuantitiesData(item)
          : [],
      productDocSeqCode: item?.itemName?.documentSequenceCode,
      productName: item?.itemName?.name,
      produceProductType: item?.produceProductType,
      costPerUnit:
        item?.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE ||
        item.itemName?.type === PRODUCT_TYPE.NON_TRACKED
          ? item?.costPerUnit ?? 0
          : item.itemName?.inventoryPrice ?? 0,
      workOrderItemCode: item?.workOrderItemCode,
      addToRequisition: item?.addToRequisition ?? false,
      sequenceNumber: item.sequenceNumber,
      quantity: item.componentProductUnitQty ?? item.quantity,
      uomQuantity: item.componentProductUnitQty ?? item.quantity,
      documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
      stockUom: item?.stockUom,
      bomMetaCode: item.bomMetaCode,
      innerBomProductDetails: item.innerBomProductDetails,
      isAdHocItem: item.isAdHocItem
    };
    if (item.isNewRow) {
      itemCopy.productId = componentProductId;
      itemCopy.productCode = componentProductId;
    }
    return itemCopy;
  });
};

export const createPayloadForWorkOrder = ({
  isAdhocEnabled,
  workOrdersArray,
  workOrderSourceDetailsFromProps,
  pristineWorkOrderData,
  isRequisitionPending,
  isFlowFromBomExplosion,
  workOrderFromProps,
  jwoDetails,
  isCopyMode
}: any) => {
  const payload: any[] = workOrdersArray.map(
    (workOrder: IWorkOrder, index: number) => {
      const workOrderItems = parseWorkOrderItemsForRequestPayload(
        workOrder,
        pristineWorkOrderData
      );
      let workOrderObj: any = {
        bomMetaCode: isAdhocEnabled
          ? null
          : workOrder?.bomMetaCode ?? workOrder?.selectedBom?.code,
        adhocBomMetaCode: isAdhocEnabled
          ? workOrder?.bomMetaCode ?? workOrder?.selectedBom?.code
          : null,
        parentWorkOrderCode: workOrder?.parentWorkOrderCode,
        documentSequenceCode: workOrder.documentSequenceCode,
        sequenceFormat: workOrder.sequenceFormat,
        productCode: workOrder.productCode || workOrder.product?.productId,
        productName: workOrder.productName,
        manufactureQuantity: workOrder.manufactureQuantity,
        actualQuantity: workOrder.actualQuantity,
        plannedYield: workOrder.plannedYield,
        actualYield: workOrder.actualYield,
        sourceWarehouseCode: workOrder.sourceWarehouseCode,
        inProgressWarehouseCode: workOrder.inProgressWarehouseCode,
        product: undefined, // remove from Req json Reason api fail  (Temporary added)
        targetWarehouse: undefined, // remove from Req json Reason api fail (Temporary added)
        advancedTrackingData: undefined, // remove from Req json Reason api fail (Temporary added)
        advancedTrackingCompleted: undefined, // remove from Req json Reason api fail (Temporary added)
        plannedEndDate: DateFormatService.getDateStrFromDate(
          new Date(workOrder.plannedEndDate),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        plannedStartDate: DateFormatService.getDateStrFromDate(
          new Date(workOrder.plannedStartDate),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        actualStartDate: DateFormatService.getDateStrFromDate(
          new Date(workOrder.actualStartDate),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        actualEndDate: DateFormatService.getDateStrFromDate(
          new Date(workOrder.actualEndDate),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        deliveryDate: DateFormatService.getDateStrFromDate(
          new Date(workOrder.deliveryDate),
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ),
        contactCode: workOrder?.contactsArr
          ?.map((cObj: any) => cObj?.code)
          ?.join(','),
        status: workOrder?.status ? workOrder?.status : WORK_ORDER_STATUS.OPEN,
        workOrderSourceDetails: workOrder?.workOrderSourceDetails,
        operationCostDetails: {
          ...workOrder.operationCostDetails,
          plannedOperatingCost: workOrder?.workOrderOperations?.reduce(
            (acc: number, operation: any) =>
              acc + (operation['operationCost'] || 0),
            0
          )
        },
        warehouseInventoryData:
          getWarehouseInventoryInformationForMainProduct(workOrder),
        taggedBinWarehouseInfo: workOrder?.taggedBinWarehouseInfo,
        workOrderItems: workOrderItems,
        workOrderOperations: workOrder?.workOrderOperations?.map(
          (operation: any) => {
            let taggedItemCode =
              operation[WORK_ORDER_OPERATION_TABLE.OPERATION_TAGGED_ITEM] ||
              null;

            if (taggedItemCode) {
              const linkedItem = workOrderItems?.find(
                (item) => item.productCode === taggedItemCode
              );
              /* If item code relates to work order component product,
              then only forwarding the same on operation level */
              taggedItemCode = linkedItem ? taggedItemCode : null;
            }

            return {
              completedQuantity: operation?.operationCompletedQty,
              operationCode: operation?.operationName?.operationCode,
              description: operation?.operationDescription,
              operationCost: operation?.operationCost,
              operatorCost: operation?.operatorCost,
              workstationCost: operation?.workstationCost,
              itemId: operation?.operationName?.id,
              operationId: operation?.operationName?.id,
              plannedStartTime: operation?.operationPlannedStartTime,
              plannedEndTime: operation?.operationPlannedEndTime,
              operationTime: isNaN(operation.operationTime)
                ? 0
                : Number(operation.operationTime),
              status: operation.operationStatus[0],
              [WORK_ORDER_OPERATION_TABLE.OPERATION_DEPENDENCY]:
                operation[WORK_ORDER_OPERATION_TABLE.OPERATION_DEPENDENCY],
              [WORK_ORDER_OPERATION_TABLE.OPERATION_PROCESS_TYPE]:
                operation.processType || null,
              productCode: taggedItemCode,
              [WORK_ORDER_OPERATION_TABLE.OPERATION_QC_NEEDED]:
                operation.qcNeeded || false
            };
          }
        ),
        reservedStock:
          workOrder?.reservedStock || isAnyStockedReserved(workOrderItems), // * this flag signifies if any stock reserved for WO or not
        workOrderChildDetails: workOrder?.workOrderChildDetails,
        includeByProductCost: workOrder?.includeByProductCost ?? false,
        additionalCharges: workOrder?.additionalCharges,
        isRequisitionPending: isRequisitionPending ?? false,
        linkedDocuments: workOrder?.linkedDocuments ?? [],
        jobWorkOutOrderCost: 0, // need to send this as 0 from UI (ZEN-11194)
        customField: workOrder?.customField ?? [],
        attachments:
          getNewlyUploadedAttachmentIds(
            workOrder?.attachments?.map(
              (attachment: any) => attachment?.attachmentId
            ),
            getAttachmentIdListFromAttachmentResponse(
              workOrderFromProps?.attachmentsResponses
            ),
            isCopyMode
          ) ?? [],
        productDocSeqCode: workOrder?.product?.documentSequenceCode,
        isAutoClosed:
          (workOrder?.parentWorkOrderCode && workOrder?.isAutoClosed) ?? false
      };

      if (workOrder?.status === WORK_ORDER_STATUS.IN_PROGRESS) {
        workOrderObj = {
          ...workOrderObj,
          actualStartDate: DateFormatService.getDateStrFromDate(
            new Date(),
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        };
      }
      if (Utility.isBlockedWOEnabled()) {
        workOrderObj = { ...workOrderObj, reserveChildQuantity: true };
      }

      if (isFlowFromBomExplosion) {
        workOrderObj = {
          ...workOrderObj,
          workOrderSourceDetails: workOrderSourceDetailsFromProps ?? []
        };
      }

      return workOrderObj;
    }
  );
  return payload;
};

const parseWarehouseInventoryData = (
  warehouseInventoryData: any,
  trackingType: ADVANCE_TRACKING,
  documentUOMSchemaDefinition: any
) => {
  if (trackingType === ADVANCE_TRACKING.NONE) {
    let result = (warehouseInventoryData || []).map(
      (warehouseInventoryObject: any) => {
        return {
          ...warehouseInventoryObject,
          quantity:
            warehouseInventoryObject.quantity > 0
              ? Utility.roundingOff(
                  warehouseInventoryObject.quantity,
                  QTY_ROUNDOFF_PRECISION
                )
              : warehouseInventoryObject.quantity
        };
      }
    );

    result = result
      ?.map((resultItem: any) => {
        return {
          ...resultItem,
          uomQuantity: resultItem.quantity
        };
      })
      ?.filter((itemRow: any) => {
        return itemRow?.quantity !== 0;
      });
    return result;
  }

  let result: any = [];
  warehouseInventoryData?.forEach((warehouseInventoryObject: any) => {
    let advanceTrackingObj =
      warehouseInventoryObject?.advancedTrackingData?.[0];
    let object = {
      advancedTrackingData: [
        {
          ...advanceTrackingObj,
          qtyToFulfilUom: advanceTrackingObj?.qtyToFulfil
        }
      ],
      id: null,
      advancedTrackingType: trackingType,
      quantity: Utility.roundingOff(
        warehouseInventoryObject?.quantity ||
          warehouseInventoryObject?.qtyToFulfil ||
          0,
        QTY_ROUNDOFF_PRECISION
      ),
      serialBatchNumber: warehouseInventoryObject?.serialBatchNumber,
      qtyToFulfil: Utility.roundingOff(
        warehouseInventoryObject?.qtyToFulfil || 0,
        QTY_ROUNDOFF_PRECISION
      ),
      batchSize: warehouseInventoryObject?.batchSize,
      manufacturingDate: warehouseInventoryObject?.manufacturingDate,
      expiryDate: warehouseInventoryObject?.expiryDate,
      costPerUnit: warehouseInventoryObject?.costPerUnit,
      rackCode: warehouseInventoryObject?.rackCode ?? null,
      rackName: warehouseInventoryObject?.rackName ?? null,
      rowCode: warehouseInventoryObject?.rowCode ?? null,
      rowName: warehouseInventoryObject?.rowName ?? null,
      binCode: warehouseInventoryObject?.binCode ?? null,
      binName: warehouseInventoryObject?.binName ?? null,
      warehouseName: warehouseInventoryObject?.warehouseName,
      warehouseCode: warehouseInventoryObject?.warehouseCode,
      uomQuantity:
        trackingType === ADVANCE_TRACKING.SERIAL
          ? Utility.getUomQuantityWithoutRoundOff(
              warehouseInventoryObject?.quantity,
              documentUOMSchemaDefinition
            )
          : warehouseInventoryObject?.quantity
    };
    result.push(object);
  });

  result = result?.filter((itemRow: any) => {
    return itemRow?.quantity !== 0;
  });

  return result;
};

const isAnyStockedReserved = (workOrderItems: any): Boolean => {
  const anySubReserved = (wo: any) =>
    wo?.productSubstitutesDetails?.some(
      (sub: any) => !isEmpty(sub?.reservedQuantitiesData)
    );
  const anyRawReserved = (wo: any) =>
    wo?.reservedQuantitiesData?.some(
      (item: any) => (item?.reservedQuantity || 0) !== 0
    );
  return workOrderItems?.some(
    (wo: any) => anyRawReserved(wo) || anySubReserved(wo)
  );
};

const getWarehouseInventoryInformationForMainProduct = (workOrder: any) => {
  let result: any = [];
  if (workOrder?.product?.advancedTracking === ADVANCE_TRACKING.NONE) {
    let totalQuantity = workOrder?.actualQuantity;
    if (
      WarehouseManagementHelper.isRRBEnabledForWarehouse(
        workOrder?.targetWarehouse
      )
    ) {
      totalQuantity = workOrder?.warehouseInventoryData?.reduce(
        (prev: number, current: any) => prev + Number(current?.quantity),
        0
      );
    }
    result =
      !Utility.isEmpty(workOrder?.warehouseInventoryData) &&
      !Utility.isWarehouseTaggingEnabled()
        ? workOrder?.warehouseInventoryData
        : [
            {
              warehouseCode: workOrder?.targetWarehouse?.code,
              warehouseName: workOrder?.targetWarehouse?.name,
              quantity: totalQuantity || workOrder?.actualQuantity, // when RRB enabled none tracked product has no Warehouse Inventory Data then we will assign actual qty to quantity because it is set undefined from previous reducer,
              advancedTrackingData: []
            }
          ];
  } else {
    result = !Utility.isEmpty(workOrder?.advancedTrackingData)
      ? workOrder?.advancedTrackingData?.map((item: any) => {
          return {
            advancedTrackingData: [
              {
                expiryDate: item?.expiryDate,
                batchSize: item?.batchSize,
                manufacturingDate: item?.manufacturingDate,
                qtyToFulfil: item?.qtyToFulfil,
                serialBatchNumber: item?.serialBatchNumber,
                customField: item?.customField
              }
            ],
            quantity: item?.qtyToFulfil,
            warehouseCode: item?.warehouseCode,
            warehouseName: item?.warehouseName,
            rowName: item?.row?.name,
            rowCode: item?.row?.code,
            rackName: item?.rack?.name,
            rackCode: item?.rack?.code,
            binName: item?.bin?.name,
            binCode: item?.bin?.code
          };
        })
      : [
          {
            advancedTrackingData: workOrder?.advancedTrackingData
              ? workOrder?.advancedTrackingData
              : [],
            quantity: isNaN(workOrder?.actualQuantity)
              ? 0
              : Number(workOrder?.actualQuantity),
            warehouseCode: workOrder?.targetWarehouse?.code,
            warehouseName: workOrder?.targetWarehouse?.name,
            rowCode: null,
            rackCode: null,
            binCode: null
          }
        ];
  }

  result = result?.map((resultItem: any) => {
    return {
      ...resultItem,
      uomQuantity: resultItem.quantity
    };
  });

  return result;
};

export const getNewlyUploadedAttachmentIds = (
  allAttachmentIds: any,
  existingAttachmentIds: any,
  isCopyMode:any
) => {
  const uniqueValues = [];
  if (Utility.isEmpty(allAttachmentIds)) allAttachmentIds = [];
  if (Utility.isEmpty(existingAttachmentIds)) existingAttachmentIds = [];

  for (let i = 0; i < allAttachmentIds.length; i++) {
    if (!existingAttachmentIds.includes(allAttachmentIds[i]) || isCopyMode) {
      uniqueValues.push(allAttachmentIds[i]);
    }
  }
  return uniqueValues;
};

export const workOrderSourceDetailsByDocCodeAndType = async (
  documentCode: string[],
  docType: DOC_TYPE
) => {
  try {
    const woSourceDetails =
      await WorkOrderService.getWOSourceDetailsByDocCodeAndType(
        documentCode,
        docType
      );
    return woSourceDetails;
  } catch (err: any) {
    console.error('Error while fetching SO source info: ', err);
  }
};

export const generateSequenceNumberForWorkOrderItem = (
  workOrderItems: IWorkOrderItems[]
) => {
  const maximumSequenceNumber = workOrderItems?.reduce(
    (prev: any, current: any) => {
      return prev && prev > current.sequenceNumber
        ? prev
        : current.sequenceNumber;
    },
    0
  );

  if (Utility.isNullish(maximumSequenceNumber)) {
    return getRandomNumber(2);
  }

  return maximumSequenceNumber + 1;
};

export const mergeAvailableAndPickedSubstituteDetailsFromExplosionData = (
  bomExplosionProductData: any
) => {
  const allSubstituteDetails =
    bomExplosionProductData?.[
      BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_SUBSTITUTES
    ] || [];
  const pickedSubstituteDetailById: { [key: string]: any } = {};
  (
    bomExplosionProductData?.[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES] ||
    []
  ).forEach((pickedSubstitute: any) => {
    pickedSubstituteDetailById[pickedSubstitute?.productId] = pickedSubstitute;
  });

  return allSubstituteDetails.map((availableSubstitute: any) => ({
    ...availableSubstitute,
    ...(pickedSubstituteDetailById[availableSubstitute?.productId] || {})
  }));
};

export const mergeAndGetSelectedSubstitutesForRawMaterialRow = (
  bomExplosionData: any,
  existingMaterialData: any
) => {
  let availableSubstitutes = bomExplosionData
    ? mergeAvailableAndPickedSubstituteDetailsFromExplosionData(
        bomExplosionData
      )
    : existingMaterialData?.[
        REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS
      ];
  const selectedSubstitutes = (availableSubstitutes || []).map(
    (substitute: any) => {
      substitute = {
        ...substitute,
        documentSequenceCode:
          substitute.documentSequenceCode ?? substitute.productDocumentSeqCode,
        [REQUIRED_ITEM_TABLE.REQUIRED_QTY]:
          substitute[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] ??
          substitute.productQuantity,
        productQuantity:
          substitute[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] ??
          substitute.productQuantity
      };
      const keysToRemove = [
        'uniqueId',
        'level',
        'pid',
        BOM_EXPLOSION_COLUMN_KEYS.UOM,
        BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_UNIT_QUANTITY,
        BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY,
        BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE
      ];
      keysToRemove.forEach((key) => delete substitute[key]);
      return substitute;
    }
  );

  return selectedSubstitutes;
};

export const getAdhocRawMaterialRow = ({
  bomExplosionData,
  workOrderData,
  produceProductType,
  existingMaterialData
}: {
  bomExplosionData?: any;
  workOrderData: IWorkOrder;
  produceProductType: PRODUCE_PRODUCT_TYPE;
  existingMaterialData?: any;
}) => {
  const warehouseData = Store.getState().warehouse.data?.content || [];
  let selectedWarehouse = null;
  if (bomExplosionData?.productDetails) {
    selectedWarehouse = warehouseData.find(
      (warehouse: any) =>
        warehouse.code ===
        bomExplosionData.productDetails.inventory?.warehouseCode
    );
  }

  /** Merge available & picked substitutes in one from bom explosion data..  */

  const requiredQty = Number(
    bomExplosionData?.[BOM_EXPLOSION_COLUMN_KEYS.REQUIRED_QUANTITY] ??
      existingMaterialData?.requiredQty ??
      0
  );
  let unitRequiredQty = Utility.roundOff(
    requiredQty / Number(workOrderData?.manufactureQuantity ?? 1),
    QTY_ROUNDOFF_PRECISION
  );

  const invalidFields = [];

  if (!bomExplosionData) {
    invalidFields.push(REQUIRED_ITEM_TABLE.ITEM_NAME);
  }

  if (!requiredQty) {
    invalidFields.push(REQUIRED_ITEM_TABLE.REQUIRED_QTY);
  }

  const selectedSubstitutes = mergeAndGetSelectedSubstitutesForRawMaterialRow(
    bomExplosionData,
    existingMaterialData
  );

  return {
    productCode:
      bomExplosionData?.pid ??
      bomExplosionData?.productCode ??
      bomExplosionData?.productDetails?.productId ??
      existingMaterialData?.itemName?.pid ??
      existingMaterialData?.itemName?.productId,
    sequenceNumber: generateSequenceNumberForWorkOrderItem(
      workOrderData?.workOrderItems || []
    ),
    itemName:
      bomExplosionData?.productDetails ?? existingMaterialData?.itemName ?? {},
    sourceWarehouse:
      selectedWarehouse ?? existingMaterialData?.selectedWarehouse ?? {},
    bomProductSubstitutesDetails: selectedSubstitutes,
    warehouseInventoryData:
      bomExplosionData?.warehouseInventoryData ??
      existingMaterialData?.warehouseInventoryData ??
      [],
    reservedQuantitiesData: existingMaterialData?.reservedQuantitiesData,
    availableQty: Utility.isNotEmpty(
      bomExplosionData?.documentUOMSchemaDefinition
    )
      ? bomExplosionData?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_UOM_QUANTITY]
      : bomExplosionData?.[BOM_EXPLOSION_COLUMN_KEYS.AVAILABLE_QUANTITY] ??
        existingMaterialData?.availableQty ??
        0,
    requiredQty: requiredQty,
    producedQuantity: requiredQty,
    plannedQuantity: requiredQty,
    actualRequiredQty: requiredQty,
    quantity: unitRequiredQty,
    componentProductUnitQty: unitRequiredQty,
    costPerUnit:
      (bomExplosionData?.productType === PRODUCT_TYPE.NON_TRACKED
        ? bomExplosionData?.costPerUnit
        : existingMaterialData?.costPerUnit) || 0,
    stockUom: bomExplosionData?.stockUom ?? 2,
    produceProductType:
      bomExplosionData?.produceProductType ?? produceProductType,
    workOrderItemCode: existingMaterialData?.workOrderItemCode,
    addToRequisition: bomExplosionData?.addToRequisition ?? false,
    isNewRow:
      Utility.isEmpty(existingMaterialData) || existingMaterialData.isNewRow,
    bomExplosionData: bomExplosionData,
    bomMetaCode:
      bomExplosionData?.bomMetaCode ??
      existingMaterialData?.bomMetaCode ??
      null,
    productDetails:
      bomExplosionData?.productDetails ?? existingMaterialData?.productDetails,
    invalidFields: invalidFields,
    documentUOMSchemaDefinition:
      bomExplosionData?.documentUOMSchemaDefinition ?? null
  };
};

export const initialStateForOperation = {
  operationName: {
    name: '',
    description: null,
    isCorrective: false,
    defaultWorkstation: 0,
    operationTime: 0,
    operationTimeUnit: null,
    operationJobCard: null,
    subOperations: null,
    operators: [],
    fixedRate: 0,
    costPerHour: 0,
    isDeleted: false,
    operationCode: '',
    workstationName: '',
    workstationDetails: {},
    instructions: '',
    attachments: [],
    attachmentIds: null,
    customField: null
  },
  operationTime: 0,
  operationStatus: ['PENDING'],
  operationCost: 0,
  operatorCost: 0,
  operationCompletedQty: 0,
  operationDescription: null,
  operationIndex: 1,
  workstationCost: 0
};

const getAllProductIdForWorkOrderItems = (
  woList: IWorkOrder[],
  activeTabIndex: number = 0
) => {
  const pidArrayForWorkOrderItems = woList?.[
    activeTabIndex
  ]?.workOrderItems?.map((item: any) => item?.itemName?.pid);
  const productIdArrayForWOSubstitutes =
    woList?.[activeTabIndex]?.workOrderItems?.map((item: any) => {
      if (item?.bomProductSubstitutesDetails?.length > 0) {
        return item.bomProductSubstitutesDetails?.map(
          (substitute: any) => substitute.productId
        );
      }
      return [];
    }) ?? [];
  const flattenedProductIdArrayForWOSubstitutes =
    productIdArrayForWOSubstitutes.flat();
  const allProductIds = pidArrayForWorkOrderItems?.concat(
    flattenedProductIdArrayForWOSubstitutes
  );

  return allProductIds;
};

const getAllWarehouseDetails = async (
  productIds: string[],
  woList: IWorkOrder[],
  activeTabIndex: number
) => {
  showLoader('Hold on! We are allocating the quantities meanwhile.');
  const warehouseDetails = await ProductService.fetchWarehouseProductsByID(
    productIds
  );
  removeLoader();

  let productWarehouseData = [];
  if (Utility.isRRBTaggingEnabled() || Utility.isWarehouseTaggingEnabled()) {
    productWarehouseData = warehouseDetails?.warehouses?.filter(
      (wh: any) => wh.code === woList?.[activeTabIndex]?.targetWarehouse?.code
    );
  } else {
    productWarehouseData = warehouseDetails?.warehouses;
  }
  return productWarehouseData;
};

const updateWHInventoryData = (
  workOrderItem: any,
  workOrderSubstituteItem: any,
  allocation: any[],
  type: any,
  isSubstitute: boolean,
  pristineWorkOrderData: any,
  activeTabIndex: number
) => {
  if (!isSubstitute) {
    if (type === ADVANCE_TRACKING.NONE) {
      const item = {
        ...workOrderItem,
        warehouseInventoryData: allocation?.map((resultItem: any) => {
          return {
            ...resultItem,
            quantity: resultItem?.quantity,
            warehouseCode: resultItem?.warehouseCode,
            warehouseName: resultItem?.warehouseName
          };
        })
      };
      return item;
    } else {
      const item = {
        ...workOrderItem,
        warehouseInventoryData: allocation?.map((resultItem: any) => {
          return {
            ...resultItem,
            quantity: resultItem?.qtyToFulfil,
            warehouseCode: resultItem?.warehouseCode,
            warehouseName: resultItem?.warehouseName
          };
        })
      };
      return item;
    }
  } else {
    let allocationWithSpecificFields: any[] = [];
    if (type === ADVANCE_TRACKING.NONE) {
      allocationWithSpecificFields =
        allocation?.map((resultItem: any) => {
          return {
            ...resultItem,
            quantity: resultItem?.quantity,
            warehouseCode: resultItem?.warehouseCode,
            warehouseName: resultItem?.warehouseName
          };
        }) ?? [];
    } else {
      allocationWithSpecificFields =
        allocation?.map((resultItem: any) => {
          return {
            ...resultItem,
            quantity: resultItem?.qtyToFulfil,
            warehouseCode: resultItem?.warehouseCode,
            warehouseName: resultItem?.warehouseName
          };
        }) ?? [];
    }

    const existingWOItem = pristineWorkOrderData?.[
      activeTabIndex
    ]?.workOrderItems?.find((itemRow: any) => {
      return itemRow.itemName.productId === workOrderItem?.itemName?.productId;
    });
    const productQuantity = allocationWithSpecificFields?.reduce(
      (acc: any, obj: any) => {
        let sum = Number(acc) + Number(obj?.quantity);
        return sum ?? 0;
      },
      0
    );
    const item = {
      ...workOrderSubstituteItem,
      isProductPicked:
        workOrderSubstituteItem?.isProductPicked ?? productQuantity > 0,
      productQuantity: productQuantity,
      warehouseInventoryData: allocationWithSpecificFields,
      reservedQuantitiesData: calculateReservedQuantitiesDataForSubstitutes(
        {
          ...workOrderSubstituteItem,
          warehouseInventoryData: allocationWithSpecificFields
        },
        existingWOItem
      )
    };

    return item;
  }
};

const getFlatArrayForObjectAndKey = (obj: any, key: any, type: any) => {
  let mapped;
  if (type === ADVANCE_TRACKING.BATCH || type === ADVANCE_TRACKING.SERIAL) {
    mapped = obj?.map((item: any) => {
      return item?.[key]?.map((lineItem: any) => {
        return {
          ...lineItem,
          ...item,
          warehouseName: item.warehouseName,
          warehouseCode: item.warehouseCode
        };
      });
    });
  } else {
    mapped = obj?.map((item: any) => {
      return item?.[key];
    });
  }

  let flatArray = mapped?.flat() || [];
  return flatArray;
};

const createProductItemForTrackingForAutoAllocate = (
  selectedProduct: any,
  type: any
) => {
  return {
    product: {
      ...selectedProduct?.itemName,
      name: selectedProduct?.itemName?.name
    },
    productCode: selectedProduct?.itemName?.pid,
    requiredQuantity:
      selectedProduct?.requiredQty -
      calculateTotalSubstituteAlloted(selectedProduct),
    productQuantity:
      selectedProduct.requiredQty -
      calculateTotalSubstituteAlloted(selectedProduct),
    advancedTracking: selectedProduct?.itemName?.advancedTracking,
    advancedTrackingFulfilmentData: getFlatArrayForObjectAndKey(
      selectedProduct?.warehouseInventoryData,
      'advancedTrackingData',
      type
    ),
    warehouseInventoryData: selectedProduct?.warehouseInventoryData ?? [],
    reservedQuantitiesData: getFlatArrayForObjectAndKey(
      selectedProduct?.reservedQuantitiesData,
      'advancedTrackingMetaDtos',
      type
    ),
    isQuickCommit: false
  };
};

const normalTrackingProductAllocation = (
  workOrderItem: any,
  workOrderSubstituteItem: any,
  allWarehouseDetails: any,
  isSubstitute: boolean,
  allLoopedAndAllocatedResultQty: any
) => {
  let result: any = [];

  let selectedObj;

  if (!isSubstitute) {
    selectedObj = {
      ...workOrderItem?.itemName,
      pendingQuantity:
        workOrderItem.requiredQty -
        calculateTotalSubstituteAlloted(workOrderItem),
      productCode: workOrderItem?.itemName?.pid
    };
    if (!Utility.isRRBEnabledForCurrentTenant()) {
      result = Utility.masterAutoAllocateNoneTrackedWithoutRRB(
        allWarehouseDetails,
        selectedObj,
        workOrderItem?.unmodifiedSelectedProduct ?? []
      );
    } else {
      result = Utility.masterAutoAllocateNoneTrackedWithRRB(
        allWarehouseDetails,
        selectedObj,
        workOrderItem?.unmodifiedSelectedProduct ?? []
      );
    }
  } else {
    const qty =
      workOrderSubstituteItem?.unmodifiedSelectedProduct?.reduce(
        (acc: any, obj: any) => {
          return acc + obj?.quantity;
        },
        0
      ) ?? 0;
    selectedObj = {
      ...workOrderSubstituteItem,
      pendingQuantity:
        workOrderItem?.requiredQty !== allLoopedAndAllocatedResultQty
          ? workOrderItem?.requiredQty - allLoopedAndAllocatedResultQty - qty
          : 0,
      productCode: workOrderSubstituteItem?.productId
    };
    if (!Utility.isRRBEnabledForCurrentTenant()) {
      result = Utility.masterAutoAllocateNoneTrackedWithoutRRB(
        allWarehouseDetails,
        selectedObj,
        workOrderSubstituteItem?.unmodifiedSelectedProduct ?? []
      );
    } else {
      result = Utility.masterAutoAllocateNoneTrackedWithRRB(
        allWarehouseDetails,
        selectedObj,
        workOrderSubstituteItem?.unmodifiedSelectedProduct ?? []
      );
    }
  }

  return result;
};

const advanceTrackingProductAllocation = (
  workOrderItem: any,
  workOrderSubstituteItem: any,
  allWarehouseDetails: any,
  type: any,
  isSubstitute: boolean,
  allLoopedAndAllocatedResultQty: any
) => {
  let result = [];

  if (!isSubstitute) {
    const selectedObj = createProductItemForTrackingForAutoAllocate(
      workOrderItem,
      type
    );

    if (type === ADVANCE_TRACKING.SERIAL) {
      result = Utility.masterAutoAllocateSerialTracked(
        allWarehouseDetails,
        selectedObj,
        workOrderItem?.unmodifiedSelectedProduct,
        workOrderItem?.itemName?.pid
      );
    }

    if (type === ADVANCE_TRACKING.BATCH) {
      result = Utility.masterAutoAllocateBatchTracked(
        allWarehouseDetails,
        selectedObj,
        workOrderItem?.unmodifiedSelectedProduct,
        workOrderItem?.itemName?.pid
      );
    }
  } else {
    const reqQty =
      workOrderItem?.requiredQty !== allLoopedAndAllocatedResultQty
        ? workOrderItem?.requiredQty - allLoopedAndAllocatedResultQty
        : 0;
    const selectedObj = createProductItemForTrackingForAutoAllocate(
      {
        ...workOrderSubstituteItem,
        pid: workOrderSubstituteItem?.productId,
        requiredQuantity: reqQty,
        requiredQty: reqQty
      },
      type
    );

    result = Utility.masterAutoAllocateSerialTracked(
      allWarehouseDetails,
      selectedObj,
      workOrderSubstituteItem?.unmodifiedSelectedProduct ?? [],
      workOrderSubstituteItem?.productId
    );
  }

  return result;
};

const allocateMaterialBasedOnTrackingType = (
  workOrderItemsList: any,
  allWarehouseDetails: any,
  pristineWorkOrderData: any,
  activeTabIndex: any
) => {
  let workOrderItemsListCopy = [...workOrderItemsList];
  workOrderItemsListCopy?.forEach(
    (workOrderItem: any, workOrderItemIndex: number) => {
      let allLoopedAndAllocatedResultQty = 0;
      if (workOrderItem?.itemName?.advancedTracking === ADVANCE_TRACKING.NONE) {
        const result = normalTrackingProductAllocation(
          workOrderItem,
          null,
          allWarehouseDetails,
          false,
          allLoopedAndAllocatedResultQty
        );
        allLoopedAndAllocatedResultQty +=
          result?.reduce((acc: any, item: any) => {
            return acc + (item.quantity ?? 0);
          }, 0) ?? 0;
        workOrderItemsListCopy[workOrderItemIndex] = updateWHInventoryData(
          workOrderItem,
          null,
          result,
          ADVANCE_TRACKING.NONE,
          false,
          pristineWorkOrderData,
          activeTabIndex
        );
      }

      if (
        workOrderItem?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL
      ) {
        const result = advanceTrackingProductAllocation(
          workOrderItem,
          null,
          allWarehouseDetails,
          ADVANCE_TRACKING.SERIAL,
          false,
          allLoopedAndAllocatedResultQty
        );
        allLoopedAndAllocatedResultQty +=
          result?.reduce((acc: any, item: any) => {
            return acc + (item.qtyToFulfil ?? 0);
          }, 0) ?? 0;
        workOrderItemsListCopy[workOrderItemIndex] = updateWHInventoryData(
          workOrderItem,
          null,
          result,
          ADVANCE_TRACKING.SERIAL,
          false,
          pristineWorkOrderData,
          activeTabIndex
        );
      }

      if (
        workOrderItem?.itemName?.advancedTracking === ADVANCE_TRACKING.BATCH
      ) {
        const result = advanceTrackingProductAllocation(
          workOrderItem,
          null,
          allWarehouseDetails,
          ADVANCE_TRACKING.BATCH,
          false,
          allLoopedAndAllocatedResultQty
        );
        allLoopedAndAllocatedResultQty +=
          result?.reduce((acc: any, item: any) => {
            return acc + (item.qtyToFulfil ?? 0);
          }, 0) ?? 0;
        workOrderItemsListCopy[workOrderItemIndex] = updateWHInventoryData(
          workOrderItem,
          null,
          result,
          ADVANCE_TRACKING.BATCH,
          false,
          pristineWorkOrderData,
          activeTabIndex
        );
      }

      if (!Utility.isEmpty(workOrderItem?.bomProductSubstitutesDetails)) {
        let workOrderItemSubstituteArr = [
          ...workOrderItem?.bomProductSubstitutesDetails
        ];

        let substitutesWithWarehouseInventoryData: any[] = [];
        workOrderItemSubstituteArr?.forEach((substitueItem: any) => {
          if (
            substitueItem?.advancedTracking === ADVANCE_TRACKING.NONE &&
            substitueItem?.isProductPicked
          ) {
            const result = normalTrackingProductAllocation(
              workOrderItem,
              substitueItem,
              allWarehouseDetails,
              true,
              allLoopedAndAllocatedResultQty
            );
            allLoopedAndAllocatedResultQty +=
              result?.reduce((acc: any, item: any) => {
                return acc + (item.quantity ?? 0);
              }, 0) ?? 0;

            substitueItem = updateWHInventoryData(
              workOrderItem,
              substitueItem,
              result,
              ADVANCE_TRACKING.NONE,
              true,
              pristineWorkOrderData,
              activeTabIndex
            );
            substitutesWithWarehouseInventoryData.push(substitueItem);
          }

          if (
            substitueItem?.advancedTracking === ADVANCE_TRACKING.SERIAL &&
            substitueItem?.isProductPicked
          ) {
            const result = advanceTrackingProductAllocation(
              workOrderItem,
              substitueItem,
              allWarehouseDetails,
              ADVANCE_TRACKING.SERIAL,
              true,
              allLoopedAndAllocatedResultQty
            );

            allLoopedAndAllocatedResultQty +=
              result?.reduce((acc: any, item: any) => {
                return acc + (item.qtyToFulfil ?? 0);
              }, 0) ?? 0;

            substitueItem = updateWHInventoryData(
              workOrderItem,
              substitueItem,
              result,
              ADVANCE_TRACKING.SERIAL,
              true,
              pristineWorkOrderData,
              activeTabIndex
            );
            substitutesWithWarehouseInventoryData.push(substitueItem);
          }

          if (
            substitueItem?.advancedTracking === ADVANCE_TRACKING.BATCH &&
            substitueItem?.isProductPicked
          ) {
            const result = advanceTrackingProductAllocation(
              workOrderItem,
              substitueItem,
              allWarehouseDetails,
              ADVANCE_TRACKING.BATCH,
              true,
              allLoopedAndAllocatedResultQty
            );

            allLoopedAndAllocatedResultQty +=
              result?.reduce((acc: any, item: any) => {
                return acc + (item.qtyToFulfil ?? 0);
              }, 0) ?? 0;

            substitueItem = updateWHInventoryData(
              workOrderItem,
              substitueItem,
              result,
              ADVANCE_TRACKING.BATCH,
              true,
              pristineWorkOrderData,
              activeTabIndex
            );
            substitutesWithWarehouseInventoryData.push(substitueItem);
          }
        });
        workOrderItemsListCopy[workOrderItemIndex] = {
          ...workOrderItemsListCopy[workOrderItemIndex],
          bomProductSubstitutesDetails:
            workOrderItem?.bomProductSubstitutesDetails?.map(
              (subsItem: any) => {
                const found = substitutesWithWarehouseInventoryData?.find(
                  (i: any) => i.productId === subsItem.productId
                );
                if (Utility.isNotEmpty(found)) {
                  subsItem = found;
                }
                return subsItem;
              }
            )
        };
      }
    }
  );

  return workOrderItemsListCopy;
};

const getWorkOrderItemsWithUnmodifiedWarehouseInventoryData = (
  workOrderItems: any[]
) => {
  return workOrderItems?.map((item: any) => {
    return {
      ...item,
      unmodifiedSelectedProduct: item?.warehouseInventoryData,
      bomProductSubstitutesDetails: item?.bomProductSubstitutesDetails?.map(
        (substituteItem: any) => {
          return {
            ...substituteItem,
            unmodifiedSelectedProduct: substituteItem?.warehouseInventoryData
          };
        }
      )
    };
  });
};

export const autoAllocateStockToWorkOrder = async (
  workOrderList: IWorkOrder[],
  activeTabIndex: number,
  pristineWorkOrderData: IWorkOrder[]
) => {
  const allProductIdsForWO =
    getAllProductIdForWorkOrderItems(workOrderList, activeTabIndex) ?? [];

  if (Utility.isEmpty(allProductIdsForWO)) {
    showAlert(
      'Please note !',
      `There are no raw materials assigned to component products.`
    );
    return;
  }

  const allWarehouseDetails = await getAllWarehouseDetails(
    allProductIdsForWO,
    workOrderList,
    activeTabIndex
  );

  const updatedWorkOrderItems = allocateMaterialBasedOnTrackingType(
    getWorkOrderItemsWithUnmodifiedWarehouseInventoryData(
      workOrderList[activeTabIndex].workOrderItems ?? []
    ),
    allWarehouseDetails,
    pristineWorkOrderData,
    activeTabIndex
  );

  return updatedWorkOrderItems;
};

export const validateSelectedInvoiceForInterLinkedWO = async (
  invoice: Invoice,
  updateCallback: () => void,
  resetCallback?: () => void
): Promise<void> => {
  const soLinkedToInvoice = invoice?.linkedDocuments?.filter(
    (doc: any) => doc.documentType === DOC_TYPE.SALES_ORDER
  );
  if (soLinkedToInvoice && soLinkedToInvoice?.length) {
    const { documentCode, documentSequenceCode } = soLinkedToInvoice?.[0];
    try {
      const workOrderSourceDetails =
        await workOrderSourceDetailsByDocCodeAndType(
          [documentCode],
          DOC_TYPE.SALES_ORDER
        );
      if (!workOrderSourceDetails?.length) {
        updateCallback();
      } else {
        // reset selections
        if (resetCallback) {
          resetCallback();
        }
        showAlert(
          'Work Order exists',
          `Work order cannot be created.<br>The sales order (${documentSequenceCode}) linked to this invoice(${invoice.documentSequenceCode}) already has a linked work order.`
        );
      }
    } catch (err: any) {
      // reset selections
      if (resetCallback) {
        resetCallback();
      }
      showAlert(
        'Error',
        'Error while fetching linked document details for creating work order.'
      );
    }
  } else {
    updateCallback();
  }
};

export const validateSelectedSOForInterLinkedWO = async (
  salesOrder: ISalesOrder,
  updateCallback: () => void,
  resetCallback?: () => void
): Promise<void> => {
  const invoicesLinkedToSO = salesOrder?.linkedSalesInvoices ?? [];
  if (invoicesLinkedToSO && invoicesLinkedToSO?.length) {
    const documentCodes = invoicesLinkedToSO.map(
      (inv: any) => inv.salesInvoiceCode
    );
    try {
      const workOrderSourceDetails =
        await workOrderSourceDetailsByDocCodeAndType(
          documentCodes,
          DOC_TYPE.INVOICE
        );
      if (!workOrderSourceDetails?.length) {
        updateCallback();
      } else {
        // reset selections
        if (resetCallback) {
          resetCallback();
        }
        const invoiceTxt = workOrderSourceDetails?.length
          ? 'Invoices'
          : 'The invoice';
        const invoiceSeqCodes = workOrderSourceDetails
          .map((obj: any) => obj?.salesOrderSequenceCode)
          .join(', ');

        showAlert(
          'Work Order exists',
          `Work order cannot be created.<br>${invoiceTxt} (${invoiceSeqCodes}) linked to this sales order(${salesOrder.documentSequenceCode}) already has linked work order.`
        );
      }
    } catch (err: any) {
      // reset selections
      if (resetCallback) {
        resetCallback();
      }
      showAlert(
        'Error',
        'Error while fetching linked document details for creating work order.'
      );
    }
  } else {
    updateCallback();
  }
};

export const isAnyMaterialTaggedToJC = (jobCardList: any) => {
  const isMaterialTagged = jobCardList?.some((jobCard: any) => {
    return Utility.isNotEmpty(jobCard?.jobCardLinkDetails);
  });

  return isMaterialTagged;
};

export const getJWOCostWithoutTax = (jwoDetails: any[]) => {
  let jwoCostWithoutTax = 0;

  jwoDetails?.forEach((jwoItem: any) => {
    jwoItem?.jobWorkOutOrderItems?.forEach((jwoOrderItem: any) => {
      const orderItemCostWithoutTax =
        jwoOrderItem?.totalAmount ?? 0 - jwoOrderItem?.taxAmount ?? 0;
      jwoCostWithoutTax = jwoCostWithoutTax + orderItemCostWithoutTax;
    });
  });

  return jwoCostWithoutTax ?? 0;
};

export const getJWOCostForLineItemWithoutTax = (
  jobWorkoutOrderItems: any[]
) => {
  let jwoLineItemCostWithoutTax = 0;

  jobWorkoutOrderItems?.forEach((orderItem: any) => {
    const amountWithoutTax =
      orderItem?.totalAmount ?? 0 - orderItem?.taxAmount ?? 0;

    jwoLineItemCostWithoutTax = jwoLineItemCostWithoutTax + amountWithoutTax;
  });

  return jwoLineItemCostWithoutTax;
};

export const getAllSalesOrdersForSelectedMaterial = async (
  productCode: string
) => {
  const salesOrders = await getSOByProductCode(productCode);
  return salesOrders;
};

export const salesOrderSelectedFromCheckboxes = (
  salesOrders: ISalesOrder[]
) => {
  let selectedSOArr: ISalesOrder[] = [];
  salesOrders?.forEach((so: any) => {
    if (so?.selectedForWO) {
      selectedSOArr.push(so);
    }
  });
  return selectedSOArr?.length;
};

export const getConsolidatedSalesOrder = (
  salesOrder: ISalesOrder[],
  productIdToFind: any
) => {
  let found = salesOrder?.find((order: ISalesOrder) => {
    return order?.salesOrderItems?.find((orderItem: SalesOrderItemsEntity) => {
      return orderItem?.productCode === productIdToFind;
    });
  });
  return found;
};

export const getSOWithCommonProduct = (
  salesOrder: ISalesOrder[],
  codeToFind: string
) => {
  let commonProductSOs: any = [];

  salesOrder?.forEach((order: ISalesOrder) => {
    order?.salesOrderItems?.forEach((orderItem: SalesOrderItemsEntity) => {
      if (orderItem?.productCode === codeToFind) {
        commonProductSOs.push(order);
      }
    });
  });

  return commonProductSOs;
};

export const populateInnerBomConfigurationInWoItemsFromBomExplosionConfig = (
  workOrderItem: any
) => {
  const bomExplosionConfig = workOrderItem?.bomExplosionData;
  if (Utility.isEmpty(bomExplosionConfig)) return workOrderItem;

  function innerConfigModelHelper(componentOrSubstituteProduct: any) {
    const subComponentDetails: any = {
      productId:
        componentOrSubstituteProduct.pid ??
        componentOrSubstituteProduct.productId,
      isSubstitute: Boolean(
        componentOrSubstituteProduct[BOM_EXPLOSION_COLUMN_KEYS.SUBSTITUTE]
      )
    };
    if (
      Utility.isNotEmpty(
        componentOrSubstituteProduct[
          BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS
        ]
      )
    ) {
      subComponentDetails.innerBomProductDetails =
        getNestedInnerBomConfiguration(componentOrSubstituteProduct);
    }

    if (
      Utility.isNotEmpty(
        componentOrSubstituteProduct[
          BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
        ]
      )
    ) {
      subComponentDetails[REQUIRED_ITEM_TABLE.BOM_PRODUCT_SUBSTITUTE_DETAILS] =
        (
          componentOrSubstituteProduct[
            BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES
          ] || []
        ).filter((substitute: any) => substitute && substitute.isProductPicked);
    }
    return subComponentDetails;
  }

  function getNestedInnerBomConfiguration(bomExplosionData: any) {
    const innerBomProductDetails: any[] = [];

    bomExplosionData[BOM_EXPLOSION_COLUMN_KEYS.COMPONENT_PRODUCTS]?.forEach(
      (componentProduct: any) => {
        innerBomProductDetails.push(innerConfigModelHelper(componentProduct));
      }
    );
    bomExplosionData[BOM_EXPLOSION_COLUMN_KEYS.ASSIGNED_SUBSTITUTES]?.forEach(
      (componentProduct: any) => {
        innerBomProductDetails.push(innerConfigModelHelper(componentProduct));
      }
    );
    return innerBomProductDetails;
  }

  workOrderItem.innerBomProductDetails =
    getNestedInnerBomConfiguration(bomExplosionConfig);

  if (workOrderItem.bomExplosionData && workOrderItem.innerBomProductDetails) {
    workOrderItem.bomExplosionData.innerBomProductDetails =
      workOrderItem.innerBomProductDetails;
  }

  return workOrderItem;
};

export const isAutoCloseWOEnabled = () => {
  let isEnabled =
    Store.getState().authInfo.currentTenantInfo?.data?.additionalSettings
      ?.AUTO_CLOSE_CHILD_WORK_ORDER ?? false;

  return isEnabled;
};

export const hasAnyActiveJobCard = (allJCs: any[]) => {
  const isAnyActive = allJCs?.some((jc: any) => {
    return (
      jc?.status !== JOB_CARD_STATUS.COMPLETED &&
      jc?.status !== JOB_CARD_STATUS.CANCELLED
    );
  });
  return isAnyActive;
};

export const isWorkOrderAllocationComplete = (activeTabWorkOrder: any) => {
  let isAllAllocated = true;
  let lineItems =
    { ...activeTabWorkOrder }?.workOrderItems?.filter(
      (item: any) =>
        item?.produceProductType !== PRODUCE_PRODUCT_TYPE.SCRAP &&
        item?.produceProductType !== PRODUCE_PRODUCT_TYPE.CO_PRODUCT
    ) || [];
  for (let index = 0; index < lineItems?.length; index++) {
    const woItem = lineItems?.[index];

    if (!RawMaterialHelper.isNonServiceProduct(woItem)) continue;

    let assignQtys = 0;
    if (woItem?.itemName?.advancedTracking === ADVANCE_TRACKING.NONE) {
      assignQtys =
        woItem?.warehouseInventoryData?.reduce((prev: number, current: any) => {
          return prev + Number(current.quantity);
        }, 0) ?? 0;
    } else {
      let advacedList = woItem?.warehouseInventoryData?.reduce(
        (prev: any[], current: any) => {
          return [...prev, ...current?.advancedTrackingData];
        },
        []
      );
      assignQtys =
        advacedList?.reduce((prev: number, current: any) => {
          return prev + Number(current.qtyToFulfil);
        }, 0) ?? 0;
    }
    // warehouseInventoryData
    let assignSubstituteQtys =
      woItem?.bomProductSubstitutesDetails?.reduce(
        (prev: number, current: any) => {
          let sum =
            current?.warehouseInventoryData?.reduce(
              (total: number, currentItem: any) => {
                return total + (currentItem?.quantity ?? 0);
              },
              0
            ) ?? 0;

          return prev + sum;
        },
        0
      ) ?? 0;
    assignQtys = Number(assignQtys ?? 0) + Number(assignSubstituteQtys ?? 0);
    if (woItem?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
      assignQtys = Utility.getUomQuantityWithoutRoundOff(
        assignQtys,
        woItem?.documentUOMSchemaDefinition
      );
    }
    if (assignQtys !== woItem?.requiredQty) {
      isAllAllocated = false;
    }
  }

  return isAllAllocated;
};
