import {
  DOCUMENT_STATUS,
  DOC_TYPE,
  RECEIVED_GOODS_STATUS
} from '../../../Constants/Constant';
import { WorkOut } from '../../../Models/Workout';
import {
  defaultAdditionalChargesObject,
  getTenantTaxSystem
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../../Utility/Utility';

export const orderEmailTemplateConfig: any = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Order Date',
    action: 'orderDate'
  },
  {
    hidden: false,
    text: 'Order Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Currency Symbol',
    action: 'currencySymbol'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Order Number',
    action: 'orderNo'
  }
];

export const setItemsFromJobWorkOutItems = (doc: any) => {
  const document = {
    ...doc,
    items: doc.jobWorkOutOrderItems?.map((item: any) => {
      return {
        ...item,
        taxSystem: getTenantTaxSystem(),
        unitPriceGstInclusive: doc.unitPriceGstInclusive,
        allowQuantityToBuild: item.allowQuantityToBuild ?? 0
      };
    }),
    contact: !Utility.isEmpty(doc.contactDto) ? doc.contactDto : doc.contact,
    documentType: DOC_TYPE.JOB_WORK_OUT_ORDER,
    documentDate: doc.documentDate,
    validTillDate: doc.orderDueDate,
    fulfillmentDate: doc.receiveByDate,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject }
  };
  return document;
};

export const getJobWorkOutObject = (props: WorkOut) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.JOB_WORK_OUT_ORDER,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    documentCode: Utility.getValue(props.documentCode, props.poCode),
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    buyType: Utility.getValue(props.buyType, props.orderType),
    orderType: Utility.getValue(props.buyType, props.orderType),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),
    validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),

    items: props.jobWorkOutOrderItems?.map((item) => {
      return {
        ...item,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.jobWorkOutOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0
  };

  return updatedObj;
};
