import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import WorkOrderService, {
  IWorkOrder,
  fetchWarehouseInfoForAllProducts,
  prepareWorkOrderFormData,
  prepareWorkOrderFormDataEditCase
} from '../../../Services/MRP/WorkOrder';
import Utility, { deepClone } from '../../../Utility/Utility';
import SalesOrderService, {
  ISalesOrder,
  SalesOrderItemsEntity
} from '../../../Services/SalesOrder';
import WorkoutService from '../../../Services/Workout';
import {
  BOOKS_DATE_FORMAT,
  CUSTOM_FIELD_TYPE,
  DOC_TYPE,
  MODULES_NAME,
  PRODUCT_TYPE
} from '../../../Constants/Constant';
import ProductService from '../../../Services/Product';
import JobCardService from '../../../Services/MRP/JobCard';
import {
  WorkOrderHelper,
  flattenOperationIdsFromWorkOrders,
  getProductShortInfoRequestDataFromWorkOrders,
  populateProductDetailWithSelectedOrDefaultBom
} from './WorkOrderHelper';
import OperationsService from '../../../Services/MRP/Operations';
import { WAREHOUSE_TYPE } from '../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import { Store } from '../../../Redux/Store';
import MRPProductsService from '../../../Services/MRP/MRPProducts';
import { selectSalesOrder } from '../../../Redux/Slices/SalesOrderSlice';
import { Invoice } from '../../../Models/Invoice';
import { selectInvoices } from '../../../Redux/Slices/InvoicesSlice';
import InvoiceService from '../../../Services/Invoice';
import { DocumentItem } from '../../../Models/DocumentItem';
import { WORK_ORDER_OPERATION_TABLE } from '../Constants/TableConstant';
import DateFormatService from '../../../Services/DateFormat';
import { BomOperationRow } from '../RoutingTemplates/AddRoutingTemplate';
import WarehouseService from '../../../Services/Warehouse';
import OperatorsService from '../../../Services/MRP/Operators';
import CustomFieldService from '../../../Services/CustomField';

export interface IAddWorkOrderSliceState {
  loading: boolean;
  updating: boolean;
  workOrders: IWorkOrder[];
  workOrderToEdit?: IWorkOrder | null;
  salesOrder: ISalesOrder | ISalesOrder[] | null;
  salesInvoice: Invoice | null;
  jwoDetails: any[];
  needBomSelection: boolean;
  bomProductDetailsList: any[];
  operationIdToJobMapping: { [operationId: string]: any };
  jobCardListResponse: any[];
  operationListResponse: any[];
  isEditMode: boolean;
  focusedField: string | null;
  invalidPlannedStartDate: boolean;
  invalidPlannedEndDate: boolean;
  showCompleteWorkOrderPopup: boolean;
  showWarehouseInventoryPopup: boolean;
  showWOLinkedRecordsPopup: boolean;
  detailsPopupData: any;
  oldPlannedQtyInState: any;
  allProductWarehouseStocks: any[];
  pristineWorkOrderData: any[];
  selectedOperatorsByProductId: { [productId: string]: any[] };
  consumptionSummaryResponse: any[];
  componentProductShortInfoList: any[];
  preSelectedTargetWarehouse?: any;
  isInnerDocumentRelatedToCurrentWOSaved?: any;
}

export const ADD_WORK_ORDER_INITIAL_STATE: IAddWorkOrderSliceState = {
  loading: true,
  updating: false,
  workOrders: [],
  workOrderToEdit: null,
  salesOrder: null,
  salesInvoice: null,
  needBomSelection: false,
  bomProductDetailsList: [],
  jwoDetails: [],
  operationIdToJobMapping: {},
  jobCardListResponse: [],
  operationListResponse: [],
  isEditMode: false,
  focusedField: null,
  invalidPlannedStartDate: false,
  invalidPlannedEndDate: false,
  showCompleteWorkOrderPopup: false,
  showWarehouseInventoryPopup: false,
  showWOLinkedRecordsPopup: false,
  detailsPopupData: null,
  oldPlannedQtyInState: {},
  allProductWarehouseStocks: [],
  pristineWorkOrderData: [],
  selectedOperatorsByProductId: {},
  consumptionSummaryResponse: [],
  componentProductShortInfoList: [],
  preSelectedTargetWarehouse: null,
  isInnerDocumentRelatedToCurrentWOSaved: true
};

export const getProcessedWorkOrderOperations = (data: {
  workOrderData: IWorkOrder;
  isEditMode: boolean;
  workOrderOperations: BomOperationRow[];
  operationListResponse: any[];
  selectedOperatorsByProductId: { [key: string]: any[] };
  operatorsListResponse: any[];
}) => {
  const {
    workOrderData,
    workOrderOperations,
    operationListResponse,
    isEditMode,
    selectedOperatorsByProductId,
    operatorsListResponse
  } = data;
  let operations: any = [];
  let allOperatorsForWorkOrder: any[] = [];

  (workOrderOperations || []).forEach(
    (workOrderOperation: any, woOperationIndex: number) => {
      let operationResponseData = operationListResponse?.find(
        (operationResponse: any) =>
          workOrderOperation.operationId === operationResponse.id
      );

      let operationObject = WorkOrderHelper.getOperationRow(
        workOrderData,
        workOrderOperation,
        operationResponseData,
        woOperationIndex,
        isEditMode,
        false,
        operatorsListResponse
      );

      const operatorsForCurrentOperation =
        operationObject[WORK_ORDER_OPERATION_TABLE.OPERATION_NAME]?.operators;
      if (Utility.isNotEmpty(operatorsForCurrentOperation)) {
        allOperatorsForWorkOrder.push(...operatorsForCurrentOperation);
      }

      operations.push(operationObject);
    }
  );

  selectedOperatorsByProductId[workOrderData.product?.productId] =
    allOperatorsForWorkOrder;

  return operations;
};

/**
 * @description This function is used to populate operation and require items table data and set it to state
 * @param workOrderData
 */
const populateOPerationsAndRequiredItemsTableData = async (
  workOrderList: IWorkOrder[],
  isEditMode: boolean
) => {
  let requiredItemsTableData: any[] = [];
  let operationsTableData: any[] = [];
  const workorderEndDate = DateFormatService.getDateStrFromDate(
    workOrderList?.[0]?.actualEndDate,
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  );
  const selectedOperatorsByProductId: { [key: string]: any[] } = {};

  let operationListResponse: any[] = [];
  let componentProductShortInfoList: any[] = [];
  let operatorsListResponse: any[] = [];
  try {
    const requestPayloadForProductDetails =
      getProductShortInfoRequestDataFromWorkOrders(workOrderList);
    const allOperationIds = flattenOperationIdsFromWorkOrders(workOrderList);

    try {
      [componentProductShortInfoList, operationListResponse] =
        await Promise.all([
          Utility.isEmptyObject(requestPayloadForProductDetails)
            ? Promise.resolve([])
            : MRPProductsService.getProductShortInfo(
                requestPayloadForProductDetails,
                workorderEndDate
              ),
          Utility.isEmptyObject(allOperationIds)
            ? Promise.resolve([])
            : OperationsService.getOperationsById(allOperationIds)
        ]);
    } catch (err: any) {
      console.error('Error fetching product short info: ', err);
    }

    try {
      const operatorIds: any = [];

      // Extract operatorIds
      operationListResponse.forEach((operation: any) => {
        if (operation?.operators) {
          operation?.operators?.forEach((operator: any) => {
            operatorIds.push(operator.operatorId);
          });
        }
      });

      operatorsListResponse = await OperatorsService.getOperatorsById(
        operatorIds
      );
    } catch (err: any) {
      console.error('Error fetching operator details: ', err);
    }

    workOrderList?.forEach((workOrderData: any, woIndex: number) => {
      if (!workOrderData) return;

      const operations = getProcessedWorkOrderOperations({
        workOrderData,
        workOrderOperations: workOrderData.workOrderOperations,
        isEditMode,
        operationListResponse,
        selectedOperatorsByProductId,
        operatorsListResponse
      });

      let requireItems: any = [];
      workOrderData.workOrderItems?.forEach(
        (workOrderItem: any, woItemIndex: number) => {
          let selectedComponentProductShortInfo: any;
          if (isEditMode) {
            selectedComponentProductShortInfo =
              componentProductShortInfoList.find(
                (productShortInfo: any) =>
                  productShortInfo.pid === workOrderItem.productCode
              );
          } else {
            // search based on product id saved as itemId in workorderItem
            selectedComponentProductShortInfo =
              componentProductShortInfoList.find(
                (productShortInfo: any) =>
                  productShortInfo.id === workOrderItem.itemId
              );
          }
          let rawMaterialItem = WorkOrderHelper.getRawMaterialRow(
            workOrderData,
            workOrderItem,
            selectedComponentProductShortInfo,
            componentProductShortInfoList,
            woItemIndex,
            isEditMode
          );
          requireItems.push(rawMaterialItem);
        }
      );
      operationsTableData.push(operations);
      requiredItemsTableData.push(requireItems);
    });
  } catch (error) {
    console.log('Error inside PopulateOperations&RequireItems', error);
  }

  return Promise.resolve({
    requiredItemsTableData,
    operationsTableData,
    selectedOperatorsByProductId,
    operationListResponse,
    componentProductShortInfoList
  });
};

const convertBomListToWorkOrderList = (
  workOrderSliceState: IAddWorkOrderSliceState,
  newBomProductList: any[]
) => {
  newBomProductList = [...newBomProductList];
  /**
   * Merging old & new products,
   * when new Material gets added in WO creation flow,
   * through "Add Material" button.
   */
  if (
    !workOrderSliceState.isEditMode &&
    Utility.isEmpty(workOrderSliceState.salesOrder) &&
    Utility.isEmpty(workOrderSliceState.salesInvoice) &&
    Utility.isNotEmpty(workOrderSliceState.workOrders)
  ) {
    const existingBomProductDetailsList =
      workOrderSliceState.bomProductDetailsList || [];
    const existingBomProductsMissingFromNewList: any[] = [];
    existingBomProductDetailsList.forEach((existingBomProduct: any) => {
      const doesPreviouslyAddedMaterialExistInNewList = newBomProductList.some(
        (newBomProduct) =>
          newBomProduct.productId === existingBomProduct.productId
      );

      if (doesPreviouslyAddedMaterialExistInNewList) return;

      existingBomProductsMissingFromNewList.push(existingBomProduct);
    });

    newBomProductList =
      existingBomProductsMissingFromNewList.concat(newBomProductList);
  }

  let updatedWorkOrders: IWorkOrder[] = [];
  if (workOrderSliceState.isEditMode) {
    updatedWorkOrders = prepareWorkOrderFormDataEditCase(
      [workOrderSliceState.workOrderToEdit],
      newBomProductList
    );
  } else {
    updatedWorkOrders = prepareWorkOrderFormData(
      newBomProductList,
      workOrderSliceState.salesOrder,
      workOrderSliceState.salesInvoice
    );
  }

  const dataToUpdate = {
    workOrders: updatedWorkOrders,
    bomProductDetailsList: newBomProductList
  };

  return dataToUpdate;
};

const processWorkOrdersCallback = async (
  sliceState: IAddWorkOrderSliceState,
  newBomProductList: any[]
): Promise<Partial<IAddWorkOrderSliceState>> => {
  let { isEditMode } = sliceState;

  const previouslyProcessedWorkOrders = [...(sliceState.workOrders || [])];

  const { workOrders: newWorkOrders, bomProductDetailsList } =
    convertBomListToWorkOrderList(sliceState, newBomProductList);

  const allProductWarehouseStocks = await fetchWarehouseInfoForAllProducts(
    bomProductDetailsList
  );

  let workOrderCustomFields = !isEditMode
    ? await CustomFieldService.getCustomFields(
        {
          limit: '1000',
          module: MODULES_NAME.MRP_WORK_ORDER
        },
        true
      )
    : null;

  const {
    requiredItemsTableData,
    operationsTableData,
    selectedOperatorsByProductId,
    operationListResponse,
    componentProductShortInfoList
  } = await populateOPerationsAndRequiredItemsTableData(
    newWorkOrders,
    isEditMode
  );

  const allWHCodes =
    newWorkOrders?.map((wo: any) => wo?.inventory?.warehouseCode) ?? [];
  let allWarehouses: any = null;

  try {
    allWarehouses = await WarehouseService.getWarehouseByWarehouseCodes(
      allWHCodes
    );
  } catch (error) {}

  const warehouseData = Store.getState().warehouse.data?.content;
  let updatedWorkOrders = newWorkOrders.map(
    (workOrderToUpdate: IWorkOrder, index: number) => {
      /**
       * @description
       * Stopping processing for previously created work order
       * this will only be applicable in case New BOM Material is selected while creating work order.
       * For retaining edits made on previously opened work order..
       */
      const existingWorkOrder = previouslyProcessedWorkOrders.find(
        (previouslyProcessedWO) =>
          previouslyProcessedWO.product &&
          workOrderToUpdate.product &&
          previouslyProcessedWO.product.productId ===
            workOrderToUpdate.product.productId &&
          previouslyProcessedWO.selectedBom?.id ===
            workOrderToUpdate.selectedBom?.id
      );
      if (!isEditMode && existingWorkOrder) {
        return existingWorkOrder;
      }

      workOrderToUpdate = { ...workOrderToUpdate };

      workOrderToUpdate.plannedYield = workOrderToUpdate.manufactureQuantity
        ? 100
        : 0;

      let filteredWarehouseData = warehouseData?.filter((whDetails: any) => {
        return whDetails.active;
      });
      let targetWarehouseEdit = warehouseData?.find((whDetails: any) => {
        return (
          whDetails.code ===
          workOrderToUpdate?.warehouseInventoryData?.[0]?.warehouseCode
        );
      }) ?? {
        name: workOrderToUpdate?.warehouseInventoryData?.[0]?.warehouseName,
        code: workOrderToUpdate?.warehouseInventoryData?.[0]?.warehouseCode
      };
      targetWarehouseEdit = {
        ...targetWarehouseEdit,
        binCode: workOrderToUpdate?.warehouseInventoryData?.[0]?.binCode,
        binName: workOrderToUpdate?.warehouseInventoryData?.[0]?.binName,
        rackCode: workOrderToUpdate?.warehouseInventoryData?.[0]?.rackCode,
        rackName: workOrderToUpdate?.warehouseInventoryData?.[0]?.rackName,
        rowCode: workOrderToUpdate?.warehouseInventoryData?.[0]?.rowCode,
        rowName: workOrderToUpdate?.warehouseInventoryData?.[0]?.rowName
      };
      let primaryWarehouse = sliceState?.preSelectedTargetWarehouse
        ? sliceState?.preSelectedTargetWarehouse
        : filteredWarehouseData?.find((whDetails: any) => whDetails.primary);
      if (!primaryWarehouse) {
        primaryWarehouse = filteredWarehouseData.find(
          (whDetails: any) =>
            whDetails.warehouseType === WAREHOUSE_TYPE.NONE && whDetails.active
        );
      }

      const whFromDefaultProduct = allWarehouses?.content?.find(
        (filteredWH: any) =>
          filteredWH?.code === workOrderToUpdate?.inventory?.warehouseCode
      );

      workOrderToUpdate.targetWarehouse = isEditMode
        ? targetWarehouseEdit
        : Utility.isNotEmpty(workOrderToUpdate?.parentWorkOrderCode) &&
          Utility.isRRBTaggingEnabled()
        ? targetWarehouseEdit
        : whFromDefaultProduct || primaryWarehouse;
      let advTrackingDataArray: any = [];
      workOrderToUpdate?.warehouseInventoryData?.forEach((existing: any) => {
        existing?.advancedTrackingData?.forEach((existingAdvTracking: any) => {
          advTrackingDataArray.push({
            ...existing,
            ...existingAdvTracking,
            row: { code: existing?.rowCode, name: existing?.rowName },
            rack: {
              code: existing?.rackCode,
              name: existing?.rackName
            },
            bin: { code: existing?.binCode, name: existing?.binName },
            warehouse: {
              code: existing?.warehouseCode,
              name: existing?.warehouseName
            }
          });
        });
      });
      workOrderToUpdate.advancedTrackingData = advTrackingDataArray;
      workOrderToUpdate.workOrderItemsClonedCopy = deepClone(
        requiredItemsTableData?.[index] || []
      ); // to compare only
      workOrderToUpdate.workOrderItems = deepClone(
        requiredItemsTableData?.[index] || []
      );
      workOrderToUpdate.workOrderOperations = operationsTableData[index];

      // Add formulaId key in Formula type CF
      const woCFfromAPIresponse = workOrderCustomFields?.content?.map(
        (cf: any) => {
          return {
            ...cf,
            formulaId:
              cf.fieldType === CUSTOM_FIELD_TYPE.FORMULA
                ? cf.formula?.id || null
                : null
          };
        }
      );
      workOrderToUpdate.customField = isEditMode
        ? workOrderToUpdate.customField
        : woCFfromAPIresponse;
      if (
        workOrderToUpdate.actualQuantity &&
        workOrderToUpdate?.actualQuantity > 0 &&
        workOrderToUpdate.manufactureQuantity &&
        workOrderToUpdate?.manufactureQuantity > 0
      ) {
        workOrderToUpdate.actualYield = workOrderToUpdate.actualYield
          ? workOrderToUpdate.actualYield
          : (workOrderToUpdate.actualQuantity /
              workOrderToUpdate.manufactureQuantity) *
            100;
      }
      workOrderToUpdate.operationCostDetails = !Utility.isEmpty(
        workOrderToUpdate.operationCostDetails
      )
        ? workOrderToUpdate.operationCostDetails
        : {
            actualOperatingCost: operationsTableData[index]?.reduce(
              (acc: number, operation: any) =>
                acc + (operation['operationCost'] || 0),
              0
            ),
            additionalOperatingCost: 0,
            plannedOperatingCost: operationsTableData[index].reduce(
              (acc: number, operation: any) =>
                acc + (operation['operationCost'] || 0),
              0
            ),
            totalOperatingCost: 0
          };
      if (
        Utility.isRRBTaggingEnabled() ||
        Utility.isWarehouseTaggingEnabled()
      ) {
        workOrderToUpdate.allProductsWarehouseData = allProductWarehouseStocks;
      }
      workOrderToUpdate.adhocBomMetaCode =
        workOrderToUpdate.adhocBomMetaCode ||
        workOrderToUpdate?.selectedBom?.code;

      return workOrderToUpdate;
    }
  );
  // this will only show product of type BOM in the tabs
  if (!isEditMode) {
    updatedWorkOrders = updatedWorkOrders
      ?.map((wo: any) => {
        return {
          ...wo,
          customField: wo?.customField?.map((woCustomField: any) => {
            return {
              ...woCustomField,
              value: woCustomField?.value || woCustomField?.defaultValue
            };
          })
        };
      })
      ?.filter((wo: any) => wo?.type === PRODUCT_TYPE.BILL_OF_MATERIALS);
  }
  // Work Order copy ##Sumeet
  const pristineWorkOrderData = deepClone(updatedWorkOrders);

  return Promise.resolve({
    workOrders: updatedWorkOrders,
    bomProductDetailsList,
    allProductWarehouseStocks,
    pristineWorkOrderData,
    selectedOperatorsByProductId,
    operationListResponse,
    componentProductShortInfoList
  });
};

export const onBomSelection = async ({
  state,
  newBomProductList,
  dispatch
}: {
  state: IAddWorkOrderSliceState;
  newBomProductList: any[];
  dispatch: React.Dispatch<AnyAction>;
}) => {
  (!state.loading || state.needBomSelection) &&
    dispatch(
      useAddWorkOrderReducer.actions.setState({
        state: {
          loading: true,
          needBomSelection: false
        }
      })
    );

  const stateToUpdate = await processWorkOrdersCallback(
    state,
    newBomProductList
  );

  dispatch(
    useAddWorkOrderReducer.actions.setState({
      state: {
        loading: false,
        needBomSelection: false,
        ...stateToUpdate
      }
    })
  );
};

export const fetchAndStoreBOMDetailsByMaterialCodes = async ({
  data,
  state,
  dispatch
}: {
  data: {
    materialCodes: string[];
    prefetchedMaterialList?: any[];
    workOrderBomMetaCode?: string;
    salesOrder?: ISalesOrder | ISalesOrder[] | null;
    salesInvoice?: Invoice | null;
    revertSalesOrderSelectionToOld?: boolean;
    revertSalesInvoiceSelectionToOld?: boolean;
  };
  state: IAddWorkOrderSliceState;
  dispatch: React.Dispatch<AnyAction>;
}) => {
  const materialCodes = data.materialCodes?.filter((code: any) => !!code);

  /** update loading state, if current store state is not set to loading */
  !state.loading &&
    dispatch(
      useAddWorkOrderReducer.actions.setState({
        state: {
          loading: true
        }
      })
    );

  let response: any[] = [];

  if (data.prefetchedMaterialList) {
    response = data.prefetchedMaterialList;
  } else if (Utility.isEmpty(materialCodes)) {
    response = [];
  } else {
    response = await ProductService.getProductsByProductIds(
      materialCodes,
      true
    );
  }

  let isBomSelected: boolean,
    bomProductDetailsList: any[] = [];

  /* In case of edit work order, only one BOM will be selected to display */
  if (state.isEditMode) {
    let bomProductDetailResponse = response?.[0];
    if (bomProductDetailResponse && data.workOrderBomMetaCode) {
      let selectedBomObj = bomProductDetailResponse.bomMetaDetailsList?.find(
        (item: any) => item?.code === data.workOrderBomMetaCode
      );

      bomProductDetailResponse = populateProductDetailWithSelectedOrDefaultBom(
        bomProductDetailResponse,
        selectedBomObj
      );
    }

    if (bomProductDetailResponse) {
      bomProductDetailsList = [bomProductDetailResponse];
    }

    isBomSelected = true;
  } else {
    if (data.salesOrder) {
      if (!Array.isArray(data.salesOrder)) {
        data.salesOrder.salesOrderItems?.forEach(
          (orderItem: SalesOrderItemsEntity) => {
            let bomProductResponse = response.find((productResponse: any) => {
              const soItemSequenceCode =
                orderItem?.product?.documentSequenceCode ??
                orderItem?.documentSequenceCode;
              return (
                productResponse.documentSequenceCode === soItemSequenceCode &&
                productResponse.type === PRODUCT_TYPE.BILL_OF_MATERIALS
              );
            });

            let previouslySelectedBom;
            if (data.revertSalesOrderSelectionToOld) {
              const currentProductWorkOrder = state.workOrders?.find(
                (workOrder) =>
                  workOrder.product?.productId === bomProductResponse?.productId
              );
              previouslySelectedBom = currentProductWorkOrder?.selectedBom;
            }

            if (bomProductResponse) {
              bomProductResponse.lineItemId = orderItem.id;
              bomProductDetailsList.push(
                populateProductDetailWithSelectedOrDefaultBom(
                  bomProductResponse,
                  previouslySelectedBom
                )
              );
            }
          }
        );
      } else {
        data?.salesOrder?.forEach((order: ISalesOrder) => {
          order?.salesOrderItems?.forEach(
            (orderItem: SalesOrderItemsEntity) => {
              let bomProductResponse = response.find((productResponse: any) => {
                const soItemSequenceCode =
                  orderItem?.product?.documentSequenceCode ??
                  orderItem?.documentSequenceCode;
                return (
                  productResponse.documentSequenceCode === soItemSequenceCode &&
                  productResponse.type === PRODUCT_TYPE.BILL_OF_MATERIALS
                );
              });

              let previouslySelectedBom;
              if (data.revertSalesOrderSelectionToOld) {
                const currentProductWorkOrder = state.workOrders?.find(
                  (workOrder) =>
                    workOrder.product?.productId ===
                    bomProductResponse?.productId
                );
                previouslySelectedBom = currentProductWorkOrder?.selectedBom;
              }

              if (bomProductResponse) {
                bomProductResponse.lineItemId = orderItem.id;
                let materialProduct =
                  populateProductDetailWithSelectedOrDefaultBom(
                    bomProductResponse,
                    previouslySelectedBom
                  );
                const productAlreadyExists = bomProductDetailsList?.some(
                  (productDetail: any) =>
                    productDetail.productId === materialProduct.productId
                );
                if (!productAlreadyExists) {
                  bomProductDetailsList.push(materialProduct);
                }
              }
            }
          );
        });
      }
    } else if (data.salesInvoice) {
      data.salesInvoice.salesInvoiceItems?.forEach(
        (invoiceItem: DocumentItem) => {
          let bomProductResponse = response.find((productResponse: any) => {
            const siItemSequenceCode =
              invoiceItem?.product?.documentSequenceCode ??
              invoiceItem?.documentSequenceCode;
            return (
              productResponse.documentSequenceCode === siItemSequenceCode &&
              productResponse.type === PRODUCT_TYPE.BILL_OF_MATERIALS
            );
          });

          let previouslySelectedBom;
          if (data.revertSalesInvoiceSelectionToOld) {
            const currentProductWorkOrder = state.workOrders?.find(
              (workOrder) =>
                workOrder.product?.productId === bomProductResponse?.productId
            );
            previouslySelectedBom = currentProductWorkOrder?.selectedBom;
          }

          if (bomProductResponse) {
            bomProductResponse.lineItemId = invoiceItem.id;
            bomProductDetailsList.push(
              populateProductDetailWithSelectedOrDefaultBom(
                bomProductResponse,
                previouslySelectedBom
              )
            );
          }
        }
      );
    } else {
      bomProductDetailsList = response.map((productResponse: any) => {
        return populateProductDetailWithSelectedOrDefaultBom(productResponse);
      });
    }

    const multipleBomsFound = response.some((productItem: any) => {
      return (
        productItem?.bomMetaDetailsList &&
        productItem.bomMetaDetailsList.length > 1
      );
    });

    if (Utility.isNotEmpty(data.salesOrder)) {
      const salesOrderEmpty = !Array.isArray(data.salesOrder)
        ? Utility.isEmpty(data.salesOrder?.documentSequenceCode)
        : Utility.isEmpty(data.salesOrder?.[0]?.documentSequenceCode);
      isBomSelected =
        (salesOrderEmpty && bomProductDetailsList.length === 1) ||
        !multipleBomsFound;
    } else if (Utility.isNotEmpty(data.salesInvoice)) {
      isBomSelected =
        (Utility.isEmpty(data.salesInvoice?.documentSequenceCode) &&
          bomProductDetailsList.length === 1) ||
        !multipleBomsFound;
    } else {
      const salesOrderEmpty = !Array.isArray(data.salesOrder)
        ? Utility.isEmpty(data.salesOrder?.documentSequenceCode)
        : Utility.isEmpty(data.salesOrder?.[0]?.documentSequenceCode);
      isBomSelected =
        ((salesOrderEmpty ||
          Utility.isEmpty(data.salesInvoice?.documentSequenceCode)) &&
          bomProductDetailsList.length === 1) ||
        !multipleBomsFound;
    }
  }

  const dataToUpdate: Partial<IAddWorkOrderSliceState> = {
    bomProductDetailsList,
    salesOrder: data.salesOrder,
    salesInvoice: data.salesInvoice,
    loading: false,
    needBomSelection:
      !isBomSelected && !Utility.isEmptyObject(bomProductDetailsList)
  };

  /**
   * REVERTING DOCUMENT TO OLD STATE,
   * ONCE NEW SALES ORDER IS SELECTED AND LATER
   * CANCELLED FROM BOM SELECTION POPUP
   */
  if (
    (data.revertSalesOrderSelectionToOld && data.salesOrder) ||
    (data.revertSalesInvoiceSelectionToOld && data.salesInvoice)
  ) {
    dispatch(
      useAddWorkOrderReducer.actions.setState({
        state: {
          ...dataToUpdate,
          needBomSelection: false
        }
      })
    );
  } else {
    dispatch(
      useAddWorkOrderReducer.actions.setState({
        state: dataToUpdate
      })
    );

    if (isBomSelected) {
      onBomSelection({
        state: {
          ...state,
          ...dataToUpdate
        },
        newBomProductList: bomProductDetailsList,
        dispatch
      });
    }
  }

  return dataToUpdate;
};

export const fetchJWODetailsByWorkOrder = async ({
  workOrderCode,
  dispatch
}: {
  workOrderCode: string | undefined;
  dispatch: React.Dispatch<AnyAction>;
}) => {
  const response = workOrderCode
    ? await WorkoutService.fetchJobWorkOutDetailsByWO(workOrderCode)
    : [];

  dispatch(
    useAddWorkOrderReducer.actions.setJwoData({
      response,
      workOrderCode
    })
  );

  return response;
};

export const fetchConsumptionDetailsByWorkOrder = async ({
  workOrderCode,
  dispatch
}: {
  workOrderCode: string | undefined;
  dispatch: React.Dispatch<AnyAction>;
}) => {
  const response = workOrderCode
    ? await WorkOrderService.fetchWOConsumptionSummary(workOrderCode)
    : [];

  dispatch(
    useAddWorkOrderReducer.actions.setState({
      state: {
        consumptionSummaryResponse: response
      }
    })
  );

  return response;
};

export const fetchJobCardsByWorkOrder = async ({
  workOrderCode,
  dispatch
}: {
  workOrderCode: string | undefined;
  dispatch: React.Dispatch<AnyAction>;
}) => {
  const response = workOrderCode
    ? await JobCardService.fetchAllWOLinkedJobCards(workOrderCode)
    : [];

  let operationIdToJobMapping = {};
  let operationIds: any = [];
  response?.forEach((element: any) => {
    operationIdToJobMapping = {
      ...operationIdToJobMapping,
      [element.operationId]: element
    };
    operationIds.push(element.operationId);
  });

  const dataToUpdate = {
    operationIdToJobMapping,
    jobCardListResponse: response
  };
  dispatch(
    useAddWorkOrderReducer.actions.setState({
      state: dataToUpdate
    })
  );

  return dataToUpdate;
};

export const initializeWorkOrderData = async ({
  data,
  dispatch
}: {
  data: {
    salesOrder?: ISalesOrder | ISalesOrder[] | null;
    salesInvoice?: Invoice | null;
    workOrder: any;
    bomMaterialList?: any[];
    preSelectedTargetWarehouse?: any;
  };
  dispatch: React.Dispatch<AnyAction>;
}) => {
  const state = deepClone(ADD_WORK_ORDER_INITIAL_STATE);
  let { salesOrder, salesInvoice, workOrder } = data;
  const isEditMode = !Utility.isEmptyObject(workOrder);
  let loading = true;
  if (workOrder && isEditMode) {
    const linkedDocumentCode =
      workOrder.workOrderSourceDetails?.[0]?.linkedDocumentCode;
    // Check link doc type
    if (
      linkedDocumentCode &&
      Utility.isEmpty(salesOrder) &&
      workOrder.workOrderSourceDetails?.[0]?.workOrderSource ===
        DOC_TYPE.SALES_ORDER
    ) {
      try {
        const allSalesOrders =
          selectSalesOrder(Store.getState())?.content || [];
        const selectedSalesOrderFromStore = allSalesOrders.find(
          (soFromStore: ISalesOrder) =>
            soFromStore?.salesOrderCode === linkedDocumentCode
        );
        if (selectedSalesOrderFromStore) {
          salesOrder = selectedSalesOrderFromStore;
        } else {
          salesOrder = await SalesOrderService.getSalesOrderByCode(
            linkedDocumentCode
          );
        }
      } catch (error) {}
    }
    if (
      linkedDocumentCode &&
      Utility.isEmpty(salesInvoice) &&
      workOrder.workOrderSourceDetails?.[0]?.workOrderSource ===
        DOC_TYPE.INVOICE
    ) {
      try {
        const allSalesInvoices =
          selectInvoices(Store.getState())?.content || [];
        const selectedSalesInvoiceFromStore = allSalesInvoices.find(
          (siFromStore: ISalesOrder) =>
            siFromStore?.salesOrderCode === linkedDocumentCode
        );
        if (selectedSalesInvoiceFromStore) {
          salesInvoice = selectedSalesInvoiceFromStore;
        } else {
          salesInvoice = await InvoiceService.getInvoiceByCode(
            linkedDocumentCode
          );
        }
      } catch (error) {}
    }

    fetchJWODetailsByWorkOrder({
      workOrderCode: workOrder.workOrderCode,
      dispatch
    });

    fetchJobCardsByWorkOrder({
      workOrderCode: workOrder.workOrderCode,
      dispatch
    });

    fetchConsumptionDetailsByWorkOrder({
      workOrderCode: workOrder.workOrderCode,
      dispatch
    });
  } else {
    loading = false;
  }

  const dataToUpdate: Partial<IAddWorkOrderSliceState> = {
    workOrderToEdit: workOrder,
    salesOrder,
    salesInvoice,
    isEditMode,
    loading,
    preSelectedTargetWarehouse: data.preSelectedTargetWarehouse
  };

  /**
   * Material Selection flow from Sales order, or preselected materials from Bom Explosion
   */
  if (isEditMode) {
    let isAdhocEnable =
      Store.getState().authInfo.currentTenantInfo.data?.additionalSettings
        ?.ADHOC_BOM?.enable;
    fetchAndStoreBOMDetailsByMaterialCodes({
      data: {
        materialCodes: [workOrder.productCode],
        workOrderBomMetaCode: isAdhocEnable
          ? workOrder.adhocBomMetaCode ?? workOrder.bomMetaCode
          : workOrder.bomMetaCode,
        salesOrder,
        salesInvoice
      },
      state: {
        ...state,
        ...dataToUpdate
      },
      dispatch
    });
  } else if (Utility.isNotEmpty(salesOrder)) {
    const materialCodes: string[] = [];

    if (!Array.isArray(salesOrder)) {
      salesOrder?.salesOrderItems?.forEach((item) =>
        materialCodes.push(item.productCode as string)
      );
    } else {
      salesOrder?.forEach((order: ISalesOrder) => {
        order?.salesOrderItems?.forEach((orderItem: SalesOrderItemsEntity) =>
          materialCodes.push(orderItem.productCode as string)
        );
      });
    }

    fetchAndStoreBOMDetailsByMaterialCodes({
      data: {
        materialCodes: materialCodes,
        salesOrder
      },
      state: {
        ...state,
        ...dataToUpdate
      },
      dispatch
    });
  } else if (Utility.isNotEmpty(salesInvoice)) {
    const materialCodes: string[] = [];
    salesInvoice?.salesInvoiceItems?.forEach((item) =>
      materialCodes.push(item.productCode as string)
    );

    fetchAndStoreBOMDetailsByMaterialCodes({
      data: {
        materialCodes: materialCodes,
        salesInvoice
      },
      state: {
        ...state,
        ...dataToUpdate
      },
      dispatch
    });
  } else if (Utility.isNotEmpty(data.bomMaterialList)) {
    fetchAndStoreBOMDetailsByMaterialCodes({
      data: {
        materialCodes: [],
        prefetchedMaterialList: data.bomMaterialList
      },
      state: {
        ...state,
        ...dataToUpdate
      },
      dispatch
    });
  }

  dispatch(
    useAddWorkOrderReducer.actions.setState({
      state: dataToUpdate
    })
  );

  return dataToUpdate;
};

const updateWorkOrderDataByKey = (data: {
  workOrders: IWorkOrder[];
  code?: string;
  activeIndex?: number;
  key: string;
  value: any;
}) => {
  return (data.workOrders || []).map((workOrderData, index) =>
    (
      typeof data.activeIndex === 'number'
        ? index === data.activeIndex
        : workOrderData.workOrderCode === data.code
    )
      ? {
          ...workOrderData,
          [data.key]: data.value
        }
      : workOrderData
  );
};

export type woSliceStateKeys = keyof IAddWorkOrderSliceState;
const updateStateKey = <
  KEY extends woSliceStateKeys,
  VALUE extends IAddWorkOrderSliceState[KEY]
>(
  obj: IAddWorkOrderSliceState,
  key: KEY,
  value: VALUE
) => {
  obj[key] = value;
};

const useAddWorkOrderReducer = createSlice({
  name: 'manageWorkOrder',
  initialState: ADD_WORK_ORDER_INITIAL_STATE,
  reducers: {
    setWorkOrderByCode: (state, action) => {
      if (
        action.payload.key &&
        typeof action.payload.activeIndex === 'number'
      ) {
        state.workOrders = updateWorkOrderDataByKey({
          workOrders: state.workOrders,
          activeIndex: action.payload.activeIndex,
          key: action.payload.key,
          value: action.payload.value
        });
      }
    },
    setJwoData: (state, action) => {
      state.jwoDetails = action.payload.response;
    },
    setState: (
      state,
      action: PayloadAction<{ state: Partial<IAddWorkOrderSliceState> }>
    ) => {
      if (action.payload.state) {
        Object.entries(action.payload.state).forEach(([keyToUpdate, value]) => {
          updateStateKey(state, keyToUpdate as woSliceStateKeys, value);
        });
      }
    },
    removeBomProduct: (state, action: PayloadAction<IWorkOrder>) => {
      const workOrderToRemove = action.payload;
      state.workOrders = state.workOrders.filter(
        (workOrder) =>
          workOrder.product?.productId !== workOrderToRemove.product?.productId
      );
      state.bomProductDetailsList = state.bomProductDetailsList.filter(
        (productDetails) =>
          productDetails.productId !== workOrderToRemove.product?.productId
      );
    }
  }
});

export default useAddWorkOrderReducer;
