import { AxiosRequestConfig } from 'axios';
import ApiConstants from '../Constants/ApiConstants';
import { IMPORT_MODULE } from '../Constants/Constant';
import http from './Interceptor';
export interface ImportConfig {
  Module?: string;
  RelativeFilePath?: string;
  isTally?: boolean;
  startRow?: number;
  isDraftImport?: boolean;
}
class ImportService {
  static importConfig: ImportConfig;

  static getFileMapping() {
    const finalEndPoint =
      (this.importConfig.isTally
        ? '/exim/tally/column-mapping?module='
        : '/exim/column-mapping?module=') +
      this.importConfig.Module +
      '&relativeFilePath=' +
      this.importConfig.RelativeFilePath +
      '&startRow=' +
      (this.importConfig.startRow ? this.importConfig.startRow : 0) +
      '&isDraftImport=' +
      (this.importConfig.isDraftImport
        ? this.importConfig.isDraftImport
        : false);
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static postFileMapping(
    module: string,
    relativePath: string,
    auditLogId: string,
    data: any,
    bankCode: string
  ) {
    var finalEndPoint =
      '/exim/import?module=' +
      module +
      '&relativeFilePath=' +
      relativePath +
      '&auditLogId=' +
      auditLogId;

    if (module === IMPORT_MODULE.BANK_STATEMENT) {
      finalEndPoint =
        '/exim/bankstmt/import?module=' +
        module +
        '&relativeFilePath=' +
        relativePath +
        '&auditLogId=' +
        auditLogId +
        '&bankAccountCode=' +
        bankCode;
    }

    return http
      .post(`${finalEndPoint}`, data)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static uploadImportFile(importFileData: FormData) {
    const finalEndPoint = '/attachment/file-upload';
    return http
      .post(`${finalEndPoint}`, importFileData)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getPreSignedURL = (path: any) => {
    return http
      .post(ApiConstants.URL.BANKS.SOURCE_DOWNLOAD(path))
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };

  static uploadFile = (uploadUrl: string, fileToUpload: File) => {
    //uploadfile
    const headers = {
      'Content-Type': 'application/octet-stream',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*'
    };
    return http
      .put(uploadUrl, fileToUpload, { headers: headers })
      .then(
        (response) => {
          console.log('responseeee');
          return Promise.resolve(response);
        },
        (error) => {
          console.log('erorrr');
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        console.error('Error uploading file: ', err);
        return Promise.reject(err);
      });
  };

  static getImportLog(filepath: any) {
    const finalEndPoint = `attachment/file-download?file=${filepath}`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      TE: 'trailers'
    };
    return http
      .get(`${finalEndPoint}`, { responseType: 'text', headers: headers })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getPresignedURL(filePath: string) {
    const config: AxiosRequestConfig = {
      params: {
        file: filePath
      },
      responseType: 'text'
    };

    return http.get(`attachment/file-download`, config);
  }

  static downloadFile(presignedURL: any) {
    const config: AxiosRequestConfig = {
      withCredentials: false,
      responseType: 'blob'
    };

    return http.get(presignedURL, config);
  }

  static getCustomImportFormats() {
    return http.get(`exim/custom-import-format`);
  }

  static getMaxImportLimit(module: string) {
    return http.get(`exim/import-limit/${module}`);
  }

  static saveImportLogs(importLogsData: any) {
    const finalEndPoint = 'exim/audit/add-import-auditlog';
    return http
      .post(`${finalEndPoint}`, importLogsData)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default ImportService;
