import e_way_bill_logo from "./Assets/e_way_bill_logo.png";
import ic_listPickerArrow2 from "./Assets/ic_listPickerArrow2.png";
import ic_close_white from "./Assets/editor/ic_close_white.png";
import ic_downArrow from "./Assets/ic_downArrow.png";
import ic_upArrow from "./Assets/ic_upArrow.png";
import ic_delete_white from "./Assets/ic_delete_white.png";
import ic_refresh_black from "./Assets/ic_refresh_black.png";
import ic_thumbnail_default2 from "./Assets/ic_thumbnail_default2.png";
import ic_edit from "./Assets/editor/ic_edit.png";
import ic_drag_indicator from "./Assets/editor/ic_drag_indicator.png";
import ic_delete from "./Assets/ic_delete.png";
import ic_checkmarkCircle from "./Assets/ic_checkmarkCircle.png";
import ic_bookmark from "./Assets/ic_bookmark.png";
import books from "./Assets/DeskerSuitesPopup/books.png";
import people from "./Assets/DeskerSuitesPopup/people.png";
import sales from "./Assets/DeskerSuitesPopup/sales.png";
import ico_deskLogo from "./Assets/ico_deskLogo.png";
import deskLogoNew from "./Assets/deskLogoNew.png";
import ic_close from "./Assets/ic_close.png";
import signature from "./Assets/signature.png";
import ic_edit_white from "./Assets/editor/ic_edit_white.png";
import ic_email from "./Assets/menuIcons/ic_email.png";
import ic_download from "./Assets/menuIcons/ic_download.png";
import ic_preview_pdf from "./Assets/menuIcons/ic_preview_pdf.png";
import ic_printer from "./Assets/menuIcons/ic_printer.png";
import ic_user from "./Assets/menuIcons/ic_user.png";
import ic_share from "./Assets/menuIcons/ic_share.png";
import ic_upArrow_1 from "./Assets/exportIcons/ic_upArrow.png";
import get_link from "./Assets/get_link.png";
import ic_loading_icon from "./Assets/ic_loading_icon.png";
import ic_web from "./Assets/ic_web.png";

import ic_dd_banner from "./Components/DocumentDesigner/assets/toolbar/ic_dd_banner.png";
import ic_dd_img from "./Components/DocumentDesigner/assets/toolbar/ic_dd_img.png";
import ic_dd_sign from "./Components/DocumentDesigner/assets/toolbar/ic_dd_sign.png";
import ic_dd_systemfield from "./Components/DocumentDesigner/assets/toolbar/ic_dd_systemfield.png";
import ic_dd_text from "./Components/DocumentDesigner/assets/toolbar/ic_dd_text.png";
import ic_dd_watermark from "./Components/DocumentDesigner/assets/toolbar/ic_dd_watermark.png";
import photoPlaceholder from "./Components/DocumentDesigner/assets/photoPlaceholder.png";
import signature_1 from "./Components/DocumentDesigner/assets/signature.png";
import watermark from "./Components/DocumentDesigner/assets/watermark.png";
import ic_listPickerArrow3  from "./Components/DocumentDesigner/assets/ic_listPickerArrow2.png";
import banner_0 from "./Components/DocumentDesigner/assets/banner/banner_0.png";
import banner_1 from "./Components/DocumentDesigner/assets/banner/banner_1.png";
import banner_2 from "./Components/DocumentDesigner/assets/banner/banner_2.png";
import banner_3 from "./Components/DocumentDesigner/assets/banner/banner_3.png";
import banner_4 from "./Components/DocumentDesigner/assets/banner/banner_4.png";
import banner_5 from "./Components/DocumentDesigner/assets/banner/banner_5.png";

import flag_us from "./Assets/flags/us.png";
import flag_fr from "./Assets/flags/fr.png";
import flag_sp from "./Assets/flags/sp.png";
import flag_id from "./Assets/flags/id.png";
import flag_ch from "./Assets/flags/ch.png";
import flag_in from "./Assets/flags/in.png";
import flag_pt from "./Assets/flags/pt.png";
import flag_de from "./Assets/flags/de.png";
import flag_it from "./Assets/flags/it.png";
import flag_ar from "./Assets/flags/ar.png";

import IN_Payslip from "./MockData/IN_Payslip.json";
import US_Payslip from "./MockData/US_Payslip.json";
import MY_Payslip from "./MockData/MY_Payslip.json";
import SG_Payslip from "./MockData/SG_Payslip.json";
import default_payslip from "./MockData/default_payslip.json";
import US_Payroll_Check from "./MockData/US_Payroll_Check.json";

import Template_1 from "./Assets/Template Samples/Template_1.png";
import Template_2 from "./Assets/Template Samples/Template_2.png";
import Template_3 from "./Assets/Template Samples/Template_3.png";
import Template_4 from "./Assets/Template Samples/Template_4.png";
import Template_5 from "./Assets/Template Samples/Template_5.png";
import Template_6 from "./Assets/Template Samples/Template_6.png";
import Template_7 from "./Assets/Template Samples/Template_7.png";
import Template_8 from "./Assets/Template Samples/Template_8.png";
import Template_9 from "./Assets/Template Samples/Template_9.png";
import Template_10 from "./Assets/Template Samples/Template_10.png";
import Template_11 from "./Assets/Template Samples/Template_11.png";
import Template_12 from "./Assets/Template Samples/Template_12.png";
import Template_13 from "./Assets/Template Samples/Template_13.png";
import Template_14 from "./Assets/Template Samples/Template_14.png";
import Template_15 from "./Assets/Template Samples/Template_15.png";
import Template_16 from "./Assets/Template Samples/Template_16.png";
import Template_17 from "./Assets/Template Samples/Template_17.png";
import Template_18 from "./Assets/Template Samples/Template_18.png";
import Template_19 from "./Assets/Template Samples/Template_19.png";
import Template_20 from "./Assets/Template Samples/Template_20.png";
import Template_21 from "./Assets/Template Samples/Template_21.png";
import Template_22 from "./Assets/Template Samples/Template_22.png";
import Template_23 from "./Assets/Template Samples/Template_23.png";
import Template_24 from "./Assets/Template Samples/Template_24.png";
import Template_25 from "./Assets/Template Samples/Template_25.png";
import Template_26 from "./Assets/Template Samples/Template_26.png";
import Template_27 from "./Assets/Template Samples/Template_27.png";
import Template_28 from "./Assets/Template Samples/Template_28.png";
import Template_29 from "./Assets/Template Samples/Template_29.png";
import Template_30 from "./Assets/Template Samples/Template_30.png";
import Template_31 from "./Assets/Template Samples/Template_31.png";
import Template_32 from "./Assets/Template Samples/Template_32.png";
import Template_33 from "./Assets/Template Samples/Template_33.png";
import Template_34 from "./Assets/Template Samples/Template_34.png";
import Template_35 from "./Assets/Template Samples/Template_35.png";
import Template_36 from "./Assets/Template Samples/Template_36.png";
import Template_37 from "./Assets/Template Samples/Template_37.png";
import Template_38 from "./Assets/Template Samples/Template_38.png";
import Template_39 from "./Assets/Template Samples/Template_39.png";
import Template_40 from "./Assets/Template Samples/Template_40.png";
import Template_41 from "./Assets/Template Samples/Template_41.png";
import Template_42 from "./Assets/Template Samples/Template_42.png";
import Template_43 from "./Assets/Template Samples/Template_43.png";
import Template_44 from "./Assets/Template Samples/Template_44.png";
import Template_45 from "./Assets/Template Samples/Template_45.png";
import IN_Payslip_1 from "./Assets/Payslip Samples/IN_Payslip_1.png";
import IN_Payslip_2 from "./Assets/Payslip Samples/IN_Payslip_2.png";
import IN_Payslip_3 from "./Assets/Payslip Samples/IN_Payslip_3.png";
import IN_Payslip_4 from "./Assets/Payslip Samples/IN_Payslip_4.png";
import IN_Payslip_5 from "./Assets/Payslip Samples/IN_Payslip_5.png";
import IN_Payslip_6 from "./Assets/Payslip Samples/IN_Payslip_6.png";
import IN_Payslip_7 from "./Assets/Payslip Samples/IN_Payslip_7.png";
import US_Payslip_1 from "./Assets/Payslip Samples/US_Payslip_1.png";
import SG_Payslip_1 from "./Assets/Payslip Samples/SG_Payslip_1.png";
import SG_Payslip_2 from "./Assets/Payslip Samples/SG_Payslip_2.png";
import SG_Payslip_3 from "./Assets/Payslip Samples/SG_Payslip_3.png";
import SG_Payslip_4 from "./Assets/Payslip Samples/SG_Payslip_4.png";
import SG_Payslip_5 from "./Assets/Payslip Samples/SG_Payslip_5.png";
import MY_Payslip_1 from "./Assets/Payslip Samples/MY_Payslip_1.png";
import MY_Payslip_2 from "./Assets/Payslip Samples/MY_Payslip_2.png";
import Payslip_Template_1 from "./Assets/Payslip Samples/Payslip_Template_1.png";
import Cheque_Template_1 from "./Assets/Cheque Samples/Cheque_Template_1.png";
import Label_Template_1 from "./Assets/Label Samples/Label_Template_1.png";
import EWayBill_Template_1 from "./Assets/EWayBillDetails Sample/EWayBill_Template_1.png";
import EWayBill_Template_2 from "./Assets/EWayBillSummary Sample/EWayBill_Template_1.png";
import Receipt_Template_1 from "./Assets/Receipt Sample/Receipt_Template_1.png";
import Receipt_Template_2 from "./Assets/Receipt Sample/Receipt_Template_2.png";
import USPayrollChequeSample_1 from "./Assets/PayrollChequeSamples/USPayrollChequeSample_1.png";
import Work_Order_Template_1 from "./Assets/Work Order Sample/Work_Order_Template_1.png";
import QC_Document_Template_1 from "./Assets/QC Document Sample/QC_Document_Template_1.png";
import QC_Inspection_Template_1 from "./Assets/QC Inspection Sample/QC_Inspection_Template_1.png";
import Inspection_Report_Template_1 from "./Assets/Inspection Report Sample/Inspection_Report_Template_1.png"
import Machine_Scheduler_Template_1  from './Assets/Machine/Machine_scheduler_template_1.png'
import Stock_Issues_Template_1 from "./Assets/Stock Issues Sample/Stock_Issues_Template_1.png";
import Stock_Request_Template_1 from "./Assets/Stock Request Sample/Stock_Request_Template_1.png";
import Stock_Transfer_Template_1 from "./Assets/Stock Transfer Sample/Stock_Transfer_Template_1.png";
import India_Template_51 from "./Assets/India Template Sample/Template_51.png";
import SecurityGateEntryTemplate1 from "./Assets/Security Gate Entry Sample/SecurityGateEntryTemplate1.png";
import PurchaseRequisitionTemplate_1 from "./Assets/Requisition Sample/PurchaseRequisitionTemplate_1.png";
import ServiceRequisitionTemplate_1 from "./Assets/Requisition Sample/SERVICE_REQUISITION_1.png";
import ServiceOrderTemplate1 from "./Assets/Service Order Sample/ServiceOrderTemplate1.png";
import ServiceOrderTemplate2 from "./Assets/Service Order Sample/ServiceOrderTemplate2.png";
import GoodsReceiptNoteTemplate1 from "./Assets/Goods Receipt Note Sample/GoodsReceiptNoteTemplate1.png";
import India_Template_61 from "./Assets/India Template Sample/Template_61.png";
import India_Template_62 from "./Assets/India Template Sample/Template_62.png";
import India_Template_63 from "./Assets/India Template Sample/Template_63.png";
import Aged_Receivable_Template_1 from "./Assets/Aged Receivable Sample/Aged_Receivable_Template_1.png";

export const Asset = {
    icon: {
        e_way_bill_logo: e_way_bill_logo,
        ic_listPickerArrow2: ic_listPickerArrow2,
        ic_close_white: ic_close_white,
        ic_downArrow: ic_downArrow,
        ic_upArrow: ic_upArrow,
        ic_delete_white: ic_delete_white,
        ic_refresh_black: ic_refresh_black,
        ic_thumbnail_default2: ic_thumbnail_default2,
        ic_edit: ic_edit,
        ic_drag_indicator: ic_drag_indicator,
        ic_delete: ic_delete,
        ic_checkmarkCircle: ic_checkmarkCircle,
        ic_bookmark: ic_bookmark,
        books: books,
        people: people,
        sales: sales,
        ico_deskLogo: ico_deskLogo,
        deskLogoNew: deskLogoNew,
        ic_close: ic_close,
        signature: signature,
        ic_edit_white: ic_edit_white,
        ic_dd_banner: ic_dd_banner,
        ic_dd_img: ic_dd_img,
        ic_dd_sign: ic_dd_sign,
        ic_dd_systemfield: ic_dd_systemfield,
        ic_dd_text: ic_dd_text,
        ic_dd_watermark: ic_dd_watermark,
        photoPlaceholder: photoPlaceholder,
        signature_1: signature_1,
        watermark: watermark,
        ic_listPickerArrow3: ic_listPickerArrow3,
        banner_0: banner_0,
        banner_1: banner_1,
        banner_2: banner_2,
        banner_3: banner_3,
        banner_4: banner_4,
        banner_5: banner_5,
        ic_email: ic_email,
        ic_download: ic_download,
        ic_preview_pdf: ic_preview_pdf,
        ic_printer: ic_printer,
        ic_user: ic_user,
        ic_share: ic_share,
        ic_upArrow_1: ic_upArrow_1,
        get_link: get_link,
        ic_loading_icon: ic_loading_icon,
        ic_web: ic_web
    },
    flag: {
        flag_us: flag_us,
        flag_fr: flag_fr,
        flag_sp: flag_sp,
        flag_id: flag_id,
        flag_ch: flag_ch,
        flag_in: flag_in,
        flag_pt: flag_pt,
        flag_de: flag_de,
        flag_it: flag_it,
        flag_ar: flag_ar
    },
    mockData: {
        IN_Payslip: IN_Payslip,
        US_Payslip: US_Payslip,
        MY_Payslip: MY_Payslip,
        SG_Payslip: SG_Payslip,
        default_payslip: default_payslip,
        US_Payroll_Check: US_Payroll_Check
    },
    template: {
        Template_1: Template_1,
        Template_2: Template_2,
        Template_3: Template_3,
        Template_4: Template_4,
        Template_5: Template_5,
        Template_6: Template_6,
        Template_7: Template_7,
        Template_8: Template_8,
        Template_9: Template_9,
        Template_10: Template_10,
        Template_11: Template_11,
        Template_12: Template_12,
        Template_13: Template_13,
        Template_14: Template_14,
        Template_15: Template_15,
        Template_16: Template_16,
        Template_17: Template_17,
        Template_18: Template_18,
        Template_19: Template_19,
        Template_20: Template_20,
        Template_21: Template_21,
        Template_22: Template_22,
        Template_23: Template_23,
        Template_24: Template_24,
        Template_25: Template_25,
        Template_26: Template_26,
        Template_27: Template_27,
        Template_28: Template_28,
        Template_29: Template_29,
        Template_30: Template_30,
        Template_31: Template_31,
        Template_32: Template_32,
        Template_33: Template_33,
        Template_34: Template_34,
        Template_35: Template_35,
        Template_36: Template_36,
        Template_37: Template_37,
        Template_38: Template_38,
        Template_39: Template_39,
        Template_40: Template_40,
        Template_41: Template_41,
        Template_42: Template_42,
        Template_43: Template_43,
        Template_44: Template_44,
        Template_45: Template_45,
        IN_Payslip_1: IN_Payslip_1,
        IN_Payslip_2: IN_Payslip_2,
        IN_Payslip_3: IN_Payslip_3,
        IN_Payslip_4: IN_Payslip_4,
        IN_Payslip_5: IN_Payslip_5,
        IN_Payslip_6: IN_Payslip_6,
        IN_Payslip_7: IN_Payslip_7,
        US_Payslip_1: US_Payslip_1,
        SG_Payslip_1: SG_Payslip_1,
        SG_Payslip_2: SG_Payslip_2,
        SG_Payslip_3: SG_Payslip_3,
        SG_Payslip_4: SG_Payslip_4,
        SG_Payslip_5: SG_Payslip_5,
        MY_Payslip_1: MY_Payslip_1,
        MY_Payslip_2: MY_Payslip_2,
        Payslip_Template_1: Payslip_Template_1,
        Cheque_Template_1: Cheque_Template_1,
        Label_Template_1: Label_Template_1,
        EWayBill_Template_1: EWayBill_Template_1,
        EWayBill_Template_2: EWayBill_Template_2,
        Receipt_Template_1: Receipt_Template_1,
        Receipt_Template_2: Receipt_Template_2,
        USPayrollChequeSample_1: USPayrollChequeSample_1,
        Work_Order_Template_1: Work_Order_Template_1,
        QC_Document_Template_1: QC_Document_Template_1,
        QC_Inspection_Template_1: QC_Inspection_Template_1,
        Inspection_Report_Template_1: Inspection_Report_Template_1,
        Machine_Scheduler_Template_1: Machine_Scheduler_Template_1,
        REQUISITION_SERVICE_TEMPLATE_1: ServiceRequisitionTemplate_1,
        Stock_Issues_Template_1: Stock_Issues_Template_1,
        Stock_Request_Template_1: Stock_Request_Template_1,
        Stock_Transfer_Template_1: Stock_Transfer_Template_1,
        India_Template_51: India_Template_51,
        SecurityGateEntryTemplate1: SecurityGateEntryTemplate1,
        PurchaseRequisitionTemplate_1: PurchaseRequisitionTemplate_1,
        ServiceOrderTemplate1: ServiceOrderTemplate1,
        ServiceOrderTemplate2: ServiceOrderTemplate2,
        GoodsReceiptNoteTemplate1: GoodsReceiptNoteTemplate1,
        India_Template_61: India_Template_61,
        India_Template_62: India_Template_62,
        India_Template_63: India_Template_63,
        Aged_Receivable_Template_1: Aged_Receivable_Template_1,
        GoodsReceiptNoteTemplate1: GoodsReceiptNoteTemplate1
    }
}
