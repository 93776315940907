import { DKButton, DKLabel, DKSpinner, showAlert } from 'deskera-ui-library';
import { isEqual } from 'lodash';
import { useState } from 'react';
import {
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCE_PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION
} from '../../../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import {
  BtnType,
  PopupClickActionType
} from '../../../../../Models/Interfaces';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import {
  completeWOWithWIPConsumptionAndProduction,
  completeWOWithWIPConsumptionAndProductionAPICall,
  IWorkOrder,
  IWorkOrderItems,
  IWorkOrderOperation,
  woProductionPopupDataToPass
} from '../../../../../Services/MRP/WorkOrder';
import PopupWrapper from '../../../../../SharedComponents/PopupWrapper';
import Utility from '../../../../../Utility/Utility';
import DetailsOpener from '../../../../Common/DetailsOpener';
import { WORK_ORDER_STATUS } from '../../../Constants/MRPColumnConfigs';
import WOLinkedRecords from '../../../Shared/WOLinkedRecords';
import { woSliceStateKeys } from '../../AddWorkOrderSlice';
import {
  WorkOrderHelper,
  autoAllocateStockToWorkOrder,
  getActionButtonTitle,
  getStatusColumnConfig,
  isAnyMaterialTaggedToJC
} from '../../WorkOrderHelper';
import RawMaterialHelper from '../BomMaterialComponent/RawMaterialHelper';
import WOStatusUpdateWithProductionPopup from '../WOStatusUpdateWithProductionPopup';

interface WorkOrderHeaderProps {
  activeTabIndex: number;
  workOrders: IWorkOrder[];
  pristineWorkOrderData: IWorkOrder[];
  workOrderObject: IWorkOrder;
  jwoDetails: any[];
  detailsOpenerData: any;
  isEditMode: boolean;
  isReadOnlyMode: boolean;
  updating: boolean;
  loading: boolean;
  hasConsumptionEntries: boolean;
  showWOLinkedRecordsPopup: boolean;
  onUpdateActiveWOKey: (key: string, value: any) => void;
  updateActiveWOKeyAndSaveWO: (key: string, value: any) => void;
  onClose: () => void;
  onSave: (
    partialSave?: boolean,
    ignoreStatus?: boolean,
    isLinkedRecord?: boolean
  ) => void;
  onCommonStateUpdate: (key: woSliceStateKeys, value: any) => void;
  onSetCanValidate: (canVaildate: boolean) => void;
  onStatusUpdateClick: () => void;
  onCloseDetailsOpener: () => void;
  onWOStatusChange: (res: any) => void;
  workOrderFromParentProps?: any;
  isReadOnlyFromParentProps?: boolean;
  jobCardList?: any[];
  isCopyMode?: boolean; // added for copy mode
}

const WorkOrderHeader = (props: WorkOrderHeaderProps) => {
  //states
  const [
    showWOStatusUpdateAndProductionPopup,
    setShowWOStatusUpdateAndProductionPopup
  ] = useState(false);

  //selectors
  const tenantInfo = useAppSelector(activeTenantInfo);

  const openWoLinkedRecords = () => {
    const isWoLinkedRecordsUnchanged =
      isEqual(
        props.pristineWorkOrderData?.[props.activeTabIndex]?.linkedDocuments,
        props.workOrderObject?.linkedDocuments
      ) &&
      isEqual(
        props.pristineWorkOrderData?.[props.activeTabIndex]
          ?.workOrderChildDetails,
        props.workOrderObject?.workOrderChildDetails
      ) &&
      isEqual(
        props.pristineWorkOrderData?.[props.activeTabIndex]?.workOrderItems,
        props.workOrderObject?.workOrderItems
      );

    if (isWoLinkedRecordsUnchanged) {
      props.onCommonStateUpdate('showWOLinkedRecordsPopup', true);
    } else {
      let buttons = [
        {
          title: 'Cancel',
          className: 'bg-gray1 text-black border-m mr-r',
          onClick: () => {}
        },
        {
          title: 'Save current WO',
          className: 'bg-button text-white mr-r',
          onClick: () => {
            props.onSave(true, false, true);
          }
        }
      ];

      showAlert(
        'Warning',
        `You need to save the current WorkOrder first.`,
        buttons
      );
    }
  };

  const onCompleteOrderClick = () => {
    props.onSetCanValidate(true);
    let validated: boolean = true;

    // validation for inventory tracking
    if (
      props.isEditMode &&
      !props.isReadOnlyMode &&
      props.workOrderObject?.allJobCardsCompleted
    ) {
      // for line items
      let lineItems =
        props.workOrderObject?.workOrderItems?.filter(
          (item: any) =>
            item?.produceProductType !== PRODUCE_PRODUCT_TYPE.SCRAP &&
            item?.produceProductType !== PRODUCE_PRODUCT_TYPE.CO_PRODUCT
        ) || [];
      for (let index = 0; index < lineItems?.length; index++) {
        const woItem = lineItems?.[index];

        if (!RawMaterialHelper.isNonServiceProduct(woItem)) continue;

        let assignQtys = 0;
        if (woItem?.itemName?.advancedTracking === ADVANCE_TRACKING.NONE) {
          assignQtys =
            woItem?.warehouseInventoryData?.reduce(
              (prev: number, current: any) => {
                return prev + Number(current.quantity);
              },
              0
            ) ?? 0;
        } else {
          let advacedList = woItem?.warehouseInventoryData?.reduce(
            (prev: any[], current: any) => {
              return [...prev, ...current?.advancedTrackingData];
            },
            []
          );
          assignQtys =
            advacedList?.reduce((prev: number, current: any) => {
              return prev + Number(current.qtyToFulfil);
            }, 0) ?? 0;
        }
        // warehouseInventoryData
        let assignSubstituteQtys =
          woItem?.bomProductSubstitutesDetails?.reduce(
            (prev: number, current: any) => {
              let sum =
                current?.warehouseInventoryData?.reduce(
                  (total: number, currentItem: any) => {
                    return total + (currentItem?.quantity ?? 0);
                  },
                  0
                ) ?? 0;

              return prev + sum;
            },
            0
          ) ?? 0;
        assignQtys = Number(assignQtys) + Number(assignSubstituteQtys);
        assignQtys = Utility.roundingOff(assignQtys, QTY_ROUNDOFF_PRECISION);
        const woRequiredQty = Utility.roundingOff(
          Number(woItem?.requiredQty),
          QTY_ROUNDOFF_PRECISION
        );
        if (woItem?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
          assignQtys = Utility.getUomQuantityWithoutRoundOff(
            assignQtys,
            woItem?.documentUOMSchemaDefinition
          );
        }
        if (assignQtys !== woRequiredQty) {
          showAlert(
            'Unallocated bom material found!',
            'Please complete allocation/tracking for all BOM materials.'
          );
          validated = false;
          return;
        }
      }
      if (!validated) {
        showAlert(
          'Unallocated bom material found!',
          'Please complete allocation/tracking for all BOM materials.'
        );
        validated = false;
        return false;
      }
    }

    if (
      validated &&
      (props.hasConsumptionEntries ||
        isAnyMaterialTaggedToJC(props?.jobCardList || []))
    ) {
      if (tenantInfo?.additionalSettings?.LINK_INVENTORY_JOB_CARDS) {
        setShowWOStatusUpdateAndProductionPopup(
          !showWOStatusUpdateAndProductionPopup
        );
        return;
      }
      completeWOWithWIPConsumptionAndProduction(
        props.workOrderFromParentProps?.id,
        props?.isEditMode,
        props.workOrderFromParentProps?.parentWorkOrderCode,
        (res: any) => {
          props.onWOStatusChange(res);
        }
      );
      return;
    }

    if (validated) {
      props.onUpdateActiveWOKey('actualEndDate', new Date());
      props.onCommonStateUpdate('showCompleteWorkOrderPopup', true);
    }
  };

  const handleStartOrderClickBtn = () => {
    showAlert(
      'Reserve Stock',
      'Once you start order, quantities will be allocated to the material if not done already.',
      [
        {
          title: 'Cancel',
          className: 'border-m bg-gray1',
          onClick: () => {}
        },
        {
          title: 'Ok',
          className: 'bg-button text-white border-m ml-s',
          onClick: async () => {
            const updatedWorkOrderItems = await autoAllocateStockToWorkOrder(
              props.workOrders,
              props.activeTabIndex,
              props.pristineWorkOrderData
            );
            props.updateActiveWOKeyAndSaveWO(
              'workOrderItems',
              updatedWorkOrderItems
            );
          }
        }
      ]
    );
  };

  // Linked records popup
  const getWOLinkedRecordsView = () => {
    const catchClicks = (data: PopupClickActionType) => {
      switch (data.type) {
        case POPUP_CLICK_TYPE.CLOSE_POPUP:
          props.onCommonStateUpdate('showWOLinkedRecordsPopup', false);
          break;
      }
    };

    const popupBtnConfigForOpen: BtnType[] = [
      {
        title: 'Close',
        class: 'bg-gray1 border-m',
        clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
      }
    ];

    return (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={'Linked Records'}
        btnList={popupBtnConfigForOpen}
        width="682px"
        maxHeight={'95%'}
        disableClickOutside
      >
        <WOLinkedRecords
          onCancel={() => {
            props.onCommonStateUpdate('showWOLinkedRecordsPopup', false);
          }}
          data={props.workOrderObject}
          jwoDetails={props.jwoDetails}
        />
      </PopupWrapper>
    );
  };

  const getLinkedRecordsBtn = () => {
    if (
      props.isEditMode &&
      !props.isCopyMode &&
      !props?.workOrderFromParentProps?.isWOLinkedRecord
    ) {
      return (
        <DKButton
          title={'Linked Records'}
          className="bg-white border-m ml-r"
          onClick={() => {
            openWoLinkedRecords();
          }}
        />
      );
    }
    return null;
  };

  const getActionButton = () => {
    if (
      props.isEditMode &&
      !props.isCopyMode &&
      !props.isReadOnlyFromParentProps &&
      props.workOrderObject?.status !== WORK_ORDER_STATUS.COMPLETED
    ) {
      if (!props.workOrderObject?.allJobCardsCompleted) {
        const actionBtnTitle = getActionButtonTitle(props.workOrderObject);
        if (actionBtnTitle) {
          return (
            <div
              className={`row border-radius-m ml-r ${
                props.updating
                  ? ' bg-white border-m pr-2'
                  : ' bg-button text-white'
              }`}
            >
              <DKButton
                title={actionBtnTitle}
                disabled={props.updating}
                onClick={() => {
                  if (
                    actionBtnTitle ===
                      WorkOrderHelper.WORK_ORDER_BTN_STATUS.START_ORDER &&
                    tenantInfo?.additionalSettings?.WO_AUTO_ALLOCATE_ON_START
                  ) {
                    handleStartOrderClickBtn();
                  } else {
                    props.onStatusUpdateClick();
                  }
                }}
              />

              {props.updating && <DKSpinner iconClassName="ic-s" />}
            </div>
          );
        }
      } else {
        const hasNewRMOrOperation =
          props.workOrderObject?.workOrderItems?.some(
            (item: IWorkOrderItems) => item?.isNewRow
          ) ||
          props.workOrderObject?.workOrderOperations?.some(
            (operation: IWorkOrderOperation) => operation?.isNewRow
          );
        return hasNewRMOrOperation ? null : (
          <div
            className={`row border-radius-m ml-r ${
              props.updating
                ? ' bg-white border-m pr-2'
                : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Complete Order'}
              disabled={props.updating}
              onClick={() => {
                onCompleteOrderClick();
              }}
            />
            {props.updating && <DKSpinner iconClassName="ic-s" />}
          </div>
        );
      }
    }
    return null;
  };

  const getSaveButton = () => {
    if (
      props.workOrderObject?.status !== WORK_ORDER_STATUS.COMPLETED &&
      !props.isReadOnlyFromParentProps
    ) {
      return (
        <div
          className={`row border-radius-m ml-r ${
            props.updating || Utility.isEmptyObject(props.workOrders)
              ? ' bg-white border-m'
              : ' bg-button text-white'
          }`}
        >
          <DKButton
            title={'Save'}
            disabled={props.updating || Utility.isEmptyObject(props.workOrders)}
            onClick={() => {
              props.onSave();
            }}
          />

          {props.updating && <DKSpinner iconClassName="ic-s mr-s" />}
        </div>
      );
    }
    return null;
  };

  const handleCancelClick = () => {
    if (
      props.isEditMode &&
      props.pristineWorkOrderData[props.activeTabIndex]?.manufactureQuantity !==
        props.workOrderObject?.manufactureQuantity
    ) {
      showAlert(
        'Please note!',
        'You have changed the manufacturing quantity, Do you wish to save the current Work Order?',
        [
          {
            title: 'No',
            className: 'border-m bg-gray1',
            onClick: () => {
              !props.updating && props.onClose?.();
            }
          },
          {
            title: 'Save',
            className: 'bg-button text-white ml-2',
            onClick: () => {
              props.onSave();
            }
          }
        ]
      );
      return;
    }
    !props.updating && props.onClose?.();
  };

  /**
   * Main renderer
   */
  return (
    <div className="row row-responsive justify-content-between p-h-r p-v-s bg-gray1">
      <div className="row pop-header-drag-handle">
        <DKLabel
          text={`${
            props.isReadOnlyMode
              ? 'View'
              : !props.isEditMode || props.isCopyMode
              ? 'Add'
              : 'Edit'
          } Work Order`}
          className="fw-m fs-l"
        />
        {props.loading && Utility.isNotEmpty(props.workOrders) && (
          <DKSpinner iconClassName="ic-s ml-r" />
        )}
        {props.isEditMode && !props.isCopyMode && (
          <DKLabel
            text={
              getStatusColumnConfig(
                !Utility.isEmpty(props.workOrderObject)
                  ? props.workOrderObject
                  : props.workOrderFromParentProps
              ).name
            }
            className={`fw-m ml-r p-h-s p-v-xs border-radius-l text-gray ${
              getStatusColumnConfig(
                !Utility.isEmpty(props.workOrderObject)
                  ? props.workOrderObject
                  : props.workOrderFromParentProps
              ).color
            }`}
          />
        )}
        {props.isReadOnlyMode ? (
          <DKLabel
            text={`Read-Only`}
            className={`text-dark-gray bg-chip-orange border-orange text-orange border-radius-r ml-r p-h-s p-v-xs fw-m`}
          />
        ) : null}
      </div>
      <div className="row mobile-mt-s width-auto ">
        <DKButton
          title={'Cancel'}
          className="bg-white border-m"
          onClick={handleCancelClick}
        />
        {getLinkedRecordsBtn()}
        {getActionButton()}
        {getSaveButton()}
        {props.showWOLinkedRecordsPopup && getWOLinkedRecordsView()}
        {props.detailsOpenerData?.showDetailsOpener && (
          <DetailsOpener
            data={props.detailsOpenerData}
            onCancel={() => {
              props.onCloseDetailsOpener();
            }}
            isReadOnly={true}
          />
        )}
      </div>
      {showWOStatusUpdateAndProductionPopup && (
        <WOStatusUpdateWithProductionPopup
          productionPopupData={woProductionPopupDataToPass(
            props.workOrderObject
          )}
          workOrderObject={props.workOrderObject}
          onCancel={() => {
            setShowWOStatusUpdateAndProductionPopup(false);
          }}
          onSuccess={() => {
            completeWOWithWIPConsumptionAndProductionAPICall(
              props.workOrderFromParentProps?.id,
              props?.isEditMode,
              props.workOrderFromParentProps?.parentWorkOrderCode,
              (res: any) => {
                props.onWOStatusChange(res);
              }
            );
          }}
        />
      )}
    </div>
  );
};

export default WorkOrderHeader;
