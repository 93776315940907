import {
  DKButton,
  DKIcons,
  DKLabel,
  DKInput,
  INPUT_TYPE,
  DKIcon,
  DKCalendar,
  showLoader,
  removeLoader,
  DKDataGrid,
  DKCheckMark,
  DKListPicker2,
  showToast,
  showAlert
} from 'deskera-ui-library';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TAX_ADJUSTMENT_SUBTYPES_INDIA,
  TAX_ADJUSTMENT_TYPES_INDIA
} from '../../../Configs/JEConfig';
import {
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  CURRENCY_HISTORY_CONSTANTS,
  CUSTOM_FIELD_TYPE,
  CUSTOM_NUMBER_INPUT_MODULES,
  DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  MULTI_BOOKS_PERSPECTIVE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  STATUS_TYPE,
  SWITCH_CURRENCY_OPTIONS,
  TAX_SYSTEM
} from '../../../Constants/Constant';
import { DocumentConfigManager } from '../../../Managers/DocumentConfigManger';
import { JEConfigManager } from '../../../Managers/JEConfigManager';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../Models/Interfaces';
import {
  CD_TYPE,
  JENormalInitialState,
  JE_TYPES
} from '../../../Models/JournalEntry';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchAccoutnsList,
  selectedAccounts
} from '../../../Redux/Slices/AccountsSlice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  fetchClassesByDimensionId,
  fetchCustomFields,
  selectCurrencyListWithExchangeRate,
  selectCustomFields
} from '../../../Redux/Slices/CommonDataSlice';
import { fetchJournal } from '../../../Redux/Slices/Journal';
import AttachmentService from '../../../Services/Attachment';
import DateFormatService from '../../../Services/DateFormat';
import JournalService from '../../../Services/Journal';
import {
  checkIfLineLevelCustomFieldIsValid,
  customFieldsContainsErrors,
  getPrimaryCurrencyCheck,
  getTenantTaxSystem,
  updateColumnConfigOnRowClick,
  updateRowDataWithParentCFValues,
  validateBudgetForAccounts
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility, { deepClone } from '../../../Utility/Utility';
import { triggerDownload } from '../../ImportExport/utility/ExportData';
import AddTaxAdjustmentDetails from './AddTaxAdjustmentDetails';
import {
  selectJournalColumnConfig,
  selectJournalColumnConfigTableId
} from '../../../Redux/Slices/Journal';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import AccountsService, { AccountAPIConfig } from '../../../Services/Accounts';
import ApiConstants from '../../../Constants/ApiConstants';
import NumberFormatService from '../../../Services/NumberFormat';
import AddCoaPopup from '../COA/AddCoaPopup';
import { getEmptyRowType, jeTypesArray } from './JournalHelper';
import { ConfigUtility } from '../../../Utility/ConfigUtility';
import { getNewColumn, getNewItem } from './JEHelper';
import {
  fetchCategoryDimensions,
  selectDimensions
} from '../../../Redux/Slices/LocationSlice';
import { Store } from '../../../Redux/Store';
import { GranularPermissionsHelper } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import {
  PERMISSIONS_BY_MODULE,
  PERMISSION_MODULE_NAME
} from '../../../Constants/Permission';
import CustomNumberFormatInput from '../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import { selectJournalEntryCustomNumbersFormats } from '../../../Redux/Slices/CustomNumberFormat';
import AddClass from '../../Settings/Classes/AddClass';
import { enableMultiViewJournalEntryMethodAndElse } from '../../../SharedComponents/MultiViewJournalEntry/enableMultiViewJounalEntry';
import CustomFieldSettings from '../../../SharedComponents/CustomFieldsHolder/CustomFieldSettings';
import CustomFieldService from '../../../Services/CustomField';
import useScreenResize from '../../../Hooks/useScreenResize';
import { addDays } from 'date-fns';
import {
  fetchCurrencyHistroy,
  selectCurrencyHistory
} from '../../../Redux/Slices/CurrencySlice';
import CurrencyService, { RealtimeCurrencyAPIConfig } from '../../../Services/Currency';

export default function AddJournalNormal(props: any) {
  const titleStyle = { color: 'gray', fontSize: 12 };
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const initialJE = {
    ...JENormalInitialState,
    type: props.type ?? JENormalInitialState.type
  };
  //states
  const [je, setJE] = useState(props.je || initialJE);
  let d;
  if (props.je) {
    d = props.je.jeDate.split('-');
  }

  const initialDate = props.je
    ? new Date(`${d[2]}, ${d[1]}, ${d[0]}`)
    : new Date();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const [journalDate, setJournalDate] = useState(initialDate);
  const laterDate = addDays(journalDate, 30);
  const [reversalDate, setReversalDate] = useState(laterDate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showReversalDatePicker, setShowReversalDatePicker] = useState(false);
  const [isReversalDateSelect, setIsReversalDateSelect] =
    useState<boolean>(false);
  const [primaryCurrencyHistory, setPrimaryCurrencyHistory] = useState<any[]>([]);
  const [currencyHistoryData, setCurrencyHistoryData] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const tenantInfo = useAppSelector(activeTenantInfo); //bookBeginningStartDate
  const [columnConfig, setColumnConfig] = useState(
    tenantInfo?.additionalSettings?.JE_CREDIT_DEBIT_COLUMN_SETTING === true
      ? JEConfigManager.docNewConfigs
      : JEConfigManager.docConfigs
  );
  const [showAccountPopup, setShowAccountPopup] = useState(false);
  const [submitButtonTapped, setSubmitButtonTapped] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState<any[]>([]);
  const isReadOnlyMode = props.readOnly ? true : false;
  const [isLocationClassPresent, setIsLocationClassPresent] = useState(false);
  const isEditMode = Utility.isEmpty(props.je) ? false : true;

  //multi-currency states
  const [showMultiCurrencyList, setShowMultiCurrencyList] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const [conversionRate, setConversionRate] = useState<any>();

  //selectors
  const currencyExchangeRates = useAppSelector(
    selectCurrencyListWithExchangeRate
  );

  const journalColumnConfig = useAppSelector(selectJournalColumnConfig);
  const journalConfigTableId = useAppSelector(selectJournalColumnConfigTableId);
  // const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const accountsData = useAppSelector(selectedAccounts);
  const systemDimensions = useAppSelector(selectDimensions);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [selectedNumberFormat, setSelectedNumberFormat] = useState<any>({});
  const [openAccountCFSettings, setOpenAccountCFSettings] = useState(false);
  const [accountsCFData, setAccountsCFData] = useState<any[]>([]);
  const cfUpdatedTimeMap = useRef<any>({});
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [gridWidth] = useScreenResize(gridContainerRef);
  const [tempPrimaryExchangeRate, setTempPrimaryExchangeRate] = useState(
    props?.je?.primaryExchangeRate
  );

  const isCurrencyChanged = useRef(false);

  //constants
  const showTaxView =
    getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST && tenantInfo.gstRegistered;

  const cdTypesArray = [jeTypesArray?.[0], jeTypesArray?.[1]];

  // effects
  useEffect(() => {
    registerInteractions();
    getAccountsCFData();

    const updatedState = { ...je };

    let oldProps: any;
    if (props.je) {
      oldProps = props.je;
    }

    //tax adjustment type
    if (updatedState?.taxAdjustmentDetails?.taxAdjustmentType) {
      updatedState.taxAdjustmentDetails.taxAdjustmentType.selectedValue =
        oldProps?.journalTaxDetailsIN?.taxAdjustmentType;
      updatedState.taxAdjustmentDetails.taxAdjustmentType.options =
        TAX_ADJUSTMENT_TYPES_INDIA;

      // tax adjustment subtype
      updatedState.taxAdjustmentDetails.taxAdjustmentSubType.selectedValue =
        oldProps?.journalTaxDetailsIN?.taxAdjustmentSubtype;

      const newSubTypes = TAX_ADJUSTMENT_SUBTYPES_INDIA.filter((item: any) => {
        return (
          item.isDisplay === oldProps?.journalTaxDetailsIN?.taxAdjustmentType
        );
      });
      updatedState.taxAdjustmentDetails.taxAdjustmentSubType.options =
        newSubTypes;

      // taxDetails
      updatedState.taxAdjustmentDetails.taxDetails =
        oldProps?.journalTaxDetailsIN;
    }

    if (!accountsData?.content) {
      dispatch(fetchAccoutnsList());
    }

    // attachment API call
    if (props.je?.id) {
      AttachmentService.attachmentConfig = {
        ...AttachmentService.attachmentConfig,
        Module: DOC_TYPE.JOURNAL_ENTRY,
        EntityId: props.je?.id
      };
      AttachmentService.getAllAttachments().then((attachmentList: any) => {
        setAttachments(attachmentList);
      });
    }

    const filteredCurr = currencyExchangeRates.find((curr) => {
      if (props.je) {
        return curr.currencyCode === updatedState.currency;
      } else {
        return curr.currencyCode === tenantInfo.currency;
      }
    });

    let rate =
      props?.je?.currencyExchangeRate === null ||
      props?.je?.currencyExchangeRate === undefined
        ? getRate(filteredCurr)
        : getRate(props?.je);
    if (props.je && !props.je?.id) {
      rate = props.je?.currencyExchangeRate;
    }

    if (!Utility.isEmpty(updatedState?.reversalJEDate)) {
      let tempReversalDate = DateFormatService.getDateFromStr(
        updatedState?.reversalJEDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      setIsReversalDateSelect(true);
      setReversalDate(tempReversalDate);
    }

    if (
      tenantInfo?.additionalSettings?.JE_CREDIT_DEBIT_COLUMN_SETTING === true
    ) {
      updatedState.lineItems.forEach((item: any) => {
        if (item.cdType === 'CREDIT') {
          item.credit = item?.amountInDocumentCurrency;
          item.debit = '0.00';
        } else {
          item.debit = item?.amountInDocumentCurrency;
          item.credit = '0.00';
        }
      });
    }
    let primaryCurrencyExchangeRate = updatedState?.primaryCurrencyExchangeRate;
    if (
      (primaryCurrencyExchangeRate === null ||
        primaryCurrencyExchangeRate === undefined) &&
      getPrimaryCurrencyCheck() &&
      !Utility.isEmpty(currencyExchangeRates)
    ) {
      const primaryCurrencyCode =
        tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
      primaryCurrencyExchangeRate = currencyExchangeRates.find(
        (data) => data.currencyCode == primaryCurrencyCode
      )?.currencyExchangeRate;
    }
    if (getPrimaryCurrencyCheck()) {
      const primaryCurrencyCode =
        tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
      showLoader();
      getCurrencyHistoryViaCode(primaryCurrencyCode)
        .then((res) => {
          setPrimaryCurrencyHistory(res);
        })
        .finally(() => {
          removeLoader();
        });
    }
    updatedState.primaryCurrencyExchangeRate = primaryCurrencyExchangeRate;
    setTempPrimaryExchangeRate(primaryCurrencyExchangeRate);
    setSelectedCurrency(filteredCurr);
    setConversionRate(rate);
    updatedState.lineItems.forEach((item: any) => {
      if (updatedState.type === JE_TYPES.BANK_TRANSFER && isReadOnlyMode) {
        item.amountInDocumentCurrency = item?.amount ?? 0
      }
    });
    setJE({ ...updatedState });
  }, []);

  useEffect(() => {
    showLoader();
    getCurrencyHistoryViaCode(selectedCurrency?.currencyCode)
      .then((res) => {
        setCurrencyHistoryData(res);
      })
      .finally(() => {
        removeLoader();
      });
  }, [selectedCurrency]);

  const getCurrencyHistoryViaCode = async (code: string) => {
    if (Utility.isNotEmpty(code)) {
      const prevConfig = CurrencyService.currencyHistoryApiConfig;
      CurrencyService.currencyHistoryApiConfig = {
        Limit: 1000,
        Page: 0,
        SortDir: CURRENCY_HISTORY_CONSTANTS.SortDir,
        Sort: CURRENCY_HISTORY_CONSTANTS.Sort,
        Dir: CURRENCY_HISTORY_CONSTANTS.Dir,
        Start: 0,
        Query: `currencyCode=${code}`
      };
      const response: any = await CurrencyService.getCurrencyHistrory();
      CurrencyService.currencyHistoryApiConfig = prevConfig;
      return response?.content ?? [];
    }
    return [];
  };

  useEffect(() => {
    if (
      Utility.isNotEmpty(selectedCurrency) &&
      Utility.isNotEmpty(currencyHistoryData) &&
      (Utility.isEmptyValue(props?.je?.currencyExchangeRate) ||
        isCurrencyChanged.current)
    ) {
      isCurrencyChanged.current = false;
      if (journalDate !== null && journalDate !== undefined) {
        let jeDateFilteredCurr = (currencyHistoryData ?? []).find(
          (curr: any) => {
            if (
              Utility.isNotEmpty(curr?.exchangeRateDate) &&
              Utility.compareDateWithoutTime(
                new Date(curr?.exchangeRateDate),
                journalDate
              ) <= 0
            ) {
              return curr.currencyCode === selectedCurrency.currencyCode;
            }
            return false;
          }
        );
        const filteredCurr = (currencyHistoryData ?? [])
          .slice()
          .reverse()
          .find((curr: any) => {
            return curr.currencyCode === selectedCurrency.currencyCode;
          });
        if (jeDateFilteredCurr === undefined || jeDateFilteredCurr === null) {
          getRealTimeCurrencyHistoryViaCode(
            tenantInfo?.currency,
            selectedCurrency?.currencyCode
          )
            .then((realtimeExchangeRateData) => {
              if (Utility.isNotEmpty(realtimeExchangeRateData)) {
                jeDateFilteredCurr = realtimeExchangeRateData?.[0];
              } else {
                jeDateFilteredCurr = filteredCurr;
              }
              setConversionRate(getRate(jeDateFilteredCurr));
              lineItemExchangeRateUpdated(getRate(jeDateFilteredCurr));
            })
            .catch((err) => {
              setConversionRate(getRate(filteredCurr));
              lineItemExchangeRateUpdated(getRate(filteredCurr));
            });
        } else {
          setConversionRate(getRate(jeDateFilteredCurr));
          lineItemExchangeRateUpdated(getRate(jeDateFilteredCurr));
        }
      }
    }
  }, [currencyHistoryData]);
  useEffect(() => {
    // wont set the 'taxAdjustmentSubType' after initial useEffect during edit
    if (props.isCopy) {
      return;
    }

    const updatedState = { ...je };

    if (updatedState?.taxAdjustmentDetails?.taxAdjustmentSubType) {
      updatedState.taxAdjustmentDetails.taxAdjustmentSubType.selectedValue = '';

      const newSubTypes = TAX_ADJUSTMENT_SUBTYPES_INDIA.filter((item: any) => {
        return (
          item.isDisplay ===
          je.taxAdjustmentDetails.taxAdjustmentType.selectedValue
        );
      });
      updatedState.taxAdjustmentDetails.taxAdjustmentSubType.options =
        newSubTypes;
    }

    setJE({ ...updatedState });
  }, [je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue]);

  useEffect(() => {
    setJE({
      ...je,
      taxAdjustmentDetails: {
        ...je?.taxAdjustmentDetails,
        showAddTaxAdjustmentPopup:
          !je?.taxAdjustmentDetails?.showAddTaxAdjustmentPopup
      }
    });
  }, [je?.taxAdjustmentDetails?.taxAdjustmentSubType?.selectedValue]);

  useEffect(() => {
    closeTaxAdjustmentPopup();
  }, [je?.taxAdjustmentDetails?.taxDetails]);

  useEffect(() => {
    updateConfig();
  }, [accountsData?.content]);

  useEffect(() => {
    registerInteractions();
  }, [attachments]);

  useEffect(() => {
    registerInteractions();
  }, [je]);

  useEffect(() => {
    registerInteractions();
    updateConfig();
  }, [selectedCurrency, columnConfig]);
  useEffect(() => {
    let date = DateFormatService.getDateStrFromDate(
      journalDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (Utility.isNotEmpty(date) && Utility.isNotEmpty(currencyHistoryData)) {
      let jeDateFilteredCurr = currencyHistoryData.find((curr: any) => {
        if (
          Utility.compareDateWithoutTime(
            new Date(curr?.exchangeRateDate),
            journalDate
          ) <= 0
        ) {
          if (selectedCurrency) {
            return curr.currencyCode === selectedCurrency?.currencyCode;
          } else {
            return curr.currencyCode === tenantInfo.currency;
          }
        }
        return false;
      });
      const filteredCurr = currencyHistoryData
        .slice()
        .reverse()
        .find((curr: any) => {
          if (selectedCurrency) {
            return curr.currencyCode === selectedCurrency?.currencyCode;
          } else {
            return curr.currencyCode === tenantInfo.currency;
          }
        });
      if (jeDateFilteredCurr === undefined || jeDateFilteredCurr === null) {
        getRealTimeCurrencyHistoryViaCode(
          tenantInfo?.currency,
          selectedCurrency?.currencyCode
        ).then((realtimeExchangeRateData) => {
          if (Utility.isNotEmpty(realtimeExchangeRateData)) {
            jeDateFilteredCurr = realtimeExchangeRateData?.[0];
          } else {
            jeDateFilteredCurr = filteredCurr;
          }
          setConversionRate(getRate(jeDateFilteredCurr));
          lineItemExchangeRateUpdated(getRate(jeDateFilteredCurr));
        });
      }else{
        setConversionRate(getRate(jeDateFilteredCurr));
        lineItemExchangeRateUpdated(getRate(jeDateFilteredCurr));
      }
      if (Utility.isNotEmpty(primaryCurrencyHistory)) {
        updatePrimaryExchangeRate(journalDate);
      }
    }
    setJE({ ...je, jeDate: date });
  }, [journalDate]);

  const getRealTimeCurrencyHistoryViaCode = async (
    baseCurrency: string,
    primaryCurrency: string
  ) => {
    if (
      Utility.isNotEmpty(baseCurrency) &&
      Utility.isNotEmpty(primaryCurrency) &&
      baseCurrency !== primaryCurrency &&
      journalDate !== null &&
      journalDate !== undefined
    ) {
      let payload: RealtimeCurrencyAPIConfig = {
        startDate: Utility.formatDate(journalDate, DATE_FORMAT.DD_MM_YYYY),
        endDate: Utility.formatDate(journalDate, DATE_FORMAT.DD_MM_YYYY),
        baseCurrency: baseCurrency,
        primaryCurrency: primaryCurrency
      };
      showLoader();
      try {
        const response = await CurrencyService.getRealtimeCurrencyHistory(
          payload
        );
        removeLoader();
        return Utility.mapToCurrencyHistoryList(response) ?? [];
      } catch {
        removeLoader();
        return [];
      }
    } else {
      return [];
    }
  };

  const updatePrimaryExchangeRate = async (documentDate: any) => {
    if (documentDate === undefined || documentDate === null) {
      return;
    }
    const primaryCurrencyCode =
      tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
    if (Utility.isNotEmpty(primaryCurrencyCode)) {
      let jeDatePrimaryFilteredCurr: any = primaryCurrencyHistory.find(
        (curr: any) => {
          if (
            Utility.compareDateWithoutTime(
              new Date(curr?.exchangeRateDate),
              documentDate
            ) <= 0
          ) {
            return curr.currencyCode === primaryCurrencyCode;
          }
          return false;
        }
      );
      const primaryFilteredCurr = primaryCurrencyHistory
        .slice()
        .reverse()
        .find((curr: any) => {
          return curr.currencyCode === primaryCurrencyCode;
        });
      if (
        jeDatePrimaryFilteredCurr === undefined ||
        jeDatePrimaryFilteredCurr === null
      ) {
        const realtimeExchangeRateData =
          await getRealTimeCurrencyHistoryViaCode(
            tenantInfo.currency,
            primaryCurrencyCode
          );
        if (Utility.isNotEmpty(realtimeExchangeRateData)) {
          jeDatePrimaryFilteredCurr = realtimeExchangeRateData?.[0];
        } else {
          jeDatePrimaryFilteredCurr = primaryFilteredCurr;
        }
      }
      if (Utility.isNotEmpty(jeDatePrimaryFilteredCurr)) {
        let primaryExchangeRate =
          jeDatePrimaryFilteredCurr?.currencyExchangeRate ??
          tempPrimaryExchangeRate;
        if (primaryExchangeRate !== undefined && primaryExchangeRate !== null) {
          setTempPrimaryExchangeRate(primaryExchangeRate);
          setJE((prevState: any) => {
            return {
              ...prevState,
              primaryCurrencyExchangeRate: primaryExchangeRate
            };
          });
        }
      }
    }
  };

  useEffect(() => {
    if (isLocationClassPresent) {
      let oldColConfigs = [...columnConfig];

      const updatedColCOnfigs = oldColConfigs?.map((col: any) => {
        if (col.id === 'type' || col.id === 'amountInDocumentCurrency') {
          return {
            ...col,
            width: 100
          };
        }

        if (col.id === 'description') {
          return {
            ...col,
            width: 200
          };
        }

        if (col.id === 'account') {
          return {
            ...col,
            width: 250
          };
        }

        return col;
      });

      setTimeout(() => {
        setColumnConfig(updatedColCOnfigs);
      }, 10);
    }
  }, [isLocationClassPresent]);

  useEffect(() => {
    if (!Utility.isEmpty(accountsCFData)) {
      let oldColConfigs = [...columnConfig];

      // let systemFields = systemDimensions?.content;
      // const locationField = systemFields?.find(
      //   (field: any) => field.label === LOCATION_CLASS_ENUM.LOCATION
      // );
      // const classField = systemFields?.find(
      //   (field: any) => field.label === LOCATION_CLASS_ENUM.CLASS
      // );

      const accCustomFields = [...accountsCFData];

      // const locationAndClassData = selectCustomFieldsData?.content?.filter(
      //   (item: any) => {
      //     return (
      //       item.system === true && locationField?.modules?.includes('JOURNAL')
      //     );
      //   }
      // );

      // const cfData = selectCustomFieldsData?.content?.filter((item: any) => {
      //   return !item.system && classField?.modules?.includes('JOURNAL');
      // });

      accCustomFields?.forEach((accCF: any) => {
        const newItem = getNewColumn(accCF);
        oldColConfigs.push(newItem);
      });

      // if (!Utility.isEmpty(locationAndClassData) && Utility.isEmpty(cfData)) {
      //   setIsLocationClassPresent(true);
      // }
      setCustomFieldsData(accCustomFields);
      setColumnConfig(oldColConfigs);
    }
  }, [accountsCFData, systemDimensions]);

  useEffect(() => {
    const updatedState = { ...je };
    let oldColConfigs = [...columnConfig];

    if (props.je?.id && !Utility.isEmpty(customFieldsData)) {
      props?.je?.lineItems?.forEach((item1: any, index1: any) => {
        item1?.customField?.forEach((item: any, index: any) => {
          let filteredCF: any = customFieldsData?.find(
            (cf: any) => cf.id === item.id
          );
          if (!Utility.isEmpty(filteredCF)) {
            const newItem = getNewItem(item, filteredCF);
            let cfValue;
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              cfValue = DateFormatService.getDateFromStr(
                item.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === item.value
              );
              if (tempCF) {
                cfValue = tempCF ? tempCF : '';
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              cfValue = item ? item : '';
            } else {
              cfValue = item.value ? item.value : '';
            }

            let alreadyPresentConfig = oldColConfigs?.find(
              (col: any) => col.id === item.id
            );

            if (Utility.isEmpty(alreadyPresentConfig)) {
              oldColConfigs.push(newItem);
            }
            updatedState.lineItems[index1][item.id] = cfValue;
          }
        });
      });

      updatedState.lineItems = updatedState?.lineItems?.map((lineItem: any) => {
        let tempLineItem = { ...lineItem };
        if (Utility.isEmpty(tempLineItem.invalidFields)) {
          tempLineItem.invalidFields = [];
        }
        tempLineItem = checkIfLineLevelCustomFieldIsValid(
          tempLineItem,
          customFieldsData
        );
        return tempLineItem;
      });
      setColumnConfig([...oldColConfigs]);
      setJE({ ...updatedState });
    }
  }, [customFieldsData]);

  useEffect(() => {
    setupSwitchCurrency();
  }, [props]);
  //////////////////////////////////// custom fields - start ///////////////////////////////

  const getCustomFieldView = () => {
    return (
      <div className="column parent-width ">
        <CustomFieldsHolder
          documentMode={isReadOnlyMode ? DOCUMENT_MODE.VIEW : DOCUMENT_MODE.NEW}
          moduleName={MODULES_NAME.JOURNAL}
          customFieldsList={props.je ? je.customField : []}
          columnConfig={journalColumnConfig}
          columnConfigTableId={journalConfigTableId ? journalConfigTableId : ''}
          onUpdate={(list: any) => {
            setJE({
              ...je,
              customField: list
            });
          }}
        />
      </div>
    );
  };

  /////////////////////////////////// custom fields - end //////////////////////////////////

  const closeCreateView = (data: any = {}) => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP,
        data: data
        // show variantform
      });
    }
  };

  const getRate = (currObj: any) => {
    return 1 / Number(currObj?.currencyExchangeRate);
  };

  const validateAccounts = () => {
    let result = true;

    //dd-mm-yyyy
    const jeDate = DateFormatService.getDateFromStr(
      je.jeDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    //yyyy-mm-dd
    const bbDate = DateFormatService.getDateFromStr(
      tenantInfo.bookBeginningStartDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );

    if (jeDate < bbDate) {
      let buttons = [
        {
          title: 'Ok',
          className: 'bg-button text-white',
          onClick: () => {}
        }
      ];
      showAlert(
        'Please Note!',
        `Journal date should be greater than books beginning date <div class="fw-b">(${DateFormatService.getFormattedDateString(
          tenantInfo.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD/MM/YYYY']
        )}).</div>`,
        buttons
      );
      result = false;
    }

    if (je.lineItems.length === 0) {
      result = false;
    }

    for (let i = 0; i < je.lineItems.length; i++) {
      const element = je.lineItems[i];
      if (
        element.account === null ||
        element.account === undefined ||
        element.account.length === 0
      ) {
        result = false;
      }
      if (
        element.type === null ||
        element.type === undefined ||
        element.type.length === 0
      ) {
        result = false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(je.customField);
    if (customFieldHasErrors) {
      return false;
    }

    let lineItemsHasErrors = false;
    for (let i = 0; i < je.lineItems.length; i++) {
      const item = je.lineItems[i];
      if (Utility.isEmpty(item.accountCode) || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }
    if (lineItemsHasErrors) {
      return false;
    }

    // Custom fields validation ends

    return result;
  };

  const saveApiCall = (currentJE: any) => {
    if (
      !currentJE.hasOwnProperty('sequenceFormat') &&
      !currentJE.hasOwnProperty('jeDocumentSequenceCode')
    ) {
      currentJE = { ...currentJE, ...selectedNumberFormat };
    }
    JournalService.saveJournal(currentJE)
      .then(
        (res: any) => {
          closeCreateView();
          dispatch(fetchJournal());
        },
        (error: any) => {
          props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
        }
      )
      .catch((error: any) => {
        props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
      });
  };

  const updateApiCall = (currentJE: any) => {
    JournalService.updateJournal(currentJE)
      .then(
        (res) => {
          closeCreateView();
          dispatch(fetchJournal());
        },
        (error: any) => {
          props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
        }
      )
      .catch((error: any) => {
        props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
      });
  };

  const validateAccountsBudget = (doc: any, isUpdate: boolean) => {
    let allAccountsData = [];
    const lineItems = doc.lineItems.filter(
      (item: any) => item.cdType === 'DEBIT'
    );
    for (let i = 0; i < lineItems?.length; i++) {
      const item = lineItems[i];
      const accountCode = item.accountCode;
      const amount = Utility.roundOffToTenantDecimalScale(
        item.amount / (doc.exchangeRate ? doc.exchangeRate : 1)
      );
      allAccountsData.push({
        accountCode,
        amount
      });
    }

    if (isUpdate) {
      doc.cfieldGenerated = null;
    }

    let customField: any = {};
    const allDropDownCustomFields = doc.customField?.filter(
      (field: any) => field.fieldType === 'DROPDOWN' && field.value
    );
    for (let i = 0; i < allDropDownCustomFields?.length; i++) {
      customField = {
        ...customField,
        [allDropDownCustomFields[i].code]: allDropDownCustomFields[i].value
      };
    }

    // swich currency Check
    if (props.type == JE_TYPES.BOOK_SPECIFIC_JE) {
      doc.documentType = 'JOURNAL_ENTRY';
      doc.type = JE_TYPES.BOOK_SPECIFIC_JE;
      if (
        je.bookSpecific?.switchCurrency?.value?.value ==
        SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      ) {
        doc.perspective = 'PRIMARY';
      } else {
        doc.perspective = 'STATUTORY';
      }
    }

    if (allAccountsData.length) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      validateBudgetForAccounts(
        doc.jeDate,
        allAccountsData,
        customField,
        je?.jeCode ? je?.jeCode : null
      ).then(
        (budgetResp: { data: any[]; limitCrossed: boolean }) => {
          if (budgetResp.limitCrossed) {
            let buttons = [
              {
                title: 'Cancel',
                className: 'bg-gray2 border-m ',
                onClick: () => {
                  if (props.passingInteraction) {
                    props.passingInteraction({
                      type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
                    });
                  }
                }
              },
              {
                title: 'Proceed',
                className: 'bg-button text-white ml-r',
                onClick: () => {
                  isUpdate ? updateApiCall(doc) : saveApiCall(doc);
                }
              }
            ];
            let message =
              '<ul class="text-align-left" style="list-style-type: disc; margin-left: 5px;">';
            budgetResp.data?.forEach((alertData: any) => {
              message += `<li>${alertData.message}</li>`;
            });
            message +=
              '<div class="text-align-center mt-l">Do you want to continue?</div>';
            message += '</ul>';
            showAlert('Warning!', message, buttons);
          } else {
            isUpdate ? updateApiCall(doc) : saveApiCall(doc);
          }
        },
        (err) => {
          console.error('Error validating accounts budget: ', err);
          if (props.passingInteraction) {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
            });
          }
        }
      );
    }
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = [...columnConfig];
    let colConfigsWithOnlyCF = oldColConfigs.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(accountsCFData)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = accountsCFData.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              lineItem[cf.id],
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'ACCOUNT',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const saveJE = () => {
    if (
      !Utility.checkActiveDateRangeValidation(
        journalDate,
        tenantInfo,
        'Document date',
        'JOURNAL_ENTRY'
      )
    ) {
      return;
    }
    if (!Utility.checkClosingDate(journalDate, 'Journal Date')) {
      return;
    }
    let currentJE = { ...je };
    const conRate = 1 / Number(conversionRate);

    currentJE.jeDate = DateFormatService.getDateStrFromDate(
      journalDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );

    currentJE.currency = selectedCurrency?.currencyCode;

    const attachmentIds =
      attachments && attachments.length > 0
        ? attachments.map((attachment: any) => attachment.attachmentId)
        : [];
    currentJE.attachmentIds = attachmentIds;

    currentJE.currencyExchangeRate = conRate;
    currentJE.exchangeRate = conRate;
    currentJE.primaryCurrencyExchangeRate = conRate;

    currentJE.lineItems &&
      currentJE.lineItems.length > 0 &&
      currentJE.lineItems.map((item: any, index: number) => {
        item.accountCode = item['account'].code;
        item.cdType = item['type'].toUpperCase();
        item.amount = Number(item.amountInDocumentCurrency);
        item.exchangeRate = Number(item.exchangeRate);
        item.customField = getLineItemCFs(item);
      });

    const tDetails = je?.taxAdjustmentDetails?.taxDetails;
    if (
      (!Utility.isEmpty(
        je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue
      ) &&
        je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue !==
          TAX_ADJUSTMENT_TYPES_INDIA[3].value) ||
      !Utility.isEmpty(
        je?.taxAdjustmentDetails?.taxAdjustmentSubType?.selectedValue
      )
    ) {
      currentJE.journalTaxDetailsIN = {
        taxRate: tDetails?.taxRate,
        taxableAmount: tDetails?.taxableAmount,
        cgstAmount: tDetails?.cgstAmount,
        sgstAmount: tDetails?.sgstAmount,
        igstAmount: tDetails?.igstAmount,
        cessAmount: tDetails?.cessAmount,
        contactCode: tDetails?.contactCode,
        taxAdjustmentType:
          je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue,
        taxAdjustmentSubtype:
          je?.taxAdjustmentDetails?.taxAdjustmentSubType?.selectedValue
      };
    }

    const primaryCurrencyCode =
      tenantInfo?.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode;

    if (selectedCurrency?.currencyCode !== primaryCurrencyCode) {
      currentJE.primaryCurrencyExchangeRate = tempPrimaryExchangeRate;
    }

    if (validateAccounts()) {
      let amt1: any = getTotalAmountFor(CD_TYPE.DEBIT);
      let amt2: any = getTotalAmountFor(CD_TYPE.CREDIT);

      let toFixedVal = Store.getState()?.authInfo?.currentTenantInfo?.data
        ?.decimalScale
        ? Store.getState()?.authInfo?.currentTenantInfo?.data.decimalScale
        : 2;

      if (amt1.toFixed(toFixedVal) - amt2.toFixed(toFixedVal) === 0) {
        if (currentJE.currency) {
          currentJE = {
            ...currentJE,
            lineItems: currentJE?.lineItems?.map((item: any) => {
              delete item.cfs;
              return item;
            })
          };
          validateAccountsBudget(currentJE, props.isCopy);
        }
      } else {
        let buttons = [
          {
            title: 'Ok',
            className: 'bg-app text-white ml-r',
            onClick: () => {}
          }
        ];
        showAlert(
          'Error!',
          'Total Debit amount should match total Credit amount.',
          buttons
        );
      }
    }
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_JE,
        data: () => {
          setSubmitButtonTapped(true);
          if (je?.lineItems?.length === 0) {
            addEmptyAccount();
          }
          saveJE();
        }
      });
  };

  const addEmptyAccount = () => {
    setJE({
      ...je,
      lineItems: [
        ...je.lineItems,
        {
          account: '',
          amount: '',
          type: getEmptyRowType(je?.lineItems),
          description: '',
          invalidFields: JEConfigManager.getRequiredFields(),
          lineNumber: je?.lineItems?.length + 1
        }
      ]
    });
  };

  const getUnbalancedAmount = () => {
    let lhs = getTotalAmountFor(CD_TYPE.DEBIT);
    let rhs = getTotalAmountFor(CD_TYPE.CREDIT);

    const ans = Number(lhs) - Number(rhs);
    return NumberFormatService.getNumber(ans);
  };

  const getTotalAmountFor = (type: any) => {
    if (je.lineItems && je.lineItems.length > 0) {
      let filtered = je.lineItems.filter(
        (item: any) => item?.type?.toUpperCase() === type
      );
      if (filtered.length > 0) {
        let sum: any = filtered.reduce(function (add: any, current: any) {
          if (current.amountInDocumentCurrency) {
            let ans: any =
              parseFloat(add) + parseFloat(current.amountInDocumentCurrency);
            return ans;
          } else {
            return 0;
          }
        }, 0);
        return sum;
        // return NumberFormatService.getNumber(Math.abs(sum));
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const selectedFormat = (selected: any) => {
    /*
     * Custom Numbering Format
     * RECEIVE Selected format {id: "", text: ""}
     */
    const updatedState: any = {};

    if (selected.manualMode) {
      updatedState.jeDocumentSequenceCode = selected.text;
      updatedState.sequenceFormat = selected.id;
      updatedState.manualMode = selected.manualMode;
      setSelectedNumberFormat({
        jeDocumentSequenceCode: selected.text,
        sequenceFormat: selected.id,
        manualMode: selected.manualMode
      });
    } else if (selected.id) {
      updatedState.sequenceFormat = selected.id;
      updatedState.manualMode = selected.manualMode;
      setSelectedNumberFormat({
        sequenceFormat: selected.id,
        manualMode: selected.manualMode
      });
    } else {
      updatedState.sequenceFormat = null;
      updatedState.manualMode = null;
      updatedState.jeDocumentSequenceCode = null;
    }

    setJE((prevState: any) => {
      return { ...prevState, ...updatedState };
    });
  };

  /////////////////////////attach files - start ////////////////////////////////////////////////
  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      if (target?.files) {
        Promise.all(
          target?.files &&
          Array.from(target.files).map((file: File) =>
            uploadAttachmentToAWS(file)
          )
        ).then(resList => {
          resList = resList.filter((x: any) => x !== undefined)
          const newAttachments = [...attachments, ...resList];
          setAttachments(newAttachments);
        }).catch((err) => {
          console.log(err);
          showToast(
            'Something went wrong while uploading the attachment, please try again.'
          );
        });
      }
    });
    input.click();
  };

  const uploadAttachmentToAWS = async (file: File) => {
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: DOC_TYPE.JOURNAL_ENTRY
    };
    return AttachmentService.uploadAttachment(file);
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {});
  };

  const removeAttachment = (attachmentId: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const newAttachments = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );
        setAttachments(newAttachments);
      })
      .catch(() => {});
  };

  const getAttachments = () => {
    return (
      <div className="col justify-content-start flex-wrap">
        {attachments.map((attachment: any) => (
          <div
            className="row width-auto border-m border-radius-s p-h-r p-v-s mt-r bg-gray0"
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-s cursor-pointer"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <DKButton
              title={attachment.attachmentFileName}
              className="ml-s cursor-pointer border-none"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-l cursor-pointer"
              onClick={() => {
                removeAttachment(attachment.attachmentId);
              }}
            />
          </div>
        ))}
      </div>
    );
  };
  /////////////////////////attach files - end ////////////////////////////////////////////////

  //////////////////////right panel - start/////////////////////////////////////////////////////////

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 150 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const getRightInfoPanel = () => {
    return (
      <div className="column parent-width align-items-end">
        {/* auto-numbering */}
        <div className="position-relative">
          <div
            className="row width-auto mb-m justify-content-between"
            style={{
              width: 200
            }}
          >
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_number}
                className="ic-s"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'No.'} className={'fw-m ml-r'} />
            </div>
            {/* {
              <DKLabel
                text={props.isCopy || isReadOnlyMode ? je.jeCode : 'JE-0000000'}
                className="w-9/12 -mr-1"
              />
            } */}
            {isEditMode && (
              <DKLabel
                text={je.jeCode}
                className={'ml-r w-9/12 text-align-right'}
              />
            )}
            {/* currently custom number format is not editable in old books */}
            {!props.isCopy && !isEditMode && (
              <div className="w-9/12 -mr-1">
                {
                  <CustomNumberFormatInput
                    module={CUSTOM_NUMBER_INPUT_MODULES.JOURNAL_ENTRY}
                    selectedFormat={selectedFormat}
                    showCompact={true}
                    extraClass={'top-12 right-0'}
                  />
                }
              </div>
            )}
          </div>
        </div>
        {/* currency */}
        <div
          className="row width-auto mb-m justify-content-between"
          style={{
            width: 200
          }}
        >
          <div
            className="row width-auto cursor-hand"
            onClick={() => {
              setShowMultiCurrencyList(!showMultiCurrencyList);
            }}
          >
            <DKIcon
              src={DKIcons.ic_currency}
              className="ic-s"
              style={{ opacity: 0.6 }}
            />
            <DKLabel text={'Currency'} className={'fw-m ml-r'} />
          </div>
          <div className="position-relative">
            <DKButton
              title={selectedCurrency?.currencyCode}
              className="bg-transparent text-black align-items-start"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0
              }}
              onClick={() => {
                setShowMultiCurrencyList(!showMultiCurrencyList);
              }}
              icon={tenantInfo.multicurrencyEnabled && DKIcons.ic_arrow_down2}
              isReverse
            />
            {tenantInfo.multicurrencyEnabled && showMultiCurrencyList && (
              <DKListPicker2
                title="Currencies"
                data={currencyExchangeRates.filter((item: any) => {
                  return item.currencyStatus === 'ACTIVE';
                })}
                style={{
                  width: 280
                }}
                allowSearch={true}
                searchableKey="currencyName"
                className="position-absolute z-index-3 right-0 shadow-m"
                onSelect={(index: number, value: any) => {
                  updateConfig();
                  isCurrencyChanged.current = true;
                  setSelectedCurrency(value);
                  setShowMultiCurrencyList(false);
                }}
                onClose={() => {
                  setShowMultiCurrencyList(!showMultiCurrencyList);
                }}
                renderer={(index: number, obj: any) => {
                  return (
                    <div className="row parent-width justify-content-between">
                      <div>{obj.currencyName}</div>
                      <div className="ml-s">{`(${obj.currencyCode})`}</div>
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
        {/* currency rate */}
        {tenantInfo.multicurrencyEnabled && (
          <div
            className={'row mb-m justify-content-between'}
            style={{
              width: 200
            }}
          >
            <div className="row">
              <DKIcon
                src={DKIcons.ic_sort}
                style={{
                  transform: 'rotate(90deg)',
                  opacity: 0.6
                }}
              />
              <DKLabel text="1" className="mr-s" />
              <DKLabel text={selectedCurrency?.currencyCode} />
            </div>
            <input
              className="outline-none text-align-right"
              placeholder="0.00"
              value={conversionRate}
              onChange={(e: any) => {
                setConversionRate(e.target.value);
                lineItemExchangeRateUpdated(e.target.value);
              }}
              style={{
                width: 100
              }}
            />
            <DKLabel text={tenantInfo.currency} className="ml-s" />
          </div>
        )}
        {/* date */}
        <div
          className="row width-auto justify-content-between cursor-pointer"
          style={{
            width: 200
          }}
        >
          <div
            className="row width-auto"
            onClick={() => {
              if (!isReadOnlyMode) {
                setShowDatePicker(!showDatePicker);
              }
            }}
          >
            <DKIcon
              src={DKIcons.data_type.ic_date}
              className="ic-s"
              style={{ opacity: 0.6 }}
            />
            <DKLabel text={'Journal Date'} className={'fw-m ml-r'} />
          </div>
          <div
            onClick={() => {
              if (!isReadOnlyMode) {
                setShowDatePicker(!showDatePicker);
              }
            }}
          >
            <DKLabel
              text={
                journalDate
                  ? DateFormatService.getDateStrFromDate(journalDate)
                  : ''
              }
              className={'ml-r '}
            />
          </div>
          {showDatePicker &&
            getCalendarView(
              journalDate,
              (date: any) => {
                if (
                  Utility.checkActiveDateRangeValidation(
                    date,
                    tenantInfo,
                    'Document date',
                    'JOURNAL_ENTRY'
                  ) &&
                  Utility.checkClosingDate(date, 'Journal Date')
                ) {
                  setJournalDate(date);
                  updateReversalJEDateConfig(
                    addDays(date, 30),
                    isReversalDateSelect
                  );
                } else {
                  setJournalDate(new Date());
                  updateReversalJEDateConfig(
                    addDays(new Date(), 30),
                    isReversalDateSelect
                  );
                }
              },
              () => {
                if (!isReadOnlyMode) {
                  setShowDatePicker(!showDatePicker);
                }
              }
            )}
        </div>
        {getPrimaryCurrencyRateField()}
        {getReversalDate()}
      </div>
    );
  };

  // Switch Currency

  function setupSwitchCurrency() {
    const updateState = { ...je };
    const switchCurrencyDataOptions = getSwitchCurrencyOptions();
    let baseCurrency;
    if (Utility.isNotEmpty(props?.je?.perspective)) {
      baseCurrency = switchCurrencyDataOptions.find((item) =>
        props?.je?.perspective === 'STATUTORY'
          ? item.value === SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY
          : item.value === SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      );
    } else {
      baseCurrency = switchCurrencyDataOptions.find(
        (item) => item.value === SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY
      );
    }

    updateState.bookSpecific = {
      ...updateState?.bookSpecific,
      switchCurrency: {
        ...updateState?.bookSpecific?.switchCurrency,
        dataOptions: switchCurrencyDataOptions,
        value: baseCurrency
      }
    };
    setJE(updateState);
  }
  function getSwitchCurrencyOptions() {
    const statutoryTitle = `${MULTI_BOOKS_PERSPECTIVE.STATUTORY} (${tenantInfo?.currency})`;
    const primaryTitle = `${MULTI_BOOKS_PERSPECTIVE.PRIMARY} (${tenantInfo?.additionalSettings?.MULTI_COMPANY?.primaryCurrencyCode})`;

    return [
      {
        title: statutoryTitle,
        value: SWITCH_CURRENCY_OPTIONS.BASE_CURRENCY
      },
      {
        title: primaryTitle,
        value: SWITCH_CURRENCY_OPTIONS.PRIMARY_CURRENCY
      }
    ];
  }

  //
  //////////////////////right panel - end///////////////////////////////////////////////////////////

  ///////////////////////////////// data grid - start ////////////////////////////////////////////////

  const getRemoveInvalidFields = (rowIndex: number, key: any) => {
    const updatedState = { ...je };
    let filtered = updatedState.lineItems[rowIndex].invalidFields;

    if (
      updatedState.lineItems[rowIndex].invalidFields &&
      updatedState.lineItems[rowIndex].invalidFields.length > 0
    ) {
      filtered = updatedState.lineItems[rowIndex].invalidFields.filter(
        (item: any) => item !== key
      );
    }

    return filtered;
  };

  const updateLineItemList = (updates: any, rowIndex: any, columnKey: any) => {
    const updatedState = { ...je };
    let oldColConfigs = [...columnConfig];

    if (columnKey === 'amountInDocumentCurrency') {
      let toFixedVal = Store.getState()?.authInfo?.currentTenantInfo?.data
        ?.decimalScale
        ? Store.getState()?.authInfo?.currentTenantInfo?.data.decimalScale
        : 2;
      updatedState.lineItems[rowIndex][columnKey] = Number(
        updates[columnKey]
      )?.toFixed(toFixedVal);
    } else {
      if (
        columnKey === 'amountInDocumentCurrency' ||
        columnKey === 'account' ||
        columnKey === 'description' ||
        columnKey === 'type' ||
        columnKey === 'exchangeRate'
      ) {
        updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
      } else {
        //update column values for custom fields directly here
        const configForColumnKey = columnConfig.find(
          (config: any) => config.key === columnKey
        );
        if (configForColumnKey.isCustomField) {
          const cfInfo: any = customFieldsData.find(
            (cf: any) => cf.id === columnKey
          );
          if (cfInfo) {
            if (
              cfInfo.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ) {
              updatedState.lineItems[rowIndex][columnKey] = new Date(
                updates[columnKey]
              );
            } else if (
              cfInfo.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.USER.toLowerCase()
            ) {
              // Use updates[columnKey].value for User or Dropdown type
              updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
            } else if (
              cfInfo.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              // Use updates[columnKey].value for User or Dropdown type
              updatedState.lineItems[rowIndex][columnKey] =
                updates[columnKey].value === 'None' ? null : updates[columnKey];
              cfUpdatedTimeMap.current = {
                ...cfUpdatedTimeMap.current,
                [cfInfo.id]: new Date().getTime()
              };
              const availableAccountCFs: any[] = accountsCFData;
              const { rowData } = updateRowDataWithParentCFValues(
                updates[columnKey].value === 'None' ? null : updates[columnKey],
                { ...updatedState.lineItems[rowIndex] },
                cfInfo,
                availableAccountCFs
              );
              updatedState.lineItems[rowIndex] = rowData;
            } else {
              updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
            }
          }
        }
      }
    }

    if (columnKey === 'account') {
      // invalid fields
      if (updatedState.lineItems[rowIndex]['account']) {
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'account'
        );
      }
      //description
      updatedState.lineItems[rowIndex]['description'] =
        updates[columnKey].description;

      //amount handling
      if (je.lineItems[rowIndex]['amount'] === '') {
        updatedState.lineItems[rowIndex]['amount'] = 0;
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'amount'
        );
      }
      if (
        je.lineItems[rowIndex]['amountInDocumentCurrency'] === undefined ||
        je.lineItems[rowIndex]['amountInDocumentCurrency'] === ''
      ) {
        updatedState.lineItems[rowIndex]['amountInDocumentCurrency'] = 0;
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'amountInDocumentCurrency'
        );
      }
      if (
        je.lineItems[rowIndex]['exchangeRate'] === undefined ||
        je.lineItems[rowIndex]['exchangeRate'] === ''
      ) {
        updatedState.lineItems[rowIndex]['exchangeRate'] = conversionRate ?? 0;
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'exchangeRate'
        );
      }
      //custom fields
      if (updates[columnKey]?.customField?.length > 0) {
        // Handle custom fields when account is selected
        const availableCFs: any[] = accountsCFData;
        let cfs: any[] = [];
        availableCFs.forEach((availableCF: any) => {
          let cfToUpdate = {
            id: availableCF.id,
            shortName: availableCF.shortName,
            module: MODULES_NAME.ACCOUNT,
            code: availableCF.code,
            label: availableCF.label,
            value: ''
          };
          let valueOfCF = '';
          const existingCF = updates[columnKey]?.customField?.find(
            (cf: any) => cf.id === availableCF.id
          );
          if (existingCF) {
            if (
              typeof existingCF.value !== 'undefined' &&
              existingCF.value !== null &&
              existingCF.value !== ''
            ) {
              if (
                availableCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                updatedState.lineItems[rowIndex][availableCF.id] =
                  DateFormatService.getDateFromStr(
                    existingCF.value,
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  );
              } else if (
                availableCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.USER.toLowerCase()
              ) {
                const cfValue = availableCF?.attributes?.find(
                  (attr: any) => attr.code === existingCF.value
                );
                updatedState.lineItems[rowIndex][availableCF.id] = cfValue
                  ? cfValue
                  : '';
              } else if (
                availableCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                const cfValue = availableCF?.attributes?.find(
                  (attr: any) => attr.value === existingCF.value
                );
                updatedState.lineItems[rowIndex][availableCF.id] = cfValue
                  ? cfValue
                  : '';
              } else {
                updatedState.lineItems[rowIndex][availableCF.id] =
                  existingCF.value;
              }
            } else {
              updatedState.lineItems[rowIndex][availableCF.id] = '';
            }
            valueOfCF = existingCF.value;
          } else {
            updatedState.lineItems[rowIndex][availableCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        });
        updatedState.lineItems[rowIndex] = {
          ...updatedState.lineItems[rowIndex],
          customField: cfs
        };
      } else {
        //this is when custom fields are empty and same account is changed
        let allStaticKeys = [
          'amountInDocumentCurrency',
          'account',
          'type',
          'description'
        ];
        const filtered = oldColConfigs?.filter((item1: any) => {
          return !allStaticKeys.includes(item1.key);
        });
        filtered?.forEach((item2: any) => {
          updatedState.lineItems[rowIndex][item2.name] = '';
        });
      }
    } else if (columnKey === 'type') {
      updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
        rowIndex,
        'type'
      );
    } else if (columnKey === 'amountInDocumentCurrency') {
      updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
        rowIndex,
        'amountInDocumentCurrency'
      );
    }
    updatedState.lineItems[rowIndex] = checkIfLineLevelCustomFieldIsValid(
      updatedState.lineItems[rowIndex],
      accountsCFData
    );
    setJE({ ...updatedState });
    setColumnConfig([...oldColConfigs]);
  };

  const updateCreditDebitAmount = (
    lineItem: any,
    columnKey: any,
    amount: any
  ) => {
    if (columnKey === 'credit') {
      lineItem[columnKey] = amount;
      lineItem['debit'] = '0.0';
      lineItem['type'] = jeTypesArray?.[0];
    } else if (columnKey === 'debit') {
      lineItem[columnKey] = amount;
      lineItem['credit'] = '0.0';
      lineItem['type'] = jeTypesArray?.[1];
    }
    lineItem['amountInDocumentCurrency'] = amount;

    return lineItem;
  };
  const updateLineItemListForCreditDebitType = (
    updates: any,
    rowIndex: any,
    columnKey: any
  ) => {
    const updatedState = { ...je };
    let oldColConfigs = [...columnConfig];

    if (columnKey === 'credit' || columnKey === 'debit') {
      let toFixedVal = Store.getState()?.authInfo?.currentTenantInfo?.data
        ?.decimalScale
        ? Store.getState()?.authInfo?.currentTenantInfo?.data.decimalScale
        : 2;

      updatedState.lineItems[rowIndex] = updateCreditDebitAmount(
        updatedState.lineItems[rowIndex],
        columnKey,
        Number(updates[columnKey])?.toFixed(toFixedVal)
      );
    } else {
      if (
        columnKey === 'amountInDocumentCurrency' ||
        columnKey === 'account' ||
        columnKey === 'description' ||
        columnKey === 'type' ||
        columnKey === 'exchangeRate'
      ) {
        updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
      } else {
        //update column values for custom fields directly here
        const configForColumnKey = columnConfig.find(
          (config: any) => config.key === columnKey
        );
        if (configForColumnKey.isCustomField) {
          const cfInfo: any = customFieldsData.find(
            (cf: any) => cf.id === columnKey
          );
          if (cfInfo) {
            if (
              cfInfo.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ) {
              updatedState.lineItems[rowIndex][columnKey] = new Date(
                updates[columnKey]
              );
            } else if (
              cfInfo.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.USER.toLowerCase()
            ) {
              // Use updates[columnKey].value for User or Dropdown type
              updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
            } else if (
              cfInfo.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              // Use updates[columnKey].value for User or Dropdown type
              updatedState.lineItems[rowIndex][columnKey] =
                updates[columnKey].value === 'None' ? null : updates[columnKey];
              cfUpdatedTimeMap.current = {
                ...cfUpdatedTimeMap.current,
                [cfInfo.id]: new Date().getTime()
              };
              const availableAccountCFs: any[] = accountsCFData;
              const { rowData } = updateRowDataWithParentCFValues(
                updates[columnKey].value === 'None' ? null : updates[columnKey],
                { ...updatedState.lineItems[rowIndex] },
                cfInfo,
                availableAccountCFs
              );
              updatedState.lineItems[rowIndex] = rowData;
            } else {
              updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
            }
          }
        }
      }
    }

    if (columnKey === 'account') {
      // invalid fields
      if (updatedState.lineItems[rowIndex]['account']) {
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'account'
        );
      }
      //description
      updatedState.lineItems[rowIndex]['description'] =
        updates[columnKey].description;

      //amount handling
      if (
        je.lineItems[rowIndex]['credit'] === undefined ||
        je.lineItems[rowIndex]['debit'] === undefined ||
        je.lineItems[rowIndex]['credit'] === '' ||
        je.lineItems[rowIndex]['debit'] === ''
      ) {
        updatedState.lineItems[rowIndex]['credit'] = '0.0';
        updatedState.lineItems[rowIndex]['debit'] = '0.0';
        updatedState.lineItems[rowIndex]['amountInDocumentCurrency'] = '0.0';
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'credit'
        );
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'debit'
        );
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'amountInDocumentCurrency'
        );
      }
      if (
        je.lineItems[rowIndex]['exchangeRate'] === undefined ||
        je.lineItems[rowIndex]['exchangeRate'] === ''
      ) {
        updatedState.lineItems[rowIndex]['exchangeRate'] = conversionRate ?? 0;
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'exchangeRate'
        );
      }
      //custom fields
      if (updates[columnKey]?.customField?.length > 0) {
        // Handle custom fields when account is selected
        const availableCFs: any[] = accountsCFData;
        let cfs: any[] = [];
        availableCFs.forEach((availableCF: any) => {
          let cfToUpdate = {
            id: availableCF.id,
            shortName: availableCF.shortName,
            module: MODULES_NAME.ACCOUNT,
            code: availableCF.code,
            label: availableCF.label,
            value: ''
          };
          let valueOfCF = '';
          const existingCF = updates[columnKey]?.customField?.find(
            (cf: any) => cf.id === availableCF.id
          );
          if (existingCF) {
            if (
              typeof existingCF.value !== 'undefined' &&
              existingCF.value !== null &&
              existingCF.value !== ''
            ) {
              if (
                availableCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                updatedState.lineItems[rowIndex][availableCF.id] =
                  DateFormatService.getDateFromStr(
                    existingCF.value,
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  );
              } else if (
                availableCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.USER.toLowerCase()
              ) {
                const cfValue = availableCF?.attributes?.find(
                  (attr: any) => attr.code === existingCF.value
                );
                updatedState.lineItems[rowIndex][availableCF.id] = cfValue
                  ? cfValue
                  : '';
              } else if (
                availableCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                const cfValue = availableCF?.attributes?.find(
                  (attr: any) => attr.value === existingCF.value
                );
                updatedState.lineItems[rowIndex][availableCF.id] = cfValue
                  ? cfValue
                  : '';
              } else {
                updatedState.lineItems[rowIndex][availableCF.id] =
                  existingCF.value;
              }
            } else {
              updatedState.lineItems[rowIndex][availableCF.id] = '';
            }
            valueOfCF = existingCF.value;
          } else {
            updatedState.lineItems[rowIndex][availableCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        });
        updatedState.lineItems[rowIndex] = {
          ...updatedState.lineItems[rowIndex],
          customField: cfs
        };
      } else {
        //this is when custom fields are empty and same account is changed
        let allStaticKeys = [
          'amountInDocumentCurrency',
          'account',
          'type',
          'description',
          'credit',
          'debit'
        ];
        const filtered = oldColConfigs?.filter((item1: any) => {
          return !allStaticKeys.includes(item1.key);
        });
        filtered?.forEach((item2: any) => {
          updatedState.lineItems[rowIndex][item2.name] = '';
        });
      }
    } else if (columnKey === 'type') {
      updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
        rowIndex,
        'type'
      );
    } else if (columnKey === 'credit' || columnKey === 'debit') {
      updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
        rowIndex,
        'credit'
      );
      updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
        rowIndex,
        'debit'
      );
      updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
        rowIndex,
        'amountInDocumentCurrency'
      );
    }
    updatedState.lineItems[rowIndex] = checkIfLineLevelCustomFieldIsValid(
      updatedState.lineItems[rowIndex],
      accountsCFData
    );
    setJE({ ...updatedState });
    setColumnConfig([...oldColConfigs]);
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    if (
      tenantInfo?.additionalSettings?.JE_CREDIT_DEBIT_COLUMN_SETTING === true
    ) {
      updateLineItemListForCreditDebitType(rowData, rowIndex, columnKey);
    } else {
      updateLineItemList(rowData, rowIndex, columnKey);
    }
  };

  const onDelete = ({ rowIndex }: any) => {
    const updatedState = { ...je };
    updatedState.lineItems.splice(rowIndex, 1);

    setJE({
      ...updatedState
    });
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const updateConfig = () => {
    let config = columnConfig;

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'account':
          conf.formatter = (obj: any) => {
            return obj?.value?.name;
          };
          let newData = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(newData);
          let newStructuredData = ConfigUtility.structuredAccountsArr;
          conf.dropdownConfig.data = newStructuredData;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };
          conf.dropdownConfig.button =
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.COA.CREATE
            )
              ? {
                  title: '+ Add New',
                  className: 'bg-button text-white',
                  onClick: () => {}
                }
              : null;
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const config: AccountAPIConfig = {
              ...AccountsService.apiConfig,
              SearchTerm: encodeURIComponent(search),
              Query: 'status=active',
              Page: 0,
              Limit: search.length > 0 ? 10 : 10000
            };
            AccountsService.apiConfig = config;
            return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
          };
          // conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
          //   return Utility.getAccountsData(response?.content);
          // };
          conf.dropdownConfig.onSelect = (response: any) => {};
          if (conf.dropdownConfig.button) {
            conf.dropdownConfig.button.onClick = () => {
              setShowAccountPopup(true);
            };
          }

          break;
        case 'type':
          conf.dropdownConfig.data = cdTypesArray;
          conf.dropdownConfig.renderer = (index: any, name: any) => {
            return name;
          };
          conf.dropdownConfig.onSelect = (response: any) => {};
          break;

        case 'amountInDocumentCurrency':
          conf.formatter = (data: any) => {
            const amount = data.value;
            return `${Utility.getCurrencySymbolFromCode(
              selectedCurrency?.currencyCode
            )} ${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
              Math.abs(amount)
            )}${amount < 0 ? ')' : ''}`;
          };
          break;

        case 'credit':
          conf.formatter = (data: any) => {
            const amount = data.value;
            return `${Utility.getCurrencySymbolFromCode(
              selectedCurrency?.currencyCode
            )} ${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
              Math.abs(amount)
            )}${amount < 0 ? ')' : ''}`;
          };
          break;

        case 'debit':
          conf.formatter = (data: any) => {
            const amount = data.value;
            return `${Utility.getCurrencySymbolFromCode(
              selectedCurrency?.currencyCode
            )} ${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
              Math.abs(amount)
            )}${amount < 0 ? ')' : ''}`;
          };
          break;

        default:
          const classData = systemDimensions?.content?.find(
            (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
          );
          if (classData && classData.id === conf.id) {
            conf.hidden = hideClassColumn();
            conf.dropdownConfig.button = {
              title: '+ Add New',
              className: 'bg-button text-white',
              onClick: () => setShowAddClassPopup(true)
            };
          }
          if (conf.type === INPUT_TYPE.DROPDOWN) {
            conf.dropdownConfig.allowSearch =
              conf.dropdownConfig?.data?.length > 5;
            conf.dropdownConfig.searchableKey = 'value';
          }
          break;
      }
    });
  };

  const lineItemExchangeRateUpdated = (price: any) => {
    const updatedState = { ...je };
    updatedState?.lineItems?.forEach((element: any) => {
      element['exchangeRate'] = price ?? 0;
    });
    setJE({ ...updatedState });
  };

  const onRowClick = ({ columnData, rowData, rowIndex }: any) => {
    const availableCFs: any[] = accountsCFData;
    updateColumnConfigOnRowClick(
      columnData,
      rowData,
      columnConfig,
      availableCFs,
      cfUpdatedTimeMap.current
    );
  };

  const AddCopyofRow = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...je?.lineItems];
    let copiedRow: any = { ...tempProductRows[rowIndex] };
    copiedRow.id = null;
    tempProductRows.splice(rowIndex + 1, 0, copiedRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });

    let tempJe = { ...je };
    tempJe.lineItems = tempProductRows;
    setJE(tempJe);
  };

  const InsertNewItem = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...je?.lineItems];
    let newRow: any = {
      account: '',
      amount: '',
      type:
        tempProductRows[rowIndex]?.type === jeTypesArray[0]
          ? jeTypesArray[1]
          : jeTypesArray[0],
      description: '',
      invalidFields: JEConfigManager.getRequiredFields(),
      lineNumber: je?.lineItems?.length + 1
    };
    tempProductRows.splice(rowIndex + 1, 0, newRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });

    let tempJe = { ...je };
    tempJe.lineItems = tempProductRows;
    setJE(tempJe);
  };

  const getRowData = (lineItems: any[]) => {
    let contextMenu: any[] = [];
    contextMenu.push({
      title: 'Arrange Custom Fields',
      icon: DKIcons.ic_settings,
      className: ' p-0',
      onClick: (data: any) => setOpenAccountCFSettings(true)
    });

    contextMenu.push({
      title: 'Insert',
      icon: DKIcons.ic_add,
      className: ' p-0',
      onClick: (data: any, index: any) => InsertNewItem(data)
    });

    contextMenu.push({
      title: 'Copy',
      icon: DKIcons.ic_copy,
      className: ' p-0',
      onClick: (data: any, index: any) => AddCopyofRow(data)
    });

    let rowData: any[] = [];
    if (lineItems?.length) {
      rowData = [...lineItems].map((item: any) => {
        return {
          ...item,
          rowContextMenu: contextMenu
        };
      });
    }

    return rowData;
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnShift={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={isReadOnlyMode ? false : true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={getRowData(je?.lineItems)}
        columns={[
          ...columnConfig,
          {
            id: 'action',
            key: 'action',
            name: '',
            type: INPUT_TYPE.BUTTON,
            width: 150,
            options: isReadOnlyMode
              ? []
              : [
                  {
                    icon: DKIcons.ic_delete,
                    className: ' p-0',
                    onClick: onDelete
                  }
                ]
          }
        ]}
        onRowUpdate={onRowUpdate}
        onRowClick={onRowClick}
        width={gridWidth}
      />
    );
  };
  //////////////////////////////// data grid - end ///////////////////////////////////////////////////

  /////////////////////////////// add account popup - start /////////////////////////////////////////
  const getAddAccountPopup = () => {
    return showAccountPopup ? (
      <AddCoaPopup
        populateFormData={null}
        onCancel={() => {
          setShowAccountPopup(false);
        }}
      />
    ) : null;
  };
  /////////////////////////////// add account popup - end ///////////////////////////////////////////

  ////////////////////////////// tax adjustment view - start ///////////////////////////////////////

  const getSelectedTaxAdjustmentType = (selectedKey: any) => {
    return je?.taxAdjustmentDetails?.taxAdjustmentType?.options.find(
      (item: any) => {
        return item.value === selectedKey;
      }
    );
  };

  const getSelectedTaxAdjustmentSubType = (selectedKey: any) => {
    return je?.taxAdjustmentDetails?.taxAdjustmentSubType?.options.find(
      (item: any) => {
        return item.value === selectedKey;
      }
    );
  };

  const getAccountBookSelection = () => {
    return (
      <div className="col align-items-start mt-r" style={{ width: '45%' }}>
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          titleStyle={titleStyle}
          title={'Accounting Book'}
          formatter={(obj: any) => {
            return obj.title;
          }}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={je.bookSpecific?.switchCurrency?.value}
          onChange={(value: any) => {
            setJE((prev: any) => {
              return {
                ...prev,
                bookSpecific: {
                  ...prev.bookSpecific,
                  switchCurrency: {
                    ...prev.bookSpecific?.switchCurrency,
                    value: value
                  }
                }
              };
            });
          }}
          dropdownConfig={{
            className: '',
            style: {
              minWidth: 150
            },
            allowSearch: false,
            searchableKey: null,
            data: je.bookSpecific?.switchCurrency?.dataOptions,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={obj.title} />;
            },
            onSelect: (index: any, value: any) => {}
          }}
        />
        {showTaxView && (
          <div className="mt-m" style={{ width: '400px' }}>
            <DKCheckMark
              isSelected={je.includeInTaxReport}
              title="Include in Tax Report"
              onClick={() => {
                setJE({
                  ...je,
                  includeInTaxReport: !je.includeInTaxReport
                });
              }}
            />
          </div>
        )}
        {!!tenantInfo.additionalSettings?.MULTI_COMPANY &&
          intercompanyTxnCheckbox}
      </div>
    );
  };
  const getTaxAdjustmentView = () => {
    return (
      <div className="col align-items-start mt-l" style={{ width: '33.33%' }}>
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          title={'Tax Adjustment Type'}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={
            getSelectedTaxAdjustmentType(
              je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue
            )?.name
          }
          onChange={(value: any) => {
            const updatedJE = { ...je };
            updatedJE.taxAdjustmentDetails.taxAdjustmentType.selectedValue =
              value.value;

            if (props.isCopy) {
              const newSubTypes = TAX_ADJUSTMENT_SUBTYPES_INDIA.filter(
                (item: any) => {
                  return item.isDisplay === value.value;
                }
              );
              updatedJE.taxAdjustmentDetails.taxAdjustmentSubType.options =
                newSubTypes;
              updatedJE.taxAdjustmentDetails.taxAdjustmentSubType.selectedValue =
                '';
            }

            setJE({
              ...updatedJE
            });
          }}
          dropdownConfig={{
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            data: je?.taxAdjustmentDetails?.taxAdjustmentType?.options,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          }}
        />

        <div className="column parent-width mt-m">
          <DKInput
            type={INPUT_TYPE.DROPDOWN}
            required={false}
            title={'Tax Adjustment Subtype'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={
              getSelectedTaxAdjustmentSubType(
                je?.taxAdjustmentDetails?.taxAdjustmentSubType?.selectedValue
              )?.name
            }
            className=""
            onChange={(value: any) => {
              const updatedJE = { ...je };
              updatedJE.taxAdjustmentDetails.taxAdjustmentSubType.selectedValue =
                value.value;
              // updatedJE.taxAdjustmentDetails.showAddTaxAdjustmentPopup =
              //   !je?.taxAdjustmentDetails?.showAddTaxAdjustmentPopup;

              setJE({
                ...updatedJE
              });
            }}
            readOnly={Utility.isEmpty(
              getSelectedTaxAdjustmentType(
                je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue
              )?.name
            )}
            dropdownConfig={{
              style: { minWidth: 150 },
              className: 'shadow-m width-auto',
              data: je?.taxAdjustmentDetails?.taxAdjustmentSubType?.options,
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.name}`} />;
              },
              onSelect: (index: any, obj: any, rowIndex: any) => {}
            }}
          />

          {!Utility.isEmpty(
            getSelectedTaxAdjustmentType(
              je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue
            )?.name
          ) &&
            !isReadOnlyMode &&
            je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue !==
              TAX_ADJUSTMENT_TYPES_INDIA[3].value && (
              <span
                className=" cursor-hand text-app-color mt-s"
                onClick={() => {
                  setJE({
                    ...je,
                    taxAdjustmentDetails: {
                      ...je.taxAdjustmentDetails,
                      showAddTaxAdjustmentPopup:
                        !je.taxAdjustmentDetails.showAddTaxAdjustmentPopup
                    }
                  });
                }}
              >
                Add Tax Adjustment Details
              </span>
            )}
        </div>
        <div className="mt-m" style={{ width: '400px' }}>
          <DKCheckMark
            isSelected={je.includeGSTReport}
            title="Include in Tax Report"
            onClick={() => {
              setJE({
                ...je,
                includeGSTReport: !je.includeGSTReport,
                includeInTaxReport: !je.includeInTaxReport
              });
            }}
          />
        </div>
      </div>
    );
  };

  ///////////////////////////// tax adjustment view - end //////////////////////////////////////////

  //////////////////////////// tax adjustment popup view - start ///////////////////////////////////
  const closeTaxAdjustmentPopup = () => {
    const updatedJE = { ...je };
    if (updatedJE?.taxAdjustmentDetails) {
      updatedJE.taxAdjustmentDetails.showAddTaxAdjustmentPopup = false;
      setJE({ ...updatedJE });
    }
  };

  // 1. Increase of tax liability + Advance paid under RCM
  // 2. Increase of tax liability + On Account of advance receipt
  const showContactField =
    (getSelectedTaxAdjustmentType(
      je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue
    )?.value === TAX_ADJUSTMENT_TYPES_INDIA[2].value &&
      getSelectedTaxAdjustmentSubType(
        je?.taxAdjustmentDetails?.taxAdjustmentSubType?.selectedValue
      )?.value === TAX_ADJUSTMENT_SUBTYPES_INDIA[10].value) ||
    getSelectedTaxAdjustmentSubType(
      je?.taxAdjustmentDetails?.taxAdjustmentSubType?.selectedValue
    )?.value === TAX_ADJUSTMENT_SUBTYPES_INDIA[13].value;

  const getTaxAdjustmentPopupView = () => {
    return (
      <AddTaxAdjustmentDetails
        taxAdjustmentDetails={je.taxAdjustmentDetails.taxDetails}
        showContactField={showContactField}
        onCancel={() => {
          closeTaxAdjustmentPopup();
        }}
        onSave={(data: any) => {
          setJE({
            ...je,
            taxAdjustmentDetails: {
              ...je.taxAdjustmentDetails,
              taxDetails: data.taxDetails
            }
          });
        }}
      />
    );
  };

  const getAccountsCFData = async () => {
    try {
      const cfData = await CustomFieldService.getCustomFields({
        status: 'ACTIVE',
        limit: '1000',
        module: 'ACCOUNT'
      });
      let sortedCF = cfData?.content?.length ? cfData?.content : [];
      sortedCF.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setAccountsCFData(sortedCF);
      return sortedCF;
    } catch (err: any) {
      console.log('Error while fetching account CFs: ', err);
    }
  };

  const updateLineLevelCForder = (updatedCFs: any[]) => {
    let accountCFs: any[] = updatedCFs;
    accountCFs.sort(
      (field1: any, field2: any) =>
        field1.customFieldIndex - field2.customFieldIndex
    );

    // const sortedAccountCFsIds = accountCFs.map((accCF: any) => accCF.id);
    const existingCFConfigs = columnConfig.filter(
      (config: any) => config.isCustomField
    );
    const existingConfigsWithoutCFs = columnConfig.filter(
      (config: any) => !!!config.isCustomField
    );
    let updatedCFConfigs: any[] = [];
    accountCFs.forEach((accCF: any) => {
      const existingCFConfig = existingCFConfigs.find(
        (cf: any) => cf.id === accCF.id
      );
      if (existingCFConfig) {
        updatedCFConfigs.push({
          ...existingCFConfig
        });
      }
    });

    const classData = systemDimensions?.content?.find(
      (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
    );
    updatedCFConfigs = updatedCFConfigs.map((cf: any) => {
      if (cf?.id === classData?.id) {
        cf = {
          ...cf,
          hidden: hideClassColumn()
        };
      }
      return cf;
    });
    setColumnConfig(
      [...existingConfigsWithoutCFs, ...updatedCFConfigs].filter(
        (col: any) => !col.hidden
      )
    );
  };

  const getAccountCustomFieldSettings = () => {
    const accountCFs: any[] = accountsCFData;
    return (
      <CustomFieldSettings
        fields={accountCFs}
        moduleName={MODULES_NAME.ACCOUNT}
        onSave={async () => {
          try {
            const updatedOrderedCFs = await getAccountsCFData();
            setOpenAccountCFSettings(false);
            updateLineLevelCForder(updatedOrderedCFs);
          } catch (err: any) {
            console.error('Error fetching product CFs: ', err);
          }
        }}
        onClose={() => setOpenAccountCFSettings(false)}
      />
    );
  };
  //////////////////////////// tax adjustment popup view - end ///////////////////////////////////

  //////////////////////////// primary currency conversion  view - start ///////////////////////////////////

  const getPrimaryCurrencyRateField = () => {
    const PrimaryCurrTotal: any =
      je.amountInBaseCurrency * (tempPrimaryExchangeRate || 1);
    const primaryCurrencyCode =
      tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
    return (
      selectedCurrency?.currencyCode !== primaryCurrencyCode &&
      getPrimaryCurrencyCheck() && (
        <div className={'mt-m'}>
          <div
            className={'row justify-content-between'}
            style={{
              width: 200
            }}
          >
            <DKLabel text="Primary Conversion Rate :" className={'fw-m '} />
          </div>
          <div
            className={'row justify-content-between'}
            style={{
              width: 200
            }}
          >
            <div className="row">
              <DKIcon
                src={DKIcons.ic_sort}
                style={{
                  transform: 'rotate(90deg)',
                  opacity: 0.6
                }}
              />
              <DKLabel text="1" className="mr-s" />
              <DKLabel text={tenantInfo.currency} />
            </div>
            <input
              className="outline-none text-align-right"
              placeholder="0.00"
              value={tempPrimaryExchangeRate}
              onChange={(e: any) => {
                setTempPrimaryExchangeRate(e.target.value);
                setJE((prevState: any) => {
                  return {
                    ...prevState,
                    primaryCurrencyExchangeRate: +e.target.value
                  };
                });
              }}
              style={{
                width: 100
              }}
            />
            <DKLabel text={primaryCurrencyCode} className="ml-s" />
          </div>
        </div>
      )
    );
  };
  //////////////////////////// primary currency conversion  view - end ///////////////////////////////////

  const updateReversalJEDateConfig = (
    currReversalDate: any,
    updateReversalJEDate = false
  ) => {
    if (updateReversalJEDate === true) {
      let tempJe = { ...je };
      if (
        currReversalDate !== null &&
        currReversalDate.getTime() >= today.getTime()
      ) {
        tempJe.reversalJEDate = DateFormatService.getDateStrFromDate(
          currReversalDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        setReversalDate(currReversalDate);
      } else if (currReversalDate !== null) {
        let tempReversalDate = addDays(new Date(), 30);
        tempJe.reversalJEDate = DateFormatService.getDateStrFromDate(
          tempReversalDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        setReversalDate(tempReversalDate);
      } else {
        tempJe.reversalJEDate = null;
      }

      setJE(tempJe);
    }
  };

  const getReversalDateConfig = () => {
    const currReversalDate = addDays(journalDate, 30);
    updateReversalJEDateConfig(currReversalDate, true);
    setIsReversalDateSelect(true);
  };

  const getCalendarViewForReversalDate = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={
          selectedCurrency?.currencyCode !==
            tenantInfo?.additionalSettings?.MULTI_COMPANY
              ?.primaryCurrencyCode && getPrimaryCurrencyCheck()
            ? { right: 0, top: 270 }
            : { right: 0, top: 200 }
        }
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const getReversalDateScreen = () => {
    return (
      <>
        <div className="row  mt-r width-auto justify-content-between cursor-pointer">
          {isReadOnlyMode !== true && (
            <DKButton
              className={'mt-r p-0'}
              icon={DKIcons.ic_close}
              onClick={() => {
                if (!isReadOnlyMode) {
                  setIsReversalDateSelect(false);
                  updateReversalJEDateConfig(null, true);
                }
              }}
            />
          )}
          <div
            className="row  mt-r width-auto justify-content-between cursor-pointer"
            style={{
              width: 200
            }}
          >
            <div
              className="row"
              style={{
                width: '50%'
              }}
              onClick={() => {
                if (!isReadOnlyMode) {
                  setShowReversalDatePicker(!showReversalDatePicker);
                }
              }}
            >
              <DKIcon
                src={DKIcons.data_type.ic_date}
                className="ic-s"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'Reversal  JE Date'} className={'fw-m ml-r'} />
            </div>
            <div
              onClick={() => {
                if (!isReadOnlyMode) {
                  setShowReversalDatePicker(!showReversalDatePicker);
                }
              }}
            >
              <DKLabel
                text={
                  reversalDate
                    ? DateFormatService.getDateStrFromDate(reversalDate)
                    : ''
                }
                className={'ml-s'}
              />
            </div>
            {showReversalDatePicker &&
              getCalendarViewForReversalDate(
                reversalDate,
                (date: any) => {
                  let currJournalDate = journalDate;
                  currJournalDate.setHours(0, 0, 0, 0);
                  if (
                    date.getTime() >= today.getTime() &&
                    date.getTime() >= currJournalDate.getTime()
                  ) {
                    updateReversalJEDateConfig(date, isReversalDateSelect);
                  } else {
                    showAlert('Alert', 'Please select valid JE Reversal Date');
                    setReversalDate(reversalDate);
                  }
                },
                () => {
                  if (!isReadOnlyMode) {
                    setShowReversalDatePicker(!showReversalDatePicker);
                  }
                }
              )}
          </div>
        </div>
      </>
    );
  };

  const getAddReversalDateScreen = () => {
    return (
      isReadOnlyMode !== true && (
        <>
          <div
            className="row width-auto  mt-xl justify-content-between cursor-pointer"
            style={{
              width: 200
            }}
          >
            <DKButton
              title={`+ Add Reversal JE Date`}
              onClick={() => {
                if (!isReadOnlyMode) {
                  getReversalDateConfig();
                }
              }}
              className={`${
                isReadOnlyMode ? 'text-gray' : 'text-blue'
              } fw-m p-0`}
              style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
            />
          </div>
        </>
      )
    );
  };
  const getReversalDate = () => {
    return (
      <>
        {isReversalDateSelect
          ? getReversalDateScreen()
          : getAddReversalDateScreen()}
      </>
    );
  };

  const intercompanyTxnCheckbox = (
    <div className="flex flex-row w-full mt-3">
      <DKCheckMark
        color="bg-button"
        isSelected={je.interCompany}
        onClick={() => {
          setJE((value: any) => ({
            ...value,
            interCompany: !value.interCompany
          }));
        }}
        className="text-black z-index-1"
        title={`Is Intercompany Transaction`}
      />
    </div>
  );

  return (
    <div className="column parent-width p-l">
      <div className="row align-items-start gap-1">
        {props.type == JE_TYPES.BOOK_SPECIFIC_JE
          ? getAccountBookSelection()
          : showTaxView && getTaxAdjustmentView()}
        {showTaxView ? null : getCustomFieldView()}
        {getRightInfoPanel()}
      </div>

      {je?.taxAdjustmentDetails?.showAddTaxAdjustmentPopup &&
        getTaxAdjustmentPopupView()}
      {props.type != JE_TYPES.BOOK_SPECIFIC_JE &&
        !!tenantInfo.additionalSettings?.MULTI_COMPANY &&
        intercompanyTxnCheckbox}

      {showTaxView ? getCustomFieldView() : null}

      <div ref={gridContainerRef} className="column parent-width mt-m">
        {getDataGrid()}
        {getAddAccountPopup()}
        {!isReadOnlyMode && (
          <DKButton
            title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
            onClick={() => {
              addEmptyAccount();
            }}
            className={`fw-m ${
              je?.lineItems?.length === 0 && submitButtonTapped
                ? 'text-red'
                : 'text-blue'
            } `}
            style={{ zIndex: 1 }}
          />
        )}
      </div>
      <div
        className="row parent-width justify-content-between px-2 py-5 align-items-start"
        style={{ gap: 20, borderTop: '1px dashed #cacaca' }}
      >
        {/* memo */}
        <textarea
          className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400"
          style={{
            width: 800,
            height: 100,
            backgroundColor: 'rgb(250, 250, 250)',
            border: '1px dashed rgb(200, 200, 200)'
          }}
          placeholder={'Memo'}
          onChange={(e) => {
            setJE({ ...je, memo: e.target.value });
          }}
          // onKeyDown={handleKeyDownPress}
          value={je.memo}
          // disabled={}
        />
        {/* summarry */}
        <div className="column w-3/6">
          {/* debit amount */}
          <div className="row justify-content-between ">
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width fw-m"
              text="Debit Amount"
            />
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width"
              text={`${Utility.getCurrencySymbolFromCode(
                selectedCurrency?.currencyCode
              )} ${NumberFormatService.getNumber(
                getTotalAmountFor(CD_TYPE.DEBIT)
              )}`}
            />
          </div>
          {/* credit amount */}
          <div className="row justify-content-between">
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width fw-m"
              text="Credit Amount"
            />
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width"
              text={`${Utility.getCurrencySymbolFromCode(
                selectedCurrency?.currencyCode
              )} ${NumberFormatService.getNumber(
                getTotalAmountFor(CD_TYPE.CREDIT)
              )}`}
            />
          </div>
          {/* unbalanced amount */}
          <div className="row justify-content-between">
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width fw-m"
              text="Unbalanced Amount"
            />
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width"
              text={`${Utility.getCurrencySymbolFromCode(
                selectedCurrency?.currencyCode
              )} ${getUnbalancedAmount()}`}
            />
          </div>
        </div>
      </div>
      {!isReadOnlyMode && (
        <DKButton
          title="+ Attach Files"
          icon={DKIcons.ic_attachment}
          className="text-blue fw-m"
          onClick={triggerAttachmentUpload}
        />
      )}
      <div className="row ">{getAttachments()}</div>
      {showAddClassPopup && getAddClassForm()}
      {openAccountCFSettings && getAccountCustomFieldSettings()}
    </div>
  );
}
