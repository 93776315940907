let it = {
    translations: {
        "component": "Componente",
        "add": "Inserisci",
        "document_type": "tipo di documento",
        "select_theme_color": "Seleziona il colore del tema",
        "company_logo": "Logo della compagnia",
        "choose_file": "Scegli il file",
        "your_company_name": "Il nome della tua azienda",
        "company_address": "indirizzo aziendale",
        "currency": "La moneta",
        "no": "No",
        "terms_&_condition": "Termini e condizioni",
        "notes": "Appunti",
        "date_format": "Formato data",
        "line_items": "Elementi pubblicitari",
        "item_name": "Nome dell'elemento",
        "bill_to": "Disegno di legge per",
        "ship_to": "Spedire a",
        "valid_till": "Valido fino a",
        "due_date": "Scadenza",
        "date": " Data",
        "item_description": "Descrizione dell'articolo",
        "discount": "Sconto",
        "tax": "Imposta",
        "delete": "Elimina",
        "add_new_item": "Aggiungi un nuovo elemento",
        "signature": "Firma",
        "show_discount": "Mostra sconto",
        "show_tax": "Mostra tasse",
        "company_name": "Nome della ditta",
        "company_billing_address": "Indirizzo di fatturazione dell'azienda",
        "billing_address_placeholder_text_value": "Nome cliente \nL'indirizzo di fatturazione del cliente",
        "shipping_address_placeholder_text_value": "Nome cliente \nIndirizzo di spedizione del cliente",
        "terms_and_condition_placeholder_text": "Il pagamento deve essere effettuato entro 30 giorni",
        "notes_placeholder_text": "Si prega di pagare il saldo entro tempo",
        "hide": "Nascondere",
        "show": "Spettacolo",
        "estimate": "Stima",
        "quotation": "Quotazione",
        "invoice": "Fattura",
        "purchase_order": "Ordinazione d'acquisto",
        "bills": "Fatture",
        "total": "Totale",
        "number": "Numero",
        "edit":"Edit",
        "products": "Prodotti",
        "description": "Descrizione",
        "qty": "Qtà",
        "unit_price": "Prezzo unitario",
        "unit price": "Prezzo unitario",
        "price": "Prezzo",
        "amount": "Quantità",
        "subtotal": "totale parziale",
        "choose_a_template": "Scegli un modello",
        "go_to_deskera": "Goto Deskera",
        "print_this_document": "Stampa questo documento",
        "send_email_to_clients": "Invia e-mail ai clienti",
        "document_generator": "Generatore di documenti",
        "please_wait": "Attendere prego...",
        "download_as_pdf": "Scarica come (.pdf)",
        "download": "Scarica",
        "print": "Stampa",
        "share": "Condividere",
        "try_deskera": "Prova Deskera",
        "copy_link": "Copia link",
        "share_on_the_web": "Condividi sul Web",
        "anyone_with_the_below_link_can_view": "Chiunque abbia il link sottostante può visualizzare",
        "send_your": "Invia il tuo",
        "quickly": "Velocemente",
        "please_sign_up_with_deskera_to_send_your": "Iscriviti a Deskera per inviare il tuo",
        "dont_worry_your": "Non preoccuparti, tuo",
        "will_still_be_here": "sarà ancora qui.",
        "sign_up_for_free": "Iscriviti gratis",
        "already_a_user_Sign_in": "Già un utente? Registrati",
        "share_on_mail": "Condividi su e-mail",
        "to": "Per",
        "subject": "Soggetto",
        "enter_recipient_email_address": "Inserisci l'indirizzo email del destinatario",
        "enter_subject_here": "Inserisci qui l'oggetto",
        "send": "Spedire",
        "cancel": "Annulla",
        "edit_table": "Modifica tabella",
        "save": "Salva",
        "buyer": "Acquirente",
        "seller": "Venditore",
        "powered_by_deskera": "Alimentato da Deskera",
        "details": "Dettagli",
        "other_comments": "Altri commenti",
        "bill": "Conto",
        "order": "Ordine",

        "email": "E-mail",

        "items": "Elementi",
        "services": "Servizi",
        "hours": "Ore",
        "rate": "Vota",
        "other": "Altro",
        "sales_tax": "Imposta sulle vendite",
        "tax_rate": "Aliquota fiscale",
        "sales tax": "Imposta sulle vendite",
        "tax rate": "Aliquota fiscale",

        "num": " No",
        "template_update_failure_message": "Impossibile aggiornare le impostazioni. Per favore riprova!",
        "template_save_failure_message": "Impossibile salvare le impostazioni. Per favore riprova!",
        "template_update_success_message": "Modello salvato con successo",
        "template_save_success_message": "Modello salvato con successo",
        "additional_information": "Informazioni aggiuntive",
        "other_options": "Altre opzioni",

        "image": "Immagine",
        "text": "Testo",
        "watermark": "Filigrana",
        "banner": "Banner",
        "custom_field": "campo personalizzato",
        "component_list": "Elenco dei componenti",

        "email_address": "Indirizzo email",
        "email_address_1": "Indirizzo email",
        "is_not_valid": "non è valido.",

        "deskera_suites": "Deskera Suites",
        "deskera_books": "Deskera Books",
        "deskera_people": "Deskera People",
        "deskera_sales": "Deskera Sales",
        "try_now": "Prova ora",


        "text_color": "Colore del testo",
        "image_ratio": "Rapporto immagine (%)",
        "text_size": "Dimensione del testo",
        "opacity": "Opacità",
        "font_style": "Stile carattere",
        "font_weight": "Peso carattere",
        "text_alignment": "Allineamento del testo",
        "image_alignment": "Allineamento dell'immagine",
        "image_preview": "Anteprima dell'immagine",
        "preview_banner": "Anteprima banner",
        "banner_selection": "Selezione banner",
        "duplicate": "Duplicare",
        "settings": "impostazioni",
        "expiry": "Scadenza",
        "batch_no": "Lotto n.",

        "saving": "Salvataggio...",
        "save_template": "Salva modello",

        "name": "Nome",

        "stock_items": "Articoli in magazzino",

        "sending": "Invio ...",
        "set_as_default": "Imposta default",

        "no_email_subject": "Nessun oggetto personalizzato trovato !! Si prega di digitare l'oggetto personalizzato e premere su salva modello",

        "expense": "Expense",
        "deposit": "Deposit",
        "credit_note": "Nota di credito",
        "debit_note": "Nota di debito",
        "accounts": "Accounts",
        "customer_address_placeholder_text_value": "Indirizzo del cliente Nome\nIl tuo cliente",
        "account_name_placeholder_text_value": "Codice account\nNome account",
        "payment_to": "Pagamento a",
        "from_account": "Dall'account",
        "payment_from": "Pagamento da",
        "to_account": "All'account",
        "payment_date": "Data pagamento",
        "document_date": "Data documento",
        "account_name": "Nome account",
        "account_description": "Descrizione account",
        "tax_amount": "Tax Amount",
        "payment_amount": "Payment Amount",
        "line_amount": "Line Amount",
        "total_tax_amount": "Importo totale IVA",
        "contact": "Contact",
        "driver_name": "Nome del Conducente",
        "transporter": "Trasportatore",
        "vehicle_no": "Numero del Veicolo",
        "lr_no": "LR No.",
        "contact_address_placeholder_text_value": "Contact Name\nYour Contact's Address",

        "printing": "Stampa ..",
        "downloading": "Download in corso ...",

        "incorrect_format": "Formato errato!",
        "few_formats_allowed": "Sono consentiti solo file jpg, jpeg, png",
        "image_missing": "Immagine mancante!",
        "data_missing": "Dati mancanti!",
        "select_image_to_proceed": "Seleziona l'immagine per procedere",
        "no_data_to_export": "Nessun dato da esportare",

        "session_expired_title": "Sessione scaduta!",
        "session_expired_message": "La tua sessione è scaduta. Per favore esegui l'accesso di nuovo.",
        "login": "Login",
        "generate_link_to_share": "Genera collegamento da condividere",
        "burn_after_read": "Brucia dopo aver letto",
        "generate_link": "creare",
        "no_never": "Mai",
        "1_min": "1 minuto",
        "1_hr": "1 ora",
        "1_day": "1 giorno",
        "1_year": "1 anno",

        "document_expired_title": "Documento scaduto!",
        "document_expired_message": "Questo documento è scaduto. Si prega di contattare il mittente",

        "loading": "Caricamento in corso...",
        "reference_date": "Data di riferimento",
        "cheque_number": "Controlla numero",
        "deposit_to": "Deposita a",

        "password": "Parola d'ordine",
        "password_empty_title": "Password vuota!",
        "password_empty_message": "La password non può essere vuota. Inserisci una password e riprova!",
        "document_not_found_title": "Documento non trovato!",
        "document_incorrect_password_title": "Password errata!",
        "document_incorrect_password_message": "Inserisci la password corretta e riprova.",
        "proceed": "Procedere",
        "document_enter_password_title": "Documento protetto da password.",
        "document_enter_password_message": "Per favore inserire una password.",

        "preparing_document_email": "Preparazione del documento per e-mail ...",
        "preparing_document_print": "Preparazione del documento da stampare ...",
        "loading_the_page": "Caricamento della pagina",
        "sender": "mittente",
        "reply_to": "Rispondi a",
        "enter_sender_name": "Inserisci nome mittente",
        "enter_reply_to_email": "Inserisci risposta a email",
        "send_a_copy_to_myself_at": "Invia una copia a me stesso a",

        "payment": "Pagamento",
        "paper_format": "Formato carta",
        "please_provide_paper_format": "Fornisci formato cartaceo",
        "paper_format_popup_note": "È comunque possibile aggiornare il formato della carta dal pannello delle impostazioni",
        "item_number": "Codice articolo",
        "item_code": "codice articolo",
        "received_from": "Modulo ricevuto",
        "payment_details": "Dettagli pagamento",
        "sn_number": "Numero di serie",
        "document_details": "dettagli del documento",
        "template_saved": "Modello salvato!",
        "you_design_template_has_been_saved": "Il modello è stato salvato, puoi chiudere questa finestra e tornare all'applicazione LIBRI.",
        "you_design_template_has_been_saved_people": "Il modello è stato salvato, puoi chiudere questa finestra e tornare all'applicazione PERSONE..",
        "go_to_books": "Vai a libri",
        "item_tax": "Imposta sul prodotto",
        "item_discount": "Sconto sul prodotto",
        "uom": "UOM",
        "unitPrice": "Prezzo unitario",
        "quantity": "Quantità",
        "code": "Codice",
        "machine_name": "Nome macchina",
        "technician_name": "Nome tecnico",
        "start_date": "Data di inizio",
        "schedule_id": "ID pianificazione",
        "end_date": "Data di fine",
        "please_select_custom_field": "Seleziona un campo personalizzato.",

        "preview_pdf": "Anteprima Pdf",

        "template_name": "Nome modello",
        "please_enter_template_name_to_save": "Immettere il nome del modello da salvare.",
        "custom_template": "Modello personalizzato",
        "add_new_template": "Aggiungi nuovo modello personalizzato",
        "vendor_name": "Nome del fornitore",

        "decimal_point": "Punto decimale",
        "footer": "Piè di pagina",

        "save_as": "Salva con nome",
        "save_as_new_template": "Salva come nuovo modello",
        "e_invoice_section": "e-fattura sezione",
        "amount_before_tax": "Importo prima delle tasse",

        "order_no": "Ordine No",
        "PURCHASE_INVOICE": 'Conto',
        "PURCHASE_ORDER": 'Ordine',
        "SALES_INVOICE": "Fattura",
        "QUOTATION": "Quotazione",
        "fulfillment": "Adempimento",
        "ship_from": "Spedisci da",
        "company_address_placeholder_text_value": "Nome azienda\nindirizzo della tua azienda",
        "goods_receipt": "Ricevuta merce",
        "required_qty": "Quantità richiesta",
        "received_qty": "Quantità ricevuta",
        "committed_qty": "Quantità impegnata",
        "warehouse_code": "Codice magazzino",
        "warehouse_name": "Nome del magazzino",
        "company_warehouse_name": "Nome dell'azienda/magazzino",
        "payment_footer": "Pagamento",
        "make_payment": "Esegui pagamento",
        "receive_payment": "Ricevi pagamento",
        "deposit_advpayment": "Caparra",
        "expense_prepayment": "Pagamento anticipato",

        "delete_template": "Elimina modello",
        "delete_template_message": "Sei sicuro di voler eliminare il modello? Una volta eliminato, il modello non è più recuperabile.",
        "delete_template_ok_label": "Sì. Eliminalo.",
        "delete_template_success_message": "Modello eliminato con successo.",
        "delete_template_failure_message": "Impossibile eliminare le impostazioni. Riprova!",
        "before_tax": "Prima delle tasse",
        "outstanding": "Importo insoluto",

        "signature_section": "Sezione firme",
        "prepared_by": "Preparato da",
        "approved_by": "Approvato da",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "Seriale/Batch",
        "amount_in_words": "Importo in parole",
        "name_with_description": "Nome",

        "invoice_number": "Numero fattura",
        "bill_number": "Numero fattura",
        "total_invoice_amount": "Importo totale della fattura",
        "total_bill_amount": "Importo totale fattura",
        "payment_made": "Pagamento effettuato",
        "current_due_amount": "Importo corrente dovuto",
        "current_balance": "Saldo attuale",
        "payment_medium": "Mezzo di pagamento",
        "cash": "contanti",
        "prepayment": "pagamento anticipato",
        "payment_label": "Pagamento",
        "receipt_label": "Ricevuta",
        "total_row": "Riga Totale",

        "journal_entry": "Voce di diario",
        "journal_entry_number": "JE no.",
        "account_code": "Codice conto",
        "credit": "credito",
        "debit": "debito",
        "GOOD_RECEIPT": "Ricevuta merce",
        "MAKE_PAYMENT": "Pagamento",
        "RECEIVE_PAYMENT": "Pagamento",
        "CREDIT_NOTE": "Nota di credito",
        "DEBIT_NOTE": "Nota di debito",
        "FULFILLMENT": "Adempimento",
        "SALES_RETURN": "Restituzione di vendita",
        "PURCHASE_RETURN": "Restituzione acquisto",
        "SALES_CONTRA": "Contra di vendita",
        "PURCHASE_CONTRA": "Contra acquisto",
        "BANK_TRANSFER": "Bonifico bancario",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "Regolazione",
        "memo": "Promemoria",

        "pick_list": "lista da cui scegliere",
        "pack_list": "lista dei pacchi",
        "ship_list": "Lista delle navi",
        "invoice_or_quotation": "Fattura/Preventivo",
        "product_code": "Codice prodotto",
        "product_name": "nome del prodotto",
        "barcode": "codice a barre",
        "quantity_to_pick": "Quantità da prelevare",
        "quantity_required_for_order": "Quantità richiesta per l'ordine",
        "picked": "scelto",
        "item_no": "Oggetto numero.",
        "unit": "Unità",
        "packed_quantity": "Quantità imballata",
        "warehouse_address": "Indirizzo di magazzino",
        "delivery": "Consegna",
        "carrier": "Vettore",
        "number_of_carton": "Numero di cartone",
        "weight": "Il peso",
        "tracking_number": "Numero di identificazione",
        "package": "Pacchetto",
        "order_number": "Numero d'ordine",

        "custom_field_is_missing_title": "Manca il campo personalizzato",
        "custom_field_is_missing_message": "Il campo personalizzato non è presente in questo documento. Aggiungi gentilmente un campo personalizzato da Book o prova con un altro documento.",

        "repeatable_header": "Intestazione tabella ripetibile",
        "margin": "Margine",
        "default": "Predefinito",
        "none": "Nessuno",
        "minimum": "Minimo",
        "large": "Grande",

        "receive_by": "Ricevi per data",
        "ship_by": "Spedizione entro data",
        "additional_date": "Data aggiuntiva",

        "header_footer_section": "Sezione piè di pagina intestazione",
        "header": "Intestazione",
        "date_only": "Solo data",
        "title_only": "Solo titolo",
        "page_count_only": "Solo conteggio pagine",
        "date_title": "Data e titolo",
        "date_page_count": "Data e numero di pagine",
        "title_page_count": "Titolo e numero di pagine",
        "date_title_space": "Data e titolo con spazio",
        "date_page_count_space": "Data e conteggio pagine con spazio",
        "title_page_count_space": "Titolo e pagina contano con lo spazio",
        "date_title_page_count": "Data, titolo, numero di pagine",
        "custom": "Costume",
        "left": "Lato sinistro",
        "center": "Centro",
        "right": "Lato destro",
        "title": "Titolo",
        "page_count": "Conteggio pagine",
        "current_page_count": "Conteggio pagine corrente",
        "total_page_count": "Conteggio totale delle pagine",
        "custom_text": "Testo personalizzato",
        "page": "Pagina",

        "company_phone_number": "Numero di telefono dell'azienda",
        "company_gstin": "Azienda GSTIN",
        "company_state_name_and_code": "Nome e codice dello stato dell'azienda",
        "payment_terms": "Termini di pagamento",
        "customer_phone_number": "Numero di telefono del cliente",
        "customer_gstin": "Cliente GSTIN",
        "customer_state_name_and_code": "Nome e codice dello stato del cliente",
        "print_only_message": "Questo è un computer generato",
        "hsn_or_sac": "HSN/SAC",
        "taxable_value": "Valore imponibile",
        "integrated_tax": "Imposta integrata",
        "central_tax": "Imposta centrale",
        "state_tax": "Tassa statale",
        "cess": "CESS",
        "state_name": "Nome dello stato",
        "state_code": "Codice",
        "label_state_code": "Codice di Stato",

        "address_format": "Formato indirizzo",
        "address_1": "Indirizzo 1",
        "address_2": "Indirizzo 2",
        "city": "Città",
        "state": "Stato",
        "postal_code": "codice postale",
        "country": "Nazione",
        "is_apply_address_format_to_other_addresses": "Applica il formato indirizzo corrente a tutti gli indirizzi.",
        "product_grouping": "Raggruppamento di prodotti",
        "product_group": "Gruppo di prodotti",
        "cheque": "Assegno",
        "bank_transfer": "Bonifico bancario",
        "card": "Carta",

        "payslip": "Busta paga",
        "earning_statement": "Dichiarazione di guadagno",
        "pay_period": "Periodo di pagamento",
        "pay_date": "Data di pagamento",
        "ssn": "SSN",
        "taxable_marital_status": "Stato civile tassabile",
        "exemptions": "Esenzioni",
        "date_of_joining": "Data di adesione",
        "pf_a_c_NUMBER": "Numero PF A/C",
        "esi_number": "Numero ESI",
        "uan_number": "Numero UAN",
        "employee_id": "Numero Identità dell'impiegato",
        "designation": "Designazione",
        "employee_net_pay": "Retribuzione netta dei dipendenti",
        "employee_earnings": "Guadagno dei dipendenti",
        "allowances": "Indennità",
        "deductions": "Detrazioni",
        "employer_contribution": "Contributo del datore di lavoro",
        "employer_cpf": "CPF del datore di lavoro",
        "net_salary_paid": "Stipendio netto pagato",
        "employee_taxes_withhold": "Ritenuta d'imposta sui dipendenti",
        "employer_taxes": "Tasse del datore di lavoro",
        "employee_deductions": "Detrazioni per i dipendenti",
        "summary": "Riepilogo",
        "basic_salary": "Salario di base",
        "net_pay": "Retribuzione netta",
        "additional_earnings": "Guadagni aggiuntivi",
        "gross_earnings": "Guadagno lordo",
        "duration": "Durata",
        "current": "Attuale",
        "year_to_date": "Da inizio anno",
        "type": "Tipo",
        "pre_tax_deductions_contributions": "Detrazioni/contributi ante imposte",
        "taxes": "Le tasse",
        "post_tax_deductions_contributions": "Detrazioni/contributi al netto delle imposte",
        "net_pay_contributions": "Retribuzione netta/contributi",
        "check_amount": "Controlla l'importo",
        "payslip_auto_message": "Questa è una busta paga generata dal sistema non richiede sigillo e firma",
        "employee_address": "indirizzo del dipendente",
        "field_configuration": "Configurazione del campo",
        "table_configuration": "Configurazione della tabella",
        "employee_name_address": "Nome e indirizzo del dipendente",
        "employee_bank_acc_number": "numero di conto bancario del dipendente",
        "header_title": "Titolo intestazione",
        "product_custom_field": "Campi personalizzati del prodotto",
        "quantity_and_uom": "Quantità e UM",
        "includes_product_custom_field": "Include i campi personalizzati del prodotto",
        "combines_product_custom_field": "Combina i campi personalizzati del prodotto",
        "serial_number": "Numero di serie",
        "batch_number": "Numero di lotto",
        "manufacturing_date": "Data di produzione",
        "expiry_date": "Data di scadenza",
        "override_theme_color": "Sostituisci il colore del tema",
        "override_global_color": "Sostituisci colore globale",
        "contact_number": "Numero di telefono",
        "contact_code": "Codice di contatto",
        "blank": "Vuota",
        "pay_to": "Paga a",
        "payroll_check": "Controllo buste paga",
        "pay_to_the": "PAGA AL",
        "order_of": "ORDINE DI",
        "dollars": "DOLLARI",
        "payroll_period": "Buste paga per il periodo di",
        "repeatable_header_footer": "Piè di pagina di intestazione ripetibile",
        "repeatable_footer": "Piè di pagina ripetibile",
        "includes_hsn_or_sac_code": "Include il codice HSN/SAC",
        "eway_bill_no":  "Eway Bill n",
        "eway_bill_date": "Eway Bill Data",
        "place_of_supply":'Luogo di Rifornimento',
        "destination_of_supply":"Destinazione di approvvigionamento",
        "source_of_supply":"Fonte di approvvigionamento",
        "subTable_configuration": "Configurazione sottotabella",
        "additional_table_configuration": "Configurazione tabella aggiuntiva",
        "select_new_type": "Seleziona Nuovo tipo",
        "qr_code": "QR Code",
        "qr_string": "QR String",
        "generated_qr": "QR generato",
        "qr_code_with_text": "Codice QR con testo",
        "image_with_text": "Immagine con testo",
        "qr_information": "Informazioni QR",
        "additional_info": "Informazioni addizionali",
        "global_discount": "Sconto globale",
        "pre_tax": "Pre-Tasse",
        "post_tax": "Post-Tasse",
        "includes_uom_schema": " Include lo schema UM",
        "table": "Tavolo",
        "font": "Font",
        "table_row_height": "Altezza della riga della tabella",
        "padding_horizontal": "Imbottitura orizzontale",
        "padding_vertical": "Imbottitura verticale",
        "original": "Originale",
        "original_for_recipient": "Originale per il destinatario",
        "duplicate_supply_of_goods": "Duplicato (fornitura di merci)",
        "duplicate_for_transporter": "Duplicato per trasportatore",
        "duplicate_supply_of_service": "Duplicato (fornitura di servizi)",
        "duplicate_for_supplier": "Duplicato per il fornitore",
        "triplicate": "Triplice copia",
        "triplicate_for_supplier": "Triplo per il fornitore",
        "position": "Posizione",
        "middle": "Mezzo",
        "sample_watermark": "Esempio di filigrana",
        "extra_copy": "Copia extra",
        "quadruplicate": "Quadruplicato",
        "MAX_IMG_UPLOAD_ALERT": "La dimensione dell'immagine non può essere maggiore di 500 KB",
        "system_default": "Default del sistema",
        "qr_resize": "Ridimensionamento QR",
        "top": "Cima",
        "bottom": "In basso",
        "click_here_to_pay": "Clicca qui per pagare",
        "generated_message": "Messaggio generato",
        "sales_order": "Ordine di vendita",
        "help": "Aiuto",
        "includes_account_custom_field": "Include i campi personalizzati del account",
        "combines_account_custom_field": "Combina i campi personalizzati del account",
        "account_custom_field": "Campi personalizzati dell'account",
        "selection": "Selezione",
        "alias_name_address": "Nome e indirizzo alias",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "Giorni retribuiti",
        "unpaid_days": "Giorni non pagati",
        "payslip_overtime": "Col tempo",
        "payslip_LOP": "Perdita di paga",
        "outstanding_balance": "Equilibrio eccezionale",
        "outstanding_balance_includes_current": "Saldo eccezionale (incluso corrente)",
        "top_section_above_table": "Sezione in alto sopra la tabella",
        "is_group_additional_charges_tax": "Sia che si tratti di un supplemento di gruppo",
        "supplier_invoice_no": "Fattura fornitore n",
        "default_template": "Modello predefinito",
        "contact_name": "Nome del contatto",
        "current_date": "Data odierna",
        "e_way_bill_summary": "Riepilogo fattura Eway",
        "e_way_bill_detail": "Eway Bill dettaglio",
        "table_column_alignment": "Allineamento delle colonne della tabella",
        "table_column_vertical_alignment": "Allineamento verticale",
        "earnings_configuration": "Configurazione guadagni",
        "label_empty_field_message": "L'etichetta del campo non può essere vuota, inserisci un valore per procedere.",
        "product_table": "Tabella dei prodotti",
        "rounding_off": "Arrontondando",
        "work_order": "Ordine di lavoro",
        "order_prep_completed": "Preparazione ordine completata",
        "barcode_settings": "Impostazioni del codice a barre",
        "barcode_resize": "Ridimensiona il codice a barre",
        "comments": "Commenti",
        "delivery_date": "Data di consegna",
        "estimated_time": "Tempo stimato",
        "actual_quantity": "Quantità effettiva",
        "manufacture_quantity": "Quantità di produzione",
        "parent_work_order": "Ordine di lavoro principale",
        "stock_transfer": "Trasferimento di magazzino",
        "source": "Origine",
        "destination": "Destinazione",
        "source_address": "Indirizzo di origine",
        "destination_address": "Indirizzo di destinazione",
        "transfer_date": "Data di trasferimento",
        "verify_date": "Data di verifica",
        "reason": "Motivo",
        "warehouse": "Magazzino",
        "stock_adjustment": "Regolazione di magazzino",
        "source_warehouse_name": "Nome magazzino di origine",
        "source_warehouse_code": "Codice magazzino di origine",
        "destination_warehouse_name": "Nome magazzino di destinazione",
        "destination_warehouse_code": "Codice magazzino di destinazione",
        "product": "Prodotto",
        "show_product": "Mostra Prodotto",
        "hide_product_with_substitute": "Nascondi Prodotto con Sostituti",
        "substitute": "Sostituto",
        "show_all_substitute": "Mostra Tutti i Sostituti",
        "show_non_zero_substitute": "Mostra Sostituti Allocati",
        "hide_substitute": "Nascondi Sostituto",
        "combines_line_item_global_discount": "Combina lo sconto globale dell'articolo di riga",
        "unit_price_before_discount": "Prezzo unitario prima dello sconto",
        "unit_price_after_discount": "Prezzo unitario dopo lo sconto",
        "discount_per_unit": "Sconto per unità",
        "show_all": "Mostra tutti",
        "percentage": "Percentuale",
        "cogs": "Costo dei beni venduti",
        "na": "NA",
        "con_number": "Numero ordine cliente",
        "document_label": "Etichetta del codice a barre",
        "expected_delivery_date": "Data di consegna prevista",
        "transaction_type": "Tipo di Transazione",
        "transaction_no": "Numero Transazione",
        "status": "Stato",
        "qc_document": "Documento QC",
        "linked_po_so": "PO/SO Collegato",
        "approver": "Approvatore",
        "sample_size": "Dimensione Campione",
        "number_of_qty_passed": "Numero di Qty Approvate",
        "number_of_qty_rejected": "Numero di Qty Rifiutate",
        "final_remark": "Nota Finale",
        "value": "Valore",
        "exchange_conversion": "Conversione di scambio",
        "contact_address": "indirizzo di contatto",
        "sales_return": "Reso delle vendite",
        "cheque_fields": "Campi del assegno",
        "cheque_details": "Dettagli del assegno",
        "fulfilled_quantity": "Quantità soddisfatta",
        "returned_quantity": "Quantità restituita",
        "parent_linked_document_no": "Documento genitore n",
        "parent_linked_document_type": "Tipo di documento principale",
        "swap_address": "Scambia Indirizzo",
        "vendor_address": "Indirizzo del Fornitore",
        "job_work_out_order": "Ordine di lavorazione esterna",
        "machine":"Escalonador de máquina",
        "kind_of_packing": "N. & Tipo di Pacchi",
        "due_on": "Scadenza il",
        "per": "Per",
        "voucher_no": "Numero Voucher",
        "dated": "Datato",
        "supplier_ref_order_no": "Rif. Fornitore/Numero Ordine",
        "mode_term_of_payment": "Modalità/Termini di Pagamento",
        "dispatch_doc_no": "Numero Doc. di Spedizione",
        "dispatched_through": "Spedito tramite",
        "duration_of_process": "Durata del Processo",
        "nature_of_processing": "Natura della Lavorazione",
        "company_pan": "PAN Aziendale",
        "authorised_signatory": "Firmatario autorizzato",
        "additional_charges": "Spese aggiuntive",
        "contact_no": "Contatta il n",
        "supplier_inv_no": "N. inventario fornitore",
        "basic_salary_wo_lop": "Stipendio base senza perdita di retribuzione",
        "cost": "Costo",
        "stock_request": "Richiesta di Magazzino",
        "target_warehouse_name": "Nome Magazzino Destinazione",
        "target_warehouse_code": "Codice Magazzino Destinazione",
        "selected_row": "Riga Selezionata",
        "selected_rack": "Rack Selezionato",
        "selected_bin": "Bin Selezionato",
        "requested_qty": "Quantità Richiesta",
        "created_by": "Creato da",
        "approved_date": "Data di Approvazione",
        "stock_issue": "Emissione di Magazzino",
        "gate_entry": "Ingresso del Cancello di Sicurezza",
        "issued_quantity": "Quantità Emessa",
        "bom_product_table": "Tabella dei prodotti BOM",
        "list": "Elenco",
        "is_multi_product_table": "Tabella prodotti multipli",
        "is_show_optional_group": "Gruppo prodotti opzionale",
        "tax_registration_number": "Numero di registrazione fiscale",
        "tax_payer_id": "ID Contribuente",
        "wo_qty": "Quantità degli ordini di lavoro",
        "adhoc_items_message": "* rappresenta elementi AdHoc",
        "production_checklist": "Lista di controllo della produzione",
        "product_description": "Descrizione del prodotto",
        "total_quantity": "Quantità totale",
        "job_status": "Stato del lavoro",
        "qc_status": "Stato del controllo di qualità",
        "show_job_status": "Mostra stato della scheda di lavoro",
        "show_instruction": "Mostra istruzione",
        "optional": "Opzionale",
        "document_title": "Titolo del documento",
        "barcode_for_row_rack_bin": "Codice a barre per Fila/Scaffale/Bin",
        "show_tagged_bin_warehouse": "Magazzino con cassette contrassegnate",
        "hsn_sac_tax_table": "Tabella delle tasse HSN/SAC",
        "approval_section": "Sezione Approvazione",
        "border": "Bordo",
        "spacing": "Spaziatura",
        "checked_by": "Verificato da",
        "authorized_by": "Autorizzato da",
        "word_spacing": "Spaziatura delle parole",
        "page_count_settings": "Impostazioni conteggio pagine",
        "single_digit": "Cifra singola",
        "page_with_numeric": "Pagina con numeri",
        "page_x_of_total_page": "Pagina x di y",
        "page_x_of_total_page_slash": "pagina x / y",
        "service_requisition": "Richiesta di servizio",
        "service_description": "Descrizione del servizio",
        "sc_no":"SC n",
        "sc_date":"Data SC",
        "sc_type":"Tipo SC",
        "delivery_order_doc_and_date": "Numero e data dell'ordine di consegna",
        "e_way_bill_doc_and_date": "Numero e data della fattura elettronica",
        "party_order_doc_and_date": "Numero e data dell'ordine di parte",
        "mode_of_transport": "Modalità di trasporto",
        "bank_details": "Dettagli bancari",
        "bank_name": "Nome della banca",
        "company_seal": "Sigillo aziendale",
        "regd_office": "Ufficio registrato",
        "sub_total_in_words": "Subtotale in parole",
        "rounding_off_in_words": "Arrotondamento in parole",
        "outstanding_balance_in_words": "Saldo residuo in parole",
        "outstanding_balance_includes_current_in_words": "Saldo residuo (incluso attuale) in parole",
        "total_tax_amount_in_words": "Importo totale delle tasse in parole",
        "before_tax_in_words": "Prima delle tasse in parole",
        "additional_charges_in_words": "Costi aggiuntivi in parole",
        "global_discount_in_words": "Sconto globale in parole",
        "cgst_in_words": "CGST in parole",
        "igst_in_words": "IGST in parole",
        "sgst_in_words": "SGST in parole",
        "person_name": "Nome della persona",
        "store_officer": "Responsabile del magazzino",
        "department_holder": "Capo del dipartimento",
        "purchase_department": "ufficio acquisti",
        "issue_by": "Emesso da",
        "paper_orientation": "Orientamento della carta",
        "landscape": "Paesaggio",
        "portrait": "Ritratto",
        "purchase_requisition": " Richiesta di acquisto",
        "phone": " Telefono",
        "requisition_qty": " Quantità richiesta",
        "stock_qty": " Qtà di riserva",
        "prev_purchase_rate": " Prec. Tasso di acquisto",
        "scheduled_date": " Data programmata",
        "estimate_rate": " Tasso stimato",
        "estimate_value": " Valore stimato",
        "cost_center": " Centro di costo",
        "reserved": " Riservato",
        "division": " Divisione",
        "department_name": " Nome Dipartimento",
        "indent_no": " trattino n",
        "indent_date": " Data rientro",
        "indent_type": " Tipo di rientro",
        "project": " Progetto",
        "authorised_date": " Data autorizzata",
        "requested_by": " Richiesto da",
        "for": " Per",
        "authorised_by": " Autorizzato da",
        "available_qty": "Quantità disponibile",
        "service_order": " Ordine di servizio",
        "gst_no": " GST n",
        "gstin_no": " N. GSTIN",
        "pan_no": " PAN n",
        "cin_no": " CIN n",
        "cont_person": " Continua Persona",
        "order_date": " Data dell'ordine",
        "shipment_address": " Indirizzo di spedizione",
        "prepared_date_time": "Data e Ora Preparata",
        "only": "Soltanto",
        "completed": "Completato",
        "location_detail": "Dettaglio della posizione",
        "transporter_detail": "Dettaglio del trasportatore",
        "item_detail": "Dettaglio dell'articolo",
        "material_receipt_note": "Nota di ricezione materiale",
        "mrn_no": "MRN n",
        "authorized": "Autorizzato",
        "inspected_by": "Ispezionato da",
        "store_manager": "Responsabile del negozio",
        "hod": "H.O.D",
        "account_manager": "Account Manager",
        "po_bill_no_date": "N. ordine/Data\nN. fattura/Data",
        "short_excess_rejected_qty": "Quantità insufficiente/eccesso/rifiutata.",
        "accepted_qty": "Qtà accettata",
        "basic_amt": "Ammontare di base",
        "charges": "Spese",
        "last_po": "L'ultimo PO",
        "cgst": "CGST",
        "sgst": "SGST",
        "igst": "IGST",
        "gst": "GST",
        "total_amount": "Importo totale",
        "total_passed_amount": "Importo totale passato",
        "inspection_required": "Ispezione richiesta",
        "qc_inspection": "Ispezione QC",
        "work_order_no": "N. ordine di lavoro",
        "work_order_date": "Data ordine di lavoro",
        "work_order_due_date": "Data di scadenza ordine di lavoro",
        "work_order_status": "Stato ordine di lavoro",
        "edit_table_columns": "Modifica colonne della tabella",
        "incoming": "In arrivo",
        "final": "Finale",
        "label": "Etichetta",
        "both": "Entrambi",
        "inspection_report": "Rapporto di ispezione",
        "inspection_date": "Data",
        "parts": "Parti",
        "reworks": "Rilavorazioni",
        "final_inspection": "Ispezione finale",
        "product_listing": "Elenco dei prodotti",
        "paper_margin": "Margine della carta",
        "is_using_custom_margin": "Usa margine personalizzato",
        "soa_customer": "Estratto Conto",
        "soa_vendor": "Estratto Conto - Fornitore",
        "company_reg_no": "N. Registro dell'Azienda",
        "gst_reg_no": "N. Registrazione IVA",
        "from_date": "Dalla Data",
        "as_of_date": "Alla Data del",
        "to_date": "Alla Data",
        "document_number": "Numero del Documento",
        "particular": "Particolare",
        "amount_in_base": "Importo Base",
        "balance_in_base": "Saldo in Base",
        "ifsc_code": "Codice IFSC",
        "purchase_request_for_quotes": "Richiesta di preventivo",
        "purchase_inward_quotation": "Preventivo d'acquisto in entrata",
        "rfq_no": "N. di richiesta di offerta",
        "show_zero": "Mostra zero",
        "show_total": "Mostra totale",
        "draft_watermark": "Filigrana di bozza",
        "draft": "Bozza",
        "rotation": "Rotazione",
        "invoice_summary": "Riepilogo della fattura",
        "bill_summary": "Riepilogo della fattura",
        "amount_due": "Importo dovuto",
        "total_pending_balance": "Saldo totale in sospeso",
        "serial_batch_custom_fields": "Campi personalizzati del lotto seriale",
        "supplier_part_name": "Nome parte fornitore",
        "supplier_part_number": "Numero parte fornitore",
        "supplier_part_description": "Descrizione parte fornitore",
        "deal_name": "Nome dell'affare",
        "deal_owner": "Proprietario dell'affare",
        "show_running_number": "Tampilkan Nomor Berjalan",
        "aged_receivable": "Saldo in scadenza",
        "document_due_date": "Data di scadenza del documento",
        "balance": "Saldo",
        "aged_receivable_1_30": "1 a 30 giorni",
        "aged_receivable_31_60": "31 a 60 giorni",
        "aged_receivable_61_90": "61 a 90 giorni",
        "aged_receivable_90_plus": "Più di 90 giorni", 
        "last_payment": "Ultimo Pagamento",
        "total_due": "Totale Dovuto",
        "remit_to": "Invia a",
        "amount_enclosed": "Importo Incluso",
        "statement_date": "Data dell'Estratto",
        "account_no": "Numero di Conto",
        "invoice_no": "Numero di Fattura",
        "current_amount": "Importo Attuale",
        "batch_quantity": "Quantità del lotto",
        "row": "Riga",
        "rack": "Scaffale",
        "bin": "Cestino",
        "hide_optional_product": "Nascondi Prodotto Opzionale",
    }
};

export default it
