import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import Utility, { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import TenantParser from "../TenantParser"

export default class ReceivePaymentParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

         //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.companyName)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = undefined
        var preferAddress = AddressParser.getTenantAddressFromApi(undefined, dataFromAPI.tenantInfo)
        if (preferAddress !== undefined) {
            fromAddressObj = new Address(undefined, preferAddress, dataFromAPI.pan, dataFromAPI.tenantInfo.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        }
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        // billing to address
        var clientBillToAddressObj = undefined
        if (dataFromAPI.contactInfo !== undefined && dataFromAPI.contactInfo !== null) {
            if (dataFromAPI.contactInfo.billingAddress !== undefined && dataFromAPI.contactInfo.billingAddress !== null) {
                clientBillToAddressObj = new Address(dataFromAPI.clientName, dataFromAPI.contactInfo.billingAddress, undefined, undefined, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
            }
        }
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        templateDataToReturn.clientShipToAddressObj = undefined
        templateDataToReturn.clientShipToAddress =  this.get_CN_DN_Payment_Detail(dataFromAPI)

        //ref number
        templateDataToReturn.refNumber = dataFromAPI.refNumber

        //start date
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), templateDataToReturn.dateFormat,true)

        //due date
        if (dataFromAPI.referenceDate !== undefined && dataFromAPI.referenceDate !== null && dataFromAPI.referenceDate !== '') {
            templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.referenceDate), templateDataToReturn.dateFormat)
            templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible
        }


        templateDataToReturn.taxRegistrationNumber = ""
        templateDataToReturn.taxPayerId = ""

        //customer order number
        if (dataFromAPI.parentDocument) {
            templateDataToReturn.customerOrderNumber = dataFromAPI.parentDocument.customerOrderNumber

            if (dataFromAPI.parentDocument.contactInformation && dataFromAPI.parentDocument.contactInformation !== null) {
                templateDataToReturn.taxRegistrationNumber = dataFromAPI.parentDocument.contactInformation?.taxNumber ?? ''
                templateDataToReturn.taxPayerId = dataFromAPI.parentDocument.contactInformation?.taxPayerIdIsrael ?? ''
            }
        }

        //line item
        templateDataToReturn.lineItems = LineItemsParser.getPaymentItem(dataFromAPI, templateDataToReturn.type)

        //notes
        if (dataFromAPI.notes) {
            templateDataToReturn.notes = dataFromAPI.notes
        }
        else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text !== 'notes_placeholder_text') {
                templateDataToReturn.notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text
            } else {
                templateDataToReturn.notes = null
            }
        }

        //location
        templateDataToReturn = TenantParser.parseLocationDetail(dataFromAPI, templateDataToReturn)

        return templateDataToReturn
    }

    static get_CN_DN_Payment_Detail(data) {
        if (data && data.accountName !== undefined && data.accountName !== null) {
            var detailString = ''
            detailString = data.accountName + '\n'
            if (data.paymentType !== null && data.paymentType !== undefined && data.accountName?.toLowerCase() !== data.paymentType?.toLowerCase()) {
                detailString = detailString + Utility.getPaymentMethod(data.paymentType, data.country)
            }
            if (data.referenceNumber !== undefined && data.referenceNumber !== null && data.referenceNumber !== '') {
                detailString = detailString + ' (' + data.referenceNumber + ') \n'
            }
            return detailString
        }
    }
}
