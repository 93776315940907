
import ComponentManager from "../../../Manager/ComponentManager"
import { getLocalisedText } from "../../../Translate/LanguageManager"
import Utility, { getIsBillOrInvoice, getIsInvoice, getIsServiceRequisition, getIsSalesOrder, getVW, getIsRequestForQuotation } from "../../../Utilities/Utility"
import { ColumnStyle } from "../ColumnStyle"
import { HeaderColumn } from "../HeaderColumn"
import { ItemColumn } from "../ItemColumn"
import { TableOption } from "../TableOption"
import { FooterRow } from "../FooterRow"
import AppManager from "../../../Manager/AppManager";
import { ProductTableItem } from "./ProductTableItem"
import { ProductTableWidth } from "./ProductTableWidth"
import NumberUtility from "../../../Utilities/NumberUtility"
import { FONT_SIZE, PRINT_SCALE, TableDimensionType, TableColumnPopupType, CONSTANTS } from "../../../Constants/Constants"
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager"
import { TableHeader } from "../TableHeader"
import { getIsSelectTotalColumn, getSortedHeader, getSortedLineItems, getTablePadding } from "../TableUtility"
import { ProductTableTotalColumn } from "./ProductTableTotalColumn"
import IndiaTaxParser from "../../../Helper/IndiaTaxParser"
import { ProductTableGroupHeaderColumn } from "./ProductTableGroupHeaderColumn"
import TableFooterUtility from "../../../Utilities/TableFooterUtility"
import TableColumnAlignmentParser from "../../../Helper/TableColumnAlignmentParser"

export class ProductTable {
    static footerColumnList: HeaderColumn[]
    static groupHeaderIndexList: number[]
    static getTableOption(canUserEdit: boolean) {
        var tableOption = new TableOption()
        tableOption.isShowBorder = true
        tableOption.isShowFooter = true
        tableOption.isShowSortButton = canUserEdit
        tableOption.isReceiptFooter = Utility.getIsReceiptPaperSize()
        return tableOption
    }

    static getDefaultHeaderColumn(tableStyle: any, isStock: boolean, printScale: number = PRINT_SCALE) {
        var newHeaderColumn = new HeaderColumn()
        newHeaderColumn.type = 'string'
        newHeaderColumn.style = new ColumnStyle()
        newHeaderColumn.style.backgroundColor = tableStyle.headerBackgroundColor
        newHeaderColumn.style.borderColor = tableStyle.headerBorderColor
        newHeaderColumn.style.fontColor = tableStyle.headerTextColor
        newHeaderColumn.style.borderStyle = 'solid'
        newHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        if (tableStyle.headerBorderBottomColor !== undefined) {
            newHeaderColumn.style.borderBottomColor = tableStyle.headerBorderBottomColor
            newHeaderColumn.style.borderBottomWidth = tableStyle.headerBorderBottomWidth
        }
        newHeaderColumn.style.paddingTop = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingBottom = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newHeaderColumn.style.paddingLeft = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.paddingRight = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newHeaderColumn.style.fontSize = Utility.getIsReceiptPaperSize() ? FONT_SIZE.large : FONT_SIZE.regular
        newHeaderColumn.style.fontWeight = 'bold'
        return newHeaderColumn
    }

    static getHeaderColumnList(columnOption: any, tableStyle: any, rawData: any) {
        var headerColumnList: HeaderColumn[] = []
        if(columnOption === undefined || columnOption === null) {
            return
        }
        if (columnOption.length === 0) {
            return
        }
        var sortedHeader = getSortedHeader(columnOption);
        var defaultTableColumnAlignmentList = TableColumnAlignmentParser.getDefaultAlignmentForProduct()
        var tableColumnAlignmentList = TableColumnAlignmentParser.getTableColumnAlignment()
        var tableColumnVerticalAlignmentList = TableColumnAlignmentParser.getTableColumnVerticalAlignment()
        var footerList = TableFooterUtility.getFooterListByType('product')
        if (getIsInvoice(rawData.type) || getIsSalesOrder(rawData.type)) {
            var index = footerList.indexOf(TableColumnPopupType.totalAmount);
            footerList.splice(index !== -1 ? index + 1 : 5, 0, TableColumnPopupType.cogs);
        }
        if (sortedHeader.length > 0) {
            sortedHeader.forEach(element => {
                if (!footerList.includes(element.type)) {
                    if (element.globalDiscountAccountCode !== undefined) {
                        element.code = element.globalDiscountAccountCode
                    }
                    var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, false, rawData.printScale)
                    newHeaderColumn = this.updateHeaderColumn(newHeaderColumn, new TableHeader(element), rawData, defaultTableColumnAlignmentList, tableColumnAlignmentList, tableColumnVerticalAlignmentList)
                    headerColumnList.push(newHeaderColumn)
                }
            });
        }

        var width = columnOption[0].width

        if (width === undefined || width === null || this.getIsResetRequiredForColumnWidth(columnOption)) {
            var newColumnOption = columnOption
            var productTableWidth = this.getHeaderColumnWidth(headerColumnList, rawData)
            headerColumnList.forEach(element => {
                element.style.width = this.getHeaderItemColumnWidth(element.name, productTableWidth, element.code) + '%'
            });

            newColumnOption.forEach((element: any) => {
                element.width = this.getHeaderItemColumnWidth(element.type, productTableWidth, element.code) + '%'
            });

            TemplateSettingsManager.updateTablePopupInfo(newColumnOption)
        }
        else {
            headerColumnList.forEach(element => {
                var items = columnOption.filter((x: TableHeader) => x.type === element.name && x.code === element.code)
                if(items !== undefined && items !== null) {
                    if(items.length > 0) {
                        var width = items[0].width
                        if(width !== undefined) {
                            element.style.width = width
                        }
                    }
                }
            });
        }
        // headerColumnList = setLastColumnHeaderRightAlign(headerColumnList, footerList)
        return headerColumnList
    }

    static getIsResetRequiredForColumnWidth(columnOptions: any) {
        var isResetRequired = false
        var dataColumn = columnOptions.filter((x: any) => x.type !== TableColumnPopupType.discount && x.type !== TableColumnPopupType.tax)
        var selectedColumnWidth = dataColumn.filter((x: any) => x.isSelected)
        selectedColumnWidth.forEach((element: any) => {
            if (element.width === null || element.width === undefined) {
                isResetRequired = true
            }
            else if (element.width === '0%') {
                isResetRequired = true
            }
        });

        var isNotSelectedColumn = dataColumn.filter((x: any) => !x.isSelected)

        isNotSelectedColumn.forEach((element: any) => {
            if (element.width !== null && element.width !== undefined) {
                var num = Number(element.width.replace('%',''))
                if(num > 0) {
                    isResetRequired = true
                }
            }
        });

        return isResetRequired
    }

    static updateHeaderColumn(headerColumn: HeaderColumn, tableHeader: TableHeader, data: any, defaultTableColumnAlignmentList: any,tableColumnAlignmentList: any,tableColumnVerticalAlignmentList: any) {
        headerColumn.columnOption.isShowColumn = tableHeader.isSelected
        headerColumn.name = tableHeader.type
        headerColumn.label = getLocalisedText(tableHeader.name)
        headerColumn.code = tableHeader.code
        headerColumn.style.alignment = 'center'

        var columnAlignment = defaultTableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
        if (columnAlignment !== undefined) {
            headerColumn.style.alignment = columnAlignment.alignment
        }

        if (TableColumnPopupType.productCustomField === tableHeader.type) {
            headerColumn.label = tableHeader.name

            if (tableHeader.code === undefined) {
                headerColumn.style.alignment = 'left'
            }
        }

        if(tableColumnAlignmentList !== undefined && tableColumnAlignmentList !== null) {
            if(tableColumnAlignmentList.length > 0) {
                var savedColumnAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnAlignment = tableColumnAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnAlignment !== undefined) {
                    headerColumn.style.alignment = savedColumnAlignment.alignment
                }
            }
        }

        if (tableColumnVerticalAlignmentList !== undefined && tableColumnVerticalAlignmentList !== null) {
            if (tableColumnVerticalAlignmentList.length > 0) {
                var savedColumnVerticalAlignment: any = undefined
                if (tableHeader.type === TableColumnPopupType.productCustomField && tableHeader.code !== undefined) {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string, code: string | undefined }) => x.type === tableHeader.type && x.code === tableHeader.code)
                }
                else {
                    savedColumnVerticalAlignment = tableColumnVerticalAlignmentList.find((x: { type: string, alignment: string }) => x.type === tableHeader.type)
                }
                if (savedColumnVerticalAlignment !== undefined) {
                    headerColumn.style.verticalAlignment = savedColumnVerticalAlignment.alignment
                }
            }
        }
        return headerColumn
    }

    static getHeaderColumnWidth(headerColumnList: HeaderColumn[], data: any) {
        var showWidthConfig = new ProductTableWidth(data)
        showWidthConfig.setIsShowWidth(headerColumnList)
        showWidthConfig.getTableHeaderWidths()
        return showWidthConfig
    }

    static getHeaderItemColumnWidth(type: string, productTableWidth: ProductTableWidth, code?: string): number {
        var width = 0
        var itemWidth = 0
        switch (type) {
            case TableColumnPopupType.lineNumber:
                width = productTableWidth.lineNumber
                break;
            case TableColumnPopupType.code:
                width = productTableWidth.code
                break;
            case TableColumnPopupType.productName:
                width = productTableWidth.name
                break;
            case TableColumnPopupType.description:
                width = productTableWidth.description
                break;
            case TableColumnPopupType.productNameWithDescription:
                width = productTableWidth.nameWithDescription
                break;
            case TableColumnPopupType.productCustomField:
                width = productTableWidth.productCustomField
                if(code !== undefined) {
                    itemWidth = productTableWidth.getProductCustomFieldWidthByCode(code)
                    if(itemWidth !== undefined) {
                        width = itemWidth
                    }
                }
                break;
            case TableColumnPopupType.quantity:
                width = productTableWidth.quantity
                break;
            case TableColumnPopupType.uom:
                width = productTableWidth.uom
                break;
            case TableColumnPopupType.serialBatch:
                width = productTableWidth.serialBatch
                break;
            case TableColumnPopupType.serialNumber:
                width = productTableWidth.serialNumber
                break;
            case TableColumnPopupType.batchNumber:
                width = productTableWidth.batchNumber
                break;
            case TableColumnPopupType.manufacturingDate:
                width = productTableWidth.manufacturingDate
                break;
            case TableColumnPopupType.expiryDate:
                width = productTableWidth.expiryDate
                break;
            case TableColumnPopupType.quantityWithUom:
                width = productTableWidth.quantityWithUom
                break;
            case TableColumnPopupType.unitPrice:
                width = productTableWidth.unitPrice
                break;
            case TableColumnPopupType.amount:
                width = productTableWidth.amount
                break;
            case TableColumnPopupType.productTax:
                width = productTableWidth.productTax
                break;
            case TableColumnPopupType.productTaxAmount:
                width = productTableWidth.productTaxAmount
                break;
            case TableColumnPopupType.productUnitPriceAfterDiscount:
                width = productTableWidth.productUnitPriceAfterDiscount
                break;
            case TableColumnPopupType.productDiscountPerUnit:
                width = productTableWidth.productDiscountPerUnit
                break;
            case TableColumnPopupType.productDiscount:
                width = productTableWidth.productDiscount
                break;
            case TableColumnPopupType.productGlobalDiscount:
                width = productTableWidth.productGlobalDiscount
                if (code !== undefined) {
                    itemWidth = productTableWidth.getProductGlobalDiscountWidthByGlobalDiscountAccountCode(code)
                    if (itemWidth !== undefined) {
                        width = itemWidth
                    }
                }
                break;
            case TableColumnPopupType.amountBeforeTax:
                width = productTableWidth.amountBeforeTax
                break;
            case TableColumnPopupType.hsn:
                width = productTableWidth.hsn
                break;
            case TableColumnPopupType.expectedDeliveryDate:
                width = productTableWidth.expectedDeliveryDate
                break;
            case TableColumnPopupType.availableQuantity:
                width = productTableWidth.availableQuantity;
                break;
            case TableColumnPopupType.supplierPartDescription:
                width = productTableWidth.supplierPartDescription;
                break;
            case TableColumnPopupType.supplierPartName:
                width = productTableWidth.supplierPartName;
                break;
            case TableColumnPopupType.supplierPartNumber:
                width = productTableWidth.supplierPartNumber;
                break;
            default:
                break;
        }

        return width
    }

    static getData(documentData: any) {
        var lineItems: string[][] = []
        var dummyData = [
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
        ]
        if(documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            var currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null ) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return []
            }

            var sortedItems = getSortedLineItems(documentData.lineItems)

            //processing grouping for the for product attribute
            var groupItems = this.getGroupData(sortedItems, documentData, currency)

            groupItems.forEach((element: any) => {
                lineItems.push(element)
            });

            //add total section
            if (getIsSelectTotalColumn()) {
                lineItems.push(new ProductTableTotalColumn(sortedItems, documentData, currency).toString())
            }

            return lineItems
        }
    }

    static getGroupData(rawItems: any, documentData: any, currency: string, tableInfo?: any) {
        var lineItems: string[][] = []
        var newProductGrouping = TemplateSettingsManager.getProductGrouping()
        var isMultiProductTable = TemplateSettingsManager.getIsMultiProductTable()
        let isHideOptional = TemplateSettingsManager.getIsHideOptionalProduct() ?? false
        let items: any[] = []

        rawItems.forEach((element: any) => {
            if (isHideOptional) {
                let isOptional = element.optional ?? false
                if (!isOptional) {
                    items.push(element)
                }
            }
            else {
                items.push(element)
            }
        });

        var grouping = 'none'
        if (newProductGrouping !== undefined && newProductGrouping !== null) {
            if (newProductGrouping.selection !== undefined) {
                grouping = newProductGrouping.selection
            }
        }
        if(grouping === 'none' || isMultiProductTable) {
            items.forEach((element: any) => {
                lineItems.push(new ProductTableItem(element, documentData, currency).toString(tableInfo))
            });
            return lineItems
        }
        var attributes: string[] = []
        this.groupHeaderIndexList = []
        items.forEach((element: any) => {
            var label = ''
            if (element.customFields !== undefined && element.customFields !== null) {
                label = this.getAttributeString(element.customFields)
            }
            if(!attributes.includes(label)) {
                attributes.push(label)
            }
        });

        if (grouping === CONSTANTS.PRODUCT_GROUP && !isMultiProductTable) {
            attributes = []
            // var groupIds: string[] = []
            items.forEach((element: any) => {
                var label = ''
                // var groupId = ''
                if (element.productGroupName !== undefined && element.productGroupName !== null) {
                    label = element.productGroupName
                    // groupId = element.productGroupId
                }
                if(!attributes.includes(label)) {
                    attributes.push(label)
                    // groupIds.push(groupId)
                }
            });

            // if not single group present, remove none and show as it is record.
            if(attributes.length === 1 && attributes[0] === '') {
                items.forEach((element: any) => {
                    lineItems.push(new ProductTableItem(element, documentData, currency).toString(tableInfo))
                });
                return lineItems
            }
        }

        if(grouping !== CONSTANTS.PRODUCT_GROUP) {
            attributes = attributes.sort((a: string, b: string) => a.localeCompare(b))
        }
        //split the item based om attr
        var attributesGroupedItems: any[] = []
        attributes.forEach((element: any) => {
            var groupedItems: any[] = []
            items.forEach((item: any) => {
                if(grouping === CONSTANTS.PRODUCT_GROUP && !isMultiProductTable) {
                    if(item.productGroupName === element) {
                        groupedItems.push(item)
                    }
                } else {
                        if (this.getAttributeString(item.customFields) === element) {
                        groupedItems.push(item)
                    }
                }
            });
            if (element === '') {
                element = 'none'
            }
            var val = {
                key: element,
                list: groupedItems
            }
            attributesGroupedItems.push(val)
        });

        var itemCount = 1
        attributesGroupedItems.forEach((element) => {
            if (element.list !== undefined && element.list !== null) {
                if (element.list.length > 0) {
                    this.groupHeaderIndexList.push(lineItems.length)
                    lineItems.push(new ProductTableGroupHeaderColumn(element.key).toString())
                }
                element.list.forEach((element: any) => {
                    var newElement = element
                    newElement.lineNumber = itemCount
                    itemCount += 1
                    lineItems.push(new ProductTableItem(newElement, documentData, currency).toString())
                });
            }
        });
        return lineItems
    }

    static getGroupHeaderIndexList() {
        return this.groupHeaderIndexList
    }

    static getAttributeString(customFields: any) {
        var label = ''
        if(customFields !== undefined && customFields !== null) {
            if (customFields.length > 0) {
                customFields.forEach((element: any, index: number) => {
                    label = label + element.label + ' - ' + element.value
                    if(index < customFields.length-1) {
                        label = label + ', '
                    }
                });
            }
        }
        return label
    }
    static getDefaultItemColumn(tableStyle: any, index: number, isStock: boolean, printScale: number = PRINT_SCALE) {
        var newItemColumn = new ItemColumn()
        newItemColumn.type = 'string'
        newItemColumn.style = new ColumnStyle()
        if (tableStyle.itemBackgroundColorIsAlternate) {
            newItemColumn.style.backgroundColor = (index %2 !== 0) ? 'transparent' : tableStyle.itemBackgroundColor
        }
        else {
            newItemColumn.style.backgroundColor = tableStyle.itemBackgroundColor
        }
        newItemColumn.style.borderColor = tableStyle.itemBorderColor
        // newItemColumn.style.fontColor = tableStyle.headerTextColor
        newItemColumn.style.borderStyle = 'solid'
        newItemColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newItemColumn.style.paddingTop = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(13, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingBottom = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(12.6, printScale)), TableDimensionType.PADDING_VERTICAL)
        newItemColumn.style.paddingLeft = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newItemColumn.style.paddingRight = isStock ? getVW(Utility.convertToPrintScale(2.5, printScale)) : getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)

        if(isStock) {
            newItemColumn.style.fontSize = FONT_SIZE.xSmall
        }
        else {
            newItemColumn.style.fontSize = Utility.getIsReceiptPaperSize() ? FONT_SIZE.large : FONT_SIZE.regular
        }
        return newItemColumn
    }


    static getItemColumn(data: any, tableStyle: any, isStock: boolean = false, rawData: any) {
        var itemColumnList: ItemColumn[][] = []
        if(data === undefined ){
            return itemColumnList
        }
        if (data.length === 0) {
            return itemColumnList
        }
        var isLastRowIndex = data.length - 1
        data.forEach((column: any[], index: number) => {
            var rowList: ItemColumn[] = []
            if(isLastRowIndex === index && getIsSelectTotalColumn()) {
                column.forEach(element => {
                    var newItemColumn = this.getDefaultItemColumn(tableStyle, index, isStock, rawData.printScale)
                    newItemColumn.value = element
                    newItemColumn.isTotalColumn = true
                    rowList.push(newItemColumn)
                });
            }
            else {
                column.forEach(element => {
                    var newItemColumn = this.getDefaultItemColumn(tableStyle, index, isStock, rawData.printScale)
                    newItemColumn.value = element
                    rowList.push(newItemColumn)
                });
            }

            itemColumnList.push(rowList)
        });
        return itemColumnList
    }

    static parseTaxBreakdown(footerColumnList: HeaderColumn[], data: any, columnOption: any, tableStyle: any) {
        var newFooterColumnList = footerColumnList
        data.taxBreakdown.forEach((tax: { taxName: string }) => {
            if (tax.taxName !== 'Rounding Off') {
                newFooterColumnList.push(this.setFooterHeader(tableStyle, tax.taxName, tax.taxName, true, undefined, data.printScale))
            }
        });

        return newFooterColumnList
    }

    static getFooterColumn(data: any, columnOption: any, tableStyle: any, isSkipAdditionalCharge: boolean) {
        var newFooterColumn: HeaderColumn[] = []

        if (columnOption === undefined || columnOption === null) {
            return
        }

        let subTotalRow = columnOption.find((x: any) => x.type === TableColumnPopupType.subTotalAmount)
        if (subTotalRow !== undefined) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.subTotalAmount, subTotalRow.name, subTotalRow.isSelected, undefined, data.printScale))
        }
        else {
            newFooterColumn.push(this.setFooterHeader(tableStyle, 'subtotal', 'subtotal', true, undefined, data.printScale))
        }

        let discount_column_data_list = columnOption.filter((obj: any) => obj.type === TableColumnPopupType.discount)
        if (discount_column_data_list.length > 0 ) {
            let discount_column_data = discount_column_data_list[0]
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.discount, discount_column_data.name, discount_column_data.isSelected, undefined, data.printScale))
        }

        let before_tax_column_data_list = columnOption.filter((obj: any) => obj.type === TableColumnPopupType.beforeTax)
        if (before_tax_column_data_list.length > 0 ) { 
            let before_tax_column_data = before_tax_column_data_list[0]
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.beforeTax, before_tax_column_data.name, before_tax_column_data.isSelected, undefined, data.printScale))
        }

        let additionalChargeColumn = columnOption.find((obj: any) => obj.type === TableColumnPopupType.additionalCharge)
        if(additionalChargeColumn) {
            isSkipAdditionalCharge = !(additionalChargeColumn?.isSelected ?? true)
        }

        if (this.getIsContainsAdditionalCharge(data) && !isSkipAdditionalCharge) {
            var isGroup = TemplateSettingsManager.getIsGroupAdditionalChargesTax()
            data.additionalCharge.forEach((tax: { taxId: string, taxName: string, taxType: string | undefined}) => {
                if(tax.taxType !== undefined) {
                    if(tax.taxType === 'parent') {
                        newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                    }

                    if (!Utility.getIsIndiaCountry(data)) {
                        if (tax.taxType === 'parent_tax') {
                            newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                        }
                    }
                    
                    if(isGroup) {
                        if (tax.taxType === 'parent_tax') {
                            newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                        }
                    }
                    else {
                        if (tax.taxType === 'gst') {
                            newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                        }
                    }
                }
                else {
                    newFooterColumn.push(this.setFooterHeader(tableStyle, tax.taxId, tax.taxName, true, undefined, data.printScale))
                }
            });
        }

        let tax_column_data_list = columnOption.filter((obj: any) => obj.type === TableColumnPopupType.tax)
        if (tax_column_data_list.length > 0) {
            let tax_column_data = tax_column_data_list[0]
            if (tax_column_data.type === TableColumnPopupType.tax && !getIsServiceRequisition(data.type)) {
                if (!ComponentManager.getIsContainTaxBreakdown(data)) {
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.tax, tax_column_data.name, tax_column_data.isSelected, undefined, data.printScale))
                } else if (tax_column_data.isSelected && ComponentManager.getIsContainTaxBreakdown(data)) {
                    newFooterColumn = this.parseTaxBreakdown(newFooterColumn, data, columnOption, tableStyle)
                }
            }
        }

        let totalTaxAmount = columnOption.find((x: any) => x.type === TableColumnPopupType.totalTaxAmount)
        if (totalTaxAmount && totalTaxAmount.isSelected) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.totalTaxAmount, totalTaxAmount.name, totalTaxAmount.isSelected))
        }

        var global_discount_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.globalDiscount)
        if(global_discount_column_data) {
            if(!global_discount_column_data.isSelected) {
                newFooterColumn = newFooterColumn.filter(x => x.name !== TableColumnPopupType.globalDiscount)
            }
        }

        let rounding_off_column_data = columnOption.find((obj: any) => obj.type === TableColumnPopupType.roundingOff)
        if (rounding_off_column_data) {
            if (rounding_off_column_data.isSelected) {
                newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.roundingOff, rounding_off_column_data.name, rounding_off_column_data.isSelected, undefined, data.printScale))
            }
        }

        let totalRow = columnOption.find((x: any) => x.type === TableColumnPopupType.totalAmount)
        if (totalRow !== undefined) {
            if(totalRow.isSelected) {
                if (this.getIsShowTotal(tableStyle, TableColumnPopupType.amount)) {
                    newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amount, totalRow.name, data.printScale))
                }
            }
        }
        else {
            if (this.getIsShowTotal(tableStyle, TableColumnPopupType.amount)) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amount, 'total', data.printScale))
            }
        }

        let cogs = columnOption.find((x: any) => x.type === TableColumnPopupType.cogs)
        if (cogs && cogs.isSelected) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.cogs, cogs.name, cogs.isSelected))
        }

        let outstandingBalance = columnOption.find((x: any) => x.type === TableColumnPopupType.outstandingBalance)
        if (outstandingBalance !== undefined) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.outstandingBalance, outstandingBalance.name, outstandingBalance.isSelected, undefined, data.printScale))
        }
        else {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.outstandingBalance, 'outstanding_balance', false, undefined, data.printScale))
        }

        let outstandingBalanceIncludesCurrent = columnOption.find((x: any) => x.type === TableColumnPopupType.outstandingBalanceIncludesCurrent)
        if (outstandingBalanceIncludesCurrent !== undefined) {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.outstandingBalanceIncludesCurrent, outstandingBalanceIncludesCurrent.name, outstandingBalanceIncludesCurrent.isSelected, undefined, data.printScale))
        }
        else {
            newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.outstandingBalanceIncludesCurrent, 'outstanding_balance_includes_current', false, undefined, data.printScale))
        }

        if(getIsBillOrInvoice(data.type)) {
            if (ComponentManager.getIsContainsPayments(data)) {
                data.payments.forEach((element: any, index: number) => {
                    var type = getLocalisedText('payment_footer')
                    if (element.documentType !== undefined) {
                        if (element.documentType.toLowerCase() !== 'make_payment' && element.documentType.toLowerCase() !== 'receive_payment') {
                            type = getLocalisedText(element.documentType.toLowerCase())
                        }
                    }
                    var documentCode = element.documentCode
                    if (element.linkedDocumentSequence && element.linkedDocumentSequence !== null) {
                        documentCode = element.linkedDocumentSequence
                    }
                    newFooterColumn.push(this.setFooterHeader(tableStyle, TableColumnPopupType.paymentFooter, type + ', ' + documentCode, true, index, data.printScale))
                });
            }

            if (ComponentManager.getIsContainsPayments(data)) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.outstandingAmount, 'outstanding', data.printScale))
            }
        }

        let amount_in_words_column_data_list = columnOption.filter((obj: any) => obj.type === TableColumnPopupType.amountInWords)
        if (amount_in_words_column_data_list.length > 0) {
            let amount_in_words_column_data = amount_in_words_column_data_list[0]
            if (amount_in_words_column_data.isSelected) {
                newFooterColumn.push(this.setLastFooterHeader(tableStyle, TableColumnPopupType.amountInWords, amount_in_words_column_data.name, data.printScale))
            }
        }

        //reorder footer list if contains global discount
        var globalDiscount = newFooterColumn.find(x => x.name === TableColumnPopupType.globalDiscount)
        var globalDiscountList = newFooterColumn.filter(x => x.name === TableColumnPopupType.globalDiscount)
        if(globalDiscount !== undefined) {
            var tempList = newFooterColumn.filter(x => x.name !== TableColumnPopupType.globalDiscount)
            var subTotalIndex = newFooterColumn.map(x => x.name).indexOf('subtotal')
            var newTempList: HeaderColumn[] = []
            var globalDiscountIndex = 0

            var isDefaultGlobalDiscount = false
            if (globalDiscountList.length === 1) {
                const defaultPreTaxString = getLocalisedText('global_discount') + ' (' + getLocalisedText('pre_tax') + ')'
                const defaultPostTaxString = getLocalisedText('global_discount') + ' (' + getLocalisedText('post_tax') + ')'
                if (globalDiscountList[0].label === defaultPreTaxString || globalDiscountList[0].label === defaultPostTaxString) {
                    isDefaultGlobalDiscount = true

                    let globalDiscountColumnOption = columnOption.find((x: any) => x.type === TableColumnPopupType.globalDiscount)
                    if(globalDiscountColumnOption) {
                        const label = globalDiscountColumnOption.name ?? undefined
                        if (isDefaultGlobalDiscount && label) {
                            if (label !== getLocalisedText('global_discount') && label !==  'global_discount') {
                                globalDiscount.label = label

                                globalDiscountList.forEach(element => {
                                    element.label = label
                                });
                            }
                        }
                    }
                }
            }


            if (globalDiscount.label.includes(getLocalisedText('pre_tax'))) {
                if(subTotalIndex !== 0) {
                    globalDiscountIndex = subTotalIndex - 1
                }
            }
            else {
                if (subTotalIndex < tempList.length -1 ) {
                    globalDiscountIndex = subTotalIndex + 1
                }
            }

            tempList.forEach(((element: any, index: number) => {
                if (index === globalDiscountIndex) {
                    newTempList = [...newTempList, ...globalDiscountList]
                }
                newTempList.push(element)
            }))

            if(newTempList.length > 0) {
                newFooterColumn = newTempList
            }

        }

        //add handling to remove duplicated data 
        var newNonDuplicatedFooterColumnList: any[] = []
        var roundingOffKey = "Rounding Off";
        newFooterColumn.forEach((element) => {
            if(element.name === roundingOffKey) {
                var roundingOffItem = newNonDuplicatedFooterColumnList.find(x => x.name === roundingOffKey)

                if(roundingOffItem === undefined) {
                    newNonDuplicatedFooterColumnList.push(element)
                }
            }
            else {
                newNonDuplicatedFooterColumnList.push(element);
            }
        });

        this.footerColumnList = newNonDuplicatedFooterColumnList;
        return newNonDuplicatedFooterColumnList;
    }

    static getFooterData(documentData: any) {
        var footerValue: string[] = []
        var dummyData = ['0.00', '0.00', '0.00', '$ 0.00']
        var currency = ''
        if (documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            currency = NumberUtility.getCurrency(documentData)
            if (documentData.lineItems === undefined || documentData.lineItems === null) {
                return dummyData
            }
            if (documentData.lineItems.length === 0) {
                return dummyData
            }
            var roundingOffValue = 0
            var totalTaxAmount = 0

            if (documentData.roundingOff && documentData.roundingOff !== null) {
                roundingOffValue = documentData.roundingOff
            }
            if (ComponentManager.getIsContainTaxBreakdown(documentData)) {
                this.footerColumnList.forEach(element => {
                    var value = ''
                    documentData.taxBreakdown.forEach((tax: { taxName: string, taxAmount: any, taxId: string }) => {
                        if (element.name === tax.taxName) {
                            var formattedValue = NumberUtility.getFormattedValue(tax.taxAmount, AppManager.getNumberFormat(), AppManager.getDecimalScale())
                            if (isNaN(formattedValue)) {
                                formattedValue = 0
                                if (element.name === 'IGST' || element.name === 'CGST' || element.name === 'SGST' ) {
                                    formattedValue = IndiaTaxParser.getIndiaTaxFooterByType(documentData, element.name)
                                }
                            }
                            value = Utility.toCurrencyFormat(formattedValue, currency)
                            totalTaxAmount = totalTaxAmount + formattedValue
                        }
                    });
                    if(this.getIsContainsAdditionalCharge(documentData)) {
                        documentData.additionalCharge.forEach((tax: { taxName: string, taxAmount: any, taxId: string, isDefault: boolean }) => {
                            if (element.name === tax.taxId) {
                                var formattedValue = NumberUtility.getFormattedValue(tax.taxAmount, AppManager.getNumberFormat(), AppManager.getDecimalScale())
                                if (isNaN(formattedValue)) {
                                    formattedValue = 0
                                }
                                value = Utility.toCurrencyFormat(formattedValue, currency)
                                if (value && element?.name === 'globalDiscount' && (tax?.taxName === element?.label || tax?.isDefault)) {
                                    footerValue.push(value)
                                }
                            }
                        });
                    }

                    if (value === '') {
                        footerValue.push(this.getFooterValue(element, documentData, roundingOffValue, currency))
                    }
                    else if(element?.name !== 'globalDiscount') {
                        footerValue.push(value)
                    }
                });

                //if tax amount value > total amount - mean invalid decimal scale
                if(!this.isValidTaxBreakdownAmount(documentData, roundingOffValue, totalTaxAmount)) {
                    footerValue = []
                    this.footerColumnList.forEach(element => {
                        var value = ''
                        documentData.taxBreakdown.forEach((tax: { taxName: string, taxAmount: any, taxId: string }) => {
                            if (element.name === tax.taxName) {
                                var formattedValue = NumberUtility.getFormattedValue(tax.taxAmount, AppManager.getNumberFormat(), AppManager.getTenantDecimalScale())
                                if (isNaN(formattedValue)) {
                                    formattedValue = 0
                                    if (element.name === 'IGST' || element.name === 'CGST' || element.name === 'SGST') {
                                        formattedValue = IndiaTaxParser.getIndiaTaxFooterByType(documentData, element.name)
                                    }
                                }
                                value = Utility.toCurrencyFormat(formattedValue, currency)
                                if(formattedValue !== undefined && formattedValue !== null) {
                                    if (typeof formattedValue == 'number') {
                                        totalTaxAmount = totalTaxAmount + formattedValue
                                    }
                                }
                            }
                        });

                        if (this.getIsContainsAdditionalCharge(documentData)) {
                            documentData.additionalCharge.forEach((tax: { taxName: string, taxAmount: any, taxId: string, isDefault: boolean }) => {
                                if (element.name === tax.taxId) {
                                    var formattedValue = NumberUtility.getFormattedValue(tax.taxAmount, AppManager.getNumberFormat(), AppManager.getDecimalScale())
                                    if (isNaN(formattedValue)) {
                                        formattedValue = 0
                                    }
                                    value = Utility.toCurrencyFormat(formattedValue, currency)
                                    if (value && element?.name === 'globalDiscount' && (tax?.taxName === element?.label || tax?.isDefault)) {
                                        footerValue.push(value)
                                    }
                                }
                            });
                        }
                        if (value === '') {
                            footerValue.push(this.getFooterValue(element, documentData, roundingOffValue, currency))
                        }
                        else if(element?.name !== 'globalDiscount') {
                            footerValue.push(value)
                        }
                    });
                }
            }
            else {
                this.footerColumnList.forEach(element => {
                    var value = ''
                    if (this.getIsContainsAdditionalCharge(documentData)) {
                        documentData.additionalCharge.forEach((tax: { taxName: string, taxAmount: any, taxId: string, isDefault: boolean }) => {
                            if (element.name === tax.taxId) {
                                var formattedValue = NumberUtility.getFormattedValue(tax.taxAmount, AppManager.getNumberFormat(), AppManager.getDecimalScale())
                                if (isNaN(formattedValue)) {
                                    formattedValue = 0
                                }
                                value = Utility.toCurrencyFormat(formattedValue, currency)
                                if(value && element?.name === 'globalDiscount' && (tax?.taxName === element?.label || tax?.isDefault)) {
                                    footerValue.push(value)
                                }
                            }
                        });
                    }
                    if (value === '') {
                        footerValue.push(this.getFooterValue(element, documentData, roundingOffValue, currency))
                    } else if(element?.name !== 'globalDiscount') {
                        footerValue.push(value)
                    }
                    
                });
            }

            return footerValue
        }
    }

    static isValidTaxBreakdownAmount(data: any, roundingOffValue: number, taxAmount: number) {
        var total = ComponentManager.getTotalOfLineItems(data.lineItems, false, false, roundingOffValue)
        if (data.total !== undefined) {
            total = data.total
        }

        var subTotal = ComponentManager.getTotalOfLineItems(data.lineItems, false, true, 0)

        var taxOrDiscount = total - subTotal

        if(taxAmount > taxOrDiscount) {
            return false
        }
        return true
    }

    static getFooterValue(element: HeaderColumn, data:any, roundingOffValue: number, currency: string) {
        switch (element.name) {
            case 'subtotal':
                var defaultSubtotal = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(data.lineItems, false, true, 0), currency)
                if (data.subTotal !== undefined && data.subTotal !== null) {
                    defaultSubtotal = Utility.toCurrencyFormat(data.subTotal, currency)
                }
                return defaultSubtotal
            case TableColumnPopupType.discount:
                var defaultDiscount = Utility.toCurrencyFormat(ComponentManager.getDiscountOnLineItems(data.lineItems), currency)
                if (data.discount !== undefined && data.discount !== null) {
                    defaultDiscount = Utility.toCurrencyFormat(data.discount, currency)
                }
                return defaultDiscount
            case TableColumnPopupType.tax:
                var defaultTax = Utility.toCurrencyFormat(ComponentManager.getTaxOnLineItems(data.lineItems), currency)
                if (data.taxAmount !== undefined && data.taxAmount !== null) {
                    defaultTax = Utility.toCurrencyFormat(data.taxAmount, currency)
                }
                return defaultTax
            case TableColumnPopupType.amount:
                var total = Utility.toCurrencyFormat(ComponentManager.getTotalOfLineItems(data.lineItems, false, false, roundingOffValue), currency)
                if(data.total !== undefined && data.total !== null) {
                    total = Utility.toCurrencyFormat(data.total, currency)
                }
                return total
            case TableColumnPopupType.cogs:
                var cogs = getLocalisedText('na')
                if(data.cogs !== null && data.cogs !== undefined) {
                    cogs = Utility.toCurrencyFormat(data.cogs, currency)
                }
                return cogs
            case TableColumnPopupType.totalTaxAmount:
                var totalTaxAmount = 0
                if (data.totalTaxAmount !== undefined) {
                    totalTaxAmount = data.totalTaxAmount
                }
                return Utility.toCurrencyFormat(totalTaxAmount, currency)
            case TableColumnPopupType.roundingOff:
                var roundingOff = 0
                if (data.roundingOff !== undefined) {
                    roundingOff = data.roundingOff
                }
                return Utility.toCurrencyFormat(roundingOff, currency)
            case TableColumnPopupType.amountInWords:
                return data.amountInWords ? data.amountInWords : ''
            case TableColumnPopupType.paymentFooter:
                var payment = ''
                if(ComponentManager.getIsContainsPayments(data)) {
                    if (element.index !== undefined) {
                        if (data.payments[element.index] !== undefined && data.payments[element.index] !== null && data.payments[element.index]) {
                            currency = Utility.getCurrencySymbolFromCode(data.payments[element.index].currency)
                        }
                        payment = Utility.toCurrencyFormat(data.payments[element.index].amount, currency)
                    }
                }
                return payment
            case TableColumnPopupType.beforeTax:
                var beforeTax = ComponentManager.getTotalOfLineItems(data.lineItems, false, true, 0) - ComponentManager.getDiscountOnLineItems(data.lineItems)
                var defaultBeforeTax = Utility.toCurrencyFormat(beforeTax, currency)
                if (data.amountBeforeTax !== undefined && data.amountBeforeTax !== null) {
                    defaultBeforeTax = Utility.toCurrencyFormat(data.amountBeforeTax, currency)
                }
                return defaultBeforeTax
            case TableColumnPopupType.outstandingAmount:
                var due = Utility.toCurrencyFormat(ComponentManager.getOutstandingAmount(data, roundingOffValue), currency)
                if(data.due !== undefined) {
                    due = Utility.toCurrencyFormat(data.due, currency)
                }
                return due
            case TableColumnPopupType.outstandingBalance:
                var outstandingBalance = ''
                if (data.outstandingBalance !== undefined) {
                    outstandingBalance = Utility.toCurrencyFormat(data.outstandingBalance, currency)
                }
                return outstandingBalance
            case TableColumnPopupType.outstandingBalanceIncludesCurrent:
                var outstandingBalanceIncludesCurrent = ''

                if (data.outstandingBalanceIncludesCurrent !== undefined && data.outstandingBalanceIncludesCurrent !== null) {
                    outstandingBalanceIncludesCurrent = Utility.toCurrencyFormat(data.outstandingBalanceIncludesCurrent, currency)
                }
                // var currentTotal = ComponentManager.getTotalOfLineItems(data.lineItems, false, false, roundingOffValue)
                // if (data.total !== undefined) {
                //     currentTotal = data.total
                // }
                // if (data.outstandingBalance !== undefined) {
                //     outstandingBalanceIncludesCurrent = data.outstandingBalance + currentTotal
                // }
                // if (outstandingBalanceIncludesCurrent !== '') {
                //     outstandingBalanceIncludesCurrent = Utility.toCurrencyFormat(outstandingBalanceIncludesCurrent, currency)
                // }
                // else if (currentTotal !== undefined) {
                //     outstandingBalanceIncludesCurrent = Utility.toCurrencyFormat(currentTotal, currency)
                // }

                return outstandingBalanceIncludesCurrent
            default:
                return ''
        }
    }

    static setFooterHeader(tableStyle: any, type: string, name: string, isShowRecord: boolean, index?: number, printScale?: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = name
        newFooterColumn.isShowRecord = isShowRecord
        newFooterColumn.index = index
        return newFooterColumn
    }
    static getDefaultFooterHeaderColumn(tableStyle: any, printScale: number = PRINT_SCALE) {
        var newFooterHeaderColumn = new HeaderColumn()
        newFooterHeaderColumn.type = 'string'
        newFooterHeaderColumn.style = new ColumnStyle()
        if (tableStyle.subTotalBorderColor !== undefined && tableStyle.subTotalBorderColor !== null) {
            newFooterHeaderColumn.style.borderColor = tableStyle.subTotalBorderColor
        }
        else {
            newFooterHeaderColumn.style.borderColor = tableStyle.itemBorderColor
        }

        newFooterHeaderColumn.style.fontColor = tableStyle.itemTextColor
        newFooterHeaderColumn.style.borderStyle = 'solid'
        newFooterHeaderColumn.style.borderWidth = getVW(Utility.convertToPrintScale(1, printScale))
        newFooterHeaderColumn.style.paddingTop = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingBottom = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_VERTICAL)
        newFooterHeaderColumn.style.paddingLeft = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.paddingRight = getTablePadding(getVW(Utility.convertToPrintScale(11, printScale)), TableDimensionType.PADDING_HORIZONTAL)
        newFooterHeaderColumn.style.fontSize = Utility.getIsReceiptPaperSize() ? FONT_SIZE.large : FONT_SIZE.regular
        // newFooterHeaderColumn.style.alignment = 'right'
        return newFooterHeaderColumn
    }

    static setLastFooterHeader(tableStyle: any, type:string, name: string, printScale: number) {
        var newFooterColumn = this.getDefaultFooterHeaderColumn(tableStyle, printScale)
        newFooterColumn.name = type
        newFooterColumn.label = getLocalisedText(name)
        newFooterColumn.style.backgroundColor = tableStyle.footerBackgroundColor
        newFooterColumn.style.fontColor = tableStyle.footerTextColor
        newFooterColumn.style.borderColor = tableStyle.footerBorderColor
        newFooterColumn.style.fontWeight = 'bold'
        if (type === TableColumnPopupType.outstandingAmount || type === TableColumnPopupType.amountInWords) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent') {
                if (tableStyle.footerBackgroundColor === undefined) {
                    newFooterColumn.style.fontColor = undefined
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    newFooterColumn.style.fontColor = undefined
                }
            }
        }

        if (tableStyle.lastRowTopColor !== undefined) {
            newFooterColumn.style.borderTopColor = tableStyle.lastRowTopColor
            newFooterColumn.style.borderTopWidth = tableStyle.lastRowTopWidth
        }
        if (tableStyle.lastRowBottomColor !== undefined) {
            newFooterColumn.style.borderBottomColor = tableStyle.lastRowBottomColor
            newFooterColumn.style.borderBottomWidth = tableStyle.lastRowBottomWidth
        }

        return newFooterColumn
    }

    static getIsShowTotal(tableStyle: any, type: string) {
        if(type === TableColumnPopupType.amount) {
            if (tableStyle.footerTextColor === 'white' || tableStyle.footerTextColor === 'transparent'){
                if (tableStyle.footerBackgroundColor === undefined) {
                    return false
                }
                if (tableStyle.footerBackgroundColor === 'transparent') {
                    return false
                }
            }
        }
        return true
    }
    
    static getStockHeaderList(tableStyle: any, rawData: any) {
        var stockHeaderList = ['products', 'batch_no', 'expiry', 'qty']
        var stockHeaderColumnList: HeaderColumn[] = []
        stockHeaderList.forEach(element => {
            var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, true, rawData.printScale)
            newHeaderColumn = this.updateStockHeaderColumn(newHeaderColumn, element)
            stockHeaderColumnList.push(newHeaderColumn)
        });
        return stockHeaderColumnList
    }

    static getPaymentHeaderList(tableStyle: any, rawData: any) {
        var stockHeaderList = ['payment']
        var stockHeaderColumnList: HeaderColumn[] = []
        stockHeaderList.forEach(element => {
            var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, true, rawData.printScale)
            newHeaderColumn = this.updateStockHeaderColumn(newHeaderColumn, element)
            stockHeaderColumnList.push(newHeaderColumn)
        });
        return stockHeaderColumnList
    }

    static updateStockHeaderColumn(stockHeaderColumn: HeaderColumn, value: string) {
        stockHeaderColumn.columnOption.isShowColumn = true
        stockHeaderColumn.name = value
        stockHeaderColumn.label = getLocalisedText(value)
        stockHeaderColumn.style.fontSize = FONT_SIZE.xSmall

        switch (value) {
            case 'products':
                stockHeaderColumn.style.alignment = 'left'
                stockHeaderColumn.style.width = '50%'
                break;
            case 'batch_no':
                stockHeaderColumn.style.alignment = 'left'
                stockHeaderColumn.style.width = '20%'
                break;
            case 'expiry':
                stockHeaderColumn.style.alignment = 'left'
                stockHeaderColumn.style.width = '20%'
                break;
            case 'qty':
                stockHeaderColumn.style.alignment = 'center'
                stockHeaderColumn.style.width = '10%'
                break;
            case 'payment':
                stockHeaderColumn.style.alignment = 'left'
                stockHeaderColumn.style.width = '100%'
                break;
            default:
                break;
        }

        return stockHeaderColumn
    }

    static getStockData(documentData:any) {
        var stockItems: string[][] = []
        var dummyData = [
            ['Stock A', 'T087231', '31/12/2002', '4'],
            ['Stock B', 'T087231', '31/12/2002', '4'],
            ['Stock C', 'T087231', '31/12/2002', '4'],
            ['Stock D', 'T087231', '31/12/2002', '4'],
        ]
        if(documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            if (documentData.stockItems === undefined || documentData.stockItems === null ) {
                return []
            }
            if (documentData.stockItems.length === 0) {
                return []
            }

            documentData.stockItems.forEach((element: any) => {
                stockItems.push(new BatchLineItem(element).toString())
            });
            return stockItems
        }
    }

    static getPaymentData(documentData:any) {
        var paymentItems: string[][] = []
        var dummyData = [
            ['www.google.com'],
        ]
        if(documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            if (documentData.paymentItems === undefined || documentData.paymentItems === null ) {
                return []
            }
            if (documentData.paymentItems.length === 0) {
                return []
            }

            documentData.paymentItems.forEach((element: any) => {
                paymentItems.push(new PaymentLineItem(element).toString())
            });
            return paymentItems
        }
    }

    static onRowChange(data: any, rowIndex: number, action: string, onDataChangeCallback: any) {
        if(data === undefined || data === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems === undefined || data.lineItems === null) {
            onDataChangeCallback(data)
        }

        if (data.lineItems.length === 0 || data.lineItems.length === 1) {
            onDataChangeCallback(data)
        }

        var newArray: any[] = []
        data.lineItems.forEach((element: any,index: number) => {
            if(index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if(action === 'up') {
            newIndex = rowIndex - 1
            if(newIndex <= 0) {
                newIndex = 0
            }
        }
        else if(action === 'down'){
            newIndex = rowIndex + 1
            if (newIndex >= data.lineItems.length) {
                newIndex = data.lineItems.length -1
            }
        }
        var selectedItem = data.lineItems[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            element.lineNumber = index + 1
        });
        data.lineItems = newArray
        onDataChangeCallback(data)
    }

    static getFooterRow(data: any, tableColumnOptions: TableOption, tableStyle: any, isSkipAdditionalCharge: boolean) {
        var keyValue: FooterRow[] = []
        var footerColumn = this.getFooterColumn(data, tableColumnOptions, tableStyle, isSkipAdditionalCharge)
        var footerData = this.getFooterData(data)

        if (footerData === undefined || footerData === null) {
            return []
        }
        if (footerData.length === 0) {
            return []
        }
        if (footerColumn === undefined || footerColumn === null) {
            return []
        }

        if(getIsRequestForQuotation(data.type)) {
            return []
        }
        var maxSize = footerColumn.length > footerData.length ? footerColumn.length : footerData.length

        for (let index = 0; index < maxSize; index++) {
            if (footerColumn.length > index) {
                var key = footerColumn[index]
                if (key !== undefined && key !== null) {
                    var newFooterRow = new FooterRow()
                    newFooterRow.header = key
                    newFooterRow.isSelected = key.isShowRecord
                    if (footerData.length > index) {
                        var value = footerData[index]
                        if (value !== undefined && value !== null) {
                            newFooterRow.value = value
                        }
                        keyValue.push(newFooterRow)
                    }
                }
            }
        }
        if (data.footerRow !== undefined) {
            var newArray: FooterRow[] = []
            data.footerRow.forEach((newFooter: FooterRow) => {
                keyValue.forEach(element => {
                    if (newFooter.header.name === element.header.name) {
                        var footer = new FooterRow()
                        footer.header = element.header
                        footer.isSelected = element.isSelected
                        footer.value = element.value
                        newArray.push(footer)
                    }
                });
            });
            keyValue = newArray
        }
        return keyValue
    }

    static onUpdateTableWidth(header: HeaderColumn, percentage: number, data: any, columnOptions: any, isAccountProduct: boolean = false, callback: any) {
        if(columnOptions === undefined || columnOptions === null) {
            return
        }

        if (columnOptions.length === 0) {
            return
        }

        var dataColumn = columnOptions.filter((x: any) => x.type !== TableColumnPopupType.discount || x.type !== TableColumnPopupType.tax)
        var isSelectedCount = dataColumn.filter((x: any) => x.isSelected).length - 1
        var offsetPercentage = percentage / isSelectedCount

        var newColumnOptions: any[] = []
        columnOptions.forEach((element: any) => {
            if(element.isSelected) {
                var item = element
                var width = element.width
                if (element.type === TableColumnPopupType.discount || element.type === TableColumnPopupType.tax) {
                    item.width = '0%'
                }
                else if (element.type === header.name && header.code === element.code) {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) + percentage
                        item.width = width + '%'
                    }
                }
                else {
                    if (element.width !== undefined && element.width !== null) {
                        width = Number(element.width.replace('%', '')) - offsetPercentage
                        item.width = width + '%'
                    }
                }
                newColumnOptions.push(item)
            }
            else {
                if (element.width !== undefined) {
                    element.width = '0%'
                }
                newColumnOptions.push(element)
            }
        });

        if(isAccountProduct) {
            TemplateSettingsManager.updateAccountProductTable(newColumnOptions);
            callback(data)
        }
        else {
            TemplateSettingsManager.updateTablePopupInfo(newColumnOptions);
            var newData = data;
            newData.tableColumnPopup = newColumnOptions;
            callback(newData);
        }
    }

    static getIsContainsAdditionalCharge(documentData: any) {
        if (documentData.additionalCharge !== undefined && documentData.additionalCharge !== null) {
            if (documentData.additionalCharge.length > 0) {
                return true
            }
        }

        return false
    }

    static getAccountProductData(documentData: any) {
        var lineItems: string[][] = []
        var dummyData = [
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
            [getLocalisedText('item_name'), getLocalisedText("item_description"), '1', '0.00', '0.00'],
        ]
        if(documentData === undefined || documentData === null) {
            return dummyData
        }
        else {
            var currency = NumberUtility.getCurrency(documentData)
            if (documentData.productLineItems === undefined || documentData.productLineItems === null ) {
                return dummyData
            }
            if (documentData.productLineItems.length === 0) {
                return [];
            }
            var sortedItems = getSortedLineItems(documentData.productLineItems);

            //processing grouping for the for product attribute
            var groupItems = this.getGroupData(sortedItems, documentData, currency, Utility.getTableColumnForAccountProduct() )

            groupItems.forEach((element: any) => {
                lineItems.push(element)
            });

            //add total section
            if (getIsSelectTotalColumn()) {
                lineItems.push(new ProductTableTotalColumn(sortedItems, documentData, currency).toString())
            }

            return lineItems
        }
    }

    static getProductGroupHeader(groupName: string, tableStyle: any, rawData: any) {
        var headerColumnList: HeaderColumn[] = []
        var newHeaderColumn = this.getDefaultHeaderColumn(tableStyle, false, rawData.printScale)
        newHeaderColumn.columnOption.isShowColumn = true
        newHeaderColumn.name = 'groupHeader'
        newHeaderColumn.label = getLocalisedText(groupName)
        newHeaderColumn.style.alignment = 'left'
        // newHeaderColumn = this.updateHeaderColumn(newHeaderColumn, new TableHeader(element), rawData, defaultTableColumnAlignmentList, tableColumnAlignmentList, tableColumnVerticalAlignmentList)
        headerColumnList.push(newHeaderColumn)

        return headerColumnList
    }
}

export class TableLineItem {
    name: string;
    quantity: string;
    description: string;
    unitPrice: string;
    tax: number;
    discount: number;
    rawItemValue: any;
    constructor(lineItem: any) {
        this.name = lineItem.name
        this.quantity = lineItem.quantity
        this.description = lineItem.description
        this.unitPrice = lineItem.unitPrice
        this.tax = lineItem.tax
        this.discount = lineItem.discount
        this.rawItemValue = lineItem
    }

    toString(): string[] {
        return [
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            parseFloat(this.quantity).toFixed(2),
            Utility.toCurrencyFormat(parseFloat(this.unitPrice) * 1),
            this.rawItemValue.priceInclusiveOfTax ?
                Utility.toCurrencyFormat(ComponentManager.getProductTaxInclusivePrice(this.rawItemValue))
                : Utility.toCurrencyFormat(parseFloat(this.quantity) * parseFloat(this.unitPrice)),
        ]
    }
}

export class BatchLineItem {
    productCode: string;
    productName: string;
    productDesc: string;
    serialBatchNumber: string;
    sequence: number;
    subSequence: number;
    expiryDate: string;
    manufacturingDate: string;
    quantity: string;
    rawItemValue: any;

    constructor(batchLineItem: any) {
        this.productCode = batchLineItem.productCode
        this.productName = batchLineItem.productName
        this.productDesc = batchLineItem.productDesc
        this.serialBatchNumber = batchLineItem.serialBatchNumber
        this.sequence = batchLineItem.sequence
        this.subSequence = batchLineItem.subSequence
        this.expiryDate = batchLineItem.expiryDate
        this.manufacturingDate = batchLineItem.manufacturingDate
        this.quantity = batchLineItem.quantity
        this.rawItemValue = batchLineItem
    }

    toString(): string[] {
        return [
            //To be shown
            this.productName,
            this.serialBatchNumber,
            this.expiryDate,
            parseFloat(this.quantity).toFixed(2),

            // //For reference
            // this.productCode,
            // this.productDesc,
            // this.sequence,
            // this.subSequence,
            // this.manufacturingDate,
            // this.rawItemValue,
        ]
    }
}

export class PaymentLineItem {
    paymentLink: string;
    rawItemValue: any;

    constructor(paymentLineItem: any) {
        this.paymentLink = paymentLineItem
        this.rawItemValue = paymentLineItem
    }

    toString(): string[] {
        return [ this.paymentLink ]
    }
}
