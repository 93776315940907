import {
  DOCUMENT_STATUS,
  DOC_TYPE,
  RECEIVED_GOODS_STATUS,
  DOCUMENT_MODE
} from '../../Constants/Constant';
import format from 'date-fns/format';
import { RequisitionOrder } from '../../Models/RequisitionOrder';
import { showAlert } from 'deskera-ui-library';
import {
  defaultAdditionalChargesObject,
  getTenantTaxSystem
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';
import { useEffect, useRef, useState } from 'react';
import { addDays, subDays } from 'date-fns';

export const reqEmailTemplateConfig: any = 'work in progress';
export const orderEmailTemplateConfig: any = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Order Date',
    action: 'orderDate'
  },
  {
    hidden: false,
    text: 'Order Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Currency Symbol',
    action: 'currencySymbol'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Order Number',
    action: 'orderNo'
  }
];

export const setItemsFromPurchaseOrderItems = (doc: any) => {
  const document = {
    ...doc,
    items: doc.purchaseRequestItems?.map((item: any) => {
      return {
        ...item,
        taxSystem: getTenantTaxSystem(),
        unitPriceGstInclusive: doc.unitPriceGstInclusive
      };
    }),
    contact: !Utility.isEmpty(doc.contactDto) ? doc.contactDto : doc.contact,
    documentType: DOC_TYPE.REQUISITION,
    documentDate: doc.documentDate,
    validTillDate: doc.orderDueDate,
    dueDate: doc.dueDate,
    fulfillmentDate: doc.receiveByDate,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject }
  };
  return document;
};
export const getRequisitionBody = (props: RequisitionOrder) => {
  let updatedObj = {
    currency: props.currency,
    id: props.id,
    purchaseRequestDate: props.purchaseRequestDate,
    purchaseRequestItems: props.purchaseRequestItems,
    shipByDate: props.shipByDate,
    taxAmount: props.taxAmount,
    totalAmount: props.totalAmount,
    unitPriceGstInclusive: props.unitPriceGstInclusive
  };
  return updatedObj;
};
export const getUpdatedPurchaseOrderObject = (props: RequisitionOrder) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.ORDER,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    documentCode: Utility.getValue(
      props.documentCode,
      props.purchaseRequestCode
    ),
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    buyType: Utility.getValue(props.buyType, props.orderType),
    orderType: Utility.getValue(props.buyType, props.orderType),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),
    validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),

    items: props.purchaseRequestItems?.map((item) => {
      return {
        ...item,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0
  };

  return updatedObj;
};

export const getUpdatedRequisitonOrderObject = (props: any) => {
  let updatedObj = {
    ...props,
    draftData: {
      draftType: DOCUMENT_MODE.EDIT
    },
    documentType: DOC_TYPE.REQUISITION,
    documentMode: DOCUMENT_MODE.EDIT,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    customField: props.customField,
    documentCode: Utility.getValue(
      props.documentCode,
      props.purchaseRequestCode
    ),
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    receiveByDate: Utility.getValue(props.receiveByDate, props.receiveByDate),

    buyType: Utility.getValue(props.buyType, props.orderType),
    orderType: Utility.getValue(props.buyType, props.orderType),
    status: DOCUMENT_STATUS.OPEN,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    linkedPurchasedOrders: props.linkedPurchasedOrders,
    dueDate: Utility.getValue(props.shipByDate, props.shipByDate),
    documentDate: Utility.getValue(
      props.purchaseRequestDate,
      props.purchaseRequestDate
    ),
    // dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    //orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.shipByDate, props.shipByDate),
    /* fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),*/
    fulfillmentDate: Utility.getValue(props.shipByDate, props.shipByDate),
    // validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    validTillDate: Utility.getValue(props.shipByDate, props.shipByDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),
    shipByDate: Utility.getValue(props.shipByDate, props.shipByDate),
    shipTo: props.shipTo,
    billTo: props.billTo,
    items: props.purchaseRequestItems?.map((item: any) => {
      return {
        ...item,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }),
    purchaseRequestItems: props.purchaseRequestItems?.map((item: any) => {
      return {
        ...item
      };
    }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0
  };

  updatedObj.requestedBy = null
  updatedObj.updatedBy = null
  updatedObj.createdBy = null
  return updatedObj;
};

export const getUpdatedRequisitonObjectForBulkOperation = (props: any) => {
  let updatedObj = {
    ...props,
    draftData: {
      draftType: DOCUMENT_MODE.EDIT
    },
    attachIds: Utility.isEmpty(props.attachments)
      ? []
      : props.attachments.map((attachment: any) => {
          const attachmentJson = JSON.parse(attachment);
          return attachmentJson.attachmentId;
        }),
    documentType: DOC_TYPE.REQUISITION,
    documentMode: DOCUMENT_MODE.EDIT,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    documentCode: Utility.getValue(
      props.documentCode,
      props.purchaseRequestCode
    ),
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    receiveByDate: Utility.getValue(props.receiveByDate, props.receiveByDate),

    buyType: Utility.getValue(props.buyType, props.orderType),
    orderType: Utility.getValue(props.buyType, props.orderType),
    status: DOCUMENT_STATUS.OPEN,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: Utility.getValue(props.shipByDate, props.shipByDate),
    documentDate: Utility.getValue(
      props.purchaseRequestDate,
      props.purchaseRequestDate
    ),
    // dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    //orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.shipByDate, props.shipByDate),
    /* fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),*/
    fulfillmentDate: Utility.getValue(props.shipByDate, props.shipByDate),
    // validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    validTillDate: Utility.getValue(props.shipByDate, props.shipByDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),
    shipByDate: Utility.getValue(props.shipByDate, props.shipByDate),
    shipTo: props.shipTo,
    billTo: props.billTo,
    items: props.purchaseRequestItems?.map((item: any) => {
      return {
        ...item,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }),
    purchaseRequestItems: props.purchaseRequestItems?.map((item: any) => {
      return {
        ...item
      };
    }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0
  };

  return updatedObj;
};

export const getUpdatedRequisitonNewOrderObject = (props: any) => {
  let updatedObj = {
    ...props,
    isDocumentTouched: true,
    draftData: {
      draftType: DOCUMENT_MODE.NEW
    },
    documentType: DOC_TYPE.REQUISITION,
    documentMode: DOCUMENT_MODE.NEW,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    documentCode: Utility.getValue(
      props.documentCode,
      props.purchaseRequestCode
    ),
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    receiveByDate: Utility.getValue(props.receiveByDate, props.receiveByDate),

    buyType: Utility.getValue(props.buyType, props.orderType),
    orderType: Utility.getValue(props.buyType, props.orderType),
    status: DOCUMENT_STATUS.OPEN,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: Utility.getValue(props.dueDate, props.dueDate) || '',
    documentDate: Utility.getValue(
      props.purchaseRequestDate,
      props.purchaseRequestDate
    ),
    // dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    //orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.shipByDate, props.shipByDate),
    /* fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),*/
    fulfillmentDate: Utility.getValue(props.shipByDate, props.shipByDate),
    // validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    validTillDate: Utility.getValue(props.shipByDate, props.shipByDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),
    shipByDate: Utility.getValue(props.shipByDate, props.shipByDate),
    shipTo: props.shipTo,
    billTo: props.billTo,
    items: props.purchaseRequestItems?.map((item: any) => {
      return {
        ...item,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }),
    purchaseRequestItems: props.purchaseRequestItems?.map((item: any) => {
      return {
        ...item
      };
    }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0
  };

  return updatedObj;
};

export const showConversionErrorMessage = (res: any) => {
  if (res.failure.length === 1) {
    showAlert(
      'Error!',
      `Failed to convert requisition${
        res.failure[0].errors?.length > 0
          ? ' - ' + res.failure[0].errors.join(', ')
          : ''
      }`
    );
  } else {
    showAlert(
      'Error!',
      `Failed to convert requisitions for <b>${res.failure
        .map((order: any) => order?.contact?.name)
        .join(', ')}</b>.` +
        (res?.success?.length > 0
          ? ` However converted to Purchase Order successfully for <b>${res.success
              .map((order: any) => order?.contact?.name)
              .join(', ')}</b>.`
          : '') +
        `<br/><br/><details>` +
        `<summary class="text-center text-blue-700 hover:text-blue-800 fs-r list-none cursor-pointer mb-2">View details</summary>` +
        `<table class="text-left">` +
        `<thead><tr><th class="px-2 border-t border-b">Supplier</th><th class="px-2 border-t border-b">Reason</th></tr></thead>` +
        `<tbody>` +
        res.failure
          .filter(
            (order: any) => order.contact?.name && order.errors?.length > 0
          )
          .map(
            (order: any) =>
              `<tr><td class="px-2 border-b">${
                order.contact.name
              }</td><td class="px-2 border-b">${order.errors.join(
                ', '
              )}</td></tr>`
          )
          .join('') +
        `</tbody>` +
        `</table>` +
        `</details>`
    );
  }
};

export const getUpdatedRFQObjectForEmail = (props: any) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    // documentCode: Utility.getValue(props.documentCode, props.poCode),
    documentCode: props.documentCode.split('-')[1],
    documentNumber: props.documentCode,
    documentSequenceCode: props.documentCode,
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    buyType: Utility.getValue(props.buyType, props.orderType),
    orderType: Utility.getValue(props.buyType, props.orderType),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),
    validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),

    items: props.items?.map((item: any) => {
      return {
        ...item,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0
  };

  return updatedObj;
};
