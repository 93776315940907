import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as formulajs from '@formulajs/formulajs'; // Import all functions from formulajs
import './ExpressionBuilder.css';
import { FORMULA_FUNCTIONS } from './formula-functions';
import {
  FormulaParameter,
  extractUsedParameters,
  evaluateExpression,
  getNonCFKeysValueForFormula
} from './formula-helper';
import { MODULES_NAME } from '../../Constants/Constant';

declare global {
  interface Window {
    formulajs: typeof formulajs; // Specify the type of formulajs
  }
}

interface ExpressionBuilderProps {
  initialExpression?: string; // Optional initial expression
  moduleName?: MODULES_NAME | string;
  canEvaluateExpression?: boolean; // New prop to control evaluation
  allParameters: FormulaParameter[]; // New prop for all parameters
  hideParameterOptions?: boolean; // New prop to hide parameter options
  onEvaluate?: (result: number | null) => void; // Callback to send result to parent
  formulaHasError?: (hasError: boolean, errorMessage?: string) => void; // New callback prop for error handling
  onExpressionUpdate?: (newExpression: string) => void; // New callback prop for expression updates
}

interface WordBoundary {
  node: Node | null;
  start: number;
  end: number;
}

// Function to sanitize the expression by wrapping parameters with ${}
const sanitizeExpression = (
  expression: string,
  parameters: FormulaParameter[]
): string => {
  const keys = parameters.map((param) => param.key);
  let pattern = keys
    .map((key) => key.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'))
    .join('|'); // Escape any special characters
  const regex = new RegExp(pattern, 'g'); // Create a regex to match parameter keys not followed by '('
  expression = parameters?.length
    ? expression.replace(regex, (match) => `\${${match}}`) // Wrap matched keys with ${}
    : expression;
  return expression.replace(/"/g, "'");
};

export const ExpressionBuilder: React.FC<ExpressionBuilderProps> = ({
  initialExpression = '',
  moduleName = '',
  canEvaluateExpression = true,
  onEvaluate,
  allParameters,
  hideParameterOptions = false,
  formulaHasError,
  onExpressionUpdate
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const [isValid, setIsValid] = useState(true);
  const [error, setError] = useState('');
  const [wordBoundary, setWordBoundary] = useState<WordBoundary>({
    node: null,
    start: 0,
    end: 0
  });
  const [selectedParameter, setSelectedParameter] = useState<string>('');
  const usedParametersRef = useRef<FormulaParameter[]>([]);
  const currentExpressionRef = useRef<string>('');
  const [finalCFObject, setFinalCFObject] = useState<any>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  // Function to update finalCFObject
  const updateFinalCFObject = () => {
    let tempObj: any = {};
    if (
      editorRef.current?.textContent &&
      editorRef.current?.textContent?.length > 2
    ) {
      tempObj['expression'] = sanitizeExpression(
        editorRef.current?.textContent || '',
        usedParametersRef.current
      ); // Use sanitized expression
      usedParametersRef.current.forEach((param) => {
        let pathArray: any[] = [];
        if (param.isCustomField) {
          if (param.isFromParent) {
            pathArray.push('parent');
          }
          pathArray = [...pathArray, 'customField', param.customFieldId];
        } else {
          const extraKeys = getNonCFKeysValueForFormula(moduleName);
          pathArray = extraKeys[`${param?.key}`]?.path;
        }

        tempObj['variablePath'] = {
          ...tempObj['variablePath'],
          [param.key]: pathArray
        };
      });
    }

    setFinalCFObject({ ...tempObj });
    // Call the new callback with the updated expression
    if (onExpressionUpdate) {
      onExpressionUpdate(JSON.stringify({ ...tempObj }, null, 2)); // Pass the current expression
    }
  };

  // Update usedParametersRef when usedParameters changes
  const setUsedParameters = (params: FormulaParameter[]) => {
    usedParametersRef.current = params; // Update the ref directly
    updateFinalCFObject(); // Call to update finalCFObject
  };

  // Set initial expression in the editor
  useEffect(() => {
    if (editorRef.current && initialExpression) {
      editorRef.current.textContent = initialExpression;
      const extractedParameters = extractUsedParameters(
        initialExpression,
        allParameters
      );
      setUsedParameters(extractedParameters); // Set used parameters based on the initial expression
      // Evaluate the expression based on canEvaluateExpression prop
      if (canEvaluateExpression) {
        const result = evaluateExpression(
          initialExpression,
          extractedParameters
        ); // Pass the used parameters
        if (initialExpression) {
          setIsValid(result.isValid);
          setError(result.errorMessage);
          formulaHasError?.(!result.isValid, result.errorMessage);
        }
        if (onEvaluate) {
          onEvaluate(result.value); // Send the result to the parent component
        }
      }
    } else {
      setIsValid(false);
      formulaHasError?.(true, '');
    }
  }, [initialExpression, canEvaluateExpression]);

  const getCurrentCursorPosition = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      // Commented for future reference, remove if testing goes fine
      // const editorRect = editorRef.current?.getBoundingClientRect();

      // if (editorRect) {
      //   return {
      //     top: rect.top - editorRect.top + rect.height,
      //     left: rect.left - editorRect.left
      //   };
      // }
      return {
        top: rect.top + window.scrollY + rect.height, // Adjusted to be relative to the document
        left: rect.left + window.scrollX // Adjusted to be relative to the document
      };
    }
    return { top: 0, left: 0 };
  };

  const getWordAtCursor = () => {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) return '';

    const range = selection.getRangeAt(0);
    const textNode = range.startContainer;

    if (textNode.nodeType !== Node.TEXT_NODE) return '';

    const text = textNode.textContent || '';
    const cursorPosition = range.startOffset;

    let startPos = cursorPosition;
    let endPos = cursorPosition;

    // Safely find start of word
    while (startPos > 0 && /[\w.]/.test(text[startPos - 1])) {
      startPos--;
    }

    // Safely find end of word
    while (endPos < text.length && /[\w.]/.test(text[endPos])) {
      endPos++;
    }

    // Store the text node reference along with positions
    setWordBoundary({
      node: textNode,
      start: startPos,
      end: endPos
    });

    return text.slice(startPos, cursorPosition);
  };

  const handleInput = () => {
    const word = getWordAtCursor();

    if (word.length > 0) {
      const functionSuggestions = FORMULA_FUNCTIONS.filter((f) =>
        f.name.toLowerCase().startsWith(word.toLowerCase())
      ).map((f) => f.name);

      // Get the text up to the cursor position
      const selection = window.getSelection();
      if (!selection || !selection.rangeCount) return;
      const range = selection.getRangeAt(0);
      const textBeforeCursor =
        range.startContainer.textContent?.slice(0, range.startOffset) || '';

      // Count unclosed parentheses to determine nesting level
      const openParens = (textBeforeCursor.match(/\(/g) || []).length;
      const closeParens = (textBeforeCursor.match(/\)/g) || []).length;
      const nestingLevel = openParens - closeParens;

      // Always show function suggestions at any level
      let suggestionsToShow = [...functionSuggestions];

      // Include all parameters in the suggestions, using contains for keys
      const allParamSuggestions = allParameters
        .filter(
          (p) =>
            p.name.toLowerCase().startsWith(word.toLowerCase()) || // Check if name contains the word
            p.key.toLowerCase().startsWith(word.toLowerCase()) // Check if key contains the word
        )
        .map((p) => `${p.name} (${p.key})`)
        .sort((a: string, b: string) =>
          a.toLowerCase() < b.toLowerCase() ? 1 : -1
        ); // Show name and key in parentheses
      suggestionsToShow = [...allParamSuggestions, ...suggestionsToShow];

      // Set suggestions and select the first suggestion by default
      setSuggestions(suggestionsToShow);

      setCursorPosition(getCurrentCursorPosition());
    } else {
      setSuggestions([]);
      setSuggestionIndex(0);
    }

    // Update the current expression ref
    currentExpressionRef.current = editorRef.current?.textContent || '';
    // Extract currently used parameters from the current expression
    const currentUsedParams = extractUsedParameters(
      currentExpressionRef.current,
      allParameters
    );

    // Update usedParametersRef to only include parameters that are still in use
    const updatedUsedParams = usedParametersRef.current.filter((param) =>
      currentUsedParams.some((currentParam) => currentParam.key === param.key)
    );

    setUsedParameters(updatedUsedParams); // Update used parameters state
    // setFinalCFObject(updatedUsedParams.length > 0 ? { /* ... construct finalCFObject based on updatedUsedParams ... */ } : {});

    // Evaluate the expression based on canEvaluateExpression prop
    if (canEvaluateExpression) {
      const result = evaluateExpression(
        currentExpressionRef.current,
        updatedUsedParams
      ); // Pass the used parameters
      if (result) {
        if (currentExpressionRef.current) {
          setIsValid(result.isValid);
          setError(result.errorMessage);
          formulaHasError?.(!result.isValid, result.errorMessage);
        } else {
          setIsValid(false);
          setError('Formula is empty');
          formulaHasError?.(true, 'Formula is empty');
        }
        if (onEvaluate) {
          onEvaluate(result.value); // Send the result to the parent component
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (suggestions.length === 0) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSuggestionIndex((prev) => {
        const newIndex = (prev + 1) % suggestions.length;
        scrollToSuggestion(newIndex); // Scroll to the new suggestion
        return newIndex;
      });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSuggestionIndex((prev) => {
        const newIndex = (prev - 1 + suggestions.length) % suggestions.length;
        scrollToSuggestion(newIndex); // Scroll to the new suggestion
        return newIndex;
      });
    } else if (e.key === 'Tab' || e.key === 'Enter') {
      e.preventDefault();
      const selectedSuggestion = suggestions[suggestionIndex];

      // Check if the selected suggestion is a function name or a parameter
      const isFunctionSuggestion = FORMULA_FUNCTIONS.some(
        (f) => f.name === selectedSuggestion
      );

      if (isFunctionSuggestion) {
        // Insert the function name as is
        insertSuggestion(selectedSuggestion, isFunctionSuggestion);
      } else {
        // Extract the key from the selected parameter suggestion
        const keyMatch = selectedSuggestion?.match(/\(([^)]+)\)/);
        if (keyMatch) {
          insertSuggestion(keyMatch[1], isFunctionSuggestion); // Insert only the key
        }
      }
      currentExpressionRef.current = editorRef.current?.textContent || '';
      validateExpression(currentExpressionRef.current);
      // Evaluate the expression based on canEvaluateExpression prop
      if (canEvaluateExpression) {
        const result = evaluateExpression(
          currentExpressionRef.current,
          usedParametersRef.current
        ); // Pass the used parameters
        if (currentExpressionRef.current) {
          setIsValid(result.isValid);
          setError(result.errorMessage);
          formulaHasError?.(!result.isValid, result.errorMessage);
        }
        if (onEvaluate) {
          onEvaluate(result.value); // Send the result to the parent component
        }
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setSuggestions([]);
      setSuggestionIndex(0);
    }
  };

  const scrollToSuggestion = (index: number) => {
    if (suggestionsRef.current) {
      const suggestionItems = suggestionsRef.current.children;
      const selectedItem = suggestionItems[index] as HTMLElement;
      if (selectedItem) {
        const { offsetTop, clientHeight } = selectedItem;
        const { scrollTop, clientHeight: containerHeight } =
          suggestionsRef.current;

        // Scroll the suggestion list to ensure the selected item is visible
        if (offsetTop < scrollTop) {
          suggestionsRef.current.scrollTop = offsetTop; // Scroll up
        } else if (offsetTop + clientHeight > scrollTop + containerHeight) {
          suggestionsRef.current.scrollTop =
            offsetTop + clientHeight - containerHeight; // Scroll down
        }
      }
    }
  };

  const insertSuggestion = (
    suggestion: string,
    isFunctionSuggestion: boolean
  ) => {
    if (!wordBoundary.node) return;

    const selection = window.getSelection();
    if (!selection) return;

    try {
      // Create a new range
      const range = document.createRange();

      // Safely set the range boundaries
      const nodeText = wordBoundary.node.textContent || '';
      const start = Math.min(wordBoundary.start, nodeText.length);
      const end = Math.min(wordBoundary.end, nodeText.length);

      range.setStart(wordBoundary.node, start);
      range.setEnd(wordBoundary.node, end);

      // Delete existing content and insert new suggestion
      range.deleteContents();
      const textNode = document.createTextNode(suggestion);
      range.insertNode(textNode);

      // Move cursor to end of inserted text
      const newRange = document.createRange();
      newRange.setStartAfter(textNode);
      newRange.setEndAfter(textNode);

      selection.removeAllRanges();
      selection.addRange(newRange);

      setSuggestions([]);
      setSuggestionIndex(0);

      // Update the used parameters state only if the key is a valid parameter
      const parameter = allParameters.find(
        (param) => param.key === suggestion.trim()
      );
      if (
        !isFunctionSuggestion &&
        parameter &&
        !usedParametersRef.current.some((p) => p.key === parameter.key)
      ) {
        setUsedParameters([...usedParametersRef.current, parameter]);
      }
    } catch (error) {
      console.error('Error inserting suggestion:', error);
    }
  };

  const validateExpression = (expr: string) => {
    try {
      const cleanExpr = expr.trim();
      if (!cleanExpr) {
        setUsedParameters([]);
        setIsValid(false);
        setError('Formula is empty');
        formulaHasError?.(true, 'Formula is empty');
      }

      let parenCount = 0;
      let inArray = false; // Track if we are inside an array
      const functionStack: {
        name: string;
        params: string[];
        currentParam: string;
      }[] = [];
      let currentFunction = '';
      let functionDetected = false; // Track if a function name has been detected

      for (let i = 0; i < cleanExpr.length; i++) {
        const char = cleanExpr[i];

        // Check for extra commas
        if (char === ',') {
          if (
            i === 0 ||
            cleanExpr[i - 1] === ',' ||
            cleanExpr[i - 1] === '(' ||
            cleanExpr[i - 1] === '['
          ) {
            throw new Error('Extra comma found');
          }
        }

        // Build function name when we see uppercase letters
        const functionName = FORMULA_FUNCTIONS.find((f) =>
          /[A-Z]/.test(char)
        )?.name; // Dynamically build function name
        if (functionName) {
          // Allow new function names at start of parameter or expression
          const lastFn = functionStack[functionStack.length - 1];
          if (!lastFn || !lastFn.currentParam.trim()) {
            currentFunction += char;
            // Check if the current function is a valid function from FORMULA_FUNCTIONS
            if (FORMULA_FUNCTIONS.some((f) => f.name === currentFunction)) {
              functionDetected = true; // Mark that a valid function name has been detected
            }
            continue;
          }
        }

        if (char === '(') {
          parenCount++;
          if (currentFunction) {
            const func = FORMULA_FUNCTIONS.find(
              (f) => f.name === currentFunction
            );
            if (!func) {
              throw new Error(`Unknown function: ${currentFunction}`);
            }
            functionStack.push({
              name: currentFunction,
              params: [],
              currentParam: ''
            });
            currentFunction = '';
            functionDetected = false; // Reset after function is confirmed
          }
        } else if (char === '[') {
          inArray = true; // We are now inside an array
          if (functionStack.length > 0) {
            const currentFn = functionStack[functionStack.length - 1];
            currentFn.currentParam += char; // Include the opening bracket
          }
        } else if (char === ']') {
          inArray = false; // We are now outside the array
          if (functionStack.length > 0) {
            const currentFn = functionStack[functionStack.length - 1];
            currentFn.currentParam += char; // Include the closing bracket
          }
        } else if (char === ',') {
          if (functionStack.length > 0) {
            const currentFn = functionStack[functionStack.length - 1];
            if (inArray) {
              // If we are inside an array, treat the comma as part of the array
              currentFn.currentParam += char;
            } else {
              // If we are not in an array, treat the comma as a parameter separator
              if (currentFn.currentParam.trim()) {
                currentFn.params.push(currentFn.currentParam.trim());
              }
              currentFn.currentParam = '';
            }
          }
        } else if (char === ')') {
          parenCount--;
          if (parenCount < 0) {
            throw new Error('Unexpected closing parenthesis');
          }
          if (functionStack.length > 0) {
            const currentFn = functionStack[functionStack.length - 1];

            if (currentFn.currentParam.trim()) {
              currentFn.params.push(currentFn.currentParam.trim());
            }

            const func = FORMULA_FUNCTIONS.find(
              (f) => f.name === currentFn.name
            );
            if (func) {
              const requiredParams = func.parameters.filter(
                (p) => p.required
              ).length;
              const maxParams = func.parameters.length;

              // Check if the function expects an array
              const arrayParamNames = func.parameters
                .filter((p) => p.type === 'number[]')
                .map((p) => p.name);

              // Validate parameters
              currentFn.params.forEach((param) => {
                // Recursively validate nested functions
                if (FORMULA_FUNCTIONS.some((f) => param.startsWith(f.name))) {
                  validateExpression(param); // Validate nested function
                }
              });

              if (currentFn.params.length < requiredParams) {
                throw new Error(
                  `${currentFn.name} requires at least ${requiredParams} parameter(s)`
                );
              }
              if (currentFn.params.length > maxParams) {
                throw new Error(
                  `${currentFn.name} accepts a maximum of ${maxParams} parameter(s)`
                );
              }

              // Validate array parameters
              currentFn.params.forEach((param, index) => {
                if (arrayParamNames.includes(func.parameters[index].name)) {
                  // Check if the parameter is a valid array
                  if (!param.startsWith('[') || !param.endsWith(']')) {
                    throw new Error(
                      `${currentFn.name} expects an array for parameter ${func.parameters[index].name}`
                    );
                  }

                  // Extract the elements from the array
                  const elements = param
                    .slice(1, -1)
                    .split(',')
                    .map((el) => el.trim());

                  // Validate each element in the array
                  elements.forEach((element) => {
                    // Check if the element is a number or a valid parameter
                    const isNumber = !isNaN(Number(element));
                    const isParameter = allParameters.some(
                      (commonParam) => commonParam.key === element.trim()
                    );

                    if (!isNumber && !isParameter) {
                      throw new Error(
                        `${currentFn.name} expects elements in the array to be numbers or valid parameters`
                      );
                    }
                  });
                }
              });
            }

            const completedFunction = `${
              currentFn.name
            }(${currentFn.params.join(',')})`;
            functionStack.pop();

            if (functionStack.length > 0) {
              functionStack[functionStack.length - 1].currentParam +=
                completedFunction;
            }
          }
        } else if (functionStack.length > 0) {
          functionStack[functionStack.length - 1].currentParam += char;
        }
      }

      if (parenCount > 0) {
        throw new Error('Unclosed parenthesis');
      }

      // Check for unclosed array brackets
      if (inArray) {
        throw new Error('Unclosed array bracket');
      }

      // Check for function names without parentheses
      if (
        currentFunction &&
        FORMULA_FUNCTIONS.find((f) => currentFunction === f.name)
      ) {
        throw new Error(
          `Function name "${currentFunction}" must have brackets 1`
        );
      }
      // console.log('functionDetected:', functionDetected, currentFunction, expr);
      // Check for any function names detected without parentheses
      if (functionDetected && !cleanExpr.includes('(')) {
        throw new Error(
          `Function name "${currentFunction}" is missing opening parentheses`
        );
      }

      setIsValid(true);
      setError('');
      formulaHasError?.(false, '');
      return true;
    } catch (err) {
      setIsValid(false);
      setError(String(err));
      formulaHasError?.(true, String(err));
      return false;
    }
  };

  const insertParameter = (key: string) => {
    if (!editorRef.current) return;

    const selection = window.getSelection();
    if (!selection) return;

    try {
      // Create a new range
      const range = document.createRange();
      const textNode = document.createTextNode(key);
      const currentNode = selection.focusNode;

      // Insert the parameter key at the cursor position
      if (currentNode) {
        range.setStart(currentNode, selection.focusOffset);
        range.insertNode(textNode);
      }

      // Move cursor to end of inserted text
      range.setStartAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);

      // Update the used parameters state only if the key is a valid parameter
      const parameter = allParameters.find((param) => param.key === key);
      if (
        parameter &&
        !usedParametersRef.current.some((p) => p.key === parameter.key)
      ) {
        setUsedParameters([...usedParametersRef.current, parameter]); // Add the parameter object
      }
    } catch (error) {
      console.error('Error inserting parameter:', error);
    }
  };

  const handleParameterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const key = event.target.value;
    setSelectedParameter(key);
    insertParameter(key); // Insert the selected parameter
  };

  return (
    <div className="expression-builder">
      {!hideParameterOptions && (
        <div className="dropdown-container">
          <select value={selectedParameter} onChange={handleParameterChange}>
            <option value="">Select a parameter</option>
            {allParameters.map((param) => (
              <option key={param.key} value={param.key}>
                {param.name} ({param.key})
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="editor-container">
        <div
          ref={editorRef}
          className={`expression-editor ${!isValid ? 'invalid' : 'is-valid'}`}
          contentEditable
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          spellCheck={false}
        />

        {suggestions.length > 0 &&
          ReactDOM.createPortal(
            <div
              ref={suggestionsRef}
              className="inline-suggestions"
              style={{
                top: `${cursorPosition.top + 5}px`,
                left: `${cursorPosition.left}px`,
                position: 'absolute',
                zIndex: 1000,
                maxHeight: 200, // Set a max height for scrolling
                overflowY: 'auto'
              }}
            >
              {suggestions.map((suggestion, index) => {
                const isSelected = index === suggestionIndex;
                // Check if the selected suggestion is a function name or a parameter
                const isFunctionSuggestion = FORMULA_FUNCTIONS.some(
                  (f) => f.name === suggestion
                );

                return (
                  <div
                    key={suggestion}
                    className={`suggestion-item ${
                      isSelected ? 'selected' : ''
                    }`}
                    // onMouseEnter={() => setSuggestionIndex(index)} // Set index on mouse hover
                    onClick={() => {
                      if (isFunctionSuggestion) {
                        // Insert the function name as is
                        insertSuggestion(suggestion, isFunctionSuggestion);
                      } else {
                        // Extract the key from the selected parameter suggestion
                        const keyMatch = suggestion.match(/\(([^)]+)\)/);
                        if (keyMatch) {
                          insertSuggestion(keyMatch[1], isFunctionSuggestion); // Insert only the key
                        }
                      }

                      // Update the current expression ref
                      currentExpressionRef.current =
                        editorRef.current?.textContent || '';
                      validateExpression(currentExpressionRef.current);
                      // Evaluate the expression based on canEvaluateExpression prop
                      if (canEvaluateExpression) {
                        const result = evaluateExpression(
                          currentExpressionRef.current,
                          usedParametersRef.current
                        ); // Pass the used parameters
                        if (currentExpressionRef.current) {
                          setIsValid(result.isValid);
                          setError(result.errorMessage);
                          formulaHasError?.(
                            !result.isValid,
                            result.errorMessage
                          );
                        }
                        if (onEvaluate) {
                          onEvaluate(result.value); // Send the result to the parent component
                        }
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {isFunctionSuggestion ? (
                      <>
                        <div
                          style={{
                            fontSize: '10px',
                            fontWeight: 600,
                            color: '#848181',
                            width: 15
                          }}
                        >
                          ⨍𝑛
                        </div>{' '}
                        {/* Indicator for function */}
                        <div style={{ fontSize: '13px' }}>
                          {suggestion}
                        </div>{' '}
                        {/* Function name */}
                      </>
                    ) : (
                      <>
                        <div style={{ fontStyle: 'normal', width: 15 }}></div>{' '}
                        {/* No indicator for parameters */}
                        <div style={{ fontSize: '13px' }}>
                          {suggestion}
                        </div>{' '}
                        {/* Parameter name */}
                      </>
                    )}
                  </div>
                );
              })}
            </div>,
            document.body // Render the portal into the body
          )}
      </div>
      {/* Below block is for debugging */}
      {false && (
        <div>
          {error && <div className="error-message">{error}</div>}

          <div className="used-parameters">
            <h4>Used Parameters:</h4>
            <pre>
              <code
                style={{
                  whiteSpace: 'pre-wrap',
                  fontSize: '12px',
                  color: '#333'
                }}
              >
                {JSON.stringify(finalCFObject, null, 2)}
              </code>
            </pre>
          </div>
        </div>
      )}
      {/* Debugging block ends */}
    </div>
  );
};
