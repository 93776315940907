import { OptionsType } from 'react-select';
import {
  CONTACT_FIELD_KEY_NAMES,
  DropdownSelectionType,
  FORM_ELEMENTS,
  STATUS_TYPE,
  DOCUMENT_MODE,
  PAYMENT_INFO_TYPE_OPTIONS,
  BANK_ACCOUNT_TYPE_OPTIONS,
  PAYMENT_FILE_FORMAT_OPTIONS
} from '../Constants/Constant';
import {
  BANK_ACCOUNT_TYPE,
  CONTACT_FORM_TAB,
  CREDIT_LIMIT_TYPE,
  PAYMENT_FILE_FORMAT,
  PAYMENT_INFO_TYPE
} from '../Constants/Enum';
import { CustomField } from './CustomField';
import {
  AddressType,
  CallBackPayloadType,
  CountryInfo,
  ReactSelectOptionsType
} from './Interfaces';
import { CalendarType } from './PaymentPopup';
export interface NewContactState {
  generalInfo: GeneralInfo;
  accounting: Accounting;
  addressInfo: Array<AddressInfo>;
  additonalInfo: AdditionalInfo;
  additionalPaymentInfo: AdditionalPaymentInfo;
  activeShippingAddressIndex: number;
  activeBillingAddressIndex: number;
  deducteeInfo: {
    showPopup: boolean;
    data: ReactSelectOptionsType<string, string>;
  };
  paymentInfo: {
    showPopup: boolean;
    data: ReactSelectOptionsType<string, string>;
  };
  activeMultiCurrencyList: any[];
  customFields: CustomField[];
  exchangeRate: string;
  activeTab: CONTACT_FORM_TAB;
}

export const initialContactFormValues: NewContactState = {
  generalInfo: {
    name: {
      key: CONTACT_FIELD_KEY_NAMES.NAME,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true,
      errorMsg: 'Contact Name cannot be left empty'
    },
    email: {
      key: CONTACT_FIELD_KEY_NAMES.EMAIL,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      errorMsg: 'Invalid email address'
    },
    countryNumberOptions: {
      hidden: true,
      value: null,
      selectionType: DropdownSelectionType.SINGLE,
      options: [],
      type: FORM_ELEMENTS.DROPDOWN,
      showDropdown: false
    },
    contactNumber: {
      key: CONTACT_FIELD_KEY_NAMES.MOBILE_NUMBER,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      errorMsg: ''
    },
    currency: {
      key: CONTACT_FIELD_KEY_NAMES.CURRENCY,
      hidden: false,
      value: 'INR',
      type: FORM_ELEMENTS.INPUT,
      hasError: false
    },
    numberingFormat: {
      key: CONTACT_FIELD_KEY_NAMES.NUMBERING_FORMAT,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      defaultValue: ''
    },
    panNumber: {
      key: CONTACT_FIELD_KEY_NAMES.PAN_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    contactUEN: {
      key: CONTACT_FIELD_KEY_NAMES.CONTACT_UEN,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    peppolID: {
      key: CONTACT_FIELD_KEY_NAMES.PEPPOL_ID,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    taxNumber: {
      key: CONTACT_FIELD_KEY_NAMES.TAX_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    vatRegistrationUae: {
      key: CONTACT_FIELD_KEY_NAMES.VAT_REGISTRATION_UAE,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    taxTreatmentUae: {
      key: CONTACT_FIELD_KEY_NAMES.TAX_TREATMENT_UAE,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    intercompanyType: {
      key: CONTACT_FIELD_KEY_NAMES.INTERCOMPANY_TYPE,
      hidden: false,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    ukBusinessRegistrationNo: {
      key: CONTACT_FIELD_KEY_NAMES.UK_BUSINESS_REGISTRATION_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    ukDefaultPurchaseTaxRate: {
      key: CONTACT_FIELD_KEY_NAMES.UK_DEFAULT_PURCHASE_TAX_RATE,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    ukDefaultSalesTaxRate: {
      key: CONTACT_FIELD_KEY_NAMES.UK_DEFAULT_SALES_TAX_RATE,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    ukVatIdentificationNo: {
      key: CONTACT_FIELD_KEY_NAMES.UK_VAT_IDENTIFICATION_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    otherEmails: {
      key: CONTACT_FIELD_KEY_NAMES.OTHER_EMAILS,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    salesTaxCodeIsrael: {
      key: CONTACT_FIELD_KEY_NAMES.SALES_TAX_RATE_ISRAEL,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    purchaseTaxCodeIsrael: {
      key: CONTACT_FIELD_KEY_NAMES.PURCHASE_TAX_RATE_ISRAEL,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    agent: {
      key: CONTACT_FIELD_KEY_NAMES.AGENT,
      hidden: false,
      value: [{ label: '', value: '' }],
      selectionType: DropdownSelectionType.MULTIPLE,
      type: FORM_ELEMENTS.DROPDOWN
    }
  },
  accounting: {
    paymentTerm: {
      key: CONTACT_FIELD_KEY_NAMES.PAYMENT_TERM,
      hidden: false,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    payableAccount: {
      key: CONTACT_FIELD_KEY_NAMES.PAYABLE_ACCOUNT,
      hidden: false,
      value: { label: '', value: '' },
      isMandatory: true,
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    receiveableAccount: {
      key: CONTACT_FIELD_KEY_NAMES.RECEIVEABLE_ACCOUNT,
      hidden: false,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      isMandatory: true,
      type: FORM_ELEMENTS.DROPDOWN
    },
    gstTreatment: {
      key: CONTACT_FIELD_KEY_NAMES.GST_TREATMENT,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    gstIn: {
      key: CONTACT_FIELD_KEY_NAMES.GST_IN,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    customerType: {
      key: CONTACT_FIELD_KEY_NAMES.CUSTOMER_TYPE,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    vendorType: {
      key: CONTACT_FIELD_KEY_NAMES.VENDOR_TYPE,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    customerTaxType: {
      key: CONTACT_FIELD_KEY_NAMES.CUSTOMER_TYPE,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    vendorTaxType: {
      key: CONTACT_FIELD_KEY_NAMES.VENDOR_TYPE,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    npwpNumber: {
      key: CONTACT_FIELD_KEY_NAMES.TAX_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    nikNumber: {
      key: CONTACT_FIELD_KEY_NAMES.NIK_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    exemptType: {
      key: CONTACT_FIELD_KEY_NAMES.TAX_EXEMPT,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    businessUnit: {
      key: CONTACT_FIELD_KEY_NAMES.BUSINESS_UNIT,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    attentionTo: {
      key: CONTACT_FIELD_KEY_NAMES.ATTENTION_TO,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    nonDeductionReasons: {
      key: CONTACT_FIELD_KEY_NAMES.NON_DEDUCTION_REASON,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    vendorTAN: {
      key: CONTACT_FIELD_KEY_NAMES.VENDER_TAN,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    deducteeType: {
      key: CONTACT_FIELD_KEY_NAMES.DEDUCTEE_TYPE,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    tdsRate: {
      key: CONTACT_FIELD_KEY_NAMES.TDS_RATE,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    certificateNumber: {
      key: CONTACT_FIELD_KEY_NAMES.CERTIFICATE_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: true
    },
    higherRate: {
      key: CONTACT_FIELD_KEY_NAMES.HIGHER_RATE,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    businessRegistrationNumber: {
      key: CONTACT_FIELD_KEY_NAMES.BUSINESS_RESGISTRATION_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    serviceTaxRegistrationNumber: {
      key: CONTACT_FIELD_KEY_NAMES.SERVICE_TAX_REGISTRATION_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    salesTaxRegistrationNumber: {
      key: CONTACT_FIELD_KEY_NAMES.SALES_TAX_REGISTRATION_NUMBER,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    customerTypeMalaysia: {
      key: CONTACT_FIELD_KEY_NAMES.CUSTOMER_TYPE_MALAYSIA,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    vendorTypeMalaysia: {
      key: CONTACT_FIELD_KEY_NAMES.VENDOR_TYPE_MALAYSIA,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    exemptionCriterialMalaysia: {
      key: CONTACT_FIELD_KEY_NAMES.EXEMPTION_CRITERIAL_MALAYSIA,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    // Israel Compliance
    taxPayerIdIsrael: {
      key: CONTACT_FIELD_KEY_NAMES.TAX_PAYER_ID_ISRAEL,
      hidden: true,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    taxCertificateValidityDateIsrael: { isOpen: false, hidden: true, date: '' },
    assessingOfficerNumberIsrael: {
      key: CONTACT_FIELD_KEY_NAMES.ASSESSING_OFFICER_NUMBER_ISRAEL,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    withHoldingTaxTypeIsraelId: {
      key: CONTACT_FIELD_KEY_NAMES.WITHHOLDING_TAX_TYPE_ID_ISRAEL,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    typeOfCompanyIsrael: {
      key: CONTACT_FIELD_KEY_NAMES.TYPE_OF_COMPANY_ISRAEL,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    withHoldingTaxPaymentGroupIsraelId: {
      key: CONTACT_FIELD_KEY_NAMES.WITHHOLDING_TAX_PAYMENT_GROUP_ID_ISRAEL,
      hidden: true,
      value: { label: '', value: '' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    withHoldingCertificatesIsrael: {
      hidden: true,
      value: []
    },
    israelBankDetails: { hidden: true, value: [] },
    israelGlobalBankDetails: { hidden: true, value: [] },
    // whtApplicableIsrael: {
    //   key: CONTACT_FIELD_KEY_NAMES.WHT_APPLICABLE_ISRAEL,
    //   hidden: true,
    //   value: false,
    //   hasError: false,
    //   isMandatory: false,
    //   type: FORM_ELEMENTS.TOGGLE
    // },
    whtApplicableIsrael: { isSelected: false, hidden: true },
    isIncludedTaxReportingAtSourceIsrael: { isSelected: false, hidden: true },
    isSelfInvoicingIsrael: { isSelected: false, hidden: true },
    // creditLimit
    creditLimit: {
      invoiceCreditLimitType: CREDIT_LIMIT_TYPE.IGNORE,
      quoteCreditLimitType: CREDIT_LIMIT_TYPE.IGNORE,
      salesOrderCreditLimitType: CREDIT_LIMIT_TYPE.IGNORE,
      invoiceSelected: false,
      quoteSelected: false,
      salesOrderSelected: false,
      creditLimitInput: {
        key: CONTACT_FIELD_KEY_NAMES.CREDIT_LIMIT,
        hidden: false,
        value: '0',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        isMandatory: false
      },
      showCreditLimitInfo: false,
      showConversionRate: false
    },
    // priceList
    purchasePriceLists: {
      key: '',
      hidden: false,
      value: [],
      selectionType: DropdownSelectionType.MULTIPLE,
      type: FORM_ELEMENTS.DROPDOWN
    },
    salesPriceLists: {
      key: '',
      hidden: false,
      value: [],
      selectionType: DropdownSelectionType.MULTIPLE,
      type: FORM_ELEMENTS.DROPDOWN
    }
  },
  addressInfo: [
    {
      contactName: {
        key: CONTACT_FIELD_KEY_NAMES.CONTACT_NAME,
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        defaultValue: '',
        isMandatory: false
      },
      address: {
        key: CONTACT_FIELD_KEY_NAMES.ADDRESS,
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        defaultValue: '',
        isMandatory: false
      },
      address2: {
        key: CONTACT_FIELD_KEY_NAMES.ADDRESS2,
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        defaultValue: '',
        isMandatory: false
      },
      state: {
        key: CONTACT_FIELD_KEY_NAMES.STATE,
        hidden: false,
        value: { label: '', value: '' },
        type: FORM_ELEMENTS.DROPDOWN,
        selectionType: DropdownSelectionType.SINGLE,
        hasError: false,
        isMandatory: false,
        errorMsg: ''
      },
      city: {
        key: CONTACT_FIELD_KEY_NAMES.CITY,
        hidden: false,
        value: { label: '', value: '' },
        type: FORM_ELEMENTS.DROPDOWN,
        selectionType: DropdownSelectionType.SINGLE,
        hasError: false,
        isMandatory: false,
        errorMsg: ''
      },
      country: {
        key: CONTACT_FIELD_KEY_NAMES.STATE,
        hidden: false,
        value: { label: '', value: '' },
        type: FORM_ELEMENTS.DROPDOWN,
        selectionType: DropdownSelectionType.SINGLE,
        isMandatory: false
      },
      postalCode: {
        key: CONTACT_FIELD_KEY_NAMES.POSTAL_CODE,
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        defaultValue: '',
        isMandatory: false
      },
      countryNumberOptions: {
        hidden: true,
        value: null,
        selectionType: DropdownSelectionType.SINGLE,
        options: [],
        type: FORM_ELEMENTS.DROPDOWN,
        showDropdown: false
      },
      contactNumber: {
        key: CONTACT_FIELD_KEY_NAMES.MOBILE_NUMBER,
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        errorMsg: '',
        isMandatory: true
      },
      email: {
        key: CONTACT_FIELD_KEY_NAMES.EMAIL,
        hidden: false,
        value: '',
        type: FORM_ELEMENTS.INPUT,
        hasError: false,
        errorMsg: 'Invalid email address'
      },
      customFields: []
    }
  ],
  additonalInfo: {
    exemptionCertificateMalaysia: { isSelected: false, hidden: true },
    resident: { isSelected: true, hidden: true },
    isTDS: { isSelected: false, hidden: true },
    taxExempted: { isSelected: false, hidden: true },
    contactSingaporeGovt: { isSelected: false, hidden: true },
    considerExemptLimit: { isSelected: false, hidden: true },
    nonDeductionApplicable: { isSelected: false, hidden: true },
    fromDate: { isOpen: false, hidden: true, date: '' },
    toDate: { isOpen: false, hidden: true, date: '' },
    lowerDeductionLimit: { isSelected: false, hidden: false },
    is1099Eligible: { isSelected: false, hidden: true }
  },
  additionalPaymentInfo: {
    customerId: {
      key: CONTACT_FIELD_KEY_NAMES.CUSTOMER_ID,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      hasError: false,
      isMandatory: false
    },
    customerInternalId: {
      key: CONTACT_FIELD_KEY_NAMES.CUSTOMER_INTERNAL_ID,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: true,
      hasError: false,
      isMandatory: false
    },
    type: {
      key: CONTACT_FIELD_KEY_NAMES.TYPE,
      hidden: false,
      value: { value: PAYMENT_INFO_TYPE.PRIMARY, label: 'Primary' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN,
      options: PAYMENT_INFO_TYPE_OPTIONS
    },
    bankAccountType: {
      key: CONTACT_FIELD_KEY_NAMES.BANK_ACCOUNT_TYPE,
      hidden: false,
      value: { value: BANK_ACCOUNT_TYPE.CHECKING, label: 'Checking' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN,
      options: BANK_ACCOUNT_TYPE_OPTIONS
    },
    bankAccountNumber: {
      key: CONTACT_FIELD_KEY_NAMES.BANK_ACCOUNT_NUMBER,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: true,
      hasError: false,
      isMandatory: false
    },
    paymentFileFormat: {
      key: CONTACT_FIELD_KEY_NAMES.PAYMENT_FILE_FORMAT,
      hidden: false,
      value: { value: PAYMENT_FILE_FORMAT.EFT, label: 'EFT' },
      selectionType: DropdownSelectionType.SINGLE,
      type: FORM_ELEMENTS.DROPDOWN,
      options: PAYMENT_FILE_FORMAT_OPTIONS
    },
    countryCheck: {
      key: CONTACT_FIELD_KEY_NAMES.COUNTRY_CHECK,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: true,
      hasError: false,
      isMandatory: false
    },
    bankCode: {
      key: CONTACT_FIELD_KEY_NAMES.BANK_CODE,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: true,
      hasError: false,
      isMandatory: false
    },
    processorCode: {
      key: CONTACT_FIELD_KEY_NAMES.PROCESSOR_CODE,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: true,
      hasError: false,
      isMandatory: false
    },
    receivingDfiId: {
      key: CONTACT_FIELD_KEY_NAMES.RECEIVING_DFI_ID,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: false,
      hasError: false,
      isMandatory: false,
      maxLength: 8
    },
    checkDigit: {
      key: CONTACT_FIELD_KEY_NAMES.CHECK_DIGIT,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: true,
      hasError: false,
      isMandatory: false,
      maxLength: 1
    },
    clientNumber: {
      key: CONTACT_FIELD_KEY_NAMES.CLIENT_NUMBER,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: false,
      hasError: false,
      isMandatory: false,
      maxLength: 10
    },
    txnCode: {
      key: CONTACT_FIELD_KEY_NAMES.TAX_CODE,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: false,
      hasError: false,
      isMandatory: false,
      maxLength: 3
    },
    cadFinancialInstTransit: {
      key: CONTACT_FIELD_KEY_NAMES.CAD_FINANCIAL_INST_TRANSIT,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: false,
      hasError: false,
      isMandatory: false,
      maxLength: 9
    },
    processingCenterId: {
      key: CONTACT_FIELD_KEY_NAMES.PROCESSING_CENTER_ID,
      hidden: false,
      value: '',
      type: FORM_ELEMENTS.INPUT,
      numberOnly: false,
      hasError: false,
      isMandatory: false,
      maxLength: 5
    }
  },
  activeShippingAddressIndex: 0,
  activeBillingAddressIndex: 0,
  deducteeInfo: { showPopup: false, data: { label: '', value: '' } },
  paymentInfo: { showPopup: false, data: { label: '', value: '' } },
  activeMultiCurrencyList: [],
  customFields: [],
  exchangeRate: '1',
  activeTab: CONTACT_FORM_TAB.GENERAL_INFO
};

export enum FUTURE_CONTACT_ACCOUNTS_APPLY {
  PAYABLE_ACCOUNT_CODE = 'payableAccountCode',
  RECEIVABLE_ACCOUNT_CODE = 'receivableAccountCode'
}

export const futureContactAccountInitialState = {
  payableAccountCode: false,
  payableAccountCodeTouched: false,
  receivableAccountCode: false,
  receivableAccountCodeTouched: false
};

export interface GeneralInfo {
  name: InputTag<CONTACT_FIELD_KEY_NAMES>;
  email: InputTag<CONTACT_FIELD_KEY_NAMES>;
  contactNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  countryNumberOptions: PhoneNumberOption;
  currency: InputTag<CONTACT_FIELD_KEY_NAMES>;
  numberingFormat: InputTag<CONTACT_FIELD_KEY_NAMES>;
  panNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  contactUEN: InputTag<CONTACT_FIELD_KEY_NAMES>;
  peppolID: InputTag<CONTACT_FIELD_KEY_NAMES>;
  taxNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  taxTreatmentUae: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  vatRegistrationUae: InputTag<CONTACT_FIELD_KEY_NAMES>;
  intercompanyType: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  ukBusinessRegistrationNo: InputTag<CONTACT_FIELD_KEY_NAMES>;
  ukDefaultPurchaseTaxRate: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  ukDefaultSalesTaxRate: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  purchaseTaxCodeIsrael: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  salesTaxCodeIsrael: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  ukVatIdentificationNo: InputTag<CONTACT_FIELD_KEY_NAMES>;
  otherEmails: InputTag<CONTACT_FIELD_KEY_NAMES>;
  agent: MultiDropdownTag<string>;
}

export interface Accounting {
  paymentTerm: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  payableAccount: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  receiveableAccount: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  gstTreatment: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  gstIn: InputTag<CONTACT_FIELD_KEY_NAMES>;
  customerType: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  vendorType: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  customerTaxType: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  vendorTaxType: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  npwpNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  nikNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  vendorTAN: InputTag<CONTACT_FIELD_KEY_NAMES>;
  deducteeType: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  exemptType: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  businessUnit: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  attentionTo: InputTag<CONTACT_FIELD_KEY_NAMES>;
  nonDeductionReasons: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  tdsRate: InputTag<CONTACT_FIELD_KEY_NAMES>;
  certificateNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  higherRate: InputTag<CONTACT_FIELD_KEY_NAMES>;
  businessRegistrationNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  serviceTaxRegistrationNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  salesTaxRegistrationNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;

  customerTypeMalaysia: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  vendorTypeMalaysia: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  exemptionCriterialMalaysia: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  // creditLimit
  creditLimit: CreditLimit;
  // priceList
  purchasePriceLists: any;
  salesPriceLists: any;

  //Israel Compliance
  taxPayerIdIsrael: InputTag<CONTACT_FIELD_KEY_NAMES>;
  assessingOfficerNumberIsrael: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  typeOfCompanyIsrael: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  withHoldingTaxTypeIsraelId: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  withHoldingTaxPaymentGroupIsraelId: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  // whtApplicableIsrael: ToggleTag<CONTACT_FIELD_KEY_NAMES>;
  whtApplicableIsrael: { isSelected: boolean; hidden: boolean };
  taxCertificateValidityDateIsrael: CalendarType;
  isIncludedTaxReportingAtSourceIsrael: {
    isSelected: boolean;
    hidden: boolean;
  };
  isSelfInvoicingIsrael: { isSelected: boolean; hidden: boolean };
  withHoldingCertificatesIsrael: { hidden: boolean; value: any };
  israelBankDetails: { hidden: boolean; value: any };
  israelGlobalBankDetails: { hidden: boolean; value: any };
}

interface CreditLimit {
  invoiceCreditLimitType: CREDIT_LIMIT_TYPE;
  quoteCreditLimitType: CREDIT_LIMIT_TYPE;
  salesOrderCreditLimitType: CREDIT_LIMIT_TYPE;
  invoiceSelected: boolean;
  quoteSelected: boolean;
  salesOrderSelected: boolean;
  creditLimitInput: InputTag<CONTACT_FIELD_KEY_NAMES>;
  showCreditLimitInfo: boolean;
  showConversionRate: boolean;
}

export interface AddressInfo {
  contactName: InputTag<CONTACT_FIELD_KEY_NAMES>;
  address: InputTag<CONTACT_FIELD_KEY_NAMES>;
  address2: InputTag<CONTACT_FIELD_KEY_NAMES>;
  state: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  city: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  postalCode: InputTag<CONTACT_FIELD_KEY_NAMES>;
  country: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  contactNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  countryNumberOptions: PhoneNumberOption;
  email: InputTag<CONTACT_FIELD_KEY_NAMES>;
  customFields: CustomField[];
}

export interface AdditionalInfo {
  exemptionCertificateMalaysia: { isSelected: boolean; hidden: boolean };
  resident: { isSelected: boolean; hidden: boolean };
  isTDS: { isSelected: boolean; hidden: boolean };
  taxExempted: { isSelected: boolean; hidden: boolean };
  contactSingaporeGovt: { isSelected: boolean; hidden: boolean };
  considerExemptLimit: { isSelected: boolean; hidden: boolean };
  nonDeductionApplicable: { isSelected: boolean; hidden: boolean };
  fromDate: CalendarType;
  toDate: CalendarType;
  lowerDeductionLimit: { isSelected: boolean; hidden: boolean };
  is1099Eligible: { isSelected: boolean; hidden: boolean };
}

export interface ComplianceInfo {
  irdNo?: string;
  gstNo?: string;
  uen?: string;
  taxNumber?: string;
  beEnterpriseNo?: string;
  beVatIdentificationNo?: string;
  beDefaultSalesTaxRate?: string;
  beDefaultPurchaseTaxRate?: string;
  businessRegNo?: string;
  serviceTaxRegNo?: string;
  salesTaxRegNo?: string;
  exemptionCertificateMalaysia?: string;
  exemptionCriterialMalaysia?: string;
  customerTypeMalaysia?: string;
  vendorTypeMalaysia?: string;
  nwpwNumber?: string;
  nikNumber?: string;
  taxTypeCustomer?: string;
  taxTypeVendor?: string;
  residentStatusIndonesia?: boolean;
}

export interface AdditionalPaymentInfo {
  customerId: InputTag<CONTACT_FIELD_KEY_NAMES>;
  customerInternalId: InputTag<CONTACT_FIELD_KEY_NAMES>;
  type: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  bankAccountType: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  bankAccountNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  paymentFileFormat: DropdownTag<CONTACT_FIELD_KEY_NAMES>;
  countryCheck: InputTag<CONTACT_FIELD_KEY_NAMES>;
  bankCode: InputTag<CONTACT_FIELD_KEY_NAMES>;
  processorCode: InputTag<CONTACT_FIELD_KEY_NAMES>;
  receivingDfiId: InputTag<CONTACT_FIELD_KEY_NAMES>;
  checkDigit: InputTag<CONTACT_FIELD_KEY_NAMES>;
  clientNumber: InputTag<CONTACT_FIELD_KEY_NAMES>;
  txnCode: InputTag<CONTACT_FIELD_KEY_NAMES>;
  cadFinancialInstTransit: InputTag<CONTACT_FIELD_KEY_NAMES>;
  processingCenterId: InputTag<CONTACT_FIELD_KEY_NAMES>;
}

export interface InputTag<KeyType> {
  value: string | number;
  hidden: boolean;
  key: KeyType;
  type: FORM_ELEMENTS;
  defaultValue?: number | string;
  hasError: boolean;
  isDisabled?: boolean;
  isMandatory?: boolean;
  errorMsg?: string;
  label?: string;
  numberOnly?: boolean;
  maxLength?: number | null;
  applyFormula?: boolean;
}
export interface ToggleTag<KeyType> {
  value: boolean | string | number;
  hidden: boolean;
  key: KeyType;
  type: FORM_ELEMENTS;
  defaultValue?: number | string;
  hasError: boolean;
  isDisabled?: boolean;
  isMandatory?: boolean;
  errorMsg?: string;
}

export interface BooleanTag<KeyType> {
  value: string | number;
  hidden: boolean;
  key: KeyType;
  type: 'boolean';
  defaultValue?: number | string;
  hasError: boolean;
  isDisabled?: boolean;
  isMandatory?: boolean;
  errorMsg?: string;
}
export interface DropdownTag<KeyType> {
  selectionType: DropdownSelectionType;
  type: FORM_ELEMENTS;
  defaultValue?: ReactSelectOptionsType<string, string>;
  hidden: boolean;
  value: ReactSelectOptionsType<string, string>;
  key: KeyType;
  options?: ReactSelectOptionsType<string, string>[];
  isMandatory?: boolean;
  errorMsg?: string;
  hasError?: boolean;
  label?: string;
  parent?: any;
}

export interface PhoneNumberOption {
  selectionType: DropdownSelectionType;
  type: FORM_ELEMENTS;
  hidden: boolean;
  value: CountryInfo | null;
  options: CountryInfo[];
  isMandatory?: boolean;
  showDropdown: boolean;
}

export interface AsyncDropdownTag {
  selectionType: any;
  type: FORM_ELEMENTS;
  hidden: boolean;
  value: string;
  optionDetails: any;
  options?: any[];
  isMandatory?: boolean;
  errorMsg?: string;
  hasError?: boolean;
}

export interface SingleSelectDropdownTag<KeyType> {
  selectionType: DropdownSelectionType;
  type: FORM_ELEMENTS;
  defaultValue?: any;
  hidden: boolean;
  value: any;
  key: KeyType;
  options?: any;
}

export interface MultiDropdownTag<KeyType> {
  selectionType: DropdownSelectionType;
  type: FORM_ELEMENTS;
  defaultValue?: any;
  hidden: boolean;
  value: OptionsType<ReactSelectOptionsType<string, string>>;
  key: KeyType;
  options?: ReactSelectOptionsType<string, string>[];
}

export interface DropdownTagForAsync<KeyType> {
  selectionType: any;
  type: FORM_ELEMENTS;
  defaultValue?: any;
  hidden: boolean;
  value: any;
  key: KeyType;
  options?: any[];
}
export interface MultiDropdownTag<KeyType> {
  selectionType: DropdownSelectionType;
  type: FORM_ELEMENTS;
  defaultValue?: any;
  hidden: boolean;
  value: OptionsType<ReactSelectOptionsType<string, string>>;
  key: KeyType;
  options?: ReactSelectOptionsType<string, string>[];
}

export interface MulitSelectDropdown {
  selectionType: DropdownSelectionType;
  type: FORM_ELEMENTS;
  defaultValue?: number[];
  hidden: boolean;
  value: number[];
  key?: string;
  options?: ReactSelectOptionsType<string, string>[];
  originalData?: any;
}

export interface NewContactProps {
  contactMode: DOCUMENT_MODE;
  populateFormData: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  readOnly?: boolean;
  onSuccess?: (data: any) => void;
  activeTab?: CONTACT_FORM_TAB;
}
export interface KeyAndValue {
  key: string | number;
  value: string | number;
}

export interface ContactSummary {
  total: number;
  customer: number;
  vendor: number;
}

export interface ContactPayload {
  sequenceFormat: string;
  name: string;
  currencyCode: string;
  owe_them: number;
  owe_you: number;
  receivableAccountCode: string;
  payableAccountCode: string;
  paymentTermCode: number;
  emailId: string;
  customField: CustomField[];
  id?: number;
  documentSequenceCode?: string;
  status?: STATUS_TYPE;
  billingAddress: AddressType[];
  shippingAddress: AddressType[];
  code?: string;
  contactNumber: string | null;
  intercompanyType: string | null;
  otherEmails: string | null;
  salesPersonMaster?: any[];
  // IND specific Fields
  tdsInfoIndia?: {
    deductionApplicable: boolean;
    nonDeductionApplicable: boolean;
    tan: string;
    considerThresholdLimit: boolean;
    noDeductionFromDate?: string;
    noDeductionToDate?: string;
    deducteeCode: string;
    deducteeType: string;
    higherRate: number | null;
    noDeductionReason?: string;
    noDeductionTdsRate?: number;
    noDeductionRefNo?: string;
  };
  pan?: string;
  vendorType?: string;
  resident?: boolean;
  vatRegistrationNo?: string;
  gstin?: string;
  gstTreatment?: string;
  customerType?: string;

  // USA specific Fields
  taxExempted?: boolean;
  taxExemptionReason?: string | null;
  taxNumber?: string;
  is1099Eligible?: boolean;

  // SG specific Fields
  peppolId?: string;
  uen?: string;
  singaporeGovt?: boolean;
  businessUnit?: string;
  attentionTo?: string;

  //Indonesia specific
  taxTypeCustomer?: string;
  taxTypeVendor?: string;
  nikNumber?: string;
  npwpNumber?: string;
  residentStatusIndonesia?: boolean;

  //Malaysia specific
  serviceTaxRegNo?: string;
  salesTaxRegNo?: string;
  businessRegNo?: string;
  customerTypeMalaysia?: string;
  vendorTypeMalaysia?: string;
  exemptionCertificateMalaysia?: boolean;
  exemptionCriterialMalaysia?: string;
  extCompanyName?: string;

  //uae specific
  taxTreatment?: string;

  // UK Specific
  ukBusinessRegistrationNo?: string;
  ukDefaultPurchaseTaxRate?: string;
  ukDefaultSalesTaxRate?: string;
  ukVatIdentificationNo?: string;

  // creditLimit
  invoiceCreditLimitType?: CREDIT_LIMIT_TYPE;
  isCreditLimitApplicable: boolean;
  isIncludeCurrentInvoice?: boolean;
  isIncludeCurrentQuotation?: boolean;
  isIncludeCurrentSalesOrder?: boolean;
  quotationCreditLimitType?: CREDIT_LIMIT_TYPE;
  salesOrderCreditLimitType?: CREDIT_LIMIT_TYPE;
  creditLimit?: number;
  creditLimitInBaseCurrency?: number;

  // priceList
  purchasePriceLists: number[] | null;
  salesPriceLists: number[] | null;

  //Israel Compliance fields
  taxPayerIdIsrael?: string;
  assessingOfficerNumberIsrael?: string;
  typeOfCompanyIsrael?: string;
  withHoldingTaxTypeIsraelId?: string;
  withHoldingTaxPaymentGroupIsraelId?: string;
  whtApplicableIsrael?: boolean;
  taxCertificateValidityDateIsrael?: string;
  isIncludedTaxReportingAtSourceIsrael?: boolean;
  isSelfInvoicingIsrael?: boolean;
  withHoldingCertificatesIsrael?: any;
  israelBankDetails?: any;
  israelGlobalBankDetails?: any;
  purchaseTaxCodeIsrael?: any;
  salesTaxCodeIsrael?: any;
  additionalPaymentInfo?: {
    customerId: string;
    customerInternalId: string | number;
    type: string;
    bankAccountType: string;
    bankAccountNumber: string | number;
    paymentFileFormat: string;
    countryCheck: string | number;
    bankCode: string | number;
    processorCode: string | number;
    receivingDfiId: string | number;
    checkDigit: string | number;
    clientNumber: string | number;
    txnCode: string | number;
    cadFinancialInstTransit: string | number;
    processingCenterId: string | number;
  };
}
