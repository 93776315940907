import { MODULES_NAME } from '../../Constants/Constant';
import { IColumnType } from '../../Models/Table';
import { Store } from '../../Redux/Store';
import Utility from '../../Utility/Utility';
import { FORMULA_FUNCTIONS } from './formula-functions';

export interface FormulaParameter {
  key: string;
  name: string;
  value: number; // Assuming value is a number for evaluation
  isCustomField?: boolean; // New optional key
  isFromParent?: boolean; // New optional key
  customFieldId?: string; // New optional key
}

export const modulesUsingFormulaCF = [MODULES_NAME.MRP_WORK_ORDER];

export const extractUsedParameters = (
  expression: string,
  allParameters: FormulaParameter[]
): FormulaParameter[] => {
  const keys = allParameters.map((param) => param.key);
  const foundKeys = keys.filter((key) =>
    new RegExp(`\\b${key}(?!\\s*\\()`).test(expression)
  );
  const extractedParams = allParameters.filter((param) =>
    foundKeys.includes(param.key)
  );
  return extractedParams;
};

export const getNonCFKeysValueForFormula = (
  selectedModule: MODULES_NAME | string,
  currentDoc?: any
) => {
  let extraKeys: any = {};
  switch (selectedModule) {
    // Update this if any new number type field is
    // added in column config of any module using formula CF
    case MODULES_NAME.MRP_WORK_ORDER:
      extraKeys = {
        manufactureQuantity: {
          value: currentDoc?.manufactureQuantity || 0,
          path: ['manufactureQuantity']
        },
        actualQuantity: {
          value: currentDoc?.actualQuantity || 0,
          path: ['actualQuantity']
        },
        plannedOperatingCost: {
          value: currentDoc?.operationCostDetails?.plannedOperatingCost || 0,
          path: ['operationCostDetails', 'plannedOperatingCost']
        },
        actualOperatingCost: {
          value: currentDoc?.operationCostDetails?.actualOperatingCost || 0,
          path: ['operationCostDetails', 'actualOperatingCost']
        },
        totalOperatingCost: {
          value: currentDoc?.operationCostDetails?.totalOperatingCost || 0,
          path: ['operationCostDetails', 'totalOperatingCost']
        }
      };
      break;
    default:
      break;
  }
  return extraKeys;
};

export const getColumnConfigForFormula = (
  selectedModule: MODULES_NAME,
  moduleColumnConfig?: any[]
) => {
  let filteredConfig: any[] = [];
  switch (selectedModule) {
    case MODULES_NAME.MRP_WORK_ORDER:
      const workOrderConfig = Utility.isNotEmpty(moduleColumnConfig)
        ? moduleColumnConfig
        : Store.getState().mrpWorkOrder.columnConfig;
      filteredConfig =
        workOrderConfig?.filter(
          (config) => config.type === IColumnType.NUMBER
        ) || [];
      break;
    default:
      break;
  }
  let tempColumnConfig: any[] = [];
  filteredConfig.forEach((config) => {
    const isCustomField = (config.columnCode as string).startsWith('custom_D-');
    let customFieldId = null;
    if (isCustomField) {
      customFieldId = (config.columnCode as string).split('_')?.[1];
    }
    tempColumnConfig.push({
      key: isCustomField
        ? (config.name as string).replace(/\s+/g, '_')
        : config.columnCode,
      name: config.name,
      value: 1, // Keep the value 1 for temporary evaluation
      isCustomField: isCustomField,
      isFromParent: false,
      customFieldId: customFieldId
    });
  });
  return tempColumnConfig;
};

// Function to evaluate the expression
export const evaluateExpression = (
  expression: string,
  parameters: FormulaParameter[]
) => {
  // Replace keys in the expression with their corresponding values
  const evaluatedExpression = parameters.reduce((expr, param) => {
    return expr.replace(
      new RegExp(`\\b${param.key}(?!\\s*\\()`, 'g'),
      param.value !== undefined && param.value !== null && !isNaN(param.value)
        ? param.value.toString()
        : '0'
    );
  }, expression);
  // Prepend "formulajs." to all function names in the evaluated expression
  const functionNames = FORMULA_FUNCTIONS.map((func) => func.name);
  const functionRegex = new RegExp(`\\b(${functionNames.join('|')})\\b`, 'g');
  const expressionWithFormulajs = evaluatedExpression.replace(
    functionRegex,
    (match) => {
      // Check if the match is followed by an opening parenthesis
      if (evaluatedExpression.charAt(match.length) === '(') {
        return `formulajs.${match}`; // Prepend "formulajs." if followed by '('
      }
      return match; // Return the match unchanged if not followed by '('
    }
  );

  try {
    // Evaluate the expression using formulajs
    // console.log('expressionWithFormulajs: ', expressionWithFormulajs);
    let result = eval(expressionWithFormulajs);
    if (isNaN(result)) {
      result = '';
    }
    return { value: result, isValid: true, errorMessage: '' }; // Return the result for the callback
  } catch (error: any) {
    // console.error('Error: ', error);
    return { value: null, isValid: false, errorMessage: 'Invalid formula' }; // Return null on error
  }
};
