import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';
import { saveAs } from 'file-saver';
import { start } from 'repl';
import {
  removeLoader,
  DKLabel,
  showToast,
  TOAST_TYPE,
  showAlert
} from 'deskera-ui-library';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';
import DateFormatService from './DateFormat';
import { BOOKS_DATE_FORMAT } from '../Constants/Constant';
import { sortBy } from 'lodash';

export interface NewReportConfig {
  StartDate?: string;
  EndDate?: string;
  Page?: number;
  SheetName?: string;
  details?: boolean;
  SheetGroupBy?: string;
  Type?: string;
  Limit?: number;
  Search?: string;
  multipleUom?: boolean;
  Warehouse?: string[];
  CustomField?: any;
  batchStatus?: any;
  toggle?: boolean;
  sort?: string;
  sortDir?: string;
  documentStartDate?: string;
  documentEndDate?: string;
  serialBatchNumber?: string;
  Basis?: string;
  BaseCurrency?: string;
  PrimaryCurrency?: string;
  Query?: string;
  salesByProductWithSalesOrder?: boolean;
  filterDate?: any;
  purchaseByProductWithPurchaseOrder?: boolean;
  batchSerialCustomField?: any;
  exemptSupplyType?: string;
}

const defaultConfig: NewReportConfig = {
  StartDate: DateFormatService.getDateStrFromDate(
    Utility.getFinancialStartDate(),
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  ),
  EndDate: DateFormatService.getDateStrFromDate(
    Utility.getFinancialEndDate(),
    BOOKS_DATE_FORMAT['DD-MM-YYYY']
  ),
  SheetName: 'B2B',
  SheetGroupBy: 'GSTIN',
  Limit: 25,
  Page: 0,
  Search: '',
  Warehouse: [],
  toggle: false,
  sort: 'documentCode',
  sortDir: 'DESC',
  documentStartDate: DateFormatService.getDateStrFromDate(
    Utility.getFinancialStartDate(),
    BOOKS_DATE_FORMAT['YYYY-MM-DD']
  ),
  documentEndDate: DateFormatService.getDateStrFromDate(
    Utility.getFinancialEndDate(),
    BOOKS_DATE_FORMAT['YYYY-MM-DD']
  ),
  salesByProductWithSalesOrder: false
};

class NewReportService {
  static apiConfig: NewReportConfig;

  static getReportData(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndPoint = ApiConstants.URL.REPORT.PURCHASE.GET_REPORT_DATA;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTallyReceivable(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    // request = {
    //   viewType: 'MONTHLY_SUMMARY',
    //   startDate: '2021-01-01',
    //   endDate: '2022-05-05'
    // };
    const finalEndPoint = ApiConstants.URL.REPORT.TALLY.RECEIVABLE;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getVendorSoaTally(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    // request = {
    //   viewType: 'MONTHLY_SUMMARY',
    //   startDate: '2021-01-01',
    //   endDate: '2022-05-05'
    // };
    const finalEndPoint = ApiConstants.URL.REPORT.TALLY.VENDOR_SOA;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getCustomerSoaTally(request: any) {
    const finalEndPoint = ApiConstants.URL.REPORT.TALLY.CUSTOMER_SOA;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTallyDayBook(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const finalEndPoint = ApiConstants.URL.REPORT.TALLY.DAY_BOOK;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTallyStockSummary(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const finalEndPoint = ApiConstants.URL.REPORT.TALLY.STOCK_SUMMARY;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTallyReceiptSummary(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const finalEndPoint = ApiConstants.URL.REPORT.TALLY.RECEIPT_SUMMARY;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getTallyCashflow(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndPoint = ApiConstants.URL.REPORT.TALLY.CASHFLOW;
    if (request.columnBy) {
      let query = `?columnBy=${request.columnBy}`;
      finalEndPoint = finalEndPoint + query;
    }
    delete request.columnBy;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getDetailedSalesReport(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndPoint = `${ApiConstants.URL.REPORT.TALLY.DETAILED_SALES}?limit=${request.limit}&page=${request.page}`;
    // if (request.columnBy) {
    //   let query = `?columnBy=${request.columnBy}`;
    //   finalEndPoint = finalEndPoint + query;
    // }
    // delete request.columnBy;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportDplReport(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndPoint = `${ApiConstants.URL.REPORT.FINANCIAL.DPL_REPORT_EXPORT}?limit=${request.limit}&toggle=${request?.toggle}&page=${request.page}&query=checked_date>=${request?.startDate},checked_date<=${request?.endDate}&format=${request?.fileFormat}`;
    let fileName = `Daily party list.xlsx`;

    return http
      .get(`${finalEndPoint}`, { responseType: 'blob' })
      .then(
        (response: any) => {
          saveAs(response, fileName);
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getDplReport(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndPoint = `${ApiConstants.URL.REPORT.FINANCIAL.DPL_REPORT}?limit=${request.limit}&toggle=${request?.toggle}&page=${request.page}&query=checked_date>=${request?.startDate},checked_date<=${request?.endDate}`;

    return http
      .get(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static mailDplReport(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndPoint = `${ApiConstants.URL.REPORT.FINANCIAL.DPL_REPORT_EMAIl}`;

    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getSalesRateAnalysisReport(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let commissionQuery = undefined;
    if (request?.commission !== '') {
      commissionQuery = `,commission=${request?.commission}`;
    }

    let productQuery = undefined;
    if (request?.productCode !== '') {
      productQuery = `,productCode=${request?.productCode}`;
    }

    let finalEndPoint =
      ApiConstants.URL.REPORT.SALES.RATE_ANALYSIS_REPORT +
      `?limit=25&page=${
        request?.pageNumber ? request.pageNumber : 0
      }&query=documentDate>=${request.startDate},documentDate<=${
        request.endDate
      }${commissionQuery ? commissionQuery : ''}${
        productQuery ? productQuery : ''
      },documentType=${request?.documentType || ''}&search=${
        request?.search ? request?.search : ''
      }&sort=createdDate&sortDir=DESC`;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportSalesRateAnalysisReport(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let commissionQuery = undefined;
    if (request?.commission !== '') {
      commissionQuery = `,commission=${request?.commission}`;
    }

    let productQuery = undefined;
    if (request?.productCode !== '') {
      productQuery = `,productCode=${request?.productCode}`;
    }

    let fileName = `RATE ANALYSIS.xlsx`;

    let finalEndPoint =
      ApiConstants.URL.REPORT.SALES.RATE_ANALYSIS_REPORT +
      `?limit=25&page=${
        request?.pageNumber ? request.pageNumber : 0
      }&query=documentDate>=${request.startDate},documentDate<=${
        request.endDate
      }${commissionQuery ? commissionQuery : ''}${
        productQuery ? productQuery : ''
      },documentType=${request?.documentType || ''}&search=${
        request?.search ? request?.search : ''
      }&sort=createdDate&sortDir=DESC&format=XLS`;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTransactionType() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const finalEndPoint = ApiConstants.URL.REPORT.TALLY.TRANSACTION_TYPE;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getSalesTaxData(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndPoint = ApiConstants.URL.REPORT.TAX.SALES_TAX;

    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportSalesTaxData(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName =
      request.fileFormat === 'PDF'
        ? `Sales_tax_report.pdf`
        : `Sales_tax_report.xlsx`;
    const finalEndPoint = ApiConstants.URL.REPORT.TAX.SALES_TAX_EXPORT;
    return http
      .post(`${finalEndPoint}`, request, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getSalesTaxUSA(query?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndPoint = ApiConstants.URL.REPORT.TAX.SALES_TAX_USA;
    if (query !== '') {
      finalEndPoint = finalEndPoint + query;
    }
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getSalesTaxDetailsUSA(query?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndPoint = ApiConstants.URL.REPORT.TAX.SALES_TAX_DETAIL_USA;
    if (query !== '') {
      finalEndPoint = finalEndPoint + query;
    }
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getCustomFieldForBOM(request?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndPoint = ApiConstants.URL.REPORT.FINANCIAL.CUSTOM_FIELD_BOM;
    const query = `?query=module=BOMASSEMBLY,isSystem=,status=&limit=1000&page=`;
    return http
      .get(`${finalEndPoint + query}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getFullfillment(id?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const EndPoint = ApiConstants.URL.REPORT.TAX.FULLFILLMENT;
    const query = `?transactionRefCode=${id}&limit=${
      this.apiConfig.Limit || 100
    }&page=${this.apiConfig.Page || 0}`;
    const finalEndPoint = EndPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGR(id?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const EndPoint = ApiConstants.URL.REPORT.TAX.GR;
    const query = `?transactionRefCode=${id}&limit=${
      this.apiConfig.Limit || 100
    }&page=${this.apiConfig.Page || 0}`;
    const finalEndPoint = EndPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getSalesTaxMALAYSIA(config: NewReportConfig, request?: any) {
    if (!config) {
      this.apiConfig = defaultConfig;
    } else {
      this.apiConfig = config;
    }
    const query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}`;
    const finalEndPoint =
      ApiConstants.URL.REPORT.TAX.SALES_TAX_MALAYSIA + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getServiceTaxMALAYSIA(config: NewReportConfig, request?: any) {
    if (!config) {
      this.apiConfig = defaultConfig;
    } else {
      this.apiConfig = config;
    }
    const query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}`;
    const finalEndPoint =
      ApiConstants.URL.REPORT.TAX.SERVICE_TAX_MALAYSIA + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getSalesByProductData(productCode?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const URL = ApiConstants.URL.REPORT.SALES.SALES_BY_PRODUCT;
    const queryString = `?type=${this.apiConfig.Type}&var-start_date=${
      this.apiConfig.StartDate
    }&var-end_date=${this.apiConfig.EndDate}&var-product_code=${productCode}${
      this.apiConfig.salesByProductWithSalesOrder ? '&docType=SALES_ORDER' : ''
    }`;
    const finalEndPoint = URL + queryString;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getOrderLevelReport(paging: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const URL = ApiConstants.URL.REPORT.PURCHASE.ORDER_LEVEL_REPORT;
    const queryString = `?page=${paging.page || 0}&limit=${paging.limit || 20}`;
    const params = {
      search: this.apiConfig.Search,
      endDate: this.apiConfig.filterDate,
      isSORequest: this.apiConfig.salesByProductWithSalesOrder,
      itemCustomFields: this.apiConfig.CustomField
        ? this.apiConfig.CustomField
        : '[]',
      isPORequest: this.apiConfig.purchaseByProductWithPurchaseOrder
    };
    const finalEndPoint = URL + queryString;
    return http
      .post(`${finalEndPoint}`, params)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getPurchaseReturnData(productName?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const URL = ApiConstants.URL.REPORT.INVENTORY.PURCHASE_RETURN;
    const queryString = `?fromDate=${this.apiConfig.StartDate}&productName=${productName}&toDate=${this.apiConfig.EndDate}`;
    const finalEndPoint = URL + queryString;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getSalesReturnData(productName?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const URL = ApiConstants.URL.REPORT.INVENTORY.SALES_RETURN;
    const queryString = `?fromDate=${this.apiConfig.StartDate}&productName=${productName}&toDate=${this.apiConfig.EndDate}`;
    const finalEndPoint = URL + queryString;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGSTRONE() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.GSTR_ONE;
    const query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGSTRTHREE() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.GSTR_THREE;
    const query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getB2BDetails() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.B2B_DETAILS;
    let query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}&sheetName=${this.apiConfig.SheetName}&sheetGroupBy=${this.apiConfig.SheetGroupBy}`;
    if (this.apiConfig.exemptSupplyType) {
      query = query + '&exemptSupplyType=' + this.apiConfig.exemptSupplyType;
    }
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGstReturnData(queryParams: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.GST_RETURN;
    const query = `?query=filingYear=${queryParams.fillingYear}&search=${queryParams.search}&limit=10&page=0`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getOtp(payload: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.OTP;
    return http
      .post(`${endPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGstReturnForm1Data(
    year: string,
    frequency: string,
    fromDate: string,
    name: string,
    period: string,
    toDate: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.GST_RETURN_FORM_ONE;
    const query = `?filingYear=${year}&frequency=${frequency}&fromDate=${fromDate}&name=${name}&period=${period}&toDate=${toDate}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGstThreeDetails() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.GST_THREE_DETAILS;
    const query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}&sheetName=${this.apiConfig.SheetName}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportGstThree(name: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `GST3B${name}.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.GST_THREE_DETAILS_EXPORT;
    const query = `?var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&sheetName=${this.apiConfig.SheetName}&format=XLS`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTDSDetails(
    contactCode: string,
    Nip: string,
    DeducteeType: string,
    PaymentStatus: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let isQueryneeded = false;
    if (
      !Utility.isEmpty(contactCode) ||
      !Utility.isEmpty(Nip) ||
      !Utility.isEmpty(DeducteeType) ||
      !Utility.isEmpty(PaymentStatus)
    ) {
      isQueryneeded = true;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.TDS_DETAILS;
    let queries = '';
    const query = `?search=${this.apiConfig.Search}&fromDate=${this.apiConfig.StartDate}&toDate=${this.apiConfig.EndDate}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&sort=${this.apiConfig.sort}&sortDir=${this.apiConfig.sortDir}`;

    if (!Utility.isEmpty(contactCode)) {
      queries += `&query=contactCode=${contactCode}`;
    }

    if (
      !Utility.isEmpty(this.apiConfig.StartDate) &&
      !Utility.isEmpty(this.apiConfig.EndDate)
    ) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=documentDate>=${this.apiConfig.StartDate},documentDate<=${this.apiConfig.EndDate}`;
      } else
        queries += `,documentDate>=${this.apiConfig.StartDate},documentDate<=${this.apiConfig.EndDate}`;
    }

    if (!Utility.isEmpty(Nip)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=natureOfPayment=${encodeURIComponent(Nip)}`;
      } else queries += `,natureOfPayment=${encodeURIComponent(Nip)}`;
    }

    if (!Utility.isEmpty(DeducteeType)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=deducteeType=${DeducteeType}`;
      } else queries += `,deducteeType=${DeducteeType}`;
    }

    if (!Utility.isEmpty(PaymentStatus)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=tdsPaid=${
          PaymentStatus === 'All' ? '' : PaymentStatus === 'Paid' ? true : false
        }`;
      } else {
        queries += `,tdsPaid=${
          PaymentStatus === 'All' ? '' : PaymentStatus === 'Paid' ? true : false
        }`;
      }
    }

    const finalEndPoint = endPoint + query + queries;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTDSNotDeducted(
    contactCode: string,
    Nip: string,
    DeducteeType: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let isQueryneeded = false;
    if (
      !Utility.isEmpty(contactCode) ||
      !Utility.isEmpty(Nip) ||
      !Utility.isEmpty(DeducteeType)
    ) {
      isQueryneeded = true;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.TDS_NOT_DED;
    let queries = '';
    const query = `?search=${this.apiConfig.Search}&fromDate=${this.apiConfig.StartDate}&toDate=${this.apiConfig.EndDate}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;

    if (!Utility.isEmpty(contactCode)) {
      queries += `&query=contactCode=${contactCode}`;
    }

    if (!Utility.isEmpty(Nip)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=natureOfPayment=${Nip}`;
      } else queries += `,natureOfPayment=${Nip}`;
    }

    if (!Utility.isEmpty(DeducteeType)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=deducteeType=${DeducteeType}`;
      } else queries += `,deducteeType=${DeducteeType}`;
    }

    const finalEndPoint = endPoint + query + queries;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static postTDSDeducted(payload: any) {
    return http
      .post(`${ApiConstants.URL.REPORT.TAX.POST_TDS_NOT_DED}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static postSoaEmail(payload: any) {
    return http
      .post(`${ApiConstants.URL.REPORT.TAX.EMAIL_SOA}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getSoaEmailCount(payload: any) {
    return http
      .post(`${ApiConstants.URL.REPORT.TAX.EMAIL_SOA_COUNT}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static validateTDSPayment(id: number) {
    return http
      .get(`${ApiConstants.URL.REPORT.TAX.VALIDATE_TDS}?ids=${id}`)
      .catch((err: any) => {
        console.error('Error creating Bank Deposit: ', err);
        return Promise.reject(err);
      });
  }

  static getStockAgeing(productName: string, warehouse: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.INVENTORY.STOCK_AGEING;
    const query = `?var-as_of_date=${
      this.apiConfig.StartDate
    }&warehouse_code=${warehouse}&limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&productName=${productName ? encodeURIComponent(productName) : ''}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getBatchStatus(payload: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.INVENTORY.BATCH_STATUS;
    const finalEndPoint = endPoint;

    return http
      .post(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getBatchSerialLedger(payload?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.INVENTORY.BATCH_SEARIAL_LEDGER;
    let query = `?startDate=${this.apiConfig.StartDate}&endDate=${
      this.apiConfig.EndDate
    }&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&productCode=${
      this.apiConfig.Search ? encodeURIComponent(this.apiConfig.Search) : ''
    }`;
    if (this.apiConfig?.batchSerialCustomField) {
      query += `&batchSerialCustomField=${encodeURIComponent(
        this.apiConfig?.batchSerialCustomField
      )}`;
    }
    const finalEndPoint = endPoint + query;
    let param = { serialBatchNumber: this.apiConfig.serialBatchNumber };
    return http
      .get(`${finalEndPoint}`, { params: param })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getDocsEmailed() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TALLY.DOCS_EMAILED;
    const query = `?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    const finalEndPoint = endPoint + query;
    let param = { serialBatchNumber: this.apiConfig.serialBatchNumber };
    return http
      .get(`${finalEndPoint}`, { params: param })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getBomAssembly(payload?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.INVENTORY.BOM_ASSEMBLY;
    const query = `?query=assemblyDate>=${
      this.apiConfig.StartDate
    },assemblyDate<=${this.apiConfig.EndDate},&search=${
      this.apiConfig.Search ? encodeURIComponent(this.apiConfig.Search) : ''
    }&SKIP_REQUEST_INTERCEPTOR=true&limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&sort=id&sortDir=DESC`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getProductionCosting(queryParams: string): Promise<any> {
    return http.get(
      ApiConstants.URL.REPORTS_URLS.MRP_REPORTS.PRODUCTION_REPORT
        .PRODUCTION_REPORT_DETAIL + `?${queryParams}`
    );
  }

  static getTaxAgency(payload?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.TAX_AGENCY;

    const finalEndPoint = endPoint;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportTdsDetails(
    contactCode: string,
    Nip: string,
    DeducteeType: string,
    PaymentStatus: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let isQueryneeded = false;
    if (
      !Utility.isEmpty(contactCode) ||
      !Utility.isEmpty(Nip) ||
      !Utility.isEmpty(DeducteeType) ||
      !Utility.isEmpty(PaymentStatus)
    ) {
      isQueryneeded = true;
    }

    let queries = '';
    // const query1 = `?search=${this.apiConfig.Search}&fromDate=${this.apiConfig.StartDate}&toDate=${this.apiConfig.EndDate}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;

    if (!Utility.isEmpty(contactCode)) {
      queries += `&query=contactCode=${contactCode}`;
    }

    if (!Utility.isEmpty(Nip)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=natureOfPayment=${encodeURIComponent(Nip)}`;
      } else queries += `,natureOfPayment=${encodeURIComponent(Nip)}`;
    }

    if (
      !Utility.isEmpty(this.apiConfig.documentStartDate) &&
      !Utility.isEmpty(this.apiConfig.documentEndDate)
    ) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=documentDate>=${this.apiConfig.documentStartDate},documentDate<=${this.apiConfig.documentEndDate}`;
      } else
        queries += `,documentDate>=${this.apiConfig.documentStartDate},documentDate<=${this.apiConfig.documentEndDate}`;
    }

    if (!Utility.isEmpty(DeducteeType)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=deducteeType=${DeducteeType}`;
      } else queries += `,deducteeType=${DeducteeType}`;
    }

    if (!Utility.isEmpty(PaymentStatus)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=tdsPaid=${
          PaymentStatus === 'All' ? '' : PaymentStatus === 'Paid' ? true : false
        }`;
      } else {
        queries += `,tdsPaid=${
          PaymentStatus === 'All' ? '' : PaymentStatus === 'Paid' ? true : false
        }`;
      }
    }
    const fileName = `TDS_Details_Report.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_TDS_DETAILS;
    const query = `?search=&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&sort=${this.apiConfig.sort}&sortDir=${this.apiConfig.sortDir}&format=XLS`;
    const finalEndPoint = endPoint + query + queries;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportIras(startDate: any, endDate: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `iras.xml`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_IRAS;
    const query = `?endDate=${endDate}&startDate=${startDate}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateTenant(payload: any) {
    const billCode: string = payload.id;
    return http
      .patch(`${ApiConstants.URL.TENANT.PATCH_TENANT}`, payload)
      .catch((err: any) => {
        console.error('Error updating tenant: ', err);
        return Promise.reject(err);
      });
  }

  static exportTdsNotDeducted(
    contactCode: string,
    Nip: string,
    DeducteeType: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let isQueryneeded = false;
    if (
      !Utility.isEmpty(contactCode) ||
      !Utility.isEmpty(Nip) ||
      !Utility.isEmpty(DeducteeType)
    ) {
      isQueryneeded = true;
    }

    let queries = '';

    if (!Utility.isEmpty(contactCode)) {
      queries += `&query=contactCode=${contactCode}`;
    }

    if (!Utility.isEmpty(Nip)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=natureOfPayment=${Nip}`;
      } else queries += `,natureOfPayment=${Nip}`;
    }

    if (!Utility.isEmpty(DeducteeType)) {
      if (Utility.isEmpty(queries)) {
        queries += `&query=deducteeType=${DeducteeType}`;
      } else queries += `,deducteeType=${DeducteeType}`;
    }

    const fileName = `TDS_Not_Deducted.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_TDS_NOT_DED;
    const query = `?search=&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&format=XLS`;
    const finalEndPoint = endPoint + query + queries;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportBomAssembly(reportFilterState?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `Bom-Assembly.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_BOM_ASSEMBLY;
    const query = `?query=assemblyDate>=${this.apiConfig.StartDate},assemblyDate<=${this.apiConfig.EndDate},&search=${this.apiConfig.Search}&SKIP_REQUEST_INTERCEPTOR=true&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&sort=id&sortDir=DESC&format=XLS`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportStockLedger(
    startDate: string,
    endDate: string,
    productCode: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_STOCKLEDGER;
    const query = `?startDate=${startDate}&endDate=${endDate}&productCode=${productCode}&format=XLS`;
    const query1: string = `?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&productCode=${this.apiConfig.Search}&startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}`;

    const finalEndPoint = endPoint + query;
    const req = {
      productCode: '',
      isDetails: false,
      page: 0,
      limit: 10,
      fileFormat: 'XLS'
    };
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          showAlert(
            'Success!',
            'Stock ledger report export will be emailed to you.'
          );
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )

      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportStockWarehouse(reportFilterState?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `TDS_Not_Deducted.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_STOCKWH;

    const finalEndPoint = endPoint;
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let payload: any = { fileFormat: 'XLS' };

    if (this.apiConfig.Warehouse && this.apiConfig.Warehouse.length > 0) {
      payload = {
        search: this.apiConfig.Search,
        isDetails: false,
        page: this.apiConfig.Page,
        limit: this.apiConfig.Limit,
        warehouse: this.apiConfig.Warehouse
      };
    }

    if (
      this.apiConfig.CustomField &&
      this.apiConfig.Warehouse &&
      this.apiConfig.Warehouse.length > 0
    ) {
      payload = {
        search: this.apiConfig.Search,
        isDetails: false,
        page: this.apiConfig.Page,
        limit: this.apiConfig.Limit,
        warehouse: this.apiConfig.Warehouse,
        customField: this.apiConfig.CustomField
      };
    } else if (
      Utility.isEmpty(this.apiConfig.CustomField) &&
      Utility.isEmpty(this.apiConfig.Warehouse)
    ) {
      payload = {
        search: this.apiConfig.Search,
        isDetails: false,
        page: this.apiConfig.Page,
        limit: this.apiConfig.Limit
      };
    }
    if (
      this.apiConfig.CustomField &&
      Utility.isEmpty(this.apiConfig.Warehouse)
    ) {
      payload = {
        customField: this.apiConfig.CustomField,
        search: this.apiConfig.Search,
        isDetails: false,
        page: this.apiConfig.Page,
        limit: this.apiConfig.Limit
      };
    }
    if (this.apiConfig.batchStatus) {
      payload = { ...payload, batchStatus: this.apiConfig.batchStatus };
    }
    if (this.apiConfig?.batchSerialCustomField) {
      payload = {
        ...payload,
        batchSerialCustomField: this.apiConfig?.batchSerialCustomField
      };
    }
    payload = {
      ...payload,
      multipleUom: this.apiConfig.toggle,
      fileFormat: 'XLS'
    };
    if (reportFilterState?.showRowRackBin) {
      payload = {
        ...payload,
        multipleUom: this.apiConfig.toggle,
        fileFormat: 'XLS',
        showRowRackBin: true
      };
    }
    return http
      .post(`${finalEndPoint}`, payload, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          showToast(
            'Stock Availability By Warehouse Report export will be emailed to you.',
            TOAST_TYPE.SUCCESS
          );
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )

      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportOutstandingPGR(reportFilterState?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `Outstanding Puchase Good Report.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_OUTSTANDING_PGR;
    let query = `?search=${this.apiConfig.Search}&fromDate=${this.apiConfig.StartDate}&toDate=${this.apiConfig.EndDate}&format=XLS`;

    if (!Utility.isEmpty(this.apiConfig.Query)) {
      query += '&query=' + this.apiConfig.Query;
    }

    const finalEndPoint = endPoint + query;

    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )

      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportGST3B(reportFilterState?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `GST3B.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_GST3B;
    const query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}`;

    const finalEndPoint = endPoint + query;

    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )

      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportCashFlow(reportFilterState?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const fileName = `Caashflow.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_CASHFLOW;
    const query = `?fromDate=${this.apiConfig.StartDate}&toDate=${this.apiConfig.EndDate}&method=DIRECT&format=XLS`;

    const finalEndPoint = endPoint + query;

    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )

      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportTdsCustomer(search?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `Tds Customer.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_TDS_CUSTOMER;
    const query = `?search=${search}&limit=&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&format=XLS`;

    const finalEndPoint = endPoint + query;

    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )

      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportStockAgeing(date: string, warehouse: any, product: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `TDS_Not_Deducted.xlsx`;
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_AGEING;

    const finalEndPoint = endPoint;
    const req = {
      'var-as_of_date': date,
      productName: product,
      warehouse_code: warehouse
    };
    return http
      .post(`${finalEndPoint}`, req, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          showAlert(
            'Success!',
            'Stock Ageing Report export will be emailed to you.'
          );
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )

      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportBatchSerialLedgerReport(query: any, format?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';
    query = query + '&format=' + format;
    fileName =
      format === 'CSV'
        ? `Batch Serial Ledger Report.csv`
        : `Batch Serial Ledger Report.xlsx`;

    const endPoint =
      ApiConstants.URL.REPORT.TAX.EXPORT_BATCH_SERIAL_LEDGER_REPORT;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportBatchStatus(payload: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = 'Batch Status.xls';

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_BATCH_STATUS;
    const finalEndPoint = endPoint;
    return http
      .post(`${finalEndPoint}`, payload, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportCreditNotes(format?: string, type?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';
    if (type === 'CREDIT')
      fileName = format === 'PDF' ? `Credit_Note.pdf` : `Credit_Note.xlsx`;
    else {
      fileName = format === 'PDF' ? `Debit_Note.pdf` : `Debit_Note.xlsx`;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_CREDIT_NOTES;
    const query = `?type=${type}&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=&format=${format}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportSalesByProduct(
    format?: string,
    product?: string,
    type?: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName =
      format === 'PDF' ? `Sales By Product.pdf` : `Sales By Product.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_SALES_BY_PROD;
    const query = `?type=SALES&var-start_date=${
      this.apiConfig.StartDate
    }&var-end_date=${
      this.apiConfig.EndDate
    }&var-product_code=${product}&format=${type}${
      this.apiConfig.salesByProductWithSalesOrder ? '&docType=SALES_ORDER' : ''
    }`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportPaymentList(format?: string, contact?: string, type?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = format === 'PDF' ? `Payment List.pdf` : `Payment List.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_PAYMENT_LIST;
    const query = `?type=MAKE&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=${contact}&format=${type}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportPurchaseByContact(
    format?: string,
    contact?: string,
    type?: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName =
      format === 'PDF' ? `PurchaseByContact.pdf` : `Purchase By Contact.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_PURCHASE_BY_CONTACT;
    const query = `?type=PURCHASE&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=${contact}&format=${type}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportPurchaseByProduct(
    format?: string,
    product?: string,
    type?: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName =
      format === 'PDF' ? `Purchase By Product.pdf` : `Purchase By Product.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_PURCHASE_BY_PROD;
    const query = `?type=PURCHASE&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-product_code=${product}&format=${type}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportOrderList(format?: string, contact?: string, type?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = format === 'PDF' ? `Order List.pdf` : `Order List.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_ORDER_LIST;
    const query = `?var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=${contact}&var-status=&format=${type}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportBillList(format?: string, contact?: string, type?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = format === 'PDF' ? `Bill List.pdf` : `Bill List.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_BILL_LIST;
    const query = `?type=PURCHASE&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=${contact}&format=${type}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportReceiptList(format?: string, contact?: string, type?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = format === 'PDF' ? `Receipt List.pdf` : `Receipt List.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_RECEIPT_LIST;
    const query = `?type=RECEIVE&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=${contact}&format=${type}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportSalesByContact(
    format?: string,
    contact?: string,
    type?: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = format === 'PDF' ? `Summary.pdf` : `Summary.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_SALES_BY_CONTACT;
    const query = `?type=SALES&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=${contact}&format=${type}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportInvoices(format: string, status: string, contact: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = format === 'PDF' ? `Invoice.pdf` : `Invoice.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_INVOICES;
    const query = `?type=SALES&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=${contact}&var-status=${status}&format=${format}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportQuotation(format: string, status: string, contact: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = format === 'PDF' ? `Quotation.pdf` : `Quotation.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_QUOTE;
    const query = `?type=SALES&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&var-contact_code=&var-quote_number=&var-status=&format=${format}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportServiceReturnMY(startDate: string, endDate: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = `Service tax return.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_SERVICE_RETURN_MY;
    const query = `?var-start_date=${startDate}&var-end_date=${endDate}&format=XLS`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportSalesReturnMY(startDate: string, endDate: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = `Sales tax return.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_SALES_RETURN_MY;
    const query = `?var-start_date=${startDate}&var-end_date=${endDate}&format=XLS`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportSalesReturn(
    startDate: string,
    endDate: string,
    productName: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = `Sales return.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_SALES_RETURN;
    const query = `?fromDate=${startDate}&productName=${productName}&toDate=${endDate}&format=XLS`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportOutstandingSales(
    startDate: string,
    endDate: string,
    searchString: string,
    selectedArray: any[],
    customField?: string,
    query?: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = `Outstanding Sales.xlsx`;

    let endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_OUTSTANDING_SALES;

    let queryParams = new URLSearchParams();
    if (!Utility.isEmpty(customField)) {
      queryParams.set('customfield', JSON.stringify(customField));
    }
    if (query && !Utility.isEmpty(query)) {
      queryParams.set('query', query);
    }

    let request: any = {
      search: searchString,
      fromDate: startDate,
      toDate: endDate,
      format: 'XLS',
      ids: selectedArray
    };

    const finalEndPoint = endPoint + '?' + `${queryParams ?? queryParams}`;
    return http
      .post(`${finalEndPoint}`, request, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportPurchaseReturn(
    startDate: string,
    endDate: string,
    productName: string
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = `Purchase return.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_PURCHASE_RETURN;
    const query = `?fromDate=${startDate}&productName=${productName}&toDate=${endDate}&format=XLS`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getFailedInvoices(request?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.FAILED_INVOICES;
    const query = `?query=salesInvoiceDate>=${this.apiConfig.StartDate},salesInvoiceDate<=${this.apiConfig.EndDate}&search=${this.apiConfig.Search}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTdsCustomer(search: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.TAX.TDS_CUSTOMER;
    const query = `?search=${search}&var-start_date=${this.apiConfig.StartDate}&var-end_date=${this.apiConfig.EndDate}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getCashflowMap() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.FINANCIAL.CASHFLOW_MAP;

    const finalEndPoint = endPoint;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getGrafanaQuery(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndPoint = ApiConstants.URL.REPORT.SALES.SALES_BY_PRODUCT;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportGstr1(type: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `GSTR-1.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    let endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_GSTR1;
    type == 'EXCEL'
      ? (endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_GSTR1)
      : (endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_GSTR1_E_FILLING);
    const query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, { responseType: 'blob', headers: headers })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportGstr1Sections(type: string, section: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `file.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    let endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_GSTR1_DETAILS;
    let query = `?startDate=${this.apiConfig.StartDate}&endDate=${this.apiConfig.EndDate}&sheetName=${section}&format=${type}`;
    if (this.apiConfig.exemptSupplyType) {
      query = query + '&exemptSupplyType=' + this.apiConfig.exemptSupplyType;
    }
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, { responseType: 'blob', headers: headers })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportPNL(
    format: string,
    cmode: any,
    basis: any,
    presetInfo: any,
    filteredData?: any,
    columnBy?: any,
    exportDetails?: boolean
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let request: any = {
      startDate: this.apiConfig.StartDate,
      endDate: this.apiConfig.EndDate,
      basis: 'ACCRUAL',
      fileFormat: format
    };

    if (exportDetails) {
      request.exportDetails = exportDetails;
    }

    if (cmode.id === 3) {
      request = { vid: 3, vname: '6 Month', basis: basis, fileFormat: 'XLS' };
    }
    if (cmode.id === 2) {
      request = { vid: 2, vname: '3 Years', basis: basis, fileFormat: 'XLS' };
    }
    if (cmode.id === 4) {
      request = {
        vid: 4,
        vname: 'Quarterly',
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (cmode.vname === '12 Month') {
      request = {
        vid: cmode.id,
        vname: '12 Month',
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (columnBy?.id === 999) {
      request = { ...request, isPeriodView: true };
    }
    if (presetInfo !== undefined && presetInfo !== null) {
      request = {
        periods: presetInfo.Periods,
        save: false,
        basis: basis,
        fileFormat: 'XLS'
      };
    }
    if (!Utility.isEmpty(filteredData?.contacts)) {
      request['contacts'] = filteredData?.contacts;
    }
    if (!Utility.isEmpty(filteredData?.products)) {
      request['products'] = filteredData?.products;
    }
    if (!Utility.isEmpty(filteredData?.custom_field)) {
      request['custom_field'] = filteredData?.custom_field;
    }

    if (!Utility.isEmpty(filteredData?.sortDir)) {
      request.sortDir = filteredData?.sortDir;
    }
    let fileName =
      format === 'PDF' ? `PROFIT AND LOSS.pdf` : `PROFIT AND LOSS.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint = ApiConstants.URL.REPORT.TAX.EXPORT_PNL + `-v2`;
    return http
      .post(`${finalEndPoint}`, request, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportTrailBalance(format: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const request1 = {
      fromDate: this.apiConfig.StartDate,
      toDate: this.apiConfig.EndDate,
      basis: this.apiConfig.Basis,
      fileFormat: format,
      customfield: this.apiConfig.CustomField,
      baseCurrency: this.apiConfig.BaseCurrency,
      sortDir: this.apiConfig.sortDir,
      sort: this.apiConfig.sort,
      primaryCurrency: Utility.isEmpty(this.apiConfig.PrimaryCurrency)
        ? 'undefined'
        : this.apiConfig.PrimaryCurrency
    };
    let fileName =
      format === 'PDF' ? `TRIAL BALANCE.pdf` : `TRIAL BALANCE.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint =
      ApiConstants.URL.REPORT.FINANCIAL.EXPORT_TRAIAL_BALANCE;
    return http
      .post(`${finalEndPoint}`, request1, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportBalanceSheet(
    payload: any,
    format: string,
    exportDetails?: boolean
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let request: any = {
      ...payload,
      fileFormat: format
    };

    if (exportDetails) {
      request.exportDetails = exportDetails;
    }

    let fileName =
      format === 'PDF' ? `Balance Sheet.pdf` : `Balance Sheet.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint = ApiConstants.URL.REPORT.TAX.EXPORT_BALANCE_SHEET;
    return http
      .post(`${finalEndPoint}`, request, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        async (response: any) => {
          const responseText = await response.text();
          if (
            typeof responseText === 'string' &&
            responseText.includes(
              'Exported document will be send on your registered email id.'
            )
          ) {
            showToast(
              'Exported document will be send on your registered email id.',
              TOAST_TYPE.SUCCESS
            );
          } else {
            saveAs(response, fileName);
          }
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportGeneralLedger(type: string, queryString: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName =
      type === 'PDF' ? `GENERAL LEDGER.pdf` : `GENERAL LEDGER.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };

    let endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_GENERAL_LEDGER;
    const query = queryString;

    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, { responseType: 'blob', headers: headers })
      .then(
        (response: any) => {
          showToast(
            'Exported document will be send on your registered email id',
            TOAST_TYPE.SUCCESS
          );
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportBankReconciliationReport(data: any) {
    let fileName = '';
    switch (data.exportformat) {
      case 'XLS':
        fileName = `${data.bankname}.xlsx`;
        break;
      case 'CSV':
        fileName = `${data.bankname}.csv`;
        break;
      case 'PDF':
        fileName = `${data.bankname}.pdf`;
        break;
    }
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint =
      ApiConstants.URL.REPORT.FINANCIAL.EXPORT_BANK_RECONCILIATION_REPORT +
      `?accountCode=${data.accountCode}&exportformat=${data.exportformat}&currency=${data.currency}&bankname=${data.bankname}&fromDate=${data.fromDate}`;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportReconciliationLogReport(data: any) {
    let fileName = '';
    if (!Utility.isEmpty(data.accountCode)) {
      switch (data.exportformat) {
        case 'XLS':
          fileName = `${data.accountCode}.xlsx`;
          break;
        case 'CSV':
          fileName = `${data.accountCode}.csv`;
          break;
        case 'PDF':
          fileName = `${data.accountCode}.pdf`;
          break;
      }
    } else {
      switch (data.exportformat) {
        case 'XLS':
          fileName = `RECONCILIATION_LOG_REPORT_ALL.xlsx`;
          break;
        case 'CSV':
          fileName = `RECONCILIATION_LOG_REPORT_ALL.csv`;
          break;
        case 'PDF':
          fileName = `RECONCILIATION_LOG_REPORT_ALL.pdf`;
          break;
      }
    }
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };

    let finalEndPoint = '';

    if (Utility.isEmpty(data.accountCode)) {
      finalEndPoint =
        ApiConstants.URL.REPORT.FINANCIAL.EXPORT_RECONCILIATION_LOG_REPORT +
        `?exportformat=${data.exportformat}&fromDate=${data.fromDate}`;
    } else {
      finalEndPoint =
        ApiConstants.URL.REPORT.FINANCIAL.EXPORT_RECONCILIATION_LOG_REPORT +
        `?exportformat=${data.exportformat}&accountCode=${data.accountCode}&fromDate=${data.fromDate}`;
    }

    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportSOA(format: string, payload: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const request1 = {
      fromDate: this.apiConfig.StartDate,
      asOfDate: this.apiConfig.EndDate,
      details: this.apiConfig.details,
      groupByCurrency: payload.groupByCurrency || false,
      contactCodeWithName: payload.contactCodeWithName || '',
      contact: payload.contact || [],
      page: this.apiConfig.Page?.toString(),
      limit: this.apiConfig.Limit?.toString(),
      fileFormat: format
    };

    let fileName = ``;
    if (!Utility.isEmpty(format)) {
      switch (format) {
        case 'XLS':
          fileName = `STATEMENTS OF ACCOUNT.xlsx`;
          break;
        case 'PDF':
          fileName = `STATEMENTS OF ACCOUNT.pdf`;
          break;
      }
    } else {
      fileName = `STATEMENTS OF ACCOUNT.pdf`;
    }
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint = ApiConstants.URL.REPORT.TAX.EXPORT_SOA;
    return http
      .post(`${finalEndPoint}`, request1, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportSOAVendor(format: string, payload: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const request1 = {
      fromDate: this.apiConfig.StartDate,
      asOfDate: this.apiConfig.EndDate,
      details: this.apiConfig.details,
      groupByCurrency: payload.groupByCurrency || false,
      contactCodeWithName: payload.contactCodeWithName || '',
      contact: payload.contact || [],
      page: this.apiConfig.Page?.toString(),
      limit: this.apiConfig.Limit?.toString(),
      fileFormat: format
    };

    let fileName = ``;
    if (!Utility.isEmpty(format)) {
      switch (format) {
        case 'XLS':
          fileName = `STATEMENTS OF ACCOUNT.xlsx`;
          break;
        case 'PDF':
          fileName = `STATEMENTS OF ACCOUNT.pdf`;
          break;
      }
    } else {
      fileName = `STATEMENTS OF ACCOUNT.pdf`;
    }
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint = ApiConstants.URL.REPORT.TAX.EXPORT_SOA_VENDOR;
    return http
      .post(`${finalEndPoint}`, request1, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateGSTN(payload: any, id: number) {
    return http
      .put(`${ApiConstants.URL.REPORTS_URLS.UPDATE_GST_FILING(id)}`, payload)
      .catch((err: any) => {
        console.error('Error while trying to update GSTN: ', err);
        return Promise.reject(err);
      });
  }

  static updateMapping(payload: any) {
    return http
      .put(`${ApiConstants.URL.REPORTS_URLS.UPDATE_MAPPING}`, payload)
      .catch((err: any) => {
        console.error('Error while trying to update Mapping: ', err);
        return Promise.reject(err);
      });
  }
  static exportTrailBalanceDetails(
    format: string,
    basis: string,
    filteredData?: any
  ) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let request1: any = {
      fromDate: this.apiConfig.StartDate,
      toDate: this.apiConfig.EndDate,
      basis: basis,
      fileFormat: format
    };

    if (!Utility.isEmpty(filteredData?.custom_field)) {
      request1['customfield'] = filteredData?.custom_field;
    }

    let fileName =
      format === 'PDF' ? `TRIAL BALANCE.pdf` : `TRIAL BALANCE.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint =
      ApiConstants.URL.REPORTS_URLS.TRIAL_BALANCE_DETAIL_EXPORT;
    // const finalEndPoint =
    //   ApiConstants.URL.REPORT.FINANCIAL.EXPORT_TRAIAL_BALANCE;
    return http
      .post(`${finalEndPoint}`, request1, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getLandedCostReport(search: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.PURCHASE.LANDED_COST_REPORT;
    const query = `?fromDate=${this.apiConfig.StartDate}&toDate=${this.apiConfig.EndDate}&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getTallyDetailedPurchaseReport(request: any) {
    let finalEndPoint = `${ApiConstants.URL.REPORT.TALLY.DETAILED_PURCHASE_REPORT}?limit=${request.limit}&page=${request.page}`;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportLandedCostReport(format?: string, type?: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName =
      format === 'PDF' ? `Landed_cost_report.pdf` : `Landed_cost_report.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_LANDED_COST;
    const query = `?fromDate=${this.apiConfig.StartDate}&toDate=${this.apiConfig.EndDate}&&limit=25&page=0&format=${type}`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static salesRegisterSummaryReportTally(request: any) {
    const finalEndPoint =
      ApiConstants.URL.REPORT.TALLY.SALES_REGISTERED_SUMMARY_REPORT;
    return http
      .post(`${finalEndPoint}`, request)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportReserveStock(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = '';

    fileName = `Reserve Stock.xlsx`;

    const endPoint = ApiConstants.URL.REPORT.INVENTORY.EXPORT_RESERVE_STOCK;
    let query = `?fromDate=${request.startDate}&toDate=${request.endDate}&limit=25&page=${this.apiConfig.Page}&format=XLS`;
    if (request?.batchSerialCustomField) {
      query += `&batchSerialCustomField=${encodeURIComponent(
        request?.batchSerialCustomField
      )}`;
    }
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static ReservedStockReport(request: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let finalEndPoint = ApiConstants.URL.REPORT.INVENTORY.RESERVED_STOCK;
    let query = `?search=${encodeURIComponent(request.search)}&limit=25&page=${
      this.apiConfig.Page
    }&fromDate=${request.startDate}&toDate=${
      request.endDate
    }&sortDir=asc&serialNo${request?.serialNo}&batchNo${
      request?.batchNo
    }&workOrderSeqCode${request?.workOrderSeqCode}`;
    if (request?.batchSerialCustomField) {
      query += `&batchSerialCustomField=${encodeURIComponent(
        request?.batchSerialCustomField
      )}`;
    }
    finalEndPoint = finalEndPoint + query;

    return http2
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static unreserveDocument(payload: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const endPoint = ApiConstants.URL.REPORT.INVENTORY.UNRESERVE;
    return http
      .post(`${endPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static ReservedStockDetails(request: any) {
    let finalEndPoint =
      ApiConstants.URL.REPORT.INVENTORY.RESERVED_STOCK_DETAILS;
    let query = `?search=${request?.search ?? ''}&limit=10&page=0&fromDate=${
      request.startDate ?? ''
    }&toDate=${request.endDate ?? ''}&sortDir=asc&productCode=${
      request.productId ?? ''
    }&serialNo${request?.serialNo ?? ''}&batchNo${
      request?.batchNo ?? ''
    }&workOrderSeqCode${request?.workOrderSeqCode ?? ''}`;
    if (request?.batchSerialCustomField) {
      query += `&batchSerialCustomField=${encodeURIComponent(
        request?.batchSerialCustomField
      )}`;
    }
    finalEndPoint = finalEndPoint + query;

    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static salesRegisterSummaryReportDetailedTally(request: any) {
    const finalEndPoint =
      ApiConstants.URL.REPORT.TALLY.SALES_REGISTERED_SUMMARY_DETAILED_REPORT +
      `?startDate=${request.startDate}&endDate=${request.endDate}`;
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportTallyReport(payload: any, fileName: string) {
    fileName = fileName + `.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };
    const finalEndPoint = ApiConstants.URL.REPORT.ADDITIONAL.EXPORT;
    return http
      .post(`${finalEndPoint}`, payload, {
        responseType: 'blob',
        headers: headers
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static exportRevaluation(type: string, queryString: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let fileName = type === 'PDF' ? `REVALUATION.pdf` : `REVALUATION.xlsx`;
    const headers = {
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*'
    };

    let endPoint = ApiConstants.URL.REPORT.TAX.EXPORT_GENERAL_LEDGER;
    const query = queryString;

    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, { responseType: 'blob', headers: headers })
      .then(
        (response: any) => {
          showToast(
            'Exported document will be send on your registered email id',
            TOAST_TYPE.SUCCESS
          );
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteBOMAssembly(id: any) {
    const finalEndpoint =
      ApiConstants.URL.REPORT.INVENTORY.BOM_ASSEMBLY_DELETE + id;
    return http2.delete(finalEndpoint).catch((err: any) => {
      console.error('Error deleting stock Issue: ', err);
      return Promise.reject(err);
    });
  }

  static reorderLevelReport(queryParams: string, payload: any): Promise<any> {
    return http.post(
      ApiConstants.URL.REPORTS_URLS.MRP_REPORTS.REORDER_LEVEL_REPORT +
        `?${queryParams}`,
      payload
    );
  }

  static postSoaPrintTemplateEmail(payload: any) {
    return http
      .post(`${ApiConstants.URL.PRINT.SOA_PRINT_TEMPLATE_EMAIL}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static postReportPrintEmail(payload: any) {
    return http
      .post(`${ApiConstants.URL.PRINT.REPORT_PRINT_TEMPLATE_EMAIL}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default NewReportService;
